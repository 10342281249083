@import "_font";
@import "_var";
@import "_mixin";
@import "_type";
@import "_coms";
@import "_init";
@import "_form";
@import "_loading";
@import "_fordev";
/**

PAGES

*/
@import "./pages/_header";
@import "./pages/_footer";
@import "./pages/home";
@import "./pages/home-ad";
@import "./pages/products";
@import "./pages/product-single";
@import "./pages/about-contact";
@import "./pages/blog";
@import "./pages/recipes";
@import "./pages/recipes-single";
@import "./pages/education";
@import "./pages/account";
@import "./pages/checkout";
//@import "./pages/checkout-success";
@import "./pages/stockist";
@import "./pages/cart";
@import "./pages/health-program";
@import "./pages/health-hub";
@import "./pages/health-hub-new";
@import "./pages/appointment";
@import "./pages/appointment-45";
@import "./pages/form-subscription";
@import "./pages/testimonials";
@import "./pages/payment";
@import "./pages/general-template";
@import "./pages/competition";
@import "./pages/referral";
@import "./pages/dashboard";