/* VAR */
$green: #62bd89;
$green-light: #eaf3bd;
$green-dark: #0F5B25;
$green-darken: #445B5A;
$green-2: #74c597;
$gray-light: #fafafa;
$gray-medium: #f4f2ed;
$gray-1: #dedede;
$gray-2: #fdfcf9;
$gray-3: #979797;
$border: #e8e6de;
$orange: #ff9f1c;
$blue-light: #c4d8e5;
$white: #fff;
$black: #000;
$rem: 1 / 2000 * 100vw;
$red: #eb6161;
$checkmark: url("../img/checkmark.svg");
$black: #000;
$white: #fff;
$gray-l: #f3f3f3;
$lock: url("../img/lock.svg");
$font-sub-body: "Circular Std";
$font-sub-heading: "Avenir Next";
$orange: #cf830b;
$paypal: url("../img/paypal.png");
$blue-dark: #001f3f;
$blue-1: rgb(6, 7, 7);
$blue-dark: #001f3f;
$blue-light: #acd8f3;
$blue-lighter: #ebf6fe;
$blue-team: #e8f6ff;
$blue: #00b2cd;
$gray-lighter: #eeeeee;
/* FONT */
$font-Cir: "Circular Std";
$font-Av: "Avenir Next";
$shadow: 0px 2px 40px rgba(#000, 0.383492);
/* BACKGROUND IMAGE */
$icon_check: url("../img/icon-check.svg");
$icon_check_g: url("../img/icon-check-green-small.svg");
$icon_face: url("../img/smile-icon.svg");
$icon_phone: url("../img/phone-icon.svg");
$icon_skype: url("../img/skype-icon.svg");
$icon_face_w: url("../img/smile-icon-white.svg");
$icon_phone_w: url("../img/phone-icon-white.svg");
$icon_skype_w: url("../img/skype-icon-white.svg");
$edit_icon: url("../img/edit-icon.svg");
$close_modal: url("../img/close-modal.svg");
$icon_blockquote: url("../img/icon-blockquote.svg");
