/*---------------------------------
**** Objects | Forms
-----------------------------------*/
// Settings
$input-font-size: 16px !default;
$input-color: black !default;
$input-placeholder-color: rgba(0, 0, 0, 0.3) !default;
$input-height: 70px !default;
$input-border-radius: 0 !default;
$input-border-color: black !default;
$input-border-color-focus: black !default;
$input-border-width: thin !default;
$input-padding-y: 4px !default;
$input-padding-x: 23px !default;

$textarea-height: 120px !default;

$form-row-spacing: 35px !default;
$form-col-spacing: 40px !default;

$custom-control-size: 32px !default;
$custom-control-dot-size: 22px !default;
$custom-control-border-radius: 0 !default;
$custom-control-gap: 22px !default;
$custom-control-font-size: 18px !default;
$custom-control-font-weight: bold !default;
$custom-control-line-height: (21/18) !default;
$custom-control-border-color: black !default;
$custom-control-border-color-active: black !default;
$custom-control-top: ceil(($custom-control-font-size * $custom-control-line-height - $custom-control-size) / 2) + 7px !default;

$select-option-padding-y: 12px !default;
$select-option-padding-x: 35px !default;
$select-option-font-size: 14px !default;
$select-option-background-color: #f9f9f9 !default;
$select-option-background-color-hover: white;
$select-option-background-color-selected: #f0f0f0 !default;
$select-arrow-width: 11px !default;
$select-arrow-spacing: 25px !default;
$select-arrow-icon: "data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5.5 5.5L10 1' stroke='black'/%3E%3C/svg%3E%0A" !default;

$validate-message-color: red !default;
$validate-message-font-size: 10px !default;
$validate-message-spacing: 6px !default;

// Form float label
$form-float: false !default;
$form-float-label-font-size: 8px !default;
$form-float-input-padding-top: 14px !default;

// Common
%form-group {
    margin-bottom: $form-row-spacing;
    position: relative;
}

%form-label {
    font-family: $font-Cir;
    font-size: 18px;
    font-weight: bold;
    line-height: (21/18);
    color: black;
    vertical-align: middle;

    @if ($form-float == false) {
        margin-bottom: 12px;
    } @else {
        position: absolute;
        top: ceil(($input-height - $input-font-size)/2);
        left: 0;
        width: 100%;
        padding: 0 $input-padding-x;
        margin-bottom: 0;
        pointer-events: none;
        transition: all 0.2s ease;

        &.freeze,
        .has-select & {
            top: ceil(($input-height - $input-font-size)/4);
            font-size: $form-float-label-font-size;
        }
    }
}

%form-control {
    display: block;
    width: 100%;
    height: $input-height;
    background-color: transparent;
    border-radius: $input-border-radius;
    border-style: solid;
    border-color: $input-border-color;
    border-width: $input-border-width;
    font-family: inherit;
    font-size: $input-font-size;
    line-height: inherit;
    color: $input-color;
    outline: none;
    @include reset-autofill;

    &:focus {
        box-shadow: none;
        border-color: $input-border-color-focus;
    }

    .ven-form-solid & {
        background-color: white;
        border-color: white;
    }

    @if ($form-float == false) {
        padding: $input-padding-y $input-padding-x;

        &::placeholder {
            color: $input-placeholder-color;
        }
    } @else {
        padding: $form-float-input-padding-top $input-padding-x 0;

        &::placeholder {
            color: transparent;
        }
    }
}

%form-textarea {
    resize: none;

    @if ($form-float == false) {
        padding: $input-padding-x;
    } @else {
        padding: ceil(($input-height - $input-font-size * 0.5) / 2) $input-padding-x $input-padding-x;

        &::placeholder {
            color: transparent;
        }
    }
}

%form-custom-control {
    display: block;
    padding-left: $custom-control-size + $custom-control-gap;
    position: relative;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: $form-col-spacing;

    label {
        font-family: $font-Cir;
        font-size: $custom-control-font-size;
        font-weight: $custom-control-font-weight;
        line-height: $custom-control-line-height;
        margin-bottom: 0;
        cursor: pointer;
        user-select: none;

        &::before {
            content: "";
            display: block;
            width: $custom-control-size;
            height: $custom-control-size;
            position: absolute;
            top: $custom-control-top;
            left: 0;
            border: thin solid $custom-control-border-color;
            border-radius: $custom-control-border-radius;
        }

        &::after {
            content: "";
            display: block;
            width: $custom-control-dot-size;
            height: $custom-control-dot-size;
            background-color: $custom-control-border-color-active;
            position: absolute;
            top: ($custom-control-size - $custom-control-dot-size) / 2 + $custom-control-top;
            left: ($custom-control-size - $custom-control-dot-size) / 2;
            opacity: 0;
        }
    }

    input {
        display: none;

        &:checked {
            & + label {
                &::after {
                    opacity: 1;
                }

                &::before {
                    border-color: $custom-control-border-color-active;
                }
            }
        }

        &[type="radio"] {
            & + label {
                &::before,
                &::after {
                    border-radius: 50%;
                }
            }
        }
    }
}

%form-validate-message {
    display: block;
    font-size: $validate-message-font-size;
    color: $validate-message-color;
    margin-top: $validate-message-spacing;
}

// Gravity form
.ven-competition-form {
    .gform_wrapper {
        .validation_error {
            display: none;
        }
    }

    .gform_fields {
        @include reset-list;
        display: flex;
        flex-wrap: wrap;
        margin: 0 (-$form-col-spacing/2);

        .validation_message {
            @extend %form-validate-message;
        }
    }

    .gfield {
        @extend %form-group;
        width: calc(100% - #{$form-col-spacing});
        margin-left: ($form-col-spacing/2);
        margin-right: ($form-col-spacing/2);

        &.medium {
            @include tablet-up {
                width: calc(50% - #{$form-col-spacing});
            }
        }

        &.hidden_label {
            .gfield_label {
                display: none;
            }
        }

        &.gsection {
            &:not(:first-child) {
                margin-top: 35px;
            }
        }

        &.field_list_dot {
            > .gfield_label {
                font-size: 24px;
                font-weight: normal;
                position: relative;
                margin-bottom: $form-row-spacing;

                &::before {
                    content: "";
                    display: inline-block;
                    vertical-align: middle;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: currentColor;
                    margin-right: 17px;

                    @include desktop-up {
                        position: absolute;
                        top: (8/24 + em);
                        right: 100%;
                    }
                }
            }
        }
    }

    .gfield_label {
        @extend %form-label;
    }

    .ginput_container {
        input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
        select:not([multiple="multiple"]) {
            @extend %form-control;

            .gfield_error & {
                border-color: $validate-message-color !important;
            }
        }

        textarea {
            @extend %form-control;
            @extend %form-textarea;
            height: $textarea-height;

            .gfield_error & {
                border-color: $validate-message-color !important;
            }
        }
    }

    // Button
    .gform_button {
        display: block;
        width: 100%;
        border: none;
        appearance: none;
        padding: 20px;
        background-color: black;
        color: white;
        font-family: $font-Cir;
        font-size: 16px;
        letter-spacing: 2px;
        text-transform: uppercase;
        cursor: pointer;
    }

    // Select box
    .select2-container--default {
        .select2-selection--single {
            @extend %form-control;
            padding: 0;
            border-bottom-left-radius: $input-border-radius !important;
            border-bottom-right-radius: $input-border-radius !important;

            .select2-selection {
                &__rendered {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 100%;
                    line-height: 1;
                    padding-left: $input-padding-x;
                    padding-right: $input-padding-x * 2;

                    @if ($form-float) {
                        padding-top: $form-float-input-padding-top;
                    }
                }

                &__arrow {
                    width: auto;
                    height: 100%;
                    top: 0;
                    right: $select-arrow-spacing;

                    b {
                        top: 0;
                        left: 0;
                        width: $select-arrow-width;
                        height: 100%;
                        background-image: url($select-arrow-icon);
                        background-repeat: no-repeat;
                        background-size: 100% auto;
                        background-position: 50% 50%;
                        border: none;
                        margin: 0;
                    }
                }
            }
        }

        .select2-search--dropdown {
            .select2-search {
                &__field {
                    outline: none;
                    border-color: $input-placeholder-color;
                    border-radius: $input-border-radius;
                    padding-left: $input-padding-x;
                    padding-right: $input-padding-x;
                }
            }
        }

        .select2-results {
            &__option {
                font-size: $select-option-font-size;
                padding: $select-option-padding-y $select-option-padding-x;

                &:focus {
                    outline: none;
                }

                &--highlighted {
                    &[aria-selected] {
                        background-color: $select-option-background-color-hover;
                        color: $input-color;
                    }
                }

                &--selected {
                    background-color: $select-option-background-color-selected;
                }
            }
        }
    }

    .select2-dropdown {
        background-color: $select-option-background-color;
        border: $input-border-width solid $input-border-color !important;
        border-radius: $input-border-radius !important;
        overflow: hidden;

        &--below {
            .select2-container--open & {
                margin-top: 2px;
            }
        }

        &--above {
            .select2-container--open & {
                margin-top: -2px;
            }
        }
    }

    // Checkbox and Radio
    .gfield_checkbox,
    .gfield_radio {
        @include reset-list;
        display: flex;
        flex-wrap: wrap;

        li {
            width: 100%;
            margin-bottom: 30px;
            @extend %form-custom-control;

            @include desktop-up {
                width: 50%;
            }
        }
    }

    .ginput_container_consent {
        @extend %form-custom-control;

        label {
            font-weight: normal;
        }
    }

    .gsection_title {
        font-family: $font-Cir;
        font-size: 28px;
        font-weight: bold;
        line-height: 1.1;

        @include desktop-up {
            font-size: 36px;
        }
    }
}

// Ven form
%form-custom-ctr {
    display: block;
    position: relative;
    font-size: 14px;

    > label {
        vertical-align: middle;
        padding-left: 24px;
        margin-bottom: 0;
        cursor: pointer;
        user-select: none;

        &::before {
            content: "";
            display: block;
            width: 14px;
            height: 14px;
            border: thin solid #000;
            position: absolute;
            top: 3px;
            left: 0;
            background-repeat: no-repeat;
            background-position: 50% 50%;
        }
    }

    > input {
        display: none;

        &[type="checkbox"] {
            &:checked + label:before {
                background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.5L3.77551 6.33333L9 1' stroke='%23000' stroke-width='2'/%3E%3C/svg%3E%0A");
                background-size: 10px auto;
            }
        }

        &[type="radio"] {
            & + label {
                &::before {
                    border-radius: 50%;
                }
            }

            &:checked + label:before {
                background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='4' cy='4' r='4' fill='black'/%3E%3C/svg%3E%0A");
                background-size: 10px auto;
            }
        }
    }
}

.ven-form-group {
    position: relative;
    margin-top: 10px;

    & + .ven-form-group {
        margin-top: 15px;
    }

    &--error {
        .ven-form-control {
            border-color: $red;
        }
    }

    &.has-select {
        .ven-form-label {
            position: static;
            font-size: 10px;
        }
    }

    select {
        display: block;
        width: 100%;
        border-radius: 0;
        border-color: #cdcdcd;
        border-width: 0 0 thin;
        padding: 8px 0;
        outline: none;
    }
}

.ven-form-label {
    margin-bottom: 0;
    position: absolute;
    top: 8px;
    color: rgba($color: #000000, $alpha: 0.35);
    pointer-events: none;
    transition: all 0.2s ease-out;

    @include tablet-up {
        font-size: 14px;
    }

    &.freeze {
        top: -10px;
        font-size: 10px;
        color: #000000;
    }
}

.ven-form-control {
    display: block;
    width: 100%;
    border-radius: 0;
    border-color: #cdcdcd;
    border-style: solid;
    border-width: 0 0 thin;
    padding: 8px 0;
    background-color: transparent;
    outline: none;

    &::placeholder {
        color: transparent;
    }

    @include tablet-up {
        font-size: 14px;
    }

    &.invalid {
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.9021L4.12245 7.0896L10 1.0896' stroke='%2333322B' stroke-width='2'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 11px auto;
        background-position: right center;
    }
}

.invalid-message {
    font-size: 10px;
    color: $red;
}

.ven-form-message {
    font-size: 10px;
    margin-top: 5px;
}

.ven-custom-control {
    @extend %form-custom-ctr;

    &--extend {
        > input {
            & ~ div {
                margin-top: 20px;
                display: none;
            }

            &:checked ~ div {
                display: block;
            }
        }
    }

    &--narrow {
        > input {
            & ~ div {
                margin-top: 20px;
            }

            &:checked ~ div {
                display: none;
            }
        }
    }
}