.ven-competition-intro {
    > .container-fluid {
        padding-left: 0;
        padding-right: 0;

        > .row {
            @media (min-width: 992px) {
                flex-direction: row-reverse;
            }
        }
    }

    &__content {
        font-family: $font-Cir;
        padding: 40px 15px 50px;

        @include desktop-up {
            padding: 85px 0;
            background: url(../img/competition-background.png) no-repeat 50% 0;
            background-size: 1067px auto;
            min-height: 910px;
        }
    }

    &__wrapper {
        max-width: 664px;
        margin-left: auto;
        margin-right: auto;
    }

    &__title {
        font-weight: 900;
        line-height: 1.1;
        letter-spacing: -0.521739px;
        margin-bottom: 30px;

        @include desktop-up {
            font-size: 78px;
            max-width: 570px;
        }
    }

    &__price {
        margin-bottom: 47px;

        &-label {
            font-size: 14px;
            font-weight: 900;
            letter-spacing: (3/14 + em);
            text-transform: uppercase;
            margin-bottom: 10px;
        }

        &-value {
            font-size: 80px;
            font-weight: 900;
            line-height: 1;
            letter-spacing: (26/100 + em);
            color: $green;
            text-shadow: 6px 7px #231f20;

            @include desktop-up {
                font-size: 100px;
            }
        }
    }

    &__description {
        font-size: 22px;
        line-height: (28/22);
        letter-spacing: -0.195652px;

        p {
            margin-bottom: 26px;
        }
    }

    &__more {
        margin-top: 40px;

        .btn-common {
            font-size: 16px;
            max-width: 100%;

            @include desktop-up {
                display: inline-block;
                max-width: none;
            }

            .pos {
                padding-left: 20px;
                padding-right: 20px;

                @include desktop-up {
                    padding-left: 60px;
                    padding-right: 60px;
                }
            }
        }
    }

    &__image {
        height: 50vh;

        @media (min-width: 992px) {
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.ven-competition-form {
    background-color: $gray-medium;
    padding: 50px 0;

    @include desktop-up {
        padding: 110px 0;
    }

    &__title {
        font-family: $font-Cir;
        font-weight: 900;
        font-size: 48px;
        line-height: (50/48);
        letter-spacing: -0.521739px;
    }

    .btn-white.login-fb {
        font-family: $font-Cir;
        font-size: 15px;
        font-weight: normal;
        letter-spacing: 1.875px;
        margin-bottom: 0;
        text-transform: uppercase;
        height: 70px;

        i {
            margin-right: 15px;
        }
    }
}

.ven-scroll-section {
    position: relative;
}

.ven-scroll-target {
    position: absolute;
    top: -60px;
    left: 0;
}