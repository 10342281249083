header.header-page {
    position: fixed;
    top: var(--offset-top);
    left: 0;
    width: 100%;
    height: 60px;
    display: block;
    background-color: #FFF;
    z-index: 9999;
    display: flex;
    align-items: center;

    @media (max-width: 600px) {
        body.admin-bar:not(.admin-bar--hidden) & {
          position: absolute;
        }
    }

    .wrap-content-mobile {
        flex: 1;
    }

    @include tablet-down {
        // border-bottom: 1px solid $gray-medium;
        padding: 0 15px;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background-color: #FFF;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
        .btn-menu-sp {
            position: relative;
            width: 30px;
            height: 20px;
            z-index: 6;
            margin-left: 30px;
            cursor: pointer;
            @include print {
                display: none !important;
            }
            &.expand {
                &:before {
                    transform: rotate(45deg);
                    top: 10px;
                }
                &:after {
                    transform: rotate(-45deg);
                    top: 10px;
                }
                span {
                    transform: scale(1.1) translateX(10px);
                    opacity: 0;
                }
            }
            &:after,
            &:before {
                content: '';
                position: absolute;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: $green;
                transition: all 0.5s ease;
            }
            &:after {
                top: 0;
            }
            &:before {
                bottom: 0;
            }
            span {
                position: relative;
                display: block;
                top: 10px;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: $green;
                transform: translateY(-50%);
                transition: all 0.5s ease;
            }
        }
        .wrap-content-mobile {
            background-color: #FFF;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            transform: translate(0, -100%);
            transition: all .8s cubic-bezier(.43, .195, .02, 1);
            overflow-y: auto;

            .wrap-inner {
                padding-top: calc(100px + var(--offset-top));
                padding-bottom: 30px;
                min-height: 100%;
                display: flex;
                flex-direction: column;

                .sidebar-left {
                    margin-top: auto;
                }
            }

            &.expand {
                transform: translate(0, 0);
            }
        }
    }
    @include print {
        position: relative !important;
        .wrap-content-mobile {
            margin-top: 0;
            min-height: 100%;
            transform: none;
            padding-top: 0;
            z-index: 5;
        }
    }
    .container-fluid {
        @include tablet-up {
            padding-right: 60px;
            padding-left: 60px;
        }
    }
    .row {
        align-items: center;
    }
    .row-header {
        align-items: flex-start;
    }
    ul.menu-top {
        font-family: $font-Cir;
        font-weight: 500;
        @include tablet-up {
            display: flex;
        }
        @include print {
            display: none;
        }
        &.right {
            justify-content: flex-end;
        }
        > li {
            // overflow: hidden;
            position: relative;
            @include tablet-up {
                &:not(:last-child) {
                    margin-right: 35px;
                    @include big-desktop {
                        margin-right: 35 * $rem;
                    }
                }
                &.login {
                    margin-right: 0;
                }
            }
            @include tablet-down {
                display: block;
            }
            @include tablet-up {
                &:after {
                    content: '';
                    position: absolute;
                    top: 56px;
                    left: 0;
                    width: 100%;
                    height: 3px;
                    background-color: #000;
                    opacity: 0;
                    transform-origin: center;
                    transform: scaleX(0);
                    transition: all 0.5s ease;
                    z-index: 2;
                    @include big-desktop {
                        height: 3 * $rem;
                    }
                }
            }
            @include print {
                &:not(:last-child) {
                    margin-right: 35px;
                }
            }
            &.current-menu-item,
            &:hover {
                &:after {
                    opacity: 1;
                    transform-origin: center;
                    transform: scaleX(1);
                }
            }
            &.current-menu-item {
                a {
                    font-weight: 700;
                }
            }
            .dropdown-carret {
                display: inline-block;
                width: 5px;
                height: 5px;
                border-left: 1px solid $green;
                border-bottom: 1px solid $green;
                transform: rotate(-45deg);
                vertical-align: middle;
                margin-left: 2px;
            }
            > a {
                display: inline-block;
                padding: 20px 0;
            }
            // &.has-sub-menu {
            //     a {
            //         @include tablet-down {
            //             padding: 20px 0 5px;
            //         }
            //     }
            // }
        }
    }
    .logo {
        transition: all 0.5s ease;
        z-index: 6;
        margin-right: auto;
        @include tablet-up {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
        @include print {
            left: 50%;
            transform: translateX(-50%);
        }
        img {
            max-width: 110px;
        }
    }

    .dropdown-menu {
        border-radius: 0;
        border-color: #000;
        margin-top: -12px;
        text-transform: capitalize;

        @include tablet-up {
            display: block;
            visibility: hidden;
            opacity: 0;
            transition: all 0.2s ease-in-out;
        }
    }

    .dropdown-toggle {
        &::after {
            width: 12px;
            height: 7px;
            background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='black'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            border: none;
            vertical-align: 0.1em;
        }
    }

    .dropdown-item {
        padding: 10px 20px;

        &:hover {
            background-color: transparent;
            color: $green;
        }
    }

    .dropdown {
        @include tablet-up {
            &:hover {
                .dropdown-menu {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}
.row-custom {
    display: flex;
    align-items: center;
    @include tablet-up {
        justify-content: flex-end;
        padding-right: 25px;
    }
}
.sidebar-left {
    padding-right: 15px;
    padding-left: 15px;

    @include tablet-up {
        width: 60px;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        background-color: #FFF;
        z-index: 5;
        padding-right: 0;
        padding-left: 0;
    }
    @include big-desktop {
        width: 60 * $rem;
    }
    @include print {
        display: none;
    }
    .list-social {
        position: relative;
        display: flex;
        padding: 20px 0;
        justify-content: flex-start;
        @include tablet-up {
            justify-content: center;
            position: absolute;
            top: 55%;
            left: 30px;
            transform: rotate(-90deg) translateY(-50%);
            transform-origin: 0 0;
        }
        @include big-desktop {
            padding: 20 * $rem 0;
            left: 30 * $rem;
        }
        li {
            position: relative;
            transition: all 0.5s ease;
            padding-left: 20px;
            &:not(:last-child) {
                margin-right: 25px;
            }
            @include tablet-up {
                margin: 0 20px;
                padding-left: 0;
                font-size: 14px;
                &:hover {
                    padding-left: 20px;
                    @include big-desktop {
                        padding-left: 20 * $rem;
                    }
                    &:before {
                        opacity: 1;
                    }
                }
            }
            @include big-desktop {
                margin: 0 20 * $rem;
                font-size: 14 * $rem;
            }
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 2px;
                width: 16px;
                height: 16px;
                transition: all 0.5s ease;
                @include big-desktop {
                    top: 2 * $rem;
                    width: 16 * $rem;
                    height: 16 * $rem;
                }
                @include tablet-up {
                    opacity: 0;
                }
            }
            &.ins {
                &:before {
                    background: url(../img/icon-ins.svg) no-repeat center/ auto 100%;
                }
            }
            &.fb {
                &:before {
                    background: url(../img/icon-fb.svg) no-repeat center/ auto 100%;
                }
            }
        }
    }
    a {
        font-family: $font-Cir;
        font-size: 16px;
        @include big-desktop {
            font-size: 16 * $rem;
        }
    }
    .list-mobile-contact {
        li {
            margin: 5px 0;
        }
    }
}
.header-btn-cart {
    position: relative;
    width: 25px;
    height: 20px;
    margin-left: 10px;
    z-index: 2;
    background: url(../img/cart.svg) no-repeat center /auto 100%;

    &.mobile-show {
        @include tablet-up {
            display: none;
        }
    }
    &.desktop-show {
        @include tablet-down {
            display: none;
        }
    }
    &:before {
        content: '';
        position: absolute;
        top: 12px;
        right: 0;
        width: 75px;
        height: 390px;
        transform: translateY(-50%);
        display: none;
    }
    @include tablet-up {
        width: 40px;
    }
    @include big-desktop {
        width: 40 * $rem;
        height: 20 * $rem;
    }
    &:hover {
        &>span {
            transform: scale(1.3);
        }
    }
    &>span {
        font-family: $font-Cir;
        position: absolute;
        top: -5px;
        right: -5px;
        background-color: $green;
        border-radius: 50%;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFF;
        width: 16px;
        height: 16px;
        text-align: center;
        transition: all 0.3s ease;
        @include big-desktop {
            width: 16 * $rem;
            height: 16 * $rem;
            font-size: 10 * $rem;
        }
        @include small-mobile {
            right: -10px;
        }
    }
    .cart_price {
        del {
            display: none;
        }
        ins {
            text-decoration: none;
        }
    }
}
.sidebar-right {
    @include tablet-up {
        background-color: #FFF;
        position: fixed;
        height: 100%;
        z-index: 5;
        width: 60px;
        top: 0;
        right: 0;
    }
    @include big-desktop {
        width: 60 * $rem;
    }
    @include print {
        display: none !important;
    }
    .btn-cart {
        position: fixed;
        top: 20px;
        right: 60px;
        width: 25px;
        height: 20px;
        z-index: 2;
        background: url(../img/cart.svg) no-repeat center /auto 100%;
        &:before {
            content: '';
            position: absolute;
            top: 12px;
            right: 0;
            width: 75px;
            height: 390px;
            transform: translateY(-50%);
            display: none;
        }
        @include tablet-up {
            position: absolute;
            width: 40px;
            top: 50%;
            right: 20%;
            transform: translateY(-50%);
        }
        @include big-desktop {
            width: 40 * $rem;
            height: 20 * $rem;
        }
        &:hover {
            &>span {
                transform: scale(1.3);
            }
        }
        &>span {
            font-family: $font-Cir;
            position: absolute;
            top: -5px;
            right: -5px;
            background-color: $green;
            border-radius: 50%;
            font-size: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFF;
            width: 16px;
            height: 16px;
            text-align: center;
            transition: all 0.3s ease;
            @include big-desktop {
                width: 16 * $rem;
                height: 16 * $rem;
                font-size: 10 * $rem;
            }
            @include small-mobile {
                right: -10px;
            }
        }
        .cart_price {
            del {
                display: none;
            }
            ins {
                text-decoration: none;
            }
        }
    }
    .icon-x {
        position: fixed;
        top: 10px;
        right: 60px;
        z-index: 2;
        display: none;
        @include tablet-up {
            position: absolute;
            top: 50%;
            right: 15%;
            transform: translateY(-50%);
        }
        @include tablet-down {
            display: none !important;
        }
    }
    /*BAG HOVER AREA*/
    @at-root .bag-hover {
        opacity: 0;
        visibility: hidden;
        transition: all .5s ease;
        position: fixed;
        background: #fff;
        right: 0;
        top: 0;
        z-index: 10000;
        width: 100%;
        height: 100%;
        text-align: left;
        transform: translateX(20%);
        box-shadow: -8px 4px 28px rgba(0, 0, 0, 0.1);
        @include tablet-up {
            width: 45%;
        }
        &:before {
            border-color: rgba(255, 255, 255, 0);
            border-left-color: #fff;
            border-width: 11px;
            margin-top: -11px;
        }
        &:after {
            border-color: rgba(255, 255, 255, 0);
            border-left-color: #fff;
            border-width: 5px;
            margin-top: -5px;
        }
        .ul-cart {
            display: flex;
            width: 100%;
            line-height: 21px;
            font: 700 16px/1.4em $font-Cir;
            letter-spacing: 0.5px;
            margin-bottom: 20px;
            position: relative;
            justify-content: space-between;
            height: 61px;
            align-items: center;
            padding: 0 20px;
            border-bottom: 1px solid $green;
            @include mobile-up {
                justify-content: flex-start;
            }
            @include tablet-up {
                padding: 0 50px;
            }
            &:after {
                content: '';
                bottom: 0;
                left: 0;
                position: absolute;
                width: 100%;
                height: 1px;
                background: $green;
            }
            &.empty {
                justify-content: flex-end;
            }
            .name {
                min-width: 50%;
                @include mobile-up {
                    margin-bottom: 0;
                }
            }
            .quanity {
                text-align: center;
                @include max-width-center(100px);
                @include mobile-up {
                    min-width: 25%;
                    margin-bottom: 0;
                    margin-left: 0;
                    width: auto;
                }
            }
            .price {
                min-width: 20%;
                text-align: center;
            }
            .delete {
                text-align: right;
                min-width: 5%;
            }
        }
        .wrap-empty {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            text-align: center;
            .cart-text {
                margin-bottom: 10px;
            }
            .btn-common {
                margin-left: auto;
                margin-right: auto;
            }
        }
        .btn-cart {
            @include tablet-up {
                @include mobile-up {
                    display: block;
                    min-width: 10%;
                }
            }
            .price {
                text-align: right;
                order: 2;
                min-width: 30%;
                @include mobile-up {
                    order: 4;
                    min-width: 10%;
                }
            }
            &.opened {
                @include tablet-down {
                    .bag-hover {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
        .header-total {
            background: $gray-medium;
            font-weight: 700;
            font-size: 14px;
            width: 100%;
            display: flex;
            padding: 12px 20px;
            justify-content: space-between;
            align-items: center;
            font: 700 20px/ normal $font-Cir;
            margin-bottom: 10px;
        }
        .header-viewbag {
            @include reset-input;
            background: transparent;
            color: #000;
            border: 1px solid #000;
            font: 500 14px/ 1.2em $font-Cir;
            text-transform: uppercase;
            height: 50px;
            letter-spacing: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
                opacity: .7;
            }
        }
        .header-checkout {
            @include reset-input;
            background: $green;
            color: #fff;
            font: 500 14px/ 1.2em $font-Cir;
            text-transform: uppercase;
            height: 50px;
            letter-spacing: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
                opacity: .7;
            }
        }
        .wrap-cart-panel-bottom {
            position: absolute;
            bottom: 15px;
            right: 15px;
            left: 15px;
            @include tablet-up {
                bottom: 30px;
                right: 50px;
                left: 50px;
            }
            .row {
                margin-left: 0;
                margin-right: 0;
            }
            .coupon {
                width: 100%;
                margin-bottom: 10px;
                input {
                    width: 100%;
                    border-color: $gray-medium;
                    font-family: $font-Cir;
                    height: 48px;
                    margin-left: 0;
                    padding: 0 100px 0 20px;
                    @include placeholder {
                        color: #DCDCDC;
                    }
                }
                button[type=submit] {
                    height: 44px;
                    padding: 5px 15px;
                }
            }
            .row-cart-buttons {
                width: 100%;
                display: flex;
                justify-content: space-between;
                a {
                    width: 49%;
                }
            }
        }
    }
    .row-cart-buttons {
        @include space-col(5px);
    }
}
.bag-hover {
    &.opened {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
    }
}
.header-cart-list {
    margin-bottom: 15px;
    max-height: calc(100vh - 350px);
    overflow-x: hidden;
    &::-webkit-scrollbar-track {
        background-color: $green;
    }
    &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $green;
    }
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar {
        background-color: #DDDDDD;
    }
    .scrollbar-macosx {
        padding-right: 0 !important;
    }
    &>li {
        margin-right: 20px;
        margin-left: 20px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #EBEBEB;
        position: relative;
        .wrap-cart-item-information {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .note-onbackorder-item {
            font-size: 14px;
            @include tablet-down {
                font-size: 13px;
                margin-top: 15px;
            }
            &.version-mobile {
                @include tablet-up {
                    display: none;
                }
            }
            &.version-desktop {
                @include tablet-down {
                    display: none;
                }
            }
        }
        @include tablet-up {
            margin-right: 50px;
            margin-left: 50px;
            justify-content: flex-start;
        }
        &:last-child {
            border-bottom: 0;
            margin-bottom: 0;
            padding-bottom: 0;
        }
        .header-cart-img {
            width: 40px;
            flex: 0 0 40px;
            margin-right: 15px;
            @include small-mobile-up {
                width: 70px;
                flex: 0 0 70px;
            }
        }
        .header-cart-info {
            width: calc(100% - 100px);
            h5 {
                font-size: 14px;
                font-weight: 700;
                line-height: 1.2em;
                margin-bottom: 2px;
                font-family: $font-Cir;
                @include small-mobile-up {
                    font-size: 16px;
                }
            }
        }
        .wrap-infor-image-title {
            display: flex;
            align-items: center;
            min-width: 50%;
        }
        .header-cart-quanity {
            text-align: right;
            margin-left: auto;
            margin-right: auto;
            @include mobile-up {
                text-align: center;
                min-width: 25%;
            }
            &.item-qty {
                width: 100px;
                @include mobile-up {
                    width: auto;
                }
            }
            .qty-holder {
                text-align: center;
                @include small-mobile {
                    width: 80px;
                }
                input {
                    width: 40px;
                    border: 1px solid #EDEDED;
                    @include small-mobile {
                        width: 30px;
                    }
                }
                .square-plus,
                .square-minus {
                    background-color: transparent;
                }
                .square-minus {
                    &:after {
                        content: '-';
                    }
                }
                .square-plus {
                    color: $green;
                }
            }
        }
        .delete-header-cart {
            cursor: pointer;
            width: 8px;
            height: 8px;
            background: transparent url('../img/cart-close.svg') right/contain no-repeat;
            opacity: 0.2;
            min-width: 5%;
            @include small-mobile {
                padding-top: 10px;
            }
            &:before {
                @include mobile-up {
                    display: none;
                }
            }
            @include mobile-up {
                text-align: right;
            }
            &:hover {
                opacity: .7;
            }
            @include break-min(2000px) {
                width: 15 * $rem;
                height: 15 * $rem;
            }
        }
        .header-cart-price {
            text-align: center;
            font: 500 14px/19px $font-Cir;
            margin: 3px 0;
            min-width: 20%;
            @include small-mobile-up {
                font-size: 16px;
            }
        }
    }
}
.side-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    display: block;
    background-color: #FFF;
    z-index: 5;
    transform: translate3d(0, 0, 0);
    @include mobile-up {
        transition: all 0.5s ease;
    }
    @include big-desktop {
        height: 60 * $rem;
    }
    @include tablet-down {
        border-top: 1px solid $gray-medium;
        display: none;
    }
    @include print {
        display: none !important;
    }
    .container-fluid {
        @include tablet-up {
            padding-right: 60px;
            padding-left: 60px;
        }
        @include big-desktop {
            padding-right: 60 * $rem;
            padding-left: 60 * $rem;
        }
    }
    .item-footer {
        display: flex;
        align-items: center;
        font-family: $font-Cir;
        font-size: 14px;
        justify-content: flex-end;
        @include tablet-up {
            justify-content: space-between;
        }
        @include big-desktop {
            font-size: 14 * $rem;
        }
        li {
            padding: 20px 0;
            @include big-desktop {
                padding: 20 * $rem 0;
            }
            &:nth-child(2) {
                font-weight: 700;
                position: absolute;
                left: 15px;
                @include tablet-up {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            &:last-child {
                a {
                    display: inline-block;
                    margin: 0 8px;
                    @include tablet-up {
                        margin: 0 15px;
                    }
                    @include big-desktop {
                        margin: 0 15 * $rem;
                    }
                }
            }
            span {
                display: none;
                @include tablet-up {
                    display: block;
                }
            }
            .icon {
                display: block;
                @include tablet-up {
                    display: none;
                }
            }
        }
    }
}
.panel-login,
.panel-signup {
    position: fixed;
    top: 60px;
    left: calc(100% - 60px);
    height: calc(100% - 60px);
    width: 600px;
    z-index: 211;
    visibility: hidden;
    padding: 50px 20px 20px;
    font-weight: 400;
    transition: all 0.5s ease;
    @include big-desktop {
        width: 600*$rem;
        padding: 20*$rem;
        height: calc(100% - #{60*$rem});
        top: 60*$rem;
    }
    @include tablet-down {
        left: 100%;
    }
    @include small-mobile {
        padding: 40px 20px;
    }
    .scrollbar-macosx {
        padding: 0 25px;
        width: 100%;
        .scroll-element.scroll-y .scroll-bar {
            background: rgba(#000, .1) !important;
            border-radius: 5px !important;
        }
        .scroll-element.scroll-y .scroll-element_size {
            background: transparent !important;
        }
    }
    @include tablet-down {
        width: 100%;
    }
    &.expand {
        left: calc(100% - 660px);
        visibility: visible;
        background-color: $green;
        transition: background 0.3s ease, left 0.5s 0.3s ease;
        @include big-desktop {
            left: calc(100% - #{660*$rem});
        }
        @include tablet-down {
            left: 0;
        }
    }
    .login-panel-container {
        height: calc(100vh - 180px);
        &>div {
            @include max-width-center(400px);
        }
    }
    .woocommerce-form-login {
        margin: 0 -8px;
        .form-text-input {
            width: 100%;
            padding: 0 8px;
            float: left;
        }
        .form-half {
            width: 50%;
        }
    }
    h1 {
        margin-bottom: 12px;
    }
    p {
        font-size: 16px;
        letter-spacing: -.2px;
        @include mobile-down {
            font-size: 14px;
        }
    }
    h3 {
        font: 400 14px/1em $font-Cir;
        letter-spacing: 1.4px;
        color: #000;
        margin-bottom: 0;
    }
    ul {
        padding-left: 0;
        margin-bottom: 0;
    }
    input[type=submit] {
        @include reset-input;
        cursor: pointer;
        width: 200px;
        margin: 0 auto 20px;
        display: block;
        color: #fff;
        background: #000;
        height: 50px;
        font: 500 14px/ normal $font-Cir;
        letter-spacing: 2px;
        &:hover {
            opacity: .85;
        }
    }
    .lost-pw {
        margin-bottom: 20px;
        @include mobile-down {
            margin-bottom: 20px;
        }
        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .footer-login {
        p {
            font: 500 16px/ 28px $font-Cir;
            letter-spacing: -.2px;
            @include mobile-down {
                font-size: 14px;
            }
        }
    }
}
.woocommerce-form-login {
    .form-text-input {
        margin-bottom: 20px;
        label {
            font: 700 15px/1.25em $font-Cir;
            display: block;
            margin-bottom: 7px;
        }
        input:not([type=submit]) {
            @include reset-input;
            @include reset-autofill;
            width: 100%;
            height: 50px;
            font-size: 14px;
            padding: 12px 15px;
            border: 1px solid #000;
            background: transparent;
        }
    }
    .lost-pw {
        font: 500 16px/ normal $font-Cir;
    }
}
#login-fields {
    display: none;
}
#btn-close-login {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 2;
    @include small-mobile {
        right: 10px;
        top: 0;
    }
    &:hover {
        &:before {
            opacity: .7;
        }
    }
    &:before {
        content: '\00d7';
        font-size: 30px;
    }
}
.white-textline {
    border-bottom: 1px solid #fff;
    position: relative;
    margin-bottom: 30px;
    @include tablet-down {
        margin-bottom: 20px;
    }
    &:before {
        content: "or";
        position: absolute;
        background: $green;
        width: 50px;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        color: #fff;
        font: 400 14px/ normal $font-Cir;
    }
}
.backdrop-cart {
    background: rgba(0, 0, 0, 0.2) !important;
}
.select2-search.select2-search--dropdown {
    display: none !important;
}
.select2-container {
    &.select2-container--open {
        .select2-results__options {
            &:-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px $green;
                background-color: #F5F5F5;
            }
            &::-webkit-scrollbar {
                width: 4px;
                background-color: #F5F5F5;
            }
            &::-webkit-scrollbar-thumb {
                background-color: $green;
            }
        }
    }
}