.db-h {
  font-family: $font-Cir;
  font-weight: 700;
  line-height: (50/48);

  @include tablet-up {
    font-size: 48px;
  }
}

.db-h1 {
  font-size: 48px;
  margin-bottom: 48px;

  @include tablet-up {
    font-size: 55px;
  }
}

.db-h2 {
  font-size: 32px;
  margin-bottom: 30px;

  @include tablet-up {
    font-size: 44px;
  }
}

.db-btn {
  display: inline-block;
  position: relative;
  padding: 20px;
  font-family: $font-Cir;
  font-size: 16px;
  line-height: (20/16);
  letter-spacing: (2/16 + em);
  text-transform: uppercase;
  text-align: center;
  min-width: 220px;
  background-color: transparent;
  border-radius: 0;
  border: thin solid #000;
  margin: 0 6px 6px 0;
  outline: none;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border-color: #605e98;
    border-style: solid;
    border-width: 0 2px 2px 0;
    position: absolute;
    right: -6px;
    bottom: -6px;
  }

  &:focus {
    outline: none;
  }

  @include icon-size(10px) {
    vertical-align: (1/16 + em);
    margin-right: 10px;
  }

  &--sm {
    padding-top: 9px;
    padding-bottom: 9px;
    min-width: unset;
  }

  &--link {
    border: none;
    padding: 0;
    margin: 0;
    min-width: unset;
    text-decoration: underline;

    &::after {
      display: none;
    }
  }
}

.db-btn-print {
  display: inline-block;
  padding: 10px 5px;
  font-family: $font-Cir;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: (2/16 + em);
  text-transform: uppercase;
  text-align: center;
  background-color: #000;
  color: #fff;
  border-radius: 0;
  width: 100%;

  &:hover {
    color: #fff;
  }

  img {
    position: relative;
    top: -4px;
    margin-right: 10px;
  }

  @include tablet-up {
    width: auto;
    min-width: 220px;
    font-size: 16px;
    height: 60px;
    line-height: 45px;
  }
}

.db-btn-reset {
  display: inline-block;
  padding: 10px 5px;
  font-family: $font-Cir;
  font-size: 14px;
  line-height: (20/16);
  letter-spacing: (2/16 + em);
  text-transform: uppercase;
  text-align: center;
  background-color: transparent;
  border: thin solid #000;
  border-radius: 0;
  width: 100%;

  @include tablet-up {
    width: auto;
    min-width: 220px;
    font-size: 16px;
    padding: 20px;
  }
}

.db-section {
  padding: 50px 0;

  @include tablet-up {
    padding: 100px 0;
  }

  &__legend {
    font-family: $font-Cir;
    font-size: 18px;
    letter-spacing: (1.9/18 + em);
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 25px;

    @include tablet-up {
      margin-bottom: 50px;
    }
  }

  &__header {
    align-items: flex-end;
    margin-bottom: 25px;

    @include tablet-up {
      margin-bottom: 50px;
    }

    .db-h:last-child {
      margin-bottom: 0 !important;
    }

    > div:not(:last-child) {
      @include tablet-down {
        margin-bottom: 15px;
      }
    }
  }
}

.db-overview {
  @include max-desktop {
    @include space-col(100px);
  }

  > div:not(:last-child) {
    @include tablet-down {
      margin-bottom: 50px;
    }
  }
}

.db-purchase {
  display: block;
  padding: 25px 15px;
  background-color: #fff;
  font-family: $font-Cir;
  font-size: 14px;

  &__title {
    @extend .ven-h;
    font-size: 20px;
    margin-bottom: 15px;
  }

  &__counter {
    margin-bottom: 15px;
  }

  &__date {
    font-size: 20px;
    opacity: 0.25;
    margin-bottom: 20px;
  }

  &__price {
    font-size: 20px;
    margin-bottom: 5px;
  }

  &__status {
    margin-bottom: 30px;
  }

  &__more {
    text-transform: uppercase;
    letter-spacing: (1.75/14 + em);
  }
}

.db-purchases {
  @include space-col(16px);
  margin-bottom: -16px;

  &__item {
    margin-bottom: 16px;
  }
}

.db-reward {
  &__image {
    text-align: center;
    margin-bottom: 24px;

    img {
      max-width: 141px;
    }
  }

  &__progress {
    position: relative;
    height: 10px;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 10px;

    &::before {
      content: "";
      display: block;
      height: 100%;
      border: thin solid #000;
      border-radius: 20px;
    }

    > span {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: $green;
    }
  }

  &__message {
    background-color: #fff;
    border-radius: 5px;
    padding: 7px 15px;
    font-family: $font-Cir;
    font-weight: 500;
    color: $green;
    letter-spacing: (0.25/16 + em);
  }
}

.db-program {
  display: block;

  &__header {
    margin-bottom: 15px;

    @include tablet-up {
      margin-bottom: 23px;
    }
  }

  &__image {
    @include img-drop(1, 1);
  }

  &__title {
    @extend .ven-h;
    font-size: 24px;
    margin-bottom: 12px;

    @include tablet-up {
      font-size: 32px;
    }
  }

  &__description {
    @include tablet-up {
      font-size: 18px;
    }
  }

  &.is-disabled {
    pointer-events: none;
    user-select: none;
    filter: grayscale(1);
    opacity: 0.5;
  }
}

.db-program-blank {
  padding-bottom: 100%;
  border: thin solid #000;
  position: relative;
  cursor: pointer;

  &::before {
    content: "";
    display: block;
    border-color: #605e98;
    border-style: solid;
    border-width: 0 2px 2px 0;
    position: absolute;
    right: -6px;
    bottom: -6px;
    width: 100%;
    height: 100%;
  }

  &__inner a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 15px;
    text-align: center;

    h3 {
      font-family: $font-Cir;
      font-size: 16px;
      line-height: (19/15);
      text-transform: uppercase;
      letter-spacing: (1.9/15 + em);
      margin-top: 12%;
      margin-bottom: 0;
      max-width: 182px;

      @include tablet-up {
        font-size: 20px;
      }
    }

    svg {
      width: (131/(313 - 30) * 100%);
    }
  }
}

.db-programs {
  margin-bottom: -30px;
  @include space-col(16px);

  @include max-desktop {
    @include space-col(60px);
    margin-bottom: -60px;
  }

  &__item {
    margin-bottom: 30px;

    @include max-desktop {
      margin-bottom: 60px;
    }
  }
}

.db-recipe {
  display: block;
  min-height: 100%;
  background-color: #fff;

  &__image {
    @include img-drop(1, 1);
  }

  &__body {
    padding: 15px;
    font-family: $font-Cir;

    @include tablet-up {
      padding: 20px;
    }
  }

  &__title {
    @extend .ven-h;
    font-size: 18px;
    margin-bottom: 22px;
  }
}

.db-favourite {
  appearance: none;
  width: 32px;
  height: 31px;
  border: none;
  border-radius: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='31' viewBox='0 0 32 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 3.29057L18.8244 12.1448L19.0464 12.8409H19.7771H28.9677L21.5166 18.3552L20.9412 18.781L21.1588 19.463L23.9951 28.3547L16.5949 22.8781L16 22.4378L15.4051 22.8781L8.00492 28.3547L10.8412 19.463L11.0588 18.781L10.4834 18.3552L3.03227 12.8409H12.2229H12.9536L13.1756 12.1448L16 3.29057Z' stroke='%2362BD89' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-repeat: none;
  background-size: 100% 100%;
  cursor: pointer;

  &:checked {
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='31' viewBox='0 0 32 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 0L19.7771 11.8409H32L22.1115 19.1591L25.8885 31L16 23.6819L6.11146 31L9.88854 19.1591L0 11.8409H12.2229L16 0Z' fill='%2362BD89'/%3E%3C/svg%3E%0A");
  }
}

.db-recipes {
  @include space-col(16px);
  margin-bottom: -16px;

  &__item {
    margin-bottom: 16px;
  }
}

.db-post {
  display: block;
  position: relative;

  &::before {
    content: "";
    display: block;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, #000000 100%);
    opacity: 0.4;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    height: 33%;
  }

  &__image {
    @include img-drop(258, 361);
  }

  &__title {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px;
    z-index: 2;
    @extend .ven-h;
    font-size: 16px;
    color: #fff;

    @include tablet-up {
      font-size: 18px;
    }
  }
}

.db-posts {
  @include space-col(16px);
  margin-bottom: -16px;

  &__item {
    margin-bottom: 16px;
  }
}

// Program single
.db-top-banner {
  img {
    display: block;
    width: 100%;
  }
}

.db-program-intro {
  padding: 40px 15px 110px;

  @include desktop-up {
    padding: 40px 60px 110px;
  }

  &__header {
    margin-bottom: 32px;

    .row {
      @include space-col(16px);
    }

    @include mobile-down {
      .db-btn {
        padding-left: 6px;
        padding-right: 6px;
        font-size: 12px;
      }
    }
  }

  &__body {
    max-width: 800px;
    margin: 0 auto;
  }

  &__title {
    text-align: center;
  }

  &__description {
    @include tablet-up {
      font-size: 18px;
    }

    &.restriction {
      font-size: 0;

      div {
        font-size: 28px;
        margin-top: 0 !important;
      }
    }

    > ul {
      list-style: disc;
      padding-left: 1.2em;
      margin-bottom: 1.5rem;
    }
  }
}

.db-program-pane {
  padding: 85px 15px;

  @include tablet-up {
    padding: 85px 60px;
  }

  &__header {
    text-align: center;
    margin-bottom: 45px;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__description {
    margin-top: 17px;

    @include mobile-up {
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
    }

    @include tablet-up {
      font-size: 18px;
    }
  }
}

.db-program-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  li {
    width: 50%;

    @include mobile-up {
      width: (100%/3);
    }

    @include tablet-up {
      width: (100%/6);
    }

    a {
      display: block;
      padding: 35px 25px 25px;
      text-align: center;
      transition: all 0.3s ease;

      img {
        height: 76px;
        margin-bottom: 28px;
        transition: all 0.2s ease;
      }

      h4 {
        font-family: $font-Cir;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.25;
        text-transform: uppercase;
        margin-bottom: 0;
      }

      &:hover {
        background-color: #fff;
        color: $green;

        img {
          filter: invert(69%) sepia(49%) saturate(357%) hue-rotate(93deg) brightness(88%) contrast(87%);
        }
      }
    }
  }
}

.db-program-status {
  display: inline-block;
  font-family: $font-Cir;
  font-size: 16px;
  line-height: (20/16);
  padding: 3px 20px 5px;
  border-radius: 40px;
  background-color: #fff;

  @include icon-size(16px) {
    margin-right: 5px;
    vertical-align: (-3/16 + em);
  }

  &--completed {
    background-color: $green;
    color: #fff;
  }
}

.db-program-progress {
  max-width: 244px;
  margin: 0 auto;
  height: 28px;
  background-color: #fff;
  border-radius: 28px;
  position: relative;

  &__inner {
    display: flex;
    align-items: center;
    padding: 0 25px 2px;
    position: relative;
    z-index: 2;
    height: 100%;
    font-family: $font-Cir;
    font-size: 12px;
    font-weight: 500;

    > span {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  &__value {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #fdcc61;
    border-radius: 28px;
  }
}

.db-loading {
  text-align: center;
  margin-top: 30px;

  > span {
    display: block;
    margin-top: 15px;
    font-family: $font-Cir;
    line-height: (20/16);
    text-transform: uppercase;
    letter-spacing: (2/16+em);
  }
}

.db-navbar {
  padding: 5px 0;
  background-color: #fff;
  font-family: $font-Cir;
  font-weight: 700;
  line-height: (23/18);
  text-transform: uppercase;
  letter-spacing: (2.25/18+em);
  position: sticky;
  top: calc(60px + var(--offset-top));
  z-index: 5;

  .container-fluid {
    @include big-desktop {
      padding-left: 60px;
      padding-right: 60px;
    }

    @include max-desktop {
      display: flex;
      align-items: flex-end;
    }
  }

  &__label {
    margin-right: 3%;
    align-self: center;
  }

  // select {
  //     appearance: none;
  //     width: 155px;
  //     height: 30px;
  //     background-color: transparent;
  //     background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L4.29289 4.29289C4.68342 4.68342 5.31658 4.68342 5.70711 4.29289L9 1' stroke='black' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  //     background-repeat: no-repeat;
  //     background-position: right 12px center;
  //     border: none;
  //     border-radius: 0;
  //     border: thin solid #000;
  //     padding: 0 24px 0 20px;
  //     outline: none;
  //     align-self: center;
  //     text-transform: uppercase;
  //     font-family: inherit;
  //     font-weight: inherit;
  //     letter-spacing: inherit;
  // }

  .dropdown {
    display: inline-block;

    button {
      background-color: transparent;
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L4.29289 4.29289C4.68342 4.68342 5.31658 4.68342 5.70711 4.29289L9 1' stroke='black' stroke-linecap='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: right 12px center;
      border-radius: 0;
      border: thin solid #000;
      padding: 5px 60px 5px 20px;
      font-family: $font-Cir;
      font-weight: 700;
      line-height: (23/18);
      text-transform: uppercase;
      letter-spacing: (1.25/18+em);
      color: $green;
      outline: none;
      margin-bottom: 2px;
    }

    .dropdown-menu {
      min-width: 80px;
      width: 100%;
      border-radius: 0;
      border-color: #000;
      margin: 0;

      @include tablet-up {
        display: block !important;
        opacity: 0;
        visibility: hidden;
      }
    }

    .dropdown-item {
      padding: 8px 20px;
      font-family: $font-Cir;
      font-weight: 700;
      line-height: (23/18);
      text-transform: uppercase;
      letter-spacing: (1.25/18+em);

      &:hover {
        background-color: transparent;
        color: $green;
      }
    }

    &:hover {
      @include tablet-up {
        .dropdown-menu {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.db-navbar-nav {
  flex: 1;
  margin-top: 20px;
  margin-bottom: -7px;

  @include max-desktop {
    margin-top: 0;
  }

  @include tablet-down {
    margin-left: -15px;
    margin-right: -15px;
    overflow-x: auto;
    @include hide-scrollbar;
  }

  ul {
    display: inline-flex;

    @include desktop-up {
      display: flex;
      flex-wrap: wrap;
    }

    @include max-desktop {
      justify-content: flex-end;
    }

    @include tablet-down {
      padding-left: 15px;
      padding-right: 15px;
      white-space: nowrap;
    }

    li {
      a {
        display: block;
        padding: 0 0 10px;
        border-bottom: 2px solid transparent;

        &.is-active {
          border-color: $green;
        }
      }

      &:not(:last-child) {
        margin-right: 3%;
      }
    }
  }
}

.db-main {
  padding: 50px 0;
  background-color: $gray-medium;

  @include tablet-up {
    padding: 100px 0;
  }
}

.db-recipes-archive {
  &__title {
    text-align: center;
  }
}

.db-recipes-toolbar {
  margin-bottom: 55px;
  @include space-col(16px);

  > div:not(:last-child) {
    @include tablet-down {
      margin-bottom: 15px;
    }
  }
}

.db-recipes-search {
  display: flex;
  height: 66px;
  border: thin solid #000;
  padding-left: 28px;

  svg {
    align-self: center;
    margin-right: 10px;
  }

  input {
    flex: 1;
    padding: 0 28px 0 0;
    appearance: none;
    background-color: transparent;
    border: none;
    border-radius: 0;
    outline: none;

    @include tablet-up {
      font-size: 18px;
    }
  }

  &.is-loading {
    background: url(../img/loading-2.svg) no-repeat;
    background-size: 35px 35px;
    background-position: 100% 50%;
    padding-right: 32px;
  }
}

.db-recipes-select {
  appearance: none;
  width: 100%;
  height: 66px;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='16' viewBox='0 0 30 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L14.2929 14.2929C14.6834 14.6834 15.3166 14.6834 15.7071 14.2929L29 1' stroke='black' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right 28px center;
  border: none;
  border-radius: 0;
  border: thin solid #000;
  padding: 0 62px 0 28px;
  outline: none;

  @include tablet-up {
    font-size: 18px;
  }
}

.db-education {
  &__title {
    text-align: center;
  }

  &__body {
    padding: 15px;
    background-color: #fff;

    @include tablet-up {
      padding: 54px;
    }
  }
}

.db-meal {
  > .container-fluid {
    @include big-desktop {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  &__header {
    margin-bottom: 50px;
    justify-content: center;
    @include space-col(16px);
  }

  &__title {
    margin-bottom: 0;
    text-align: center;
  }
}

.db-table-meal-wrapper {
  position: relative;

  &.inview {
    .db-table-meal-arrow {
      &::before {
        position: fixed;
        top: 190px;
      }

      &--prev {
        &::before {
          left: 15px;

          @include tablet-up {
            left: 75px;
          }
        }
      }

      &--next {
        &::before {
          right: 15px;

          @include tablet-up {
            right: 75px;
          }
        }
      }
    }
  }

  &.inbottom {
    .db-table-meal-arrow {
      &::before {
        top: auto;
        bottom: 158px;
      }
    }
  }
}

.db-table-meal-arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 120px;
  z-index: 3;
  cursor: pointer;
  transition: all 0.3s ease-out;

  &::before {
    content: "";
    display: block;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url(../img/arrow-c-next.svg);
    position: absolute;
    top: 130px;
  }

  &--prev {
    background: linear-gradient(270deg, rgba(244, 242, 237, 0) 0%, rgba(244, 242, 237, 1) 80%);
    left: 0;

    &::before {
      left: 0;
      transform: scale(-1);
    }
  }

  &--next {
    background: linear-gradient(90deg, rgba(244, 242, 237, 0) 0%, rgba(244, 242, 237, 1) 80%);
    right: 0;

    &::before {
      right: 0;
    }
  }

  &.disabled {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}

.db-table-meal-content {
  overflow-x: auto;
  scroll-behavior: smooth;

  @include tablet-down {
    @include hide-scrollbar;
  }
}

.db-table-meal {
  width: 100%;
  table-layout: fixed;
  min-width: 1500px;

  th,
  td {
    padding: 8px;
    width: 25%;

    &:first-child {
      width: (120/24+em);
    }

    @include desktop-up {
      padding: 15px;
    }
  }

  th {
    font-size: 18px;
    font-weight: 700;
    vertical-align: middle;
    text-align: center;

    a {
      font-size: 15px;
      font-weight: 400;
      text-decoration: underline;
    }
  }

  thead {
    text-align: center;
  }

  tbody {
    tr {
      transition: all 0.2s ease-out;

      &:hover {
        box-shadow: inset 0 0 0 1px #000000;
      }

      &.is-active {
        th,
        .db-meal-card {
          opacity: 0.5;
        }

        .reset-day {
          display: inline-block;
        }
      }
    }
  }

  &.hide-cat{
    .cat{
      display: none;
    }
  }
}

.db-meal-card {
  display: block;

  &__image {
    @include img-drop(162, 130);
  }

  &__body {
    background-color: #fff;
    padding: 10px;
    text-align: center;
    @extend .ven-h;
    font-size: 14px;
    min-height: 55px;
  }
}

.db-btn-check {
  cursor: pointer;
  text-align: center;

  input {
    display: none;

    & + span {
      display: inline-block;
      position: relative;
      padding: 9px;
      font-family: $font-Cir;
      font-size: 14px;
      line-height: (20/16);
      letter-spacing: (2/16 + em);
      text-transform: uppercase;
      text-align: center;
      background-color: transparent;
      border-radius: 0;
      border: thin solid #000;
      min-width: 156px;
      margin: 0 6px 6px 0;

      &::before {
        display: block;
        content: attr(data-text);
        background-repeat: no-repeat;
        background-position: 0 50%;
        transition: all 0.2s ease-out;
      }

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        border-color: #605e98;
        border-style: solid;
        border-width: 0 2px 2px 0;
        position: absolute;
        right: -6px;
        bottom: -6px;
      }
    }

    &:checked + span {
      background-color: $green;

      &::before {
        content: attr(data-text-checked);
        background-image: url("data:image/svg+xml,%3Csvg width='17' height='11' viewBox='0 0 17 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5L5.79289 9.79289C6.18342 10.1834 6.81658 10.1834 7.20711 9.79289L16.5 0.5' stroke='black' stroke-linecap='round'/%3E%3C/svg%3E%0A");
        padding-left: 20px;
      }
    }
  }

  .reset-day {
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 1.875px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #000;
    margin-top: 15px;
    display: none;
  }
}

.db-shopping {
  background-color: $gray-medium;
  background-image: url(../img/bg-vegetables.png);
  background-repeat: no-repeat;
  background-size: 100vw auto;
  background-position: 50% 15vw;

  @include tablet-up {
    background-size: (939/1920*100+vw) auto;
  }

  &__title {
    text-align: center;
  }

  &__more {
    margin-top: 90px;
    text-align: center;
  }
}

.db-form-check {
  margin-bottom: 0;
  display: flex;
  user-select: none;

  @include tablet-up {
    font-size: 18px;
  }

  input {
    appearance: none;
    width: (28/18+em);
    height: (28/18+em);
    border: thin solid #000;
    border-radius: 50%;
    margin-right: 10px;

    &:checked {
      background-color: $green;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5.50024L4.79289 9.29314C5.18342 9.68366 5.81658 9.68366 6.20711 9.29314L14.5 1.00024' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
  }

  span {
    flex: 1;
    padding-top: (2/18+em);
    font-weight: 500;
  }
}

.db-check-list {
  li {
    margin-bottom: 20px;
  }

  &:last-child {
    li {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.db-shopping-list {
  .card-header {
    border-top: 2px solid $border;
    display: flex;
    align-items: center;
    padding: 17px 0;
    font-family: $font-Cir;
    line-height: (40/32);
    cursor: pointer;

    &::before {
      content: "";
      display: inline-block;
      border-style: solid;
      border-width: 11px 0 11px 12px;
      border-color: transparent transparent transparent #000;
      margin-right: 24px;
      align-self: flex-start;

      @include tablet-up {
        margin-top: 0.35em;
      }
    }

    h3 {
      flex: 1;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 0;

      @include tablet-up {
        font-size: 32px;
      }
    }

    p {
      color: $gray-3;
      margin-bottom: 0;
      margin-left: 15px;
    }

    &[aria-expanded="true"] {
      border-color: $green;

      &::before {
        border-color: transparent transparent transparent $green;
        transform: rotate(90deg) translate(0, -5px);
      }

      h3 {
        color: $green;
      }
    }
  }

  .card-body {
    padding: 15px 0 30px;

    h3 {
      font-family: $font-Cir;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 23px;

      @include tablet-up {
        font-size: 24px;
      }

      &:not(:first-child) {
        margin-top: 48px;
      }
    }

    .db-check-list {
      @include tablet-up {
        column-gap: 30px;
        column-count: 2;
      }
    }
  }
}

.db-check-wrapper {
  overflow: hidden;

  .container-fluid {
    @include desktop-up {
      padding-left: 60px;
      padding-right: 60px;
    }
  }

  &__title {
    text-align: center;
  }

  &__more {
    margin-top: 90px;
    text-align: center;
  }
}

.db-check-card {
  @include desktop-up {
    display: flex;
    flex-wrap: wrap;
  }

  &__header {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: thin solid #000;
    font-family: $font-Cir;
    font-size: 24px;
    font-weight: 700;

    @include desktop-up {
      width: 130px;
      padding-bottom: 0;
      padding-right: 30px;
      margin-bottom: 0;
      margin-right: 80px;
      border-bottom: none;
      border-right: thin solid #000;
      display: flex;
      align-items: center;
    }
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
    margin-right: -30px;

    @include tablet-up {
      flex: 1;
      padding: 60px 0 0;
      border-bottom: thin solid #000;
    }

    @include small-mobile {
      height: auto !important;
    }

    section {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
      margin-bottom: 60px;

      @include small-mobile-up {
        width: (100%/2);
      }

      @include tablet-up {
        width: (100%/3);
      }

      @include big-desktop {
        width: (100%/5);
      }

      @include small-mobile {
        position: static !important;
      }

      section{
        width: 100%;
        padding: 0;
      }

      h3 {
        font-family: $font-Cir;
        font-size: 18px;
        font-weight: 700;
        line-height: (23/18);
        letter-spacing: (2.25/18+em);
        color: $green;
        text-transform: uppercase;
        margin-bottom: 18px;

        &:not(:first-child) {
          margin-top: 45px;
        }
      }

      p {
        font-size: 18px;
        font-weight: 500;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }

      .db-btn {
        margin-top: 15px;

        @include icon-size(24px) {
          margin-top: -3px;
          margin-bottom: -3px;
          vertical-align: -3px;
          color: $gray-3;
        }
      }
    }
  }

  &:first-child {
    .db-check-card__body {
      padding-top: 0;
    }
  }

  &:last-child {
    .db-check-card__body {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}

.db-form-inline {
  display: flex;
  align-items: center;

  label {
    margin-bottom: 0;
    margin-right: 13px;
    font-family: $font-Cir;
    font-size: 18px;
  }

  input {
    flex: 1;
    appearance: none;
    background-color: transparent;
    border: thin solid #000;
    border-radius: 0;
    padding: 5px 10px;
    outline: none;
    overflow: hidden;
  }
}

.db-check-rating {
  //display: flex;
  font-size: 18px;
  margin-bottom: 16px;

  input {
    appearance: none;
    width: 28px;
    height: 28px;
    border: thin solid #000;
    border-radius: 50%;
    margin-right: 15px;

    &:checked {
      background-color: $green;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5.50024L4.79289 9.29314C5.18342 9.68366 5.81658 9.68366 6.20711 9.29314L14.5 1.00024' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
  }
}

.db-modal-simple {

  .modal-dialog {
    @include mobile-up {
      max-width: 630px;
    }
  }

  &#modal-reminder {
    .modal-dialog {
      max-width: 1160px;
    }
    .db-textarea .content {
      padding: 15px;
      font-size: 16px;
      display: block;
      width: 100%;
      background-color: transparent;
      border: thin solid #000;
      resize: none;
      outline: none;
      height: calc(100vh - 400px);
      overflow-y: auto;
      text-align: left;

      @include tablet-up {
        min-height: 450px;
        padding: 25px 30px 50px;
        font-size: 22px;
      }
    }
  }

  .modal-content {
    background-color: $gray-medium;
    border-radius: 0;
    border: none;
    text-align: center;
  }

  .modal-header {
    display: block;
    padding: 50px 15px 20px;
    border-bottom: none;

    @include tablet-up {
      padding: 70px 25px 20px;
    }

    .close {
      background-color: transparent;
      padding: 0;
      margin: 0;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: thin solid #000;
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 30px;
      cursor: pointer;
      outline: none;

      @include tablet-up {
        top: 25px;
        right: 25px;
      }
    }
  }

  .modal-title {
    font-family: $font-Cir;
    font-size: 32px;
    font-weight: 700;
    line-height: (58/46);
    color: $green;

    @include tablet-up {
      font-size: 46px;
    }
  }

  .modal-body {
    padding: 0 15px;
    font-size: 22px;

    @include tablet-up {
      padding: 0 25px;
    }
  }

  .modal-footer {
    display: block;
    padding: 30px 15px;
    border-top: none;

    @include tablet-up {
      padding: 30px 25px 55px;
    }

    .db-btn {
      min-width: 200px;
    }
  }
}

.db-textarea {
  position: relative;

  textarea {
    display: block;
    width: 100%;
    padding: 15px 15px 40px;
    background-color: transparent;
    border: thin solid #000;
    resize: none;
    outline: none;

    @include tablet-up {
      padding: 25px 30px 50px;
      font-size: 22px;
    }
  }

  &__info {
    position: absolute;
    right: 15px;
    bottom: 15px;
    font-size: 12px;
    font-weight: 500;
    color: $gray-3;
  }
}

.db-classes-wrapper {
  .container-fluid {
    @include desktop-up {
      padding-left: 120px;
      padding-right: 120px;
    }
  }

  &__title {
    text-align: center;
  }
}

.db-classes {
  margin-bottom: -45px;

  @include desktop-up {
    margin-bottom: -90px;
    @include space-col(90px);
  }

  &__item {
    margin-bottom: 45px;

    @include tablet-up {
      margin-bottom: 90px;
    }
  }
}

.db-class {
  &__header {
    margin-bottom: 40px;
  }

  &__title {
    font-size: 24px;
    color: $green;

    @include tablet-up {
      font-size: 32px;
    }
  }

  &__description {
    @include tablet-up {
      font-size: 18px;
      font-weight: 500;
    }
  }
}

.db-modal-tutorial {
  .modal-dialog {
    @include small-mobile-up {
      max-width: 100%;
      margin: 1.75rem 40px;
    }

    @include big-desktop {
      max-width: 1300px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.db-tutorial {
  background-color: $gray-medium;
  padding: 15px;

  @include tablet-up {
    padding: 30px 60px;
  }

  &__header {
    text-align: center;
    margin-bottom: 20px;

    img {
      width: 164px;
    }
  }

  &__body {
  }
}

.db-tutorial-slider {
  position: static;

  .owl-dots {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    left: 50%;
    bottom: 5px;
    transform: translate(-50%, 0);

    @include tablet-up {
      bottom: 20px;
    }

    button {
      display: inline-block;
      vertical-align: middle;
      width: 20px;
      height: 6px;
      border: thin solid #000;
      border-radius: 6px;
      margin: 0 7px;
      outline: none;

      &.active {
        background-color: #000;
      }
    }
  }
}

.db-tutorial-card {
  &__body {
    height: 50vh;
    background-color: transparent;
    overflow-y: auto;

    @include tablet-up {
      height: 500px;
    }
  }

  &__content {
    padding: 15px;
    height: 100%;

    @include tablet-up {
      padding: 30px;
    }

    img {
      height: 100%;
      width: auto !important;
      margin: 0 auto;
    }
  }

  &__footer {
    font-family: $font-Cir;
    font-weight: 700;
    font-size: 20px;
    line-height: (50/35);
    margin-top: 0px;
    text-align: center;

    @include tablet-up {
      font-size: 35px;
    }
  }
}

.db-heading {
  background-color: $gray-medium;
  padding: 40px 0 20px;
  text-align: center;

  h2 {
    margin-bottom: 0;
    font-family: $font-Cir;
    font-size: 18px;
    letter-spacing: (1.875/18 + em);
    text-transform: uppercase;
  }
}

.db-nav-spy {
  overflow: hidden;
  background-color: $gray-medium;

  @include tablet-up {
    position: sticky;
    top: calc(60px + var(--offset-top));
    z-index: 2;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0 40px;
    box-shadow: 0 -2px $border inset;

    li {
      a {
        display: block;
        padding: 10px 0;
        font-weight: 600;
        letter-spacing: (2.25/16 + em);
        text-transform: uppercase;
        position: relative;

        &::after {
          content: "";
          display: block;
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
          border-bottom: 2px solid $green;
          width: 0;
          transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
        }
      }

      &.active {
        a {
          color: $green;

          &::after {
            width: 100%;
          }
        }
      }
    }
  }
}

#modal-tutorial {
  .db-tutorial-slider {
    .owl-nav {
      position: absolute;
      top: 45%;
      width: 100%;
      left: 0;
    }

    .owl-prev, .owl-next {
      outline: none;
      opacity: 0.4;

      &:hover {
        opacity: 1;
      }
    }

    .owl-prev {
      float: left;
      margin-left: 90px;

    }

    .owl-next {
      float: right;
      margin-right: 90px;
    }

    &__text-footer {
      font-weight: 400;
      font-size: 20px;
      line-height: 133.5%;
      text-align: center;
      max-width: 70%;
      margin: 0 auto;
      margin-bottom: 15px;
    }

    @include mobile-down {
      .owl-prev {
        margin-left: 25px;

      }

      .owl-next {
        margin-right: 25px;
      }
    }
    @include tablet-down {
      &__text-footer {
        font-size: 18px;
        max-width: 100%;
      }
    }
  }
}

.db-magazines {
  gap: 1rem 0;
  @include space-col(1rem);

  @include tablet-up {
    gap: 22px 0;
    @include space-col(22px);
  }
}

.db-magazine {
  display: block;

  &__image {
    @include img-drop(258, 361);

    > div {
      position: absolute;
      top: 0;
      bottom: 6px;
      left: 0;
      right: 6px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      gap: 1rem 0;
      border: thin solid #000;
      font-weight: 500;
      line-height: (25/20);
      letter-spacing: (1.9/20+em);
      text-transform: uppercase;
      text-align: center;

      @include mobile-up {
        font-size: 20px;
      }

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        border-color: #605E98;
        border-style: solid;
        border-width: 0 2px 2px 0;
        position: absolute;
        top: 6px;
        right: -6px;
      }

      svg {
        width: (131/249*100%);
      }

      > span {
        padding: 0 10% 0;
      }
    }
  }
}