.banner-home {
    margin-bottom: 40px;
    margin-top: 60px;
    @include tablet-up {
        margin-bottom: 0;
        margin-top: 0;
    }
    .container-fluid {
        padding-right: 0;
        padding-left: 0;
        .row {
            @include space-col(0);
            .img-banner {
                padding-top: 100%;
                @include mobile-up {
                    min-height: calc(75vh - 120px);
                    margin-bottom: 0;
                }
                @include tablet-up {
                    padding-top: calc(100vh - 120px);
                }
                @include big-desktop {
                    padding-top: calc(100vh - 6vw);
                }
            }
        }
        .a-center {
            display: flex;
            align-items: center;
            @include mobile-down {
                padding-top: 40px;
                padding-bottom: 40px;
            }
        }
        .wrap-owl-carousel {
            position: relative;
            width: 100%;
            padding-bottom: 45px;
            @include tablet-up {
                padding-top: 0;
                padding-bottom: 0;
            }
            .owl-item {
                .title-medium {
                    position: relative;
                }
                .desc {
                    position: relative;
                }
            }
            .owl-dots {
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translate3d(-50%, 0, 0);
                opacity: 0.5;
                @include tablet-up {
                    bottom: -50px;
                }
                button {
                    @include reset-input;
                    border: 1px solid #000000;
                    box-sizing: border-box;
                    border-radius: 50%;
                    width: 10px;
                    height: 10px;
                    margin: 0 5px;
                    transition: all 0.5s ease;
                    @include big-desktop {
                        width: 20 * $rem;
                        height: 6 * $rem;
                        margin: 0 5 * $rem;
                    }
                    &.active {
                        background-color: #000;
                    }
                }
            }
        }
    }
}
.feature-home {
    padding-top: 45px;
    padding-bottom: 45px;
    background-color: #fafafa;
    position: relative;
    @include small-mobile-up {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    @include tablet-up {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .product-wrap-ie {
        display: block;
        text-align: center;
        .product-wrapblock {
            display: inline-block;
        }
    }
    .img-bg-1 {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-40%);
        height: 630px;
        display: none;
        @include tablet-up {
            display: block;
        }
        img {
            width: auto;
            height: 100%;
        }
    }
    .img-bg-2 {
        position: absolute;
        bottom: 100px;
        right: 0;
        transform: translateX(10%);
        height: 700px;
        display: none;
        @include tablet-up {
            display: block;
        }
        img {
            width: auto;
            height: 100%;
        }
    }
    .container-fluid {
        @include big-desktop {
            @include max-width-center(75%);
        }
    }
    .feature-block-holder {
        .product-feature-title {
            margin-bottom: 10px;
        }
        a.button-wrap {
            display: block;
            &:hover {
                background: transparent !important;
            }
        }
        .btn-common {
            margin-left: auto;
            margin-right: auto;
            del {
                opacity: 0.45;
                margin-right: 3px;
            }
        }
        .info {
            margin-bottom: 10px;
        }
    }
    .owl-featured-product-home {
        padding-bottom: 45px;
        .owl-stage-outer {
            padding-bottom: 10px;
        }
        .owl-dots {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate3d(-50%, 0, 0);
            button.owl-dot {
                @include reset-input;
                border: 1px solid #000000;
                box-sizing: border-box;
                border-radius: 50%;
                width: 10px;
                height: 10px;
                margin: 0 5px;
                transition: all 0.5s ease;
                @include big-desktop {
                    width: 20 * $rem;
                    height: 6 * $rem;
                    margin: 0 5 * $rem;
                }
                &.active {
                    background-color: #000;
                }
            }
        }
    }
    .wrap-title {
        margin-bottom: 35px;
        @include tablet-up {
            margin-bottom: 30px;
        }

        .title-medium {
            color: $green-dark;
        }
    }
    .wrap-text-4-item {
        .btn-common {
            margin-left: auto;
            margin-right: auto;
        }
        .desc {
            @include small-mobile {
                @include max-width-center(420px);
            }
        }
    }
    .img-custom-mobile {
        margin-bottom: 15px;
    }
}
.feature-home-list {
    > div {
        margin-bottom: 45px;
    }
}

#content-1,
#content-2,
.feature-home {
    .img-drop {
        padding-top: 100%;
        position: relative;
    }
    .img-custom-mobile {
        @include tablet-down {
            @include max-width-center(300px);
        }
        @include mobile-down {
            @include max-width-center(250px);
        }
    }
    .img-trans {
        position: absolute;
        z-index: -1;
        top: -40%;
        right: -10%;
        max-width: 375px;
        img {
            width: 100%;
        }
        @include tablet {
            right: -22%;
        }
        @include tablet-up {
            top: -35%;
            max-width: 100%;
            img {
                width: 100%;
            }
        }
        @include desktop-up {
            right: -20%;
        }
        @include big-desktop {
            max-width: 100vw;
            top: 0;
            img {
                width: 100%;
            }
        }
    }
}
#content-2 {
    &.section-scroll {
        align-items: flex-start;
        min-height: auto;
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .img-custom-mobile {
        @include tablet-down {
            margin-left: auto;
        }
    }
    .img-trans {
        max-width: 200px;
        left: 0;
        @include tablet-up {
            left: -18%;
            top: -43%;
        }
        @include big-desktop {
            max-width: 300 * $rem;
            top: 0;
        }
    }
}
#content-3 {
    background-color: $gray-medium;
    padding: 80px 0 40px;

    @include tablet-up {
        padding: 180px 0 100px;
    }

    .row {
        @include desktop-up {
            @include space-col(90px);
        }
    }
    .img-circle {
        background-color: #e8e6de;
        border-radius: 50%;
        overflow: hidden;

        .img-drop {
            padding-top: 100%;
        }
    }
    .desc {
        margin-top: 30px;
        @include big-desktop {
            margin-top: 30 * $rem;
        }
    }
    .btn-common {
        margin-top: 30px;
        @include tablet-up {
            margin-top: 40px;
        }
        @include big-desktop {
            margin-top: 40 * $rem;
        }
    }
}
#content-4 {
    background-color: $gray-medium;
    padding: 40px 0 80px;

    @include tablet-up {
        padding: 100px 0 180px;
    }
    .row {
        @include desktop-up {
            @include space-col(90px);
        }
    }
    .title-medium {
        &:first-letter {
            text-transform: lowercase;
        }
    }
    .wrap-text-4-item {
        .btn-common {
            margin-top: 30px;
            @include tablet-up {
                margin-top: 40px;
            }
            @include big-desktop {
                margin-top: 40 * $rem;
            }
        }
    }
    .wrap-book {
        position: relative;
        padding-bottom: (577/496 * 100%);
        .book-add {
            position: absolute;
            width: (340/496 * 100%);
            img {
                filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.102582));
            }
            &.under {
                top: 0;
                left: 0;
            }
            &.upper {
                bottom: 0;
                right: 0;
                z-index: 2;
            }
        }
    }
}
#content-5 {
    .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
    .row {
        @include space-col(10px);
        @include big-desktop {
            @include space-col(10 * $rem);
        }
        .row {
            [class*="col-"] {
                &:nth-child(1),
                &:nth-child(2) {
                    margin-bottom: 10px;
                }
            }
        }
        .bg-green {
            position: relative;
            align-items: center;
            display: flex;
            background: transparent;
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: calc(100% - 5px);
                height: 100%;
                background-color: $green;
            }
        }
    }
    .wrap-text-4-item {
        @include max-width-center(400px);
        padding-right: 15px;
        padding-left: 15px;
        @include big-desktop {
            @include max-width-center(400 * $rem);
        }
        @include tablet-down {
            padding-top: 60px;
            padding-bottom: 60px;
        }
        .title-medium {
            @include tablet {
                font-size: 38px;
            }
        }
        .btn-common {
            margin-top: 30px;
            @include tablet-up {
                margin-top: 40px;
            }
        }
    }
    .item-health {
        position: relative;
        display: block;
        overflow: hidden;
        z-index: 1;
        margin-bottom: 0;
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 60%, #000000 100%);
            mix-blend-mode: normal;
            width: 100%;
            height: 100%;
        }
        img {
            transition: all 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53);
        }
        .ratio-169 {
            @include desktop-up {
                padding-top: 40%;
            }
        }
        .ratio-11 {
            @include desktop-up {
                padding-top: 80%;
            }
        }
        &:hover {
            img {
                transform: scale(1.05);
            }
        }
        .wrap-title {
            position: absolute;
            z-index: 1;
            bottom: 25px;
            left: 15px;
            color: #fff;
            @include big-desktop {
                bottom: 25 * $rem;
                left: 30 * $rem;
            }
            span {
                display: block;
                text-transform: uppercase;
                font-family: $font-Cir;
                font-weight: 700;
                letter-spacing: 2px;
                font-size: 14px;
                padding-bottom: 10px;
                @include big-desktop {
                    font-size: 14 * $rem;
                }
            }
            h3 {
                font: 700 26px / normal $font-Cir;
                letter-spacing: -0.347826px;
                padding-right: 30px;
                @include big-desktop {
                    font-size: 26 * $rem;
                }
                @include tablet-down {
                    font-size: 24px;
                }
            }
        }
    }
}

// new update 07-2022
.ven-top-banner {
    position: relative;
}

.ven-top-sliders {
    .slick-dots {
        position: absolute;
        left: 50%;
        bottom: 10px;
        transform: translate(-50%, 0);
        display: flex;
        z-index: 10;

        @include tablet-up {
            bottom: 40px;
        }

        li {
            button {
                display: block;
                padding: 14px 10px;
                background: transparent;
                font-size: 0;
                border: none;
                border-radius: 0;
                outline: none;
                cursor: pointer;

                &::before {
                    content: "";
                    display: block;
                    width: 24px;
                    height: 10px;
                    border-radius: 8px;
                    border: thin solid #000;
                    outline: none;
                    box-shadow: 0 0 10px #fff, 0 0 10px #fff;
                }
            }

            &.slick-active {
                button {
                    &::before {
                        background-color: #000;
                    }
                }
            }
        }
    }
}

.ven-top-slider {
    position: relative;
    height: calc(100vh - 120px - var(--offset-top));

    @include tablet-up {
        min-height: 667px;
    }

    figure {
        margin: 0;
        @include img-drop(1, 1);

        @include tablet-up {
            height: 100%;
            padding: 0;
        }
    }

    &__header {
        @include tablet-down {
            display: none;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__image {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        picture {
            @include img-drop(100%);
        }
    }

    &__content {
        position: relative;
        z-index: 1;
        height: 100%;
        padding: 80px 0;
        color: $green-darken;
        pointer-events: none;

        @include tablet-up {
            padding: 160px 0;
        }

        .container {
            height: 100%;
        }

        .btn-common {
            pointer-events: visible;
        }
    }

    &__row {
        height: 100%;
        align-items: flex-end;
    }

    &__title {
        font-size: 48px;
        margin-bottom: 8px;
        line-height: 1.04;

        @include tablet-up {
            font-size: 80px;
            margin-bottom: 22px;
        }
    }

    &__subtitle {
        font-size: 24px;
        margin-bottom: 23px;

        @include tablet-up {
            font-size: 32px;
        }
    }

    &__description {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 25px;

        @include tablet-up {
            font-size: 24px;
            margin-bottom: 50px;
        }
    }

    &__video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &.is-playing {
            .ven-top-slider__button {
                opacity: 0;
                transition-delay: 1s;
                visibility: hidden;

                svg[role="img"] {
                    &:nth-child(1) {
                        display: none;
                    }

                    &:nth-child(2) {
                        display: block;
                    }
                }
            }
        }
    }

    &__sound {
        position: absolute;
        left: 34px;
        bottom: 34px;
        pointer-events: none;
        z-index: 10;
        font-size: 0;

        button {
            appearance: none;
            border-radius: 0;
            border: none;
            padding: 0;
            width: 35px;
            height: 35px;
            background-color: transparent;
            background-image: url("data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.5 0C7.83333 0 0 7.83333 0 17.5C0 27.1667 7.83333 35 17.5 35C27.1667 35 35 27.1667 35 17.5C35 7.83333 27.1667 0 17.5 0ZM17.9999 27.9167L10.9582 22.1666H5.41673V12.7083H11.125L17.9998 7.08311L17.9999 27.9167ZM20.875 22.2915L19.125 20.5415C20.3751 20.0832 21.2499 18.9165 21.2499 17.4999C21.2499 16.1249 20.3748 14.9167 19.125 14.4583L20.875 12.7083C22.5 13.6666 23.6251 15.4584 23.6251 17.4999C23.5835 19.5416 22.5 21.3332 20.875 22.2914V22.2915ZM24.125 25.5833L22.4585 23.8749C24.5002 22.4583 25.7919 20.1251 25.7919 17.4999C25.7919 14.8748 24.4584 12.5 22.4585 11.1249L24.125 9.41652C26.5832 11.2914 28.1667 14.208 28.1667 17.4999C28.1667 20.7914 26.5832 23.7499 24.125 25.5832V25.5833Z' fill='white'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            pointer-events: visible;
            outline: none;

            &.is-muted {
                background-image: url("data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.5 0C7.83333 0 0 7.83333 0 17.5C0 27.1667 7.83333 35 17.5 35C27.1667 35 35 27.1667 35 17.5C35 7.83333 27.1667 0 17.5 0ZM5.41667 12.7083H11.1249L17.9998 7.08318V11.0833L6.91644 22.1667H5.41644L5.41667 12.7083ZM24.125 25.5833L22.4585 23.8749C24.5002 22.4583 25.7919 20.1251 25.7919 17.4999C25.7919 15.2501 24.8336 13.2083 23.2502 11.7917L21.7083 13.3333C22.875 14.3749 23.5833 15.8334 23.5833 17.4999C23.5833 19.5417 22.5 21.3333 20.8332 22.2914L19.0832 20.5414C20.3333 20.0831 21.2081 18.9164 21.2081 17.4999C21.2081 16.4998 20.7498 15.583 19.9999 14.9997L17.9582 17.0414L17.9585 27.9164L12.0418 22.9999L7.50022 27.5415L5.8337 25.875L26.042 5.66667L27.7086 7.33318L24.9585 10.0833C26.9168 11.9167 28.1669 14.5833 28.1669 17.4999C28.1666 20.7914 26.5832 23.7499 24.1249 25.5833L24.125 25.5833Z' fill='white'/%3E%3C/svg%3E%0A");
            }
        }
    }

    &__button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        transition: all 0.3s ease;
        z-index: 1;
        cursor: pointer;
        outline: none;

        @include icon-size(88px) {
            &:nth-child(2) {
                display: none;
            }
        }
    }

    .item-intro {
        padding: 40px 0;

        .wrap-img {
            @include max-width-center(200px);
            margin-bottom: 20px;
            @include tablet-up {
                @include max-width-center(300px);
                margin-bottom: 50px;
            }
            @include big-desktop {
                @include max-width-center(300 * $rem);
                margin-bottom: 50 * $rem;
            }
        }
        .img-drop {
            padding-top: 100%;
            display: block;
        }
        .wrap-text {
            width: 100%;
            @include max-width-center(450px);
            text-align: center;
            padding-right: 15px;
            padding-left: 15px;
            @include big-desktop {
                @include max-width-center(450 * $rem);
            }
            .title-medium {
                font-family: $font-Cir;
                font-size: 34px;
                font-weight: 700;
                margin-bottom: 15px;
                @include tablet-up {
                    font-size: 42px;
                }
                @include big-desktop {
                    font-size: 42 * $rem;
                    margin-bottom: 15 * $rem;
                }
            }
            .desc {
                line-height: 28 / 16 * 1em;
                letter-spacing: -0.195652px;
                @include mobile-down {
                    padding: 0 15px;
                }

                a {
                    color: #000;
                    background: $green;
                    display: block;
                    max-width: 250px;
                    text-align: center;
                    font-size: 14px;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    font-family: $font-Cir;
                    position: relative;
                    z-index: 0;
                    cursor: pointer;
                    border: 1px solid #000;
                    padding: 10px 0;
                    margin: 20px auto 0;
                }
            }
        }
    }
}

.ven-programs-wrapper {
    padding: 80px 0;
    background-image: url(../img/bg-program.jpg);
    background-repeat: no-repeat;
    background-size: 400px auto;
    background-position: right -150px top -80px;

    @include desktop-up {
        padding: 150px 0 110px;
        background-size: 773px auto;
        background-position: right top -150px;
    }

    &__header {
        margin-bottom: 50px;

        @include desktop-up {
            margin-bottom: 94px;
        }
    }

    &__description {
        @include tablet-up {
            font-size: 18px;
        }
    }

    &__footer {
        text-align: center;
        margin-top: 80px;

        .btn-common {
            &::after {
                border-color: #605e98;
            }
        }
    }
}

.ven-modal-subscribe {
    .modal-dialog {
        @include mobile-up {
            max-width: 600px;
        }
    }
}

.ven-subscribe-card {
    background: $gray-medium url(../img/bg-subscribe.svg) no-repeat;
    background-position: 50% 10px;
    background-size: 95% auto;
    padding: 15px;

    @include tablet-up {
        padding: 30px;
    }

    &__header {
        text-align: center;
        padding-bottom: 15px;
        margin-bottom: 15px;
        position: relative;

        @include tablet-up {
            padding-bottom: 30px;
            margin-bottom: 30px;
        }

        &::before {
            content: "";
            display: block;
            width: 38px;
            border-bottom: thin solid #000;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
        }

        img {
            width: 164px;
        }
    }

    &__body {
        font-family: $font-Cir;
        text-align: center;
        margin-bottom: 20px;

        @include tablet-up {
            margin-bottom: 40px;
        }

        h3 {
            font-size: 18px;
            font-weight: 900;
            line-height: (40/22);

            @include tablet-up {
                font-size: 22px;
            }
        }

        h2 {
            font-size: 60px;
            font-weight: 900;
            line-height: 1;
            color: $green;
            text-shadow: (8/120+em) (6/120+em) #000;

            @include tablet-up {
                font-size: 120px;
            }
        }

        hr {
            max-width: 200px;
            margin-left: auto;
            margin-right: auto;
        }

        p {
            small {
                font-size: 10px;
                color: $gray-3;
            }
        }
    }
}

.ven-subscribe-form {
    position: relative;

    @include tablet-up {
        max-width: (370/468*100%);
        margin-left: auto;
        margin-right: auto;
    }
    .gform_validation_errors{
        display: none;
    }

    .gfield {
        &:not(:last-child) {
            margin-bottom: 25px;
        }

        &.is-email {
            position: relative;

            .ginput_container{
                position: relative;
                z-index: 2;
            }

            &::after {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                border-color: $green;
                border-style: solid;
                border-width: 0 2px 2px 0;
                position: absolute;
                right: -6px;
                bottom: -6px;
            }
        }
    }

    .gfield_label {
        display: none;
    }

    .ginput_container {
        input[type="text"],
        input[type="email"] {
            display: block;
            width: 100%;
            background-color: transparent;
            border: thin solid #000;
            border-radius: 0;
            padding: 18px 120px 18px 20px;
            outline: none;
        }
    }

    .gfield_checkbox {
        li {
            display: flex;
            user-select: none;

            input {
                appearance: none;
                width: 26px;
                height: 26px;
                border: thin solid #000;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: 20px;
                border-radius: 0;
                margin-right: 15px;

                &:checked {
                    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='19' height='19' fill='black' stroke='black'/%3E%3C/svg%3E%0A");
                }
            }

            label {
                flex: 1;
                margin-bottom: 0;
                padding-top: 5px;
                font-size: 12px;
            }
        }
    }

    .gform_button {
        background-color: transparent;
        border: none;
        border-radius: 0;
        text-transform: uppercase;
        letter-spacing: (2/16+em);
        padding: 20px 20px 18px;
    }

    .gform_footer {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 3;
    }

    .gform_ajax_spinner {
        top: 15px;
        right: 100px;
    }
}

.v-block-banner-info {
    margin: 40px 0 0;
}

.v-banner-info {
    min-height: 300px;
    margin: 0;

    &__header {
        padding-left: 0;
        padding-right: 0;
    }

    &__image {
        @include img-drop(700, 520);

        @include tablet-up {
            padding-bottom: 0;
            height: 100%;
        }
    }

    &__body {
        padding: 20px 15px;
        background-color: $green;
        color: #ffffff;

        @include tablet-up {
            padding: 30px 60px;
        }
    }

    &__content {
        max-width: 600px;
    }

    &__subtitle {
        margin-bottom: 0;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        font-family: $font-Cir;
        font-weight: 500;

        @include tablet-up {
            font-size: 18px;
        }
    }

    &__title {
        margin-bottom: 1rem;
        font-family: $font-Cir;
        font-weight: bold;
    }

    &__description {
        margin-bottom: 24px;
    }

    &__more {
        p {
            font-weight: 500;
            margin-bottom: 10px;
        }
    }

    &__button {
        display: inline-block;
        background-color: #fff;
        border: none;
        border-radius: 0;
        padding: 14px 20px;
        font-size: 14px;
        color: $green;
        text-align: left;
        outline: none;

        &:hover {
            text-decoration: none;
            color: $green;
        }

        &:focus {
            outline: none;
        }
    }
}