.banner-testimonials {
    @include tablet-up {
        padding-top: 109px;
        padding-bottom: 109px;
    }
    .img-drop {
        width: 100%;
        height: 139/375*100%;
        max-height: 100% !important;
        min-height: 100% !important;
        @include tablet-up {
            position: absolute;
            top: 0;
            left: 0;
            height: 100% !important;
        }
    }
    .text-cover.big {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        background-color: $white;
        padding-bottom: 40px;
        @include tablet-down {
            padding-right: 16px;
            padding-left: 16px;
        }
        .title-page {
            @include mobile-down {
                font-size: 32px;
                margin-bottom: 16px;
            }
        }
    }
}
.testimonials-content {
    .container {
        @include desktop-up {
            max-width: 1190px;
        }
    }
    .tab-navi-learn {
        margin-bottom: 100px;
        position: relative;
        z-index: 1;
        @include tablet-down {
            padding-top: 16px;
            padding-bottom: 16px;
            margin-bottom: 40px;
        }
        li {
            flex-basis: 100%;
            @include mobile-up {
                flex-basis: auto;
            }
            &:not(:last-child) {
                margin-right: 0;
                @include mobile-up {
                    margin-right: 35px;
                }
            }
            a {
                text-align: left;
                display: inline-block;
                @include mobile-up {
                    text-align: center;
                }
            }
        }
        &.nav-sticky-onscroll {
            &.stick {
                @include tablet-up {
                    position: fixed;
                    left: 0;
                    right: 0;
                    background: $gray-medium;
                    top: 0;
                    padding-top: 60px;
                    z-index: 2;
                    + .tab-content {
                        padding-top: 189px;
                    }
                }
            }
        }
    }
    .wrapper-testimonials {
        .tab-content-testimonials {
            min-height: 600px;
            padding-bottom: 60px;
            @include tablet-up {
                padding-bottom: 220px;
            }
            &.show-all {
                display: block;
                opacity: 1;
            }
        }
        .wrap-img {
            position: relative;
            margin-right: auto;
            margin-left: auto;
            @include tablet-down {
                @include max-width-center(500px);
                margin-bottom: 70px;
                padding-top: 60px;
            }
            .img-drop {
                padding-top: 216/287*100%;
                position: relative;
                z-index: 1;
            }
            .img-trans {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
                img {
                    width: 100%;
                }
            }
        }
        .wrap-title-desktop {
            margin-bottom: 24px;
            @include tablet-down {
                display: none;
            }
        }
        .wrap-title-mobile {
            text-align: center;
            @include tablet-up {
                display: none;
            }
        }
        .title-page {
            margin-bottom: 0;
            @include tablet-up {
                font-size: 48px;
            }
        }
        .text-green {
            font-family: $font-Cir;
            font-size: 16px;
            font-weight: 700;
            line-height: 50px;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: $green;
            @include mobile-up {
                font-size: 22px;
            }
        }
        .wrap-blockquote {
            background-color: $gray-2;
            border-radius: 8px;
            padding: 16px;
            margin-bottom: 16px;
            .wrap-content-blockquote {
                &::-webkit-scrollbar {
                    width: 4px;
                }
                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                }
                &::-webkit-scrollbar-thumb {
                    background-color: $green;
                    outline: 1px solid slategrey;
                }
                &.has-scroll {
                    max-height: 285px;
                    overflow-y: scroll;
                    overflow-x: auto;
                    padding-right: 20px;
                }
            }
            @include mobile-up {
                padding: 30px 30px 25px;
                margin-bottom: 20px;
            }
            blockquote {
                padding-left: 36px;
                background: $icon_blockquote no-repeat left top 5px/26px auto;
                line-height: 20px;
                letter-spacing: -0.2px;
                margin-bottom: 10px;
                min-height: 26px;
                @include mobile-up {
                    padding-left: 50px;
                    font-size: 18px;
                    line-height: 28px;
                    background-size: 30px auto;
                }
            }
            .signature {
                font-weight: 700;
                line-height: 20px;
                padding-left: 36px;
                &.has-padding {
                    padding-top: 15px;
                }
                @include mobile-up {
                    line-height: 28px;
                    font-size: 18px;
                    padding-left: 50px;
                }
                span {
                    font-weight: 400;
                    color: $gray-3;
                }
            }
        }
        .btn-common {
            margin-top: 20px;
            @include mobile-up {
                margin-top: 40px;
            }
        }
    }
    #testimonials-1 {
        .wrap-img {
            @include tablet-up {
                padding-top: 135px;
            }
        }
        .img-trans {
            top: -28%;
            left: -45%;
            @include tablet-up {
                top: -10%;
            }
        }
    }
    #testimonials-2 {
        .wrap-img {
            @include mobile-up {
                margin-top: 45px;
            }
        }
        .img-trans {
            top: -10%;
            left: -7%;
            max-width: 50%;
            @include mobile-up {
                top: -40%;
                max-width: 100%;
            }
        }
    }
    #testimonials-3 {
        padding-bottom: 80px;
        @include tablet-up {
            padding-bottom: 160px;
        }
        .wrap-img {
            @include tablet-up {
                margin-top: 175px;
            }
        }
        .img-trans {
            top: 5%;
            left: -10%;
            max-width: 50%;
            @include tablet-up {
                top: -60%;
                left: -28%;
                max-width: 100%;
            }
        }
    }
    #testimonials-4 {
        .wrap-img {
            margin-top: 0;
            @include max-width-center(300px);
            padding-top: 16px;
            margin-bottom: 30px;
            @include tablet-up {
                max-width: 450px;
                margin-top: 40px;
                margin-left: auto;
                margin-right: 0;
            }
        }
        .wrap-title-desktop {
            margin-bottom: 16px;
            @include mobile-up {
                margin-bottom: 50px;
            }
        }
        .img-drop {
            padding-top: 614/537*100%;
        }
    }
    #testimonials-5 {
        padding-bottom: 0;
        .bg-img {
            position: relative;
            background-position: top left;
            background-repeat: no-repeat;
            background-size: auto 100%;
        }
        .wrapper-custom {
            display: flex;
            flex-wrap: wrap;
            @include tablet-up {
                flex-wrap: nowrap;
            }
            .bg-img {
                width: 100%;
                height: 217/375*100%;
                @include tablet-up {
                    flex: 0 0 42%;
                    max-width: 42%;
                    height: auto;
                }
                img {
                    width: 100%;
                }
            }
            .wrap-content {
                padding: 20px 16px;
                flex-basis: 100%;
                @include mobile-up {
                    padding: 50px 0;
                }
                @include tablet-up {
                    flex: 0 0 58%;
                    max-width: 58%;
                    padding: 100px 0;
                }
                .wrap-inner {
                    @include max-width-center(650px);
                }
                .title-page {
                    margin-bottom: 16px;
                    text-align: center;
                    @include mobile-up {
                        margin-bottom: 50px;
                        text-align: left;
                    }
                }
            }
        }
    }
}
.desktop-show {
    @include tablet-down {
        display: none;
    }
}
.mobile-show {
    @include tablet-up {
        display: none;
    }
}
.testimonials-submit {
    background-color: $green-2;
    padding-top: 30px;
    padding-bottom: 60px;
    background-repeat: no-repeat, no-repeat;
    background-position: top -50px left -100px, right -50px bottom 0;
    background-size: 100% auto, 50% auto;
    @include mobile-up {
        padding-top: 100px;
        padding-bottom: 100px;
        background-size: 80% auto, auto;
        background-position: top -15% left -60%, right 0% bottom -2%;
    }
    @include tablet-up {
        background-size: auto, auto;
        background-position: top left -4%, right 15% bottom 10%;
    }
    .title-page {
        margin-bottom: 16px;
        text-align: center;
        @include mobile-up {
            text-align: left;
            margin-bottom: 40px;
        }
    }
    .wrap-form-submit {
        ul {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .form-row {
                margin-bottom: 16px;
                flex-basis: 100%;
                @include mobile-up {
                    margin-bottom: 30px;
                }
                &.col-half {
                    @include tablet-up {
                        flex-basis: calc(50% - 30px);
                    }
                }
                &.col-full {
                    flex-basis: 100%;
                }
            }
        }
        label {
            font-family: $font-Cir;
            font-weight: 700;
            line-height: 23px;
            display: block;
            @include mobile-up {
                font-size: 18px;
            }
        }
        input:not([type='submit']),
        textarea {
            @include reset-input;
            @include reset-autofill;
            background-color: $green-2;
            width: 100%;
            border: 1px solid $black;
            padding: 13px 16px;
            @include mobile-up {
                padding: 23px 25px;
            }
        }
        input[type='submit'] {
            @include reset-input;
            max-width: 270px;
            width: 100%;
            text-align: center;
            letter-spacing: 2px;
            text-transform: uppercase;
            background-color: $black;
            color: $white;
            padding-top: 20px;
            padding-bottom: 20px;
            cursor: pointer;
            transition: opacity 0.5s ease;
            &:hover {
                opacity: 0.7;
            }
        }
    }
    .validation_error {
        display: none;
    }
    .gform_footer {
        position: relative;
        .gform_ajax_spinner {
            position: absolute;
            top: 15px;
            width: 30px;
            height: 30px;
            right: auto;
        }
    }
}
