.ven-general-template {
    font-size: 18px;
    line-height: (28/18);
    padding: 40px 60px;
    background-color: $gray-medium;

    @include desktop-up {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    h2,
    h3,
    h4,
    h5 {
        font-weight: bold;
        margin-bottom: 30px;
    }
    h5 {
        font-size: 26px;
        font-weight: normal;
    }
    a {
        color: #4a90e2;

        &:hover {
            text-decoration: underline;
        }
    }

    hr {
        margin: 70px 0;
    }

    ol {
        counter-reset: my-awesome-counter;

        &:not(:first-child) {
            margin-top: 80px;
        }

        > li {
            counter-increment: my-awesome-counter;
            padding-left: 45px;
            position: relative;

            &::before {
                content: counter(my-awesome-counter) ".";
                font-family: $font-Cir;
                font-size: 28px;
                font-weight: bold;
                line-height: 1;
                position: absolute;
                top: -3px;
                left: 0;
            }

            &:not(:last-child) {
                margin-bottom: 40px;
            }
        }
    }

    ul {
        list-style: initial;
        list-style-position: outside;
        margin-left: 1em;

        li {
            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }
    }

    li > ul {
        margin-top: 20px;
    }
}
