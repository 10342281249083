.logo-subscription {
    img {
        max-width: 145px;
        height: auto;
    }
}
.modal-form {
    .modal-dialog {
        margin: 1rem 1.3rem;
        min-height: calc(100% - 5.5rem);
        @include small-mobile {
            min-height: calc(100% - 1.8rem);
        }
        @include small-mobile-up {
            margin-top: 2rem;
            margin-bottom: 2rem;
            margin-left: auto;
            margin-right: auto;
            max-width: 600px;
        }
    }
    .modal-content {
        border-radius: 0;
    }
    .wrap-info-form {
        @include max-width-center(365px);
        padding-bottom: 25px;
    }
    .logo-subscription {
        position: relative;
        padding-bottom: 20px;
        margin-bottom: 15px;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 38px;
            height: 1px;
            background: #231F20;
        }
    }
    .close-form {
        @include reset-input;
        position: absolute;
        top: 0;
        right: 0;
        background: #ebebeb;
        border-radius: 50%;
        transform: translate(50%, -50%);
        outline: none;
        cursor: pointer;
        width: 40px;
        height: 40px;
        @include small-mobile-up {
            width: 50px;
            height: 50px;
        }
    }
    .close {
        width: 11px;
        height: 11px;
    }
    .modal-content {
        padding: 17px;
        background: #fff;
        @include small-mobile-up {
            padding: 36px;
        }
    }
    .modal-body {
        background: url('../img/vege-bg.png') 7px 10px / auto 60% no-repeat;
        background-color: #F4F2ED;
        padding: 15px;
        @include small-mobile-up {
            padding: 30px 15px;
            background: url('../img/vege-bg.png') 7px 10px / auto 60% no-repeat, url('../img/ginger-bg.png') right 30px bottom 35% / auto 25% no-repeat;
            background-color: #F4F2ED;
        }
    }
    .title-form {
        font-family: $font-Cir;
        font-weight: 900;
        font-size: 16px;
        line-height: 40/22*1em;
        letter-spacing: 2.5px;
        color: #231F20;
        text-transform: uppercase;
        margin-bottom: 10px;
        @include small-mobile-up {
            font-size: 22px;
            letter-spacing: 5px;
        }
    }
    .percent-off {
        font-weight: 900;
        font-size: 70px;
        line-height: 100%;
        letter-spacing: 26px;
        color: #62BD89;
        position: relative;
        display: inline-block;
        z-index: 2;
        text-transform: uppercase;
        margin-bottom: 10px;
        font-family: $font-Cir;
        @include small-mobile-up {
            font-size: 90px;
        }
        .text-shadow {
            color: #000;
            position: absolute;
            z-index: -1;
            top: 5px;
            left: 6px;
            @include small-mobile-up {
                top: 6px;
                left: 9px;
            }
        }
    }
    .required-condition {
        @include max-width-center(230px);
        font-size: 16px;
        line-height: 28/16*1em;
        letter-spacing: -0.195652px;
        color: #231F20;
        font-family: $font-Cir;
        margin-bottom: 15px;
    }
    .bottomline-text {
        position: relative;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: -0.195652px;
        color: #9B9B9B;
        padding-top: 10px;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 207px;
            height: 1px;
            background: #D8D8D8;
        }
    }
    .wrap-form-popup {
        position: relative;
        @include max-width-center(365px);
        .validation_error {
            display: none;
        }
        .gfield_description.validation_message {
            padding-top: 10px;
            font-size: 12px;
            font-weight: 500;
        }
        .gform_ajax_spinner {
            position: absolute;
            top: -3px;
            right: -20px;
        }
        .gform_confirmation_message {
            text-align: center;
            font-weight: 500;
        }
        .gform_body {
            .gfield_label {
                display: none;
            }
            .gfield {
                margin-bottom: 25px;
            }
            .no-mg {
                margin-bottom: 0;
            }
            .ginput_container_email {
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    width: calc(100% + 6px);
                    height: 100%;
                    top: 6px;
                    left: 0;
                    z-index: 0;
                    border-right: 2px solid #62BD89;
                    border-bottom: 2px solid #62BD89;
                }
            }
            input[type=text],
            input[type=email] {
                @include reset-input;
                @include reset-autofill;
                font-family: $font-Av;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: -0.195652px;
                padding: 15px 105px 15px 15px;
                width: 100%;
                color: #000;
                background: transparent;
                border: 1px solid #000000;
                position: relative;
                z-index: 2;
                @include small-mobile-up {
                    padding: 18px 116px 18px 20px;
                }
                @include placeholder {
                    color: #9B9B9B;
                }
            }
            .gfield_checkbox {
                &>li {
                    input {
                        display: none;
                        &:checked {
                            &+label:after {
                                opacity: 1;
                            }
                        }
                    }
                    label {
                        position: relative;
                        padding-left: 41px;
                        font-size: 14px;
                        line-height: 20px;
                        color: #000000;
                        margin-bottom: 0;
                        cursor: pointer;
                        &:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: -3px;
                            width: 26px;
                            height: 26px;
                            border: 1px solid #000;
                        }
                        &:after {
                            content: '';
                            position: absolute;
                            left: 3px;
                            top: 0px;
                            width: 20px;
                            height: 20px;
                            background: #000;
                            opacity: 0;
                            transition: opacity .3s ease;
                        }
                    }
                    a {
                        font-family: $font-Cir;
                        font-weight: 700;
                        display: inline-block;
                        position: relative;
                        &:after {
                            content: '';
                            position: absolute;
                            bottom: -1px;
                            left: 0;
                            width: 100%;
                            height: 1px;
                            background-color: #62BD89;
                        }
                    }
                }
            }
        }
        .gform_footer {
            position: absolute;
            z-index: 5;
            top: 15px;
            right: 15px;
            @include small-mobile-up {
                right: 20px;
                top: 18px;
            }
            input[type=submit] {
                @include reset-input;
                background: transparent;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 2px;
                text-transform: uppercase;
                font-family: $font-Cir;
                color: #000000;
                cursor: pointer;
                transition: all .3s ease;
                &:hover {
                    opacity: .7;
                }
            }
        }
    }
    .gform_not_found {
        text-align: center;
    }
}