.ven-quantity {
    display: flex;
    width: 62px;
    border-bottom: thin solid #000;

    input {
        width: 100%;
        font-size: 16px;
        flex: 1;
        background-color: transparent;
        border: none;
        border-radius: 0;
        padding: 0 5px;
        appearance: none;
        text-align: center;
        color: #000;
        outline: none;
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        @include tablet-up {
            font-size: 14px;
        }
    }

    button {
        background-color: transparent;
        border: none;
        border-radius: 0;
        padding: 0;
        outline: none;
        cursor: pointer;
    }
}

.ven-cart {
    background-color: #f4f2ed;
    padding: 100px 0 50px;

    @include tablet-up {
        padding: 150px 60px 110px;
    }

    &__row {
        > div:not(:last-child) {
            @include tablet-down {
                margin-bottom: 60px;
            }
        }
    }
}

.ven-cart-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.02;
    letter-spacing: 0.06em;
    margin-bottom: 25px;

    @include tablet-up {
        font-size: 38px;
    }
}

.ven-order-summary-title {
    font-family: $font-Cir;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.02;
    text-transform: uppercase;

    @include tablet-up {
        font-size: 32px;
    }
}

.table-list {
    margin-left: auto;
    width: 100%;
    max-width: 660px;
    table-layout: fixed;
    margin-bottom: 20px;
    font-weight: 500;
    @include big-desktop {
        max-width: 660*$rem;
    }
    @include tablet-down {
        margin-left: 0;
        table-layout: unset;
        max-width: 100%;
    }
    @include break-max(640px) {
        border: 0;
        thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
        tbody {
            &>tr {
                display: block;
                margin-bottom: 15px;
                padding: 10px;
                background: #F8F6F4;
                border: 0 !important;
            }
            td {
                width: auto;
                border-bottom: 1px solid $border;
                display: block;
                font-size: .9em;
                text-align: right;
            }
            td:before {
                content: attr(data-label) !important;
                float: left;
                font-weight: bold;
                text-transform: uppercase;
                padding-right: 15px;
                text-align: left;
            }
            td:last-child {
                border-bottom: 0;
            }
        }
    }
    thead {
        &>tr {
            border-bottom: 1px solid $border;
            &>td {
                padding-bottom: 16px;
                font-size: 20px;
                font-weight: 700;
                font-family: $font-Cir;
                vertical-align: bottom;
                &:last-child {
                    text-align: right;
                }
                @include break-min(2000px) {
                    padding-bottom: 10 * $rem;
                }
                @include break-max(640px) {
                    padding-bottom: 10px;
                }
            }
        }
    }
    tbody {
        &>tr {
            border-bottom: 1px solid $border;
            &>td {
                padding: 10px 0;
                @include break-min(2000px) {
                    padding: 10 * $rem 0;
                }
                @include break-max(640px) {
                    padding: 10px 0;
                    vertical-align: top;
                }
            }
        }
        &>tr.coupon-cart-row {
            border-bottom: 0;
            &>td {
                padding-top: 30px;
                @include break-max(640px) {
                    padding-top: 10px;
                }
            }
        }
        &>tr.cart_item,
        &>tr.wishlist_item {
            &>td {
                &:last-child {
                    text-align: right;
                }
            }
        }
        &>tr.wishlist_item {
            &>td {
                vertical-align: middle;
            }
        }
    }
    button[name=update_cart] {
        font-family: $font-Cir;
        cursor: pointer;
        color: #fff;
        display: inline-flex;
        letter-spacing: 1.2px;
        align-items: center;
        height: 35px;
        font-weight: 500;
        font-size: 16px;
        padding: 5px 20px;
        border: 0;
        max-width: 210px;
        width: 100%;
        background: #000 url(../../src/img/arrows-rotate.svg) center right 20px/ 18px 18px no-repeat;
        outline: 0 !important;
        &[disabled] {
            opacity: .5;
            &:hover {
                opacity: .5;
                cursor: not-allowed;
            }
        }
        &:hover {
            opacity: .9;
        }
        @include break-min(2000px) {
            height: 60 * $rem;
            padding: 5*$rem 20*$rem;
            max-width: 210*$rem;
            font-size: 16*$rem;
            background: #000 url(../../src/img/arrows-rotate.svg) center right (20*$rem)/ (18*$rem) (18*$rem) no-repeat;
        }
        @include mobile-down {
            max-width: 100%;
            margin-bottom: 15px;
        }
    }
}

.coupon {
    display: inline-flex;
    float: right;
    position: relative;
    @include mobile-down {
        width: 100%;
        float: none;
    }
    input {
        margin-left: 10px;
        width: 395px;
        @include reset-input;
        @include reset-autofill;
        border: 1px solid #000;
        padding: 0 100px 0 30px;
        height: 60px;
        font-size: 16px;
        letter-spacing: -.17px;
        line-height: 1em;
        font-weight: 400;
        display: inline-block;
        @include break-min(2000px) {
            width: 395 * $rem;
            font-size: 16 * $rem;
            height: 60 * $rem;
            padding: 0 100*$rem 0 30*$rem;
        }
        @include tablet-down {
            width: 300px;
        }
        @include mobile-down {
            width: 100%;
            margin-left: 0;
        }
        @include placeholder {
            color: #9b9b9b;
        }
    }
    button[type=submit] {
        @include reset-input;
        font-family: $font-Cir;
        font-weight: 700;
        font-size: 16px;
        cursor: pointer;
        background: #fff;
        color: #000;
        display: inline-flex;
        letter-spacing: 2px;
        align-items: center;
        justify-content: center;
        height: 50px;
        padding: 5px 20px;
        text-transform: uppercase;
        border: 0;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        &:hover {
            opacity: .7;
        }
        @include break-min(2000px) {
            height: 50 * $rem;
            padding: 5*$rem 20*$rem;
            font-size: 16*$rem;
        }
    }
    .wrap-coupon-complete {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .wrap-txt {
            font: 700 16px/19px $font-Cir;
        }
        .wrap-price {
            font-size: 16px;
            .woocommerce-Price-amount {
                display: inline-block;
                margin-left: 5px;
                margin-right: 10px;
                font-weight: 700;
            }
        }
    }
}