.v-fordev {
  color: black;

  #ven-fordev-toggler {
    display: none;

    &:checked + .v-fordev__inner {
      transform: translateX(0);
    }
  }

  &__inner {
    width: 150px;
    position: fixed;
    top: 100px;
    right: 0;
    z-index: 999999;
    transform: translateX(100%);
    transition: all 300ms ease-out;
    z-index: 999999;
    font-size: 12px;
  }

  &__header {
    display: block;
    width: 32px;
    height: 32px;
    line-height: 32px;
    position: absolute;
    top: 0;
    right: 100%;
    margin-bottom: 0;
    cursor: pointer;
    background-color: #000;
    color: #fff;
    text-align: center;
    box-shadow: 1px 2px 10px rgba(85, 85, 85, 0.2);

    img {
      display: block;
      width: 100%;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    background-color: #eeeeee;
    max-height: 70vh;
  }

  &__title {
    padding: 10px 10px 0;
  }

  &__content {
    padding: 10px 0;
    flex: 1 1 auto;
    overflow-y: auto;
  }
}

.v-fordev-list {
  margin-bottom: 0;
  padding-left: 0;

  li {
    list-style: none;

    a {
      display: block;
      color: #007bff;
      padding: 5px 10px;
      text-decoration: none;

      &:hover,
      &:focus {
        color: black;
        text-decoration: none;
        background-color: #f2f2f2;
      }
    }
  }
}
