/*---------------------------------
**** Objects | Loading
-----------------------------------*/
$icon-loading-size: 1.5em;
$icon-spacing: 5px;

.v-loading {
  position: relative;
  color: transparent;
  pointer-events: none;

  &:hover {
    color: transparent;
  }

  &::before {
    content: "";
    display: inline-block;
    width: $icon-loading-size;
    height: $icon-loading-size;
    background-image: url("../img/loading-2.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: $icon-loading-size auto;
    vertical-align: middle;
    position: absolute;
    top: calc(50% - #{$icon-loading-size / 2});
    left: calc(50% - #{$icon-loading-size / 2});
  }

  svg {
    opacity: 0;
  }
}