@charset "UTF-8";
@font-face {
  font-family: 'Avenir Next';
  src: url("../fonts/AvenirNext-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/AvenirNext-Regular.woff2") format("woff2"), url("../fonts/AvenirNext-Regular.woff") format("woff"), url("../fonts/AvenirNext-Regular.ttf") format("truetype");
  /* Safari, Android, iOS */
  /* Legacy iOS */
  font-weight: 400;
}

@font-face {
  font-family: 'Avenir Next';
  src: url("../fonts/AvenirNext-Medium.woff2") format("woff2"), url("../fonts/AvenirNext-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url("../fonts/AvenirNext-DemiBold.woff2") format("woff2"), url("../fonts/AvenirNext-DemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url("../fonts/avenir-next-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/avenir-next-bold.woff2") format("woff2"), url("../fonts/avenir-next-bold.woff") format("woff"), url("../fonts/avenir-next-bold.ttf") format("truetype");
  /* Safari, Android, iOS */
  /* Legacy iOS */
  font-weight: 700;
}

@font-face {
  font-family: 'Circular Std';
  src: url("../fonts/CircularStd-Bold.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/CircularStd-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/CircularStd-Bold.woff2") format("woff2"), url("../fonts/CircularStd-Bold.woff") format("woff"), url("../fonts/CircularStd-Bold.ttf") format("truetype");
  /* Safari, Android, iOS */
  /* Legacy iOS */
  font-weight: 700;
}

@font-face {
  font-family: 'Circular Std';
  src: url("../fonts/CircularStd-Book.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/CircularStd-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/CircularStd-Book.woff2") format("woff2"), url("../fonts/CircularStd-Book.woff") format("woff"), url("../fonts/CircularStd-Book.ttf") format("truetype");
  /* Safari, Android, iOS */
  /* Legacy iOS */
  font-weight: 500;
}

@font-face {
  font-family: 'Circular Std';
  src: url("../fonts/CircularStd-BookItalic.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/CircularStd-BookItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/CircularStd-BookItalic.woff2") format("woff2"), url("../fonts/CircularStd-BookItalic.woff") format("woff"), url("../fonts/CircularStd-BookItalic.ttf") format("truetype");
  /* Safari, Android, iOS */
  /* Legacy iOS */
  font-weight: 500;
  font-style: italic;
}

/* VAR */
/* FONT */
/* BACKGROUND IMAGE */
/* MIXIN*/
/* FORM */
.ven-h, .db-purchase__title, .db-program__title, .db-recipe__title, .db-post__title, .db-meal-card__body {
  font-family: "Circular Std";
  font-weight: 700;
  line-height: 1.2;
}

.ven-h2 {
  font-size: 36px;
}

@media (min-width: 992px) {
  .ven-h2 {
    font-size: 64px;
  }
}

.ven-h5 {
  font-size: 16px;
  letter-spacing: 0.125em;
  text-transform: uppercase;
}

.ven-bg-light {
  background-color: #f4f2ed;
}

.ven-bg-bluelight {
  background-color: #e7e9f5;
}

.ven-bg-greenlight {
  background-color: #dff4eb;
}

.ven-bg-purplelight {
  background-color: #d7d3ea;
}

.container-max {
  max-width: 1300px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
}

@media (max-width: 1299px) {
  .container-max {
    padding-left: 15px;
    padding-right: 15px;
  }
}

svg[role="img"] {
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  fill: currentColor;
}

.img-drop {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.img-drop img,
.img-drop video,
.img-drop iframe,
.img-drop embed,
.img-drop object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
  transform: translateZ(0);
}

.img-drop.contain-image img {
  object-fit: contain;
}

@media (max-width: 767px) {
  .img-drop video,
  .img-drop iframe,
  .img-drop embed,
  .img-drop object {
    object-fit: fill;
  }
}

.img-drop .cap {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
}

.img-drop.ratio-11 {
  padding-top: 100%;
}

.img-drop.ratio-169 {
  padding-top: 56.25%;
}

.img-drop.ratio-43 {
  padding-top: 75%;
}

@media (max-width: 767px) {
  .img-drop.sm-no-drop {
    padding-top: 0;
  }
  .img-drop.sm-no-drop img {
    position: static;
  }
}

a {
  outline: 0;
}

#wrap-page {
  position: relative;
}

.owl-fix-android {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 1;
}

.tab-content.tabs-anim {
  transition: all 0.8s ease;
  overflow: hidden;
}

/**
*
* BACKDROP
*
**/
.backdrop-core {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: rgba(255, 255, 255, 0.4);
  transition: opacity 0.5s ease;
  opacity: 0;
}

.backdrop-core.opening {
  opacity: 1;
}

.backdrop-core.top-menu {
  background: transparent;
}

/* PRELOADER */
#preloader {
  position: fixed;
  transform: translate3d(0, 0, 0);
  background: #fff;
  top: 0;
  left: 0;
  z-index: 9999999;
  width: 100%;
  height: 100%;
}

#preloader .loading {
  position: absolute;
  top: 48%;
  left: 50%;
  width: 80px;
  height: 80px;
  background: transparent url("../img/loading.svg") no-repeat center/100% auto;
  transform: translate3d(-50%, -50%, 0);
}

.gform_ajax_spinner {
  position: absolute;
  top: 50%;
  right: 50px;
  width: 30px;
  height: 30px;
}

/* LOADING CORE */
.ven-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
  z-index: 11;
}

.ven-loading.opening {
  opacity: 1;
  visibility: visible;
}

.ven-loading .img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  border-radius: 2px;
}

/* VENCREATIVE NOTI */
.ven-noti {
  position: fixed;
  top: 10vh;
  right: 0;
  min-width: 300px;
  padding: 30px 20px;
  background: #000;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  color: #fff;
  z-index: 99999;
  font-size: 18px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.3);
  transform: translate3d(100%, 0, 0);
  transition: 0.4s ease;
  opacity: 0;
}

.ven-noti.opening {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

@media (max-width: 767px) {
  .ven-noti {
    width: 100%;
    border-radius: 0;
  }
}

.ven-img-drop,
.ven-img-drop-contain {
  display: block;
  position: relative;
  overflow: hidden;
}

.ven-img-drop img,
.ven-img-drop-contain img {
  display: block;
  width: 100%;
  height: 100%;
  object-position: 50% 50%;
  position: absolute;
  left: 0;
  top: 0;
}

.ven-img-drop img {
  object-fit: cover;
}

.ven-img-drop-contain img {
  object-fit: contain;
}

.ven-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.ven-icon-minus,
.ven-icon-plus {
  font-size: 7px;
  width: 1em;
  height: 1em;
}

.ven-icon-minus::before,
.ven-icon-plus::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: currentColor;
}

.ven-icon-plus {
  position: relative;
}

.ven-icon-plus::after {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  background-color: currentColor;
  position: absolute;
  top: 0;
  left: calc((1em - 1px) / 2);
}

.ven-icon-remove {
  width: 1em;
  height: 1em;
  position: relative;
}

.ven-icon-remove::before, .ven-icon-remove::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: currentColor;
  position: absolute;
  left: 0;
  top: 50%;
}

.ven-icon-remove::before {
  transform: rotate(45deg);
}

.ven-icon-remove::after {
  transform: rotate(-45deg);
}

.ven-general {
  font-size: 18px;
}

.ven-general h2,
.ven-general h3,
.ven-general h4,
.ven-general h5,
.ven-general h6 {
  font-family: "Circular Std";
  font-weight: 700;
  line-height: 1.25;
  margin-top: 20px;
  margin-bottom: 10px;
}

.ven-general h2:first-child,
.ven-general h3:first-child,
.ven-general h4:first-child,
.ven-general h5:first-child,
.ven-general h6:first-child {
  margin-top: 0 !important;
}

.ven-general p + *:not(p) {
  margin-top: 50px;
}

.ven-general h2 {
  font-size: 36px;
}

@media (min-width: 992px) {
  .ven-general h2 {
    font-size: 48px;
  }
}

.ven-general h3 {
  font-size: 28px;
}

@media (min-width: 992px) {
  .ven-general h3 {
    font-size: 32px;
  }
}

.ven-general h4 {
  font-size: 24px;
}

.ven-general h5 {
  font-size: 18px;
}

.ven-general h6 {
  font-size: 16px;
}

.ven-general ul {
  list-style: initial;
  padding-left: 1em;
}

.ven-general ul li {
  margin-bottom: 16px;
}

.ven-modal-default .modal-content {
  border-radius: 0;
  border: none;
}

.ven-modal-default .close {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #ebebeb;
  border: none;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 24px;
  z-index: 5;
  outline: none;
  cursor: pointer;
}

@media (min-width: 992px) {
  .ven-modal-default .close {
    width: 50px;
    height: 50px;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    font-size: 32px;
  }
}

@media (min-width: 992px) {
  .ven-modal-default .modal-body {
    padding: 36px;
  }
}

body {
  --offset-top: 0px;
  font-family: "Avenir Next";
  color: #000;
  -webkit-font-smoothing: antialiased;
}

body.admin-bar {
  --offset-top: 46px;
}

@media (max-width: 600px) {
  body.admin-bar.admin-bar--hidden {
    --offset-top: 0px;
  }
}

@media (min-width: 782px) {
  body.admin-bar {
    --offset-top: 32px;
  }
}

@media print {
  body {
    background-color: #ffffff !important;
  }
}

body .menu-top.right .login {
  display: block;
}

body .menu-top.right .info-user {
  display: none;
}

body.logged .menu-top.right .login {
  display: none;
}

body.logged .menu-top.right .info-user {
  display: block;
}

body.logged .menu-top.right .info-user a {
  pointer-events: none;
}

@media (min-width: 992px) {
  .container.normal {
    max-width: 1350px;
  }
}

.checkout-page .btn-cart {
  display: none;
}

.checkout-page .icon-x {
  display: block !important;
}

.checkout-page .footer-page {
  display: none;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  color: #000;
}

a:hover {
  text-decoration: none;
  color: #000;
}

.icon {
  display: inline-block;
}

.icon.spinner {
  width: 70px;
  height: 70px;
  background: url(../img/loading.svg) center/contain no-repeat;
}

.icon.person {
  width: 15px;
  height: 15px;
  background: url(../img/person-icon.png) center/contain no-repeat;
}

.icon.tel {
  width: 22px;
  height: 22px;
  background: url(../img/icon-phone.svg) no-repeat center/auto 100%;
}

.icon.fb-square {
  width: 25px;
  height: 25px;
  background: url(../img/icon-fb-square.svg) no-repeat center/auto 100%;
}

.icon.mail {
  width: 22px;
  height: 22px;
  background: url(../img/icon-mail.svg) no-repeat center/auto 100%;
}

.icon.pdf-icon {
  width: 18px;
  height: 23px;
  background: url(../img/pdf.svg) no-repeat center/auto 100%;
}

.icon.icon-x {
  width: 42px;
  height: 42px;
  background: url(../img/icon-x.svg) no-repeat center/auto 100%;
}

.icon.arrow-down {
  width: 24px;
  height: 13px;
  background: url(../img/arrow-down.svg) center center/auto 100% no-repeat;
}

.icon.loading {
  width: 24px;
  height: 24px;
  background: url(../img/loading.svg) center center/auto 42px no-repeat;
}

.icon.close {
  width: 13px;
  height: 13px;
  background: url(../img/icon-close.svg) center center/auto 100% no-repeat;
}

.icon.locker {
  width: 40px;
  height: 47px;
  background: url(../img/icon-locker.svg) center center/auto 100% no-repeat;
}

.icon.icon-face {
  background: url("../img/smile-icon.svg") center center/contain no-repeat;
}

.icon.icon-phone {
  background: url("../img/phone-icon.svg") center center/contain no-repeat;
}

.icon.icon-skype {
  background: url("../img/skype-icon.svg") center center/contain no-repeat;
}

.icon.edit-icon {
  width: 20px;
  height: 20px;
  background: url("../img/edit-icon.svg") center center/contain no-repeat;
}

@media (min-width: 992px) {
  .scrollpage {
    padding-top: 60px;
  }
}

@media (min-width: 1600px) {
  .scrollpage {
    padding-top: 3vw;
  }
}

.section-scroll {
  min-height: 600px;
}

@media (min-width: 992px) {
  .section-scroll {
    padding-right: 60px;
    padding-left: 60px;
  }
}

@media (min-width: 992px) {
  .section-scroll.scroll-effect {
    height: 660px;
  }
}

@media (min-width: 1600px) {
  .section-scroll.scroll-effect {
    height: 30vw;
  }
}

@media (min-width: 768px) {
  .row-center {
    position: relative;
    display: flex;
    align-items: center;
  }
  .row-center .row {
    align-items: center;
  }
}

.wrap-text-4-item,
.wrap-product-information {
  position: relative;
  z-index: 1;
}

.wrap-text-4-item .text-green,
.wrap-product-information .text-green {
  color: #62bd89;
  font-family: "Circular Std";
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

@media (min-width: 1600px) {
  .wrap-text-4-item .text-green,
  .wrap-product-information .text-green {
    font-size: 0.7vw;
  }
}

.title-medium {
  font-family: "Circular Std";
  font-size: 24px;
  font-weight: 700;
  text-transform: capitalize;
  color: #000;
}

@media (min-width: 992px) {
  .title-medium {
    font-size: 32px;
  }
}

.wrap-text-4-item .title-small,
.wrap-product-information .title-small {
  font-family: "Circular Std";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.195652px;
  color: #9b9b9b;
  margin-top: 10px;
}

@media (min-width: 1600px) {
  .wrap-text-4-item .title-small,
  .wrap-product-information .title-small {
    font-size: 0.8vw;
  }
}

.wrap-text-4-item .desc,
.wrap-product-information .desc {
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .wrap-text-4-item .desc,
  .wrap-product-information .desc {
    font-size: 18px;
  }
}

.wrap-text-4-item .learn-more,
.wrap-product-information .learn-more {
  font-family: "Circular Std";
  font-weight: 700;
  margin-bottom: 20px;
  display: inline-block;
  position: relative;
}

@media (max-width: 991px) {
  .wrap-text-4-item .learn-more,
  .wrap-product-information .learn-more {
    margin-bottom: 20px;
  }
}

.wrap-text-4-item .learn-more:after,
.wrap-product-information .learn-more:after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #62bd89;
}

.btn-common,
.jssocials-share {
  display: inline-block;
  min-width: 230px;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "Circular Std";
  position: relative;
  z-index: 0;
  cursor: pointer;
}

.btn-common.disabled,
.jssocials-share.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.btn-common.large,
.jssocials-share.large {
  max-width: 355px;
  margin-left: auto;
  margin-right: auto;
}

.btn-common.trans .pos,
.jssocials-share.trans .pos {
  background-color: #fafafa;
}

@media (min-width: 992px) {
  .btn-common.trans .pos.for-testimonial,
  .jssocials-share.trans .pos.for-testimonial {
    font-size: 16px;
  }
}

.btn-common.gray .pos,
.jssocials-share.gray .pos {
  background-color: #f4f2ed;
}

.btn-common.green-border,
.jssocials-share.green-border {
  max-width: 270px;
  display: inline-block;
  width: 100%;
}

@media (min-width: 1200px) {
  .btn-common.green-border,
  .jssocials-share.green-border {
    max-width: 300px;
  }
}

.btn-common.green-border .pos,
.jssocials-share.green-border .pos {
  background-color: #62bd89;
}

@media print {
  .btn-common.green-border .pos,
  .jssocials-share.green-border .pos {
    background-color: #ffffff !important;
  }
}

.btn-common.no-hover .pos > span,
.jssocials-share.no-hover .pos > span {
  width: auto;
}

.btn-common.green .pos,
.jssocials-share.green .pos {
  background-color: #62bd89;
}

@media print {
  .btn-common.green .pos,
  .jssocials-share.green .pos {
    background-color: #ffffff !important;
  }
}

.btn-common.white .pos,
.jssocials-share.white .pos {
  background-color: #fff;
}

.btn-common .pos,
.btn-common .jssocials-share-link,
.jssocials-share .pos,
.jssocials-share .jssocials-share-link {
  display: block;
  position: relative;
  z-index: 1;
  padding: 20px;
  border: 1px solid #000;
}

.btn-common .pos > span,
.jssocials-share .pos > span {
  height: 14px;
  width: auto;
  overflow: hidden;
  display: inline-flex;
  justify-content: flex-end;
  transition: all 0.5s ease;
}

@media (min-width: 1600px) {
  .btn-common .pos > span,
  .jssocials-share .pos > span {
    height: 0.7vw;
  }
}

.btn-common:after,
.jssocials-share:after {
  content: "";
  position: absolute;
  bottom: -6px;
  right: -6px;
  width: 100%;
  height: 100%;
  border-color: #62bd89;
  border-style: solid;
  border-width: 0 2px 2px 0;
  transition: all 0.1s ease-out;
  z-index: -1;
}

@media (min-width: 1600px) {
  .btn-common:hover span,
  .jssocials-share:hover span {
    height: 0.7vw;
  }
}

.btn-common:hover:after,
.jssocials-share:hover:after {
  border-width: 0 6px 6px 0;
}

.btn-common.full-width,
.jssocials-share.full-width {
  width: 100%;
  max-width: 100%;
}

.btn-common del,
.jssocials-share del {
  opacity: 0.45;
  margin-right: 3px;
}

.btn-common .pos > span {
  display: initial;
}

.btn-black {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  position: relative;
  vertical-align: top;
  display: inline-flex;
  height: 60px;
  min-width: 290px;
  color: #fff;
  background: #000;
  font: 400 16px/1.2em "Circular Std";
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 5px 12px;
  cursor: pointer;
  outline: 0 !important;
}

.btn-black i {
  margin-right: 18px;
}

.btn-black:hover {
  color: #fff;
  opacity: 0.8;
}

.btn-black.full-width {
  width: 100%;
  min-width: auto;
}

@media (max-width: 575px) {
  .btn-black {
    height: 50px;
    min-width: 220px;
    font-size: 14px;
  }
}

.btn-white {
  height: 50px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background: #fff;
  color: #000;
  font-family: "Circular Std";
  font-weight: 600;
  border: 1px solid #000;
  margin-bottom: 20px;
}

.btn-white i {
  margin-right: 25px;
}

.btn-transparent {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  vertical-align: top;
  display: inline-flex;
  height: 60px;
  min-width: 290px;
  color: #000;
  background: transparent;
  border: 1px solid #000;
  font: 400 16px/1.2em "Circular Std";
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 5px 12px;
  cursor: pointer;
}

.btn-transparent i {
  margin-right: 18px;
}

.btn-transparent:hover {
  opacity: 0.8;
}

.btn-transparent.full-width {
  width: 100%;
  min-width: auto;
}

@media (max-width: 575px) {
  .btn-transparent {
    height: 50px;
    min-width: 220px;
    font-size: 14px;
  }
}

.backdrop-core.for-panel-menu {
  background: rgba(232, 230, 222, 0.85);
  cursor: not-allowed;
  z-index: 7;
}

/*
    ================ COLOR =============
*/
.bg-skin {
  background: #f4f2ed;
}

.bg-green {
  background: #62bd89;
}

.bg-gray {
  background: #e8e6de;
}

.text-green {
  color: #62bd89;
}

.text-greendark {
  color: #0F5B25;
}

.text-purple {
  color: #605e98;
}

/*
    ================ SPACING =============
*/
.spacing-header {
  margin-top: 60px;
}

@media (min-width: 1600px) {
  .spacing-header {
    margin-top: 3vw;
  }
}

@media print {
  .spacing-header {
    margin-top: 0 !important;
  }
}

.spacing-footer {
  margin-bottom: 60px;
}

@media (min-width: 1600px) {
  .spacing-footer {
    margin-bottom: 3vw;
  }
}

@media (min-width: 992px) {
  .spacing-around {
    padding-right: 60px;
    padding-left: 60px;
  }
}

@media (min-width: 1600px) {
  .spacing-around {
    padding-right: 3vw;
    padding-left: 3vw;
  }
}

@media print {
  .spacing-around {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.spacing-section {
  padding-top: 60px;
}

@media (min-width: 992px) {
  .spacing-section {
    padding-top: 110px;
  }
}

@media print {
  .spacing-section {
    padding-top: 50px !important;
  }
}

.admin-bar .spacing-section {
  padding-top: 100px;
}

@media (min-width: 992px) {
  .admin-bar .spacing-section {
    padding-top: 110px;
  }
}

.spacing-section-medium {
  padding-top: 60px;
}

@media (min-width: 992px) {
  .spacing-section-medium {
    padding-top: 80px;
  }
}

.spacing-end {
  padding-bottom: 60px;
}

@media (min-width: 992px) {
  .spacing-end {
    padding-bottom: 110px;
  }
}

@media print {
  .spacing-end {
    padding-bottom: 50px !important;
  }
}

.spacing-checkout {
  padding-bottom: 100px;
}

@media (min-width: 992px) {
  .spacing-checkout {
    padding-bottom: 150px;
  }
}

@media (min-width: 992px) {
  .col-gap-medium {
    margin-left: -30px;
    margin-right: -30px;
  }
  .col-gap-medium > .col,
  .col-gap-medium > [class*="col"] {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.mh-100vh {
  min-height: 100vh;
}

/*
    ================ LAYOUT =============
*/
.title-page {
  font: 700 34px/normal "Circular Std";
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .title-page.for-testimonial {
    font: 700 48px/normal "Circular Std";
  }
}

@media (min-width: 992px) {
  .title-page {
    font: 700 42px/normal "Circular Std";
  }
}

@media (min-width: 1600px) {
  .title-page {
    font-size: 2.1vw;
  }
}

@media (max-width: 575px) {
  .title-page {
    font-size: 28px;
  }
}

.title-section {
  font: 700 28px/normal "Circular Std";
  line-height: 1.32353em;
}

@media (min-width: 992px) {
  .title-section {
    font-size: 34px;
  }
}

@media (min-width: 1600px) {
  .title-section {
    font-size: 1.7vw;
  }
}

.title-product {
  font: 700 42px/1.2em "Circular Std";
  letter-spacing: -0.52px;
  color: #000;
  margin-bottom: 0.5em;
}

@media (max-width: 991px) {
  .title-product {
    font-size: 36px;
  }
}

@media (max-width: 575px) {
  .title-product {
    font-size: 28px;
  }
}

.title-product.mb-15 {
  margin-bottom: 15px !important;
}

.title-product.for-checkout {
  position: relative;
}

@media (max-width: 575px) {
  .title-product.for-checkout {
    padding-bottom: 50px;
  }
}

.title-featured {
  font: 700 26px/normal "Circular Std";
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .title-featured {
    font: 700 32px/normal "Circular Std";
  }
}

@media (min-width: 1600px) {
  .title-featured {
    font-size: 1.6vw;
  }
}

.medium-title {
  font: 700 32px/1.25em "Circular Std";
  margin-bottom: 35px;
}

@media (max-width: 991px) {
  .medium-title {
    font-size: 28px;
    margin-bottom: 25px;
  }
}

.des-page,
.des-intro {
  line-height: 1.55556em;
}

@media (min-width: 992px) {
  .des-page,
  .des-intro {
    font-size: 18px;
  }
}

@media (min-width: 1600px) {
  .des-page,
  .des-intro {
    font-size: 0.9vw;
  }
}

.sub-cate {
  font: 700 14px/normal "Circular Std";
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

/*
    ================ SPACING =============
*/
.pb-150 {
  padding-bottom: 75px;
}

@media (min-width: 992px) {
  .pb-150 {
    padding-bottom: 150px;
  }
}

@media print {
  .pb-150 {
    padding-bottom: 50px !important;
  }
}

.pb-100 {
  padding-bottom: 50px;
}

@media (min-width: 992px) {
  .pb-100 {
    padding-bottom: 100px;
  }
}

@media print {
  .pb-100 {
    padding-bottom: 50px !important;
  }
}

.pb-80 {
  padding-bottom: 80px;
}

@media (max-width: 991px) {
  .pb-80 {
    padding-bottom: 50px;
  }
}

.mb-80 {
  margin-bottom: 80px;
}

@media (max-width: 991px) {
  .mb-80 {
    margin-bottom: 50px;
  }
}

.mb-45 {
  margin-bottom: 45px;
}

@media (max-width: 991px) {
  .mb-45 {
    margin-bottom: 30px;
  }
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mt-auto {
  margin-top: auto;
}

@media print {
  .wow {
    visibility: visible !important;
  }
}

.page-default-template h2,
.page-default-template h3,
.page-default-template h4,
.page-default-template h5,
.page-default-template h6 {
  font-weight: 700;
  font-family: "Circular Std";
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .page-default-template h2,
  .page-default-template h3,
  .page-default-template h4,
  .page-default-template h5,
  .page-default-template h6 {
    font-size: 22px;
  }
}

.page-default-template p {
  margin-bottom: 20px;
}

.not-reset-p p,
.not-reset-p ul {
  margin-bottom: 1rem;
}

.tooltip .tooltip-inner {
  background: #313131;
  color: #fff;
}

.product-display-block {
  display: flex;
}

.product-display-block .product-wrapblock {
  display: flex;
}

.modal {
  z-index: 99999;
}

.modal-backdrop {
  z-index: 9999;
}

.sale-box {
  position: absolute;
  top: 15%;
  right: 20%;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #62bd89;
  box-shadow: 2px 6px 12px rgba(0, 0, 0, 0.05);
  color: #62bd89;
  transform: translate3d(50%, -50%, 0);
  z-index: 2;
  font-family: "Circular Std";
  font-weight: 600;
  font-size: 16px;
  font-style: italic;
  text-transform: uppercase;
  letter-spacing: 1px;
}

img {
  max-width: 100%;
}

.ven-container {
  padding-top: 59px;
}

@media (min-width: 992px) {
  .ven-container {
    padding-left: 59px;
    padding-right: 59px;
  }
}

/*---------------------------------
**** Objects | Forms
-----------------------------------*/
.ven-competition-form .gfield {
  margin-bottom: 35px;
  position: relative;
}

.ven-competition-form .gfield_label {
  font-family: "Circular Std";
  font-size: 18px;
  font-weight: bold;
  line-height: 1.16667;
  color: black;
  vertical-align: middle;
  margin-bottom: 12px;
}

.ven-competition-form .ginput_container input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
.ven-competition-form .ginput_container select:not([multiple="multiple"]), .ven-competition-form .ginput_container textarea, .ven-competition-form .select2-container--default .select2-selection--single {
  display: block;
  width: 100%;
  height: 70px;
  background-color: transparent;
  border-radius: 0;
  border-style: solid;
  border-color: black;
  border-width: thin;
  font-family: inherit;
  font-size: 16px;
  line-height: inherit;
  color: black;
  outline: none;
  padding: 4px 23px;
}

.ven-competition-form .ginput_container input:-webkit-autofill:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
.ven-competition-form .ginput_container select:-webkit-autofill:not([multiple="multiple"]), .ven-competition-form .ginput_container textarea:-webkit-autofill, .ven-competition-form .select2-container--default .select2-selection--single:-webkit-autofill, .ven-competition-form .ginput_container input:-webkit-autofill:hover:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
.ven-competition-form .ginput_container select:-webkit-autofill:hover:not([multiple="multiple"]), .ven-competition-form .ginput_container textarea:-webkit-autofill:hover, .ven-competition-form .select2-container--default .select2-selection--single:-webkit-autofill:hover, .ven-competition-form .ginput_container input:-webkit-autofill:focus:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
.ven-competition-form .ginput_container select:-webkit-autofill:focus:not([multiple="multiple"]), .ven-competition-form .ginput_container textarea:-webkit-autofill:focus, .ven-competition-form .select2-container--default .select2-selection--single:-webkit-autofill:focus, .ven-competition-form .ginput_container input:-webkit-autofill:active:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
.ven-competition-form .ginput_container select:-webkit-autofill:active:not([multiple="multiple"]), .ven-competition-form .ginput_container textarea:-webkit-autofill:active, .ven-competition-form .select2-container--default .select2-selection--single:-webkit-autofill:active {
  transition: background 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000;
}

.ven-competition-form .ginput_container input:focus:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
.ven-competition-form .ginput_container select:focus:not([multiple="multiple"]), .ven-competition-form .ginput_container textarea:focus, .ven-competition-form .select2-container--default .select2-selection--single:focus {
  box-shadow: none;
  border-color: black;
}

.ven-form-solid .ven-competition-form .ginput_container input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]), .ven-competition-form .ginput_container .ven-form-solid input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
.ven-form-solid .ven-competition-form .ginput_container select:not([multiple="multiple"]), .ven-competition-form .ginput_container .ven-form-solid select:not([multiple="multiple"]), .ven-form-solid .ven-competition-form .ginput_container textarea, .ven-competition-form .ginput_container .ven-form-solid textarea, .ven-form-solid .ven-competition-form .select2-container--default .select2-selection--single, .ven-competition-form .select2-container--default .ven-form-solid .select2-selection--single {
  background-color: white;
  border-color: white;
}

.ven-competition-form .ginput_container input:not([type="file"]):not([type="image"]):not([type="button"]):not([type="submit"]):not([type="checkbox"]):not([type="radio"])::placeholder,
.ven-competition-form .ginput_container select:not([multiple="multiple"])::placeholder, .ven-competition-form .ginput_container textarea::placeholder, .ven-competition-form .select2-container--default .select2-selection--single::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.ven-competition-form .ginput_container textarea {
  resize: none;
  padding: 23px;
}

.ven-competition-form .gfield_checkbox li,
.ven-competition-form .gfield_radio li, .ven-competition-form .ginput_container_consent {
  display: block;
  padding-left: 54px;
  position: relative;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 40px;
}

.ven-competition-form .gfield_checkbox li label, .ven-competition-form .gfield_radio li label, .ven-competition-form .ginput_container_consent label {
  font-family: "Circular Std";
  font-size: 18px;
  font-weight: bold;
  line-height: 1.16667;
  margin-bottom: 0;
  cursor: pointer;
  user-select: none;
}

.ven-competition-form .gfield_checkbox li label::before, .ven-competition-form .gfield_radio li label::before, .ven-competition-form .ginput_container_consent label::before {
  content: "";
  display: block;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 2px;
  left: 0;
  border: thin solid black;
  border-radius: 0;
}

.ven-competition-form .gfield_checkbox li label::after, .ven-competition-form .gfield_radio li label::after, .ven-competition-form .ginput_container_consent label::after {
  content: "";
  display: block;
  width: 22px;
  height: 22px;
  background-color: black;
  position: absolute;
  top: 7px;
  left: 5px;
  opacity: 0;
}

.ven-competition-form .gfield_checkbox li input, .ven-competition-form .gfield_radio li input, .ven-competition-form .ginput_container_consent input {
  display: none;
}

.ven-competition-form .gfield_checkbox li input:checked + label::after, .ven-competition-form .gfield_radio li input:checked + label::after, .ven-competition-form .ginput_container_consent input:checked + label::after {
  opacity: 1;
}

.ven-competition-form .gfield_checkbox li input:checked + label::before, .ven-competition-form .gfield_radio li input:checked + label::before, .ven-competition-form .ginput_container_consent input:checked + label::before {
  border-color: black;
}

.ven-competition-form .gfield_checkbox li input[type="radio"] + label::before, .ven-competition-form .gfield_radio li input[type="radio"] + label::before, .ven-competition-form .ginput_container_consent input[type="radio"] + label::before, .ven-competition-form .gfield_checkbox li input[type="radio"] + label::after, .ven-competition-form .gfield_radio li input[type="radio"] + label::after, .ven-competition-form .ginput_container_consent input[type="radio"] + label::after {
  border-radius: 50%;
}

.ven-competition-form .gform_fields .validation_message {
  display: block;
  font-size: 10px;
  color: red;
  margin-top: 6px;
}

.ven-competition-form .gform_wrapper .validation_error {
  display: none;
}

.ven-competition-form .gform_fields {
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
}

.ven-competition-form .gform_fields li {
  list-style: none;
}

.ven-competition-form .gfield {
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
}

@media (min-width: 992px) {
  .ven-competition-form .gfield.medium {
    width: calc(50% - 40px);
  }
}

.ven-competition-form .gfield.hidden_label .gfield_label {
  display: none;
}

.ven-competition-form .gfield.gsection:not(:first-child) {
  margin-top: 35px;
}

.ven-competition-form .gfield.field_list_dot > .gfield_label {
  font-size: 24px;
  font-weight: normal;
  position: relative;
  margin-bottom: 35px;
}

.ven-competition-form .gfield.field_list_dot > .gfield_label::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: currentColor;
  margin-right: 17px;
}

@media (min-width: 1200px) {
  .ven-competition-form .gfield.field_list_dot > .gfield_label::before {
    position: absolute;
    top: 0.33333em;
    right: 100%;
  }
}

.gfield_error .ven-competition-form .ginput_container input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]), .gfield_error
.ven-competition-form .ginput_container select:not([multiple="multiple"]) {
  border-color: red !important;
}

.ven-competition-form .ginput_container textarea {
  height: 120px;
}

.gfield_error .ven-competition-form .ginput_container textarea {
  border-color: red !important;
}

.ven-competition-form .gform_button {
  display: block;
  width: 100%;
  border: none;
  appearance: none;
  padding: 20px;
  background-color: black;
  color: white;
  font-family: "Circular Std";
  font-size: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;
}

.ven-competition-form .select2-container--default .select2-selection--single {
  padding: 0;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ven-competition-form .select2-container--default .select2-selection--single .select2-selection__rendered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  line-height: 1;
  padding-left: 23px;
  padding-right: 46px;
}

.ven-competition-form .select2-container--default .select2-selection--single .select2-selection__arrow {
  width: auto;
  height: 100%;
  top: 0;
  right: 25px;
}

.ven-competition-form .select2-container--default .select2-selection--single .select2-selection__arrow b {
  top: 0;
  left: 0;
  width: 11px;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5.5 5.5L10 1' stroke='black'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: 50% 50%;
  border: none;
  margin: 0;
}

.ven-competition-form .select2-container--default .select2-search--dropdown .select2-search__field {
  outline: none;
  border-color: rgba(0, 0, 0, 0.3);
  border-radius: 0;
  padding-left: 23px;
  padding-right: 23px;
}

.ven-competition-form .select2-container--default .select2-results__option {
  font-size: 14px;
  padding: 12px 35px;
}

.ven-competition-form .select2-container--default .select2-results__option:focus {
  outline: none;
}

.ven-competition-form .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: white;
  color: black;
}

.ven-competition-form .select2-container--default .select2-results__option--selected {
  background-color: #f0f0f0;
}

.ven-competition-form .select2-dropdown {
  background-color: #f9f9f9;
  border: thin solid black !important;
  border-radius: 0 !important;
  overflow: hidden;
}

.select2-container--open .ven-competition-form .select2-dropdown--below {
  margin-top: 2px;
}

.select2-container--open .ven-competition-form .select2-dropdown--above {
  margin-top: -2px;
}

.ven-competition-form .gfield_checkbox,
.ven-competition-form .gfield_radio {
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
}

.ven-competition-form .gfield_checkbox li,
.ven-competition-form .gfield_radio li {
  list-style: none;
}

.ven-competition-form .gfield_checkbox li,
.ven-competition-form .gfield_radio li {
  width: 100%;
  margin-bottom: 30px;
}

@media (min-width: 1200px) {
  .ven-competition-form .gfield_checkbox li,
  .ven-competition-form .gfield_radio li {
    width: 50%;
  }
}

.ven-competition-form .ginput_container_consent label {
  font-weight: normal;
}

.ven-competition-form .gsection_title {
  font-family: "Circular Std";
  font-size: 28px;
  font-weight: bold;
  line-height: 1.1;
}

@media (min-width: 1200px) {
  .ven-competition-form .gsection_title {
    font-size: 36px;
  }
}

.ven-custom-control, .woocommerce-SavedPaymentMethods-saveNew {
  display: block;
  position: relative;
  font-size: 14px;
}

.ven-custom-control > label, .woocommerce-SavedPaymentMethods-saveNew > label {
  vertical-align: middle;
  padding-left: 24px;
  margin-bottom: 0;
  cursor: pointer;
  user-select: none;
}

.ven-custom-control > label::before, .woocommerce-SavedPaymentMethods-saveNew > label::before {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  border: thin solid #000;
  position: absolute;
  top: 3px;
  left: 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.ven-custom-control > input, .woocommerce-SavedPaymentMethods-saveNew > input {
  display: none;
}

.ven-custom-control > input[type="checkbox"]:checked + label:before, .woocommerce-SavedPaymentMethods-saveNew > input[type="checkbox"]:checked + label:before {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.5L3.77551 6.33333L9 1' stroke='%23000' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-size: 10px auto;
}

.ven-custom-control > input[type="radio"] + label::before, .woocommerce-SavedPaymentMethods-saveNew > input[type="radio"] + label::before {
  border-radius: 50%;
}

.ven-custom-control > input[type="radio"]:checked + label:before, .woocommerce-SavedPaymentMethods-saveNew > input[type="radio"]:checked + label:before {
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='4' cy='4' r='4' fill='black'/%3E%3C/svg%3E%0A");
  background-size: 10px auto;
}

.ven-form-group {
  position: relative;
  margin-top: 10px;
}

.ven-form-group + .ven-form-group {
  margin-top: 15px;
}

.ven-form-group--error .ven-form-control {
  border-color: #eb6161;
}

.ven-form-group.has-select .ven-form-label {
  position: static;
  font-size: 10px;
}

.ven-form-group select {
  display: block;
  width: 100%;
  border-radius: 0;
  border-color: #cdcdcd;
  border-width: 0 0 thin;
  padding: 8px 0;
  outline: none;
}

.ven-form-label {
  margin-bottom: 0;
  position: absolute;
  top: 8px;
  color: rgba(0, 0, 0, 0.35);
  pointer-events: none;
  transition: all 0.2s ease-out;
}

@media (min-width: 992px) {
  .ven-form-label {
    font-size: 14px;
  }
}

.ven-form-label.freeze {
  top: -10px;
  font-size: 10px;
  color: #000000;
}

.ven-form-control {
  display: block;
  width: 100%;
  border-radius: 0;
  border-color: #cdcdcd;
  border-style: solid;
  border-width: 0 0 thin;
  padding: 8px 0;
  background-color: transparent;
  outline: none;
}

.ven-form-control::placeholder {
  color: transparent;
}

@media (min-width: 992px) {
  .ven-form-control {
    font-size: 14px;
  }
}

.ven-form-control.invalid {
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.9021L4.12245 7.0896L10 1.0896' stroke='%2333322B' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 11px auto;
  background-position: right center;
}

.invalid-message {
  font-size: 10px;
  color: #eb6161;
}

.ven-form-message {
  font-size: 10px;
  margin-top: 5px;
}

.ven-custom-control--extend > input ~ div {
  margin-top: 20px;
  display: none;
}

.ven-custom-control--extend > input:checked ~ div {
  display: block;
}

.ven-custom-control--narrow > input ~ div {
  margin-top: 20px;
}

.ven-custom-control--narrow > input:checked ~ div {
  display: none;
}

/*---------------------------------
**** Objects | Loading
-----------------------------------*/
.v-loading {
  position: relative;
  color: transparent;
  pointer-events: none;
}

.v-loading:hover {
  color: transparent;
}

.v-loading::before {
  content: "";
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  background-image: url("../img/loading-2.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 1.5em auto;
  vertical-align: middle;
  position: absolute;
  top: calc(50% - 0.75em);
  left: calc(50% - 0.75em);
}

.v-loading svg {
  opacity: 0;
}

.v-fordev {
  color: black;
}

.v-fordev #ven-fordev-toggler {
  display: none;
}

.v-fordev #ven-fordev-toggler:checked + .v-fordev__inner {
  transform: translateX(0);
}

.v-fordev__inner {
  width: 150px;
  position: fixed;
  top: 100px;
  right: 0;
  z-index: 999999;
  transform: translateX(100%);
  transition: all 300ms ease-out;
  z-index: 999999;
  font-size: 12px;
}

.v-fordev__header {
  display: block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  position: absolute;
  top: 0;
  right: 100%;
  margin-bottom: 0;
  cursor: pointer;
  background-color: #000;
  color: #fff;
  text-align: center;
  box-shadow: 1px 2px 10px rgba(85, 85, 85, 0.2);
}

.v-fordev__header img {
  display: block;
  width: 100%;
}

.v-fordev__body {
  display: flex;
  flex-direction: column;
  background-color: #eeeeee;
  max-height: 70vh;
}

.v-fordev__title {
  padding: 10px 10px 0;
}

.v-fordev__content {
  padding: 10px 0;
  flex: 1 1 auto;
  overflow-y: auto;
}

.v-fordev-list {
  margin-bottom: 0;
  padding-left: 0;
}

.v-fordev-list li {
  list-style: none;
}

.v-fordev-list li a {
  display: block;
  color: #007bff;
  padding: 5px 10px;
  text-decoration: none;
}

.v-fordev-list li a:hover, .v-fordev-list li a:focus {
  color: black;
  text-decoration: none;
  background-color: #f2f2f2;
}

/**

PAGES

*/
header.header-page {
  position: fixed;
  top: var(--offset-top);
  left: 0;
  width: 100%;
  height: 60px;
  display: block;
  background-color: #FFF;
  z-index: 9999;
  display: flex;
  align-items: center;
}

@media (max-width: 600px) {
  body.admin-bar:not(.admin-bar--hidden) header.header-page {
    position: absolute;
  }
}

header.header-page .wrap-content-mobile {
  flex: 1;
}

@media (max-width: 991px) {
  header.header-page {
    padding: 0 15px;
  }
  header.header-page:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: #FFF;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  header.header-page .btn-menu-sp {
    position: relative;
    width: 30px;
    height: 20px;
    z-index: 6;
    margin-left: 30px;
    cursor: pointer;
  }
}

@media print and (max-width: 991px) {
  header.header-page .btn-menu-sp {
    display: none !important;
  }
}

@media (max-width: 991px) {
  header.header-page .btn-menu-sp.expand:before {
    transform: rotate(45deg);
    top: 10px;
  }
  header.header-page .btn-menu-sp.expand:after {
    transform: rotate(-45deg);
    top: 10px;
  }
  header.header-page .btn-menu-sp.expand span {
    transform: scale(1.1) translateX(10px);
    opacity: 0;
  }
  header.header-page .btn-menu-sp:after, header.header-page .btn-menu-sp:before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #62bd89;
    transition: all 0.5s ease;
  }
  header.header-page .btn-menu-sp:after {
    top: 0;
  }
  header.header-page .btn-menu-sp:before {
    bottom: 0;
  }
  header.header-page .btn-menu-sp span {
    position: relative;
    display: block;
    top: 10px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #62bd89;
    transform: translateY(-50%);
    transition: all 0.5s ease;
  }
  header.header-page .wrap-content-mobile {
    background-color: #FFF;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translate(0, -100%);
    transition: all 0.8s cubic-bezier(0.43, 0.195, 0.02, 1);
    overflow-y: auto;
  }
  header.header-page .wrap-content-mobile .wrap-inner {
    padding-top: calc(100px + var(--offset-top));
    padding-bottom: 30px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
  header.header-page .wrap-content-mobile .wrap-inner .sidebar-left {
    margin-top: auto;
  }
  header.header-page .wrap-content-mobile.expand {
    transform: translate(0, 0);
  }
}

@media print {
  header.header-page {
    position: relative !important;
  }
  header.header-page .wrap-content-mobile {
    margin-top: 0;
    min-height: 100%;
    transform: none;
    padding-top: 0;
    z-index: 5;
  }
}

@media (min-width: 992px) {
  header.header-page .container-fluid {
    padding-right: 60px;
    padding-left: 60px;
  }
}

header.header-page .row {
  align-items: center;
}

header.header-page .row-header {
  align-items: flex-start;
}

header.header-page ul.menu-top {
  font-family: "Circular Std";
  font-weight: 500;
}

@media (min-width: 992px) {
  header.header-page ul.menu-top {
    display: flex;
  }
}

@media print {
  header.header-page ul.menu-top {
    display: none;
  }
}

header.header-page ul.menu-top.right {
  justify-content: flex-end;
}

header.header-page ul.menu-top > li {
  position: relative;
}

@media (min-width: 992px) {
  header.header-page ul.menu-top > li:not(:last-child) {
    margin-right: 35px;
  }
}

@media (min-width: 992px) and (min-width: 1600px) {
  header.header-page ul.menu-top > li:not(:last-child) {
    margin-right: 1.75vw;
  }
}

@media (min-width: 992px) {
  header.header-page ul.menu-top > li.login {
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  header.header-page ul.menu-top > li {
    display: block;
  }
}

@media (min-width: 992px) {
  header.header-page ul.menu-top > li:after {
    content: '';
    position: absolute;
    top: 56px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #000;
    opacity: 0;
    transform-origin: center;
    transform: scaleX(0);
    transition: all 0.5s ease;
    z-index: 2;
  }
}

@media (min-width: 992px) and (min-width: 1600px) {
  header.header-page ul.menu-top > li:after {
    height: 0.15vw;
  }
}

@media print {
  header.header-page ul.menu-top > li:not(:last-child) {
    margin-right: 35px;
  }
}

header.header-page ul.menu-top > li.current-menu-item:after, header.header-page ul.menu-top > li:hover:after {
  opacity: 1;
  transform-origin: center;
  transform: scaleX(1);
}

header.header-page ul.menu-top > li.current-menu-item a {
  font-weight: 700;
}

header.header-page ul.menu-top > li .dropdown-carret {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-left: 1px solid #62bd89;
  border-bottom: 1px solid #62bd89;
  transform: rotate(-45deg);
  vertical-align: middle;
  margin-left: 2px;
}

header.header-page ul.menu-top > li > a {
  display: inline-block;
  padding: 20px 0;
}

header.header-page .logo {
  transition: all 0.5s ease;
  z-index: 6;
  margin-right: auto;
}

@media (min-width: 992px) {
  header.header-page .logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media print {
  header.header-page .logo {
    left: 50%;
    transform: translateX(-50%);
  }
}

header.header-page .logo img {
  max-width: 110px;
}

header.header-page .dropdown-menu {
  border-radius: 0;
  border-color: #000;
  margin-top: -12px;
  text-transform: capitalize;
}

@media (min-width: 992px) {
  header.header-page .dropdown-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
}

header.header-page .dropdown-toggle::after {
  width: 12px;
  height: 7px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='black'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: none;
  vertical-align: 0.1em;
}

header.header-page .dropdown-item {
  padding: 10px 20px;
}

header.header-page .dropdown-item:hover {
  background-color: transparent;
  color: #62bd89;
}

@media (min-width: 992px) {
  header.header-page .dropdown:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
  }
}

.row-custom {
  display: flex;
  align-items: center;
}

@media (min-width: 992px) {
  .row-custom {
    justify-content: flex-end;
    padding-right: 25px;
  }
}

.sidebar-left {
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 992px) {
  .sidebar-left {
    width: 60px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #FFF;
    z-index: 5;
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1600px) {
  .sidebar-left {
    width: 3vw;
  }
}

@media print {
  .sidebar-left {
    display: none;
  }
}

.sidebar-left .list-social {
  position: relative;
  display: flex;
  padding: 20px 0;
  justify-content: flex-start;
}

@media (min-width: 992px) {
  .sidebar-left .list-social {
    justify-content: center;
    position: absolute;
    top: 55%;
    left: 30px;
    transform: rotate(-90deg) translateY(-50%);
    transform-origin: 0 0;
  }
}

@media (min-width: 1600px) {
  .sidebar-left .list-social {
    padding: 1vw 0;
    left: 1.5vw;
  }
}

.sidebar-left .list-social li {
  position: relative;
  transition: all 0.5s ease;
  padding-left: 20px;
}

.sidebar-left .list-social li:not(:last-child) {
  margin-right: 25px;
}

@media (min-width: 992px) {
  .sidebar-left .list-social li {
    margin: 0 20px;
    padding-left: 0;
    font-size: 14px;
  }
  .sidebar-left .list-social li:hover {
    padding-left: 20px;
  }
}

@media (min-width: 992px) and (min-width: 1600px) {
  .sidebar-left .list-social li:hover {
    padding-left: 1vw;
  }
}

@media (min-width: 992px) {
  .sidebar-left .list-social li:hover:before {
    opacity: 1;
  }
}

@media (min-width: 1600px) {
  .sidebar-left .list-social li {
    margin: 0 1vw;
    font-size: 0.7vw;
  }
}

.sidebar-left .list-social li:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 16px;
  height: 16px;
  transition: all 0.5s ease;
}

@media (min-width: 1600px) {
  .sidebar-left .list-social li:before {
    top: 0.1vw;
    width: 0.8vw;
    height: 0.8vw;
  }
}

@media (min-width: 992px) {
  .sidebar-left .list-social li:before {
    opacity: 0;
  }
}

.sidebar-left .list-social li.ins:before {
  background: url(../img/icon-ins.svg) no-repeat center/auto 100%;
}

.sidebar-left .list-social li.fb:before {
  background: url(../img/icon-fb.svg) no-repeat center/auto 100%;
}

.sidebar-left a {
  font-family: "Circular Std";
  font-size: 16px;
}

@media (min-width: 1600px) {
  .sidebar-left a {
    font-size: 0.8vw;
  }
}

.sidebar-left .list-mobile-contact li {
  margin: 5px 0;
}

.header-btn-cart {
  position: relative;
  width: 25px;
  height: 20px;
  margin-left: 10px;
  z-index: 2;
  background: url(../img/cart.svg) no-repeat center/auto 100%;
}

@media (min-width: 992px) {
  .header-btn-cart.mobile-show {
    display: none;
  }
}

@media (max-width: 991px) {
  .header-btn-cart.desktop-show {
    display: none;
  }
}

.header-btn-cart:before {
  content: '';
  position: absolute;
  top: 12px;
  right: 0;
  width: 75px;
  height: 390px;
  transform: translateY(-50%);
  display: none;
}

@media (min-width: 992px) {
  .header-btn-cart {
    width: 40px;
  }
}

@media (min-width: 1600px) {
  .header-btn-cart {
    width: 2vw;
    height: 1vw;
  }
}

.header-btn-cart:hover > span {
  transform: scale(1.3);
}

.header-btn-cart > span {
  font-family: "Circular Std";
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #62bd89;
  border-radius: 50%;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  width: 16px;
  height: 16px;
  text-align: center;
  transition: all 0.3s ease;
}

@media (min-width: 1600px) {
  .header-btn-cart > span {
    width: 0.8vw;
    height: 0.8vw;
    font-size: 0.5vw;
  }
}

@media (max-width: 575px) {
  .header-btn-cart > span {
    right: -10px;
  }
}

.header-btn-cart .cart_price del {
  display: none;
}

.header-btn-cart .cart_price ins {
  text-decoration: none;
}

.sidebar-right {
  /*BAG HOVER AREA*/
}

@media (min-width: 992px) {
  .sidebar-right {
    background-color: #FFF;
    position: fixed;
    height: 100%;
    z-index: 5;
    width: 60px;
    top: 0;
    right: 0;
  }
}

@media (min-width: 1600px) {
  .sidebar-right {
    width: 3vw;
  }
}

@media print {
  .sidebar-right {
    display: none !important;
  }
}

.sidebar-right .btn-cart {
  position: fixed;
  top: 20px;
  right: 60px;
  width: 25px;
  height: 20px;
  z-index: 2;
  background: url(../img/cart.svg) no-repeat center/auto 100%;
}

.sidebar-right .btn-cart:before {
  content: '';
  position: absolute;
  top: 12px;
  right: 0;
  width: 75px;
  height: 390px;
  transform: translateY(-50%);
  display: none;
}

@media (min-width: 992px) {
  .sidebar-right .btn-cart {
    position: absolute;
    width: 40px;
    top: 50%;
    right: 20%;
    transform: translateY(-50%);
  }
}

@media (min-width: 1600px) {
  .sidebar-right .btn-cart {
    width: 2vw;
    height: 1vw;
  }
}

.sidebar-right .btn-cart:hover > span {
  transform: scale(1.3);
}

.sidebar-right .btn-cart > span {
  font-family: "Circular Std";
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #62bd89;
  border-radius: 50%;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  width: 16px;
  height: 16px;
  text-align: center;
  transition: all 0.3s ease;
}

@media (min-width: 1600px) {
  .sidebar-right .btn-cart > span {
    width: 0.8vw;
    height: 0.8vw;
    font-size: 0.5vw;
  }
}

@media (max-width: 575px) {
  .sidebar-right .btn-cart > span {
    right: -10px;
  }
}

.sidebar-right .btn-cart .cart_price del {
  display: none;
}

.sidebar-right .btn-cart .cart_price ins {
  text-decoration: none;
}

.sidebar-right .icon-x {
  position: fixed;
  top: 10px;
  right: 60px;
  z-index: 2;
  display: none;
}

@media (min-width: 992px) {
  .sidebar-right .icon-x {
    position: absolute;
    top: 50%;
    right: 15%;
    transform: translateY(-50%);
  }
}

@media (max-width: 991px) {
  .sidebar-right .icon-x {
    display: none !important;
  }
}

.bag-hover {
  opacity: 0;
  visibility: hidden;
  transition: all .5s ease;
  position: fixed;
  background: #fff;
  right: 0;
  top: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  text-align: left;
  transform: translateX(20%);
  box-shadow: -8px 4px 28px rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .bag-hover {
    width: 45%;
  }
}

.bag-hover:before {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
  border-width: 11px;
  margin-top: -11px;
}

.bag-hover:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
  border-width: 5px;
  margin-top: -5px;
}

.bag-hover .ul-cart {
  display: flex;
  width: 100%;
  line-height: 21px;
  font: 700 16px/1.4em "Circular Std";
  letter-spacing: 0.5px;
  margin-bottom: 20px;
  position: relative;
  justify-content: space-between;
  height: 61px;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid #62bd89;
}

@media (min-width: 768px) {
  .bag-hover .ul-cart {
    justify-content: flex-start;
  }
}

@media (min-width: 992px) {
  .bag-hover .ul-cart {
    padding: 0 50px;
  }
}

.bag-hover .ul-cart:after {
  content: '';
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 1px;
  background: #62bd89;
}

.bag-hover .ul-cart.empty {
  justify-content: flex-end;
}

.bag-hover .ul-cart .name {
  min-width: 50%;
}

@media (min-width: 768px) {
  .bag-hover .ul-cart .name {
    margin-bottom: 0;
  }
}

.bag-hover .ul-cart .quanity {
  text-align: center;
  max-width: 100px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .bag-hover .ul-cart .quanity {
    min-width: 25%;
    margin-bottom: 0;
    margin-left: 0;
    width: auto;
  }
}

.bag-hover .ul-cart .price {
  min-width: 20%;
  text-align: center;
}

.bag-hover .ul-cart .delete {
  text-align: right;
  min-width: 5%;
}

.bag-hover .wrap-empty {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
}

.bag-hover .wrap-empty .cart-text {
  margin-bottom: 10px;
}

.bag-hover .wrap-empty .btn-common {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 992px) and (min-width: 768px) {
  .bag-hover .btn-cart {
    display: block;
    min-width: 10%;
  }
}

.bag-hover .btn-cart .price {
  text-align: right;
  order: 2;
  min-width: 30%;
}

@media (min-width: 768px) {
  .bag-hover .btn-cart .price {
    order: 4;
    min-width: 10%;
  }
}

@media (max-width: 991px) {
  .bag-hover .btn-cart.opened .bag-hover {
    opacity: 1;
    visibility: visible;
  }
}

.bag-hover .header-total {
  background: #f4f2ed;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
  display: flex;
  padding: 12px 20px;
  justify-content: space-between;
  align-items: center;
  font: 700 20px/normal "Circular Std";
  margin-bottom: 10px;
}

.bag-hover .header-viewbag {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  background: transparent;
  color: #000;
  border: 1px solid #000;
  font: 500 14px/1.2em "Circular Std";
  text-transform: uppercase;
  height: 50px;
  letter-spacing: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bag-hover .header-viewbag:hover {
  opacity: .7;
}

.bag-hover .header-checkout {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  background: #62bd89;
  color: #fff;
  font: 500 14px/1.2em "Circular Std";
  text-transform: uppercase;
  height: 50px;
  letter-spacing: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bag-hover .header-checkout:hover {
  opacity: .7;
}

.bag-hover .wrap-cart-panel-bottom {
  position: absolute;
  bottom: 15px;
  right: 15px;
  left: 15px;
}

@media (min-width: 992px) {
  .bag-hover .wrap-cart-panel-bottom {
    bottom: 30px;
    right: 50px;
    left: 50px;
  }
}

.bag-hover .wrap-cart-panel-bottom .row {
  margin-left: 0;
  margin-right: 0;
}

.bag-hover .wrap-cart-panel-bottom .coupon {
  width: 100%;
  margin-bottom: 10px;
}

.bag-hover .wrap-cart-panel-bottom .coupon input {
  width: 100%;
  border-color: #f4f2ed;
  font-family: "Circular Std";
  height: 48px;
  margin-left: 0;
  padding: 0 100px 0 20px;
}

.bag-hover .wrap-cart-panel-bottom .coupon input::-webkit-input-placeholder {
  color: #DCDCDC;
}

.bag-hover .wrap-cart-panel-bottom .coupon input::-moz-placeholder {
  color: #DCDCDC;
}

.bag-hover .wrap-cart-panel-bottom .coupon input:-ms-input-placeholder {
  color: #DCDCDC;
}

.bag-hover .wrap-cart-panel-bottom .coupon input:-moz-placeholder {
  color: #DCDCDC;
}

.bag-hover .wrap-cart-panel-bottom .coupon button[type=submit] {
  height: 44px;
  padding: 5px 15px;
}

.bag-hover .wrap-cart-panel-bottom .row-cart-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.bag-hover .wrap-cart-panel-bottom .row-cart-buttons a {
  width: 49%;
}

.sidebar-right .row-cart-buttons {
  margin-left: -2.5px;
  margin-right: -2.5px;
}

.sidebar-right .row-cart-buttons > .col,
.sidebar-right .row-cart-buttons > [class*="col"] {
  padding-left: 2.5px;
  padding-right: 2.5px;
}

.bag-hover.opened {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}

.header-cart-list {
  margin-bottom: 15px;
  max-height: calc(100vh - 350px);
  overflow-x: hidden;
}

.header-cart-list::-webkit-scrollbar-track {
  background-color: #62bd89;
}

.header-cart-list::-webkit-scrollbar {
  width: 3px;
}

.header-cart-list::-webkit-scrollbar-thumb {
  background-color: #62bd89;
}

.header-cart-list::-webkit-scrollbar-track, .header-cart-list::-webkit-scrollbar {
  background-color: #DDDDDD;
}

.header-cart-list .scrollbar-macosx {
  padding-right: 0 !important;
}

.header-cart-list > li {
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #EBEBEB;
  position: relative;
}

.header-cart-list > li .wrap-cart-item-information {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-cart-list > li .note-onbackorder-item {
  font-size: 14px;
}

@media (max-width: 991px) {
  .header-cart-list > li .note-onbackorder-item {
    font-size: 13px;
    margin-top: 15px;
  }
}

@media (min-width: 992px) {
  .header-cart-list > li .note-onbackorder-item.version-mobile {
    display: none;
  }
}

@media (max-width: 991px) {
  .header-cart-list > li .note-onbackorder-item.version-desktop {
    display: none;
  }
}

@media (min-width: 992px) {
  .header-cart-list > li {
    margin-right: 50px;
    margin-left: 50px;
    justify-content: flex-start;
  }
}

.header-cart-list > li:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.header-cart-list > li .header-cart-img {
  width: 40px;
  flex: 0 0 40px;
  margin-right: 15px;
}

@media (min-width: 576px) {
  .header-cart-list > li .header-cart-img {
    width: 70px;
    flex: 0 0 70px;
  }
}

.header-cart-list > li .header-cart-info {
  width: calc(100% - 100px);
}

.header-cart-list > li .header-cart-info h5 {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2em;
  margin-bottom: 2px;
  font-family: "Circular Std";
}

@media (min-width: 576px) {
  .header-cart-list > li .header-cart-info h5 {
    font-size: 16px;
  }
}

.header-cart-list > li .wrap-infor-image-title {
  display: flex;
  align-items: center;
  min-width: 50%;
}

.header-cart-list > li .header-cart-quanity {
  text-align: right;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .header-cart-list > li .header-cart-quanity {
    text-align: center;
    min-width: 25%;
  }
}

.header-cart-list > li .header-cart-quanity.item-qty {
  width: 100px;
}

@media (min-width: 768px) {
  .header-cart-list > li .header-cart-quanity.item-qty {
    width: auto;
  }
}

.header-cart-list > li .header-cart-quanity .qty-holder {
  text-align: center;
}

@media (max-width: 575px) {
  .header-cart-list > li .header-cart-quanity .qty-holder {
    width: 80px;
  }
}

.header-cart-list > li .header-cart-quanity .qty-holder input {
  width: 40px;
  border: 1px solid #EDEDED;
}

@media (max-width: 575px) {
  .header-cart-list > li .header-cart-quanity .qty-holder input {
    width: 30px;
  }
}

.header-cart-list > li .header-cart-quanity .qty-holder .square-plus,
.header-cart-list > li .header-cart-quanity .qty-holder .square-minus {
  background-color: transparent;
}

.header-cart-list > li .header-cart-quanity .qty-holder .square-minus:after {
  content: '-';
}

.header-cart-list > li .header-cart-quanity .qty-holder .square-plus {
  color: #62bd89;
}

.header-cart-list > li .delete-header-cart {
  cursor: pointer;
  width: 8px;
  height: 8px;
  background: transparent url("../img/cart-close.svg") right/contain no-repeat;
  opacity: 0.2;
  min-width: 5%;
}

@media (max-width: 575px) {
  .header-cart-list > li .delete-header-cart {
    padding-top: 10px;
  }
}

@media (min-width: 768px) {
  .header-cart-list > li .delete-header-cart:before {
    display: none;
  }
}

@media (min-width: 768px) {
  .header-cart-list > li .delete-header-cart {
    text-align: right;
  }
}

.header-cart-list > li .delete-header-cart:hover {
  opacity: .7;
}

@media (min-width: 2000px) {
  .header-cart-list > li .delete-header-cart {
    width: 0.75vw;
    height: 0.75vw;
  }
}

.header-cart-list > li .header-cart-price {
  text-align: center;
  font: 500 14px/19px "Circular Std";
  margin: 3px 0;
  min-width: 20%;
}

@media (min-width: 576px) {
  .header-cart-list > li .header-cart-price {
    font-size: 16px;
  }
}

.side-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: block;
  background-color: #FFF;
  z-index: 5;
  transform: translate3d(0, 0, 0);
}

@media (min-width: 768px) {
  .side-bottom {
    transition: all 0.5s ease;
  }
}

@media (min-width: 1600px) {
  .side-bottom {
    height: 3vw;
  }
}

@media (max-width: 991px) {
  .side-bottom {
    border-top: 1px solid #f4f2ed;
    display: none;
  }
}

@media print {
  .side-bottom {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .side-bottom .container-fluid {
    padding-right: 60px;
    padding-left: 60px;
  }
}

@media (min-width: 1600px) {
  .side-bottom .container-fluid {
    padding-right: 3vw;
    padding-left: 3vw;
  }
}

.side-bottom .item-footer {
  display: flex;
  align-items: center;
  font-family: "Circular Std";
  font-size: 14px;
  justify-content: flex-end;
}

@media (min-width: 992px) {
  .side-bottom .item-footer {
    justify-content: space-between;
  }
}

@media (min-width: 1600px) {
  .side-bottom .item-footer {
    font-size: 0.7vw;
  }
}

.side-bottom .item-footer li {
  padding: 20px 0;
}

@media (min-width: 1600px) {
  .side-bottom .item-footer li {
    padding: 1vw 0;
  }
}

.side-bottom .item-footer li:nth-child(2) {
  font-weight: 700;
  position: absolute;
  left: 15px;
}

@media (min-width: 992px) {
  .side-bottom .item-footer li:nth-child(2) {
    left: 50%;
    transform: translateX(-50%);
  }
}

.side-bottom .item-footer li:last-child a {
  display: inline-block;
  margin: 0 8px;
}

@media (min-width: 992px) {
  .side-bottom .item-footer li:last-child a {
    margin: 0 15px;
  }
}

@media (min-width: 1600px) {
  .side-bottom .item-footer li:last-child a {
    margin: 0 0.75vw;
  }
}

.side-bottom .item-footer li span {
  display: none;
}

@media (min-width: 992px) {
  .side-bottom .item-footer li span {
    display: block;
  }
}

.side-bottom .item-footer li .icon {
  display: block;
}

@media (min-width: 992px) {
  .side-bottom .item-footer li .icon {
    display: none;
  }
}

.panel-login,
.panel-signup {
  position: fixed;
  top: 60px;
  left: calc(100% - 60px);
  height: calc(100% - 60px);
  width: 600px;
  z-index: 211;
  visibility: hidden;
  padding: 50px 20px 20px;
  font-weight: 400;
  transition: all 0.5s ease;
}

@media (min-width: 1600px) {
  .panel-login,
  .panel-signup {
    width: 30vw;
    padding: 1vw;
    height: calc(100% - 3vw);
    top: 3vw;
  }
}

@media (max-width: 991px) {
  .panel-login,
  .panel-signup {
    left: 100%;
  }
}

@media (max-width: 575px) {
  .panel-login,
  .panel-signup {
    padding: 40px 20px;
  }
}

.panel-login .scrollbar-macosx,
.panel-signup .scrollbar-macosx {
  padding: 0 25px;
  width: 100%;
}

.panel-login .scrollbar-macosx .scroll-element.scroll-y .scroll-bar,
.panel-signup .scrollbar-macosx .scroll-element.scroll-y .scroll-bar {
  background: rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
}

.panel-login .scrollbar-macosx .scroll-element.scroll-y .scroll-element_size,
.panel-signup .scrollbar-macosx .scroll-element.scroll-y .scroll-element_size {
  background: transparent !important;
}

@media (max-width: 991px) {
  .panel-login,
  .panel-signup {
    width: 100%;
  }
}

.panel-login.expand,
.panel-signup.expand {
  left: calc(100% - 660px);
  visibility: visible;
  background-color: #62bd89;
  transition: background 0.3s ease, left 0.5s 0.3s ease;
}

@media (min-width: 1600px) {
  .panel-login.expand,
  .panel-signup.expand {
    left: calc(100% - 33vw);
  }
}

@media (max-width: 991px) {
  .panel-login.expand,
  .panel-signup.expand {
    left: 0;
  }
}

.panel-login .login-panel-container,
.panel-signup .login-panel-container {
  height: calc(100vh - 180px);
}

.panel-login .login-panel-container > div,
.panel-signup .login-panel-container > div {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.panel-login .woocommerce-form-login,
.panel-signup .woocommerce-form-login {
  margin: 0 -8px;
}

.panel-login .woocommerce-form-login .form-text-input,
.panel-signup .woocommerce-form-login .form-text-input {
  width: 100%;
  padding: 0 8px;
  float: left;
}

.panel-login .woocommerce-form-login .form-half,
.panel-signup .woocommerce-form-login .form-half {
  width: 50%;
}

.panel-login h1,
.panel-signup h1 {
  margin-bottom: 12px;
}

.panel-login p,
.panel-signup p {
  font-size: 16px;
  letter-spacing: -.2px;
}

@media (max-width: 767px) {
  .panel-login p,
  .panel-signup p {
    font-size: 14px;
  }
}

.panel-login h3,
.panel-signup h3 {
  font: 400 14px/1em "Circular Std";
  letter-spacing: 1.4px;
  color: #000;
  margin-bottom: 0;
}

.panel-login ul,
.panel-signup ul {
  padding-left: 0;
  margin-bottom: 0;
}

.panel-login input[type=submit],
.panel-signup input[type=submit] {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  cursor: pointer;
  width: 200px;
  margin: 0 auto 20px;
  display: block;
  color: #fff;
  background: #000;
  height: 50px;
  font: 500 14px/normal "Circular Std";
  letter-spacing: 2px;
}

.panel-login input[type=submit]:hover,
.panel-signup input[type=submit]:hover {
  opacity: .85;
}

.panel-login .lost-pw,
.panel-signup .lost-pw {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .panel-login .lost-pw,
  .panel-signup .lost-pw {
    margin-bottom: 20px;
  }
}

.panel-login .lost-pw a:hover,
.panel-signup .lost-pw a:hover {
  text-decoration: underline;
}

.panel-login .footer-login p,
.panel-signup .footer-login p {
  font: 500 16px/28px "Circular Std";
  letter-spacing: -.2px;
}

@media (max-width: 767px) {
  .panel-login .footer-login p,
  .panel-signup .footer-login p {
    font-size: 14px;
  }
}

.woocommerce-form-login .form-text-input {
  margin-bottom: 20px;
}

.woocommerce-form-login .form-text-input label {
  font: 700 15px/1.25em "Circular Std";
  display: block;
  margin-bottom: 7px;
}

.woocommerce-form-login .form-text-input input:not([type="submit"]) {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  width: 100%;
  height: 50px;
  font-size: 14px;
  padding: 12px 15px;
  border: 1px solid #000;
  background: transparent;
}

.woocommerce-form-login .form-text-input input:not([type="submit"]):-webkit-autofill, .woocommerce-form-login .form-text-input input:not([type="submit"]):-webkit-autofill:hover, .woocommerce-form-login .form-text-input input:not([type="submit"]):-webkit-autofill:focus, .woocommerce-form-login .form-text-input input:not([type="submit"]):-webkit-autofill:active {
  transition: background 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000;
}

.woocommerce-form-login .lost-pw {
  font: 500 16px/normal "Circular Std";
}

#login-fields {
  display: none;
}

#btn-close-login {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 2;
}

@media (max-width: 575px) {
  #btn-close-login {
    right: 10px;
    top: 0;
  }
}

#btn-close-login:hover:before {
  opacity: .7;
}

#btn-close-login:before {
  content: '\00d7';
  font-size: 30px;
}

.white-textline {
  border-bottom: 1px solid #fff;
  position: relative;
  margin-bottom: 30px;
}

@media (max-width: 991px) {
  .white-textline {
    margin-bottom: 20px;
  }
}

.white-textline:before {
  content: "or";
  position: absolute;
  background: #62bd89;
  width: 50px;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  color: #fff;
  font: 400 14px/normal "Circular Std";
}

.backdrop-cart {
  background: rgba(0, 0, 0, 0.2) !important;
}

.select2-search.select2-search--dropdown {
  display: none !important;
}

.select2-container.select2-container--open .select2-results__options:-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #62bd89;
  background-color: #F5F5F5;
}

.select2-container.select2-container--open .select2-results__options::-webkit-scrollbar {
  width: 4px;
  background-color: #F5F5F5;
}

.select2-container.select2-container--open .select2-results__options::-webkit-scrollbar-thumb {
  background-color: #62bd89;
}

footer.footer-page {
  padding-top: 60px;
  min-height: 700px;
}

@media (max-width: 991px) {
  footer.footer-page {
    padding-bottom: 60px;
  }
}

@media print {
  footer.footer-page {
    min-height: 100% !important;
    padding-top: 50px;
    padding-bottom: 150px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: 0 !important;
    display: none;
  }
}

footer.footer-page .row {
  align-items: flex-start;
}

footer.footer-page .form-subcribe {
  padding-bottom: 50px;
  margin-bottom: 30px;
  border-bottom: 1px solid #000;
}

@media (min-width: 992px) {
  footer.footer-page .form-subcribe {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 100px;
    margin-bottom: 60px;
  }
}

@media (min-width: 1600px) {
  footer.footer-page .form-subcribe {
    padding-bottom: 5vw;
    margin-bottom: 3vw;
  }
}

@media print {
  footer.footer-page .form-subcribe {
    display: none !important;
  }
}

footer.footer-page .form-subcribe h3 {
  font: 700 42px/normal "Circular Std";
  max-width: 400px;
}

@media (min-width: 1600px) {
  footer.footer-page .form-subcribe h3 {
    font-size: 2.1vw;
    max-width: 20vw;
  }
}

@media (max-width: 991px) {
  footer.footer-page .form-subcribe h3 {
    font-size: 32px;
  }
}

@media (max-width: 575px) {
  footer.footer-page .form-subcribe h3 {
    font-size: 28px;
  }
}

footer.footer-page .form-subcribe .wrap-form {
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  position: relative;
  padding: 28px 30px;
  border: 1px solid #000000;
}

@media (min-width: 1600px) {
  footer.footer-page .form-subcribe .wrap-form {
    max-width: 27vw;
    margin-left: auto;
    margin-right: auto;
    padding: 1.4vw 1.5vw;
  }
}

@media (max-width: 991px) {
  footer.footer-page .form-subcribe .wrap-form {
    margin-top: 20px;
    padding: 20px 15px;
  }
}

footer.footer-page .form-subcribe .wrap-form:after {
  content: '';
  position: absolute;
  bottom: -6px;
  right: -6px;
  width: 100%;
  height: 100%;
  border-right: 2px solid #62bd89;
  border-bottom: 2px solid #62bd89;
  transition: all 0.5s ease;
  z-index: -1;
}

@media (min-width: 1600px) {
  footer.footer-page .form-subcribe .wrap-form:after {
    bottom: -0.3vw;
    right: -0.3vw;
    border-width: 0.1vw;
  }
}

footer.footer-page .form-subcribe .wrap-form ul li input:not([type="submit"]) {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  background-color: transparent;
  text-overflow: ellipsis;
  padding-right: 80px;
  width: 100%;
}

footer.footer-page .form-subcribe .wrap-form ul li input:not([type="submit"]):-webkit-autofill, footer.footer-page .form-subcribe .wrap-form ul li input:not([type="submit"]):-webkit-autofill:hover, footer.footer-page .form-subcribe .wrap-form ul li input:not([type="submit"]):-webkit-autofill:focus, footer.footer-page .form-subcribe .wrap-form ul li input:not([type="submit"]):-webkit-autofill:active {
  transition: background 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000;
}

@media (min-width: 1600px) {
  footer.footer-page .form-subcribe .wrap-form ul li input:not([type="submit"]) {
    padding-right: 4vw;
  }
}

footer.footer-page .form-subcribe .wrap-form ul li input:not([type="submit"])::-webkit-input-placeholder {
  transition: opacity 0.5s ease;
  color: #9B9B9B;
}

footer.footer-page .form-subcribe .wrap-form ul li input:not([type="submit"])::-moz-placeholder {
  transition: opacity 0.5s ease;
  color: #9B9B9B;
}

footer.footer-page .form-subcribe .wrap-form ul li input:not([type="submit"]):-ms-input-placeholder {
  transition: opacity 0.5s ease;
  color: #9B9B9B;
}

footer.footer-page .form-subcribe .wrap-form ul li input:not([type="submit"]):-moz-placeholder {
  transition: opacity 0.5s ease;
  color: #9B9B9B;
}

footer.footer-page .form-subcribe .wrap-form ul li input:not([type="submit"]):focus::-webkit-input-placeholder {
  opacity: 0;
}

footer.footer-page .form-subcribe .wrap-form ul li input:not([type="submit"]):focus::-moz-placeholder {
  opacity: 0;
}

footer.footer-page .form-subcribe .wrap-form ul li input:not([type="submit"]):focus:-ms-input-placeholder {
  opacity: 0;
}

footer.footer-page .form-subcribe .wrap-form ul li input:not([type="submit"]):focus:-moz-placeholder {
  opacity: 0;
}

footer.footer-page .form-subcribe .wrap-form input[type="submit"] {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  background: transparent;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Circular Std";
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.5s ease;
}

@media (min-width: 992px) {
  footer.footer-page .form-subcribe .wrap-form input[type="submit"] {
    right: 30px;
  }
}

footer.footer-page .form-subcribe .wrap-form input[type="submit"]:hover {
  color: #62bd89;
}

footer.footer-page .form-subcribe .wrap-form .gfield .gfield_description {
  color: red;
  padding-top: 5px;
  font-size: 15px;
}

footer.footer-page .form-subcribe .wrap-form .validation_error,
footer.footer-page .form-subcribe .wrap-form .gfield_label,
footer.footer-page .form-subcribe .wrap-form .gform_validation_container .gfield_description,
footer.footer-page .form-subcribe .wrap-form .gform_validation_container .ginput_container {
  display: none !important;
}

footer.footer-page .form-subcribe .wrap-form .gform_confirmation_message {
  color: #62bd89;
  font-size: 18px;
  font-weight: bold;
}

footer.footer-page .list-menu-footer li {
  max-width: 180px;
}

@media (min-width: 1600px) {
  footer.footer-page .list-menu-footer li {
    max-width: 9vw;
  }
}

footer.footer-page .list-menu-footer li a {
  font-family: "Circular Std";
  font-size: 18px;
  line-height: 40px;
}

@media (min-width: 1600px) {
  footer.footer-page .list-menu-footer li a {
    font-size: 0.9vw;
    line-height: 2vw;
  }
}

footer.footer-page .list-menu-footer li a:hover {
  text-decoration: underline;
}

footer.footer-page .container {
  position: relative;
}

footer.footer-page .img-animation {
  max-width: 110px;
  width: 100%;
  text-align: center;
}

@media (min-width: 1600px) {
  footer.footer-page .img-animation {
    max-width: 5.5vw;
    bottom: -6vw;
  }
}

@media (min-width: 992px) {
  footer.footer-page .img-animation {
    position: absolute;
    right: 0;
    bottom: -120px;
  }
}

@media (max-width: 991px) {
  footer.footer-page .img-animation {
    margin-left: auto;
  }
}

footer.footer-page .img-animation img {
  width: 100%;
}

footer.footer-page .copyright {
  display: block;
  position: absolute;
  bottom: -100px;
  font-family: "Circular Std";
}

@media (max-width: 991px) {
  footer.footer-page .copyright {
    bottom: 0;
  }
}

@media (max-width: 575px) {
  footer.footer-page .copyright {
    bottom: 15px;
  }
}

footer.footer-page .copyright span {
  display: block;
}

@media (min-width: 992px) {
  footer.footer-page .copyright span {
    display: none;
  }
}

.banner-home {
  margin-bottom: 40px;
  margin-top: 60px;
}

@media (min-width: 992px) {
  .banner-home {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.banner-home .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.banner-home .container-fluid .row {
  margin-left: 0;
  margin-right: 0;
}

.banner-home .container-fluid .row > .col,
.banner-home .container-fluid .row > [class*="col"] {
  padding-left: 0;
  padding-right: 0;
}

.banner-home .container-fluid .row .img-banner {
  padding-top: 100%;
}

@media (min-width: 768px) {
  .banner-home .container-fluid .row .img-banner {
    min-height: calc(75vh - 120px);
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .banner-home .container-fluid .row .img-banner {
    padding-top: calc(100vh - 120px);
  }
}

@media (min-width: 1600px) {
  .banner-home .container-fluid .row .img-banner {
    padding-top: calc(100vh - 6vw);
  }
}

.banner-home .container-fluid .a-center {
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .banner-home .container-fluid .a-center {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.banner-home .container-fluid .wrap-owl-carousel {
  position: relative;
  width: 100%;
  padding-bottom: 45px;
}

@media (min-width: 992px) {
  .banner-home .container-fluid .wrap-owl-carousel {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.banner-home .container-fluid .wrap-owl-carousel .owl-item .title-medium {
  position: relative;
}

.banner-home .container-fluid .wrap-owl-carousel .owl-item .desc {
  position: relative;
}

.banner-home .container-fluid .wrap-owl-carousel .owl-dots {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate3d(-50%, 0, 0);
  opacity: 0.5;
}

@media (min-width: 992px) {
  .banner-home .container-fluid .wrap-owl-carousel .owl-dots {
    bottom: -50px;
  }
}

.banner-home .container-fluid .wrap-owl-carousel .owl-dots button {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  transition: all 0.5s ease;
}

@media (min-width: 1600px) {
  .banner-home .container-fluid .wrap-owl-carousel .owl-dots button {
    width: 1vw;
    height: 0.3vw;
    margin: 0 0.25vw;
  }
}

.banner-home .container-fluid .wrap-owl-carousel .owl-dots button.active {
  background-color: #000;
}

.feature-home {
  padding-top: 45px;
  padding-bottom: 45px;
  background-color: #fafafa;
  position: relative;
}

@media (min-width: 576px) {
  .feature-home {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .feature-home {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.feature-home .product-wrap-ie {
  display: block;
  text-align: center;
}

.feature-home .product-wrap-ie .product-wrapblock {
  display: inline-block;
}

.feature-home .img-bg-1 {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-40%);
  height: 630px;
  display: none;
}

@media (min-width: 992px) {
  .feature-home .img-bg-1 {
    display: block;
  }
}

.feature-home .img-bg-1 img {
  width: auto;
  height: 100%;
}

.feature-home .img-bg-2 {
  position: absolute;
  bottom: 100px;
  right: 0;
  transform: translateX(10%);
  height: 700px;
  display: none;
}

@media (min-width: 992px) {
  .feature-home .img-bg-2 {
    display: block;
  }
}

.feature-home .img-bg-2 img {
  width: auto;
  height: 100%;
}

@media (min-width: 1600px) {
  .feature-home .container-fluid {
    max-width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
}

.feature-home .feature-block-holder .product-feature-title {
  margin-bottom: 10px;
}

.feature-home .feature-block-holder a.button-wrap {
  display: block;
}

.feature-home .feature-block-holder a.button-wrap:hover {
  background: transparent !important;
}

.feature-home .feature-block-holder .btn-common {
  margin-left: auto;
  margin-right: auto;
}

.feature-home .feature-block-holder .btn-common del {
  opacity: 0.45;
  margin-right: 3px;
}

.feature-home .feature-block-holder .info {
  margin-bottom: 10px;
}

.feature-home .owl-featured-product-home {
  padding-bottom: 45px;
}

.feature-home .owl-featured-product-home .owl-stage-outer {
  padding-bottom: 10px;
}

.feature-home .owl-featured-product-home .owl-dots {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.feature-home .owl-featured-product-home .owl-dots button.owl-dot {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  transition: all 0.5s ease;
}

@media (min-width: 1600px) {
  .feature-home .owl-featured-product-home .owl-dots button.owl-dot {
    width: 1vw;
    height: 0.3vw;
    margin: 0 0.25vw;
  }
}

.feature-home .owl-featured-product-home .owl-dots button.owl-dot.active {
  background-color: #000;
}

.feature-home .wrap-title {
  margin-bottom: 35px;
}

@media (min-width: 992px) {
  .feature-home .wrap-title {
    margin-bottom: 30px;
  }
}

.feature-home .wrap-title .title-medium {
  color: #0F5B25;
}

.feature-home .wrap-text-4-item .btn-common {
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 575px) {
  .feature-home .wrap-text-4-item .desc {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
  }
}

.feature-home .img-custom-mobile {
  margin-bottom: 15px;
}

.feature-home-list > div {
  margin-bottom: 45px;
}

#content-1 .img-drop,
#content-2 .img-drop,
.feature-home .img-drop {
  padding-top: 100%;
  position: relative;
}

@media (max-width: 991px) {
  #content-1 .img-custom-mobile,
  #content-2 .img-custom-mobile,
  .feature-home .img-custom-mobile {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 767px) {
  #content-1 .img-custom-mobile,
  #content-2 .img-custom-mobile,
  .feature-home .img-custom-mobile {
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
  }
}

#content-1 .img-trans,
#content-2 .img-trans,
.feature-home .img-trans {
  position: absolute;
  z-index: -1;
  top: -40%;
  right: -10%;
  max-width: 375px;
}

#content-1 .img-trans img,
#content-2 .img-trans img,
.feature-home .img-trans img {
  width: 100%;
}

@media (min-width: 768px) and (max-width: 991px) {
  #content-1 .img-trans,
  #content-2 .img-trans,
  .feature-home .img-trans {
    right: -22%;
  }
}

@media (min-width: 992px) {
  #content-1 .img-trans,
  #content-2 .img-trans,
  .feature-home .img-trans {
    top: -35%;
    max-width: 100%;
  }
  #content-1 .img-trans img,
  #content-2 .img-trans img,
  .feature-home .img-trans img {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  #content-1 .img-trans,
  #content-2 .img-trans,
  .feature-home .img-trans {
    right: -20%;
  }
}

@media (min-width: 1600px) {
  #content-1 .img-trans,
  #content-2 .img-trans,
  .feature-home .img-trans {
    max-width: 100vw;
    top: 0;
  }
  #content-1 .img-trans img,
  #content-2 .img-trans img,
  .feature-home .img-trans img {
    width: 100%;
  }
}

#content-2.section-scroll {
  align-items: flex-start;
  min-height: auto;
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (max-width: 991px) {
  #content-2 .img-custom-mobile {
    margin-left: auto;
  }
}

#content-2 .img-trans {
  max-width: 200px;
  left: 0;
}

@media (min-width: 992px) {
  #content-2 .img-trans {
    left: -18%;
    top: -43%;
  }
}

@media (min-width: 1600px) {
  #content-2 .img-trans {
    max-width: 15vw;
    top: 0;
  }
}

#content-3 {
  background-color: #f4f2ed;
  padding: 80px 0 40px;
}

@media (min-width: 992px) {
  #content-3 {
    padding: 180px 0 100px;
  }
}

@media (min-width: 1200px) {
  #content-3 .row {
    margin-left: -45px;
    margin-right: -45px;
  }
  #content-3 .row > .col,
  #content-3 .row > [class*="col"] {
    padding-left: 45px;
    padding-right: 45px;
  }
}

#content-3 .img-circle {
  background-color: #e8e6de;
  border-radius: 50%;
  overflow: hidden;
}

#content-3 .img-circle .img-drop {
  padding-top: 100%;
}

#content-3 .desc {
  margin-top: 30px;
}

@media (min-width: 1600px) {
  #content-3 .desc {
    margin-top: 1.5vw;
  }
}

#content-3 .btn-common {
  margin-top: 30px;
}

@media (min-width: 992px) {
  #content-3 .btn-common {
    margin-top: 40px;
  }
}

@media (min-width: 1600px) {
  #content-3 .btn-common {
    margin-top: 2vw;
  }
}

#content-4 {
  background-color: #f4f2ed;
  padding: 40px 0 80px;
}

@media (min-width: 992px) {
  #content-4 {
    padding: 100px 0 180px;
  }
}

@media (min-width: 1200px) {
  #content-4 .row {
    margin-left: -45px;
    margin-right: -45px;
  }
  #content-4 .row > .col,
  #content-4 .row > [class*="col"] {
    padding-left: 45px;
    padding-right: 45px;
  }
}

#content-4 .title-medium:first-letter {
  text-transform: lowercase;
}

#content-4 .wrap-text-4-item .btn-common {
  margin-top: 30px;
}

@media (min-width: 992px) {
  #content-4 .wrap-text-4-item .btn-common {
    margin-top: 40px;
  }
}

@media (min-width: 1600px) {
  #content-4 .wrap-text-4-item .btn-common {
    margin-top: 2vw;
  }
}

#content-4 .wrap-book {
  position: relative;
  padding-bottom: 116.33065%;
}

#content-4 .wrap-book .book-add {
  position: absolute;
  width: 68.54839%;
}

#content-4 .wrap-book .book-add img {
  filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.102582));
}

#content-4 .wrap-book .book-add.under {
  top: 0;
  left: 0;
}

#content-4 .wrap-book .book-add.upper {
  bottom: 0;
  right: 0;
  z-index: 2;
}

#content-5 .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

#content-5 .row {
  margin-left: -5px;
  margin-right: -5px;
}

#content-5 .row > .col,
#content-5 .row > [class*="col"] {
  padding-left: 5px;
  padding-right: 5px;
}

@media (min-width: 1600px) {
  #content-5 .row {
    margin-left: -0.25vw;
    margin-right: -0.25vw;
  }
  #content-5 .row > .col,
  #content-5 .row > [class*="col"] {
    padding-left: 0.25vw;
    padding-right: 0.25vw;
  }
}

#content-5 .row .row [class*="col-"]:nth-child(1), #content-5 .row .row [class*="col-"]:nth-child(2) {
  margin-bottom: 10px;
}

#content-5 .row .bg-green {
  position: relative;
  align-items: center;
  display: flex;
  background: transparent;
}

#content-5 .row .bg-green:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 5px);
  height: 100%;
  background-color: #62bd89;
}

#content-5 .wrap-text-4-item {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1600px) {
  #content-5 .wrap-text-4-item {
    max-width: 20vw;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 991px) {
  #content-5 .wrap-text-4-item {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #content-5 .wrap-text-4-item .title-medium {
    font-size: 38px;
  }
}

#content-5 .wrap-text-4-item .btn-common {
  margin-top: 30px;
}

@media (min-width: 992px) {
  #content-5 .wrap-text-4-item .btn-common {
    margin-top: 40px;
  }
}

#content-5 .item-health {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
  margin-bottom: 0;
}

#content-5 .item-health:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 60%, #000000 100%);
  mix-blend-mode: normal;
  width: 100%;
  height: 100%;
}

#content-5 .item-health img {
  transition: all 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

@media (min-width: 1200px) {
  #content-5 .item-health .ratio-169 {
    padding-top: 40%;
  }
}

@media (min-width: 1200px) {
  #content-5 .item-health .ratio-11 {
    padding-top: 80%;
  }
}

#content-5 .item-health:hover img {
  transform: scale(1.05);
}

#content-5 .item-health .wrap-title {
  position: absolute;
  z-index: 1;
  bottom: 25px;
  left: 15px;
  color: #fff;
}

@media (min-width: 1600px) {
  #content-5 .item-health .wrap-title {
    bottom: 1.25vw;
    left: 1.5vw;
  }
}

#content-5 .item-health .wrap-title span {
  display: block;
  text-transform: uppercase;
  font-family: "Circular Std";
  font-weight: 700;
  letter-spacing: 2px;
  font-size: 14px;
  padding-bottom: 10px;
}

@media (min-width: 1600px) {
  #content-5 .item-health .wrap-title span {
    font-size: 0.7vw;
  }
}

#content-5 .item-health .wrap-title h3 {
  font: 700 26px/normal "Circular Std";
  letter-spacing: -0.347826px;
  padding-right: 30px;
}

@media (min-width: 1600px) {
  #content-5 .item-health .wrap-title h3 {
    font-size: 1.3vw;
  }
}

@media (max-width: 991px) {
  #content-5 .item-health .wrap-title h3 {
    font-size: 24px;
  }
}

.ven-top-banner {
  position: relative;
}

.ven-top-sliders .slick-dots {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translate(-50%, 0);
  display: flex;
  z-index: 10;
}

@media (min-width: 992px) {
  .ven-top-sliders .slick-dots {
    bottom: 40px;
  }
}

.ven-top-sliders .slick-dots li button {
  display: block;
  padding: 14px 10px;
  background: transparent;
  font-size: 0;
  border: none;
  border-radius: 0;
  outline: none;
  cursor: pointer;
}

.ven-top-sliders .slick-dots li button::before {
  content: "";
  display: block;
  width: 24px;
  height: 10px;
  border-radius: 8px;
  border: thin solid #000;
  outline: none;
  box-shadow: 0 0 10px #fff, 0 0 10px #fff;
}

.ven-top-sliders .slick-dots li.slick-active button::before {
  background-color: #000;
}

.ven-top-slider {
  position: relative;
  height: calc(100vh - 120px - var(--offset-top));
}

@media (min-width: 992px) {
  .ven-top-slider {
    min-height: 667px;
  }
}

.ven-top-slider figure {
  margin: 0;
  padding-bottom: 100%;
}

@media (min-width: 992px) {
  .ven-top-slider figure {
    height: 100%;
    padding: 0;
  }
}

@media (max-width: 991px) {
  .ven-top-slider__header {
    display: none;
  }
}

.ven-top-slider__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ven-top-slider__image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.ven-top-slider__image picture {
  height: 100%;
}

.ven-top-slider__content {
  position: relative;
  z-index: 1;
  height: 100%;
  padding: 80px 0;
  color: #445B5A;
  pointer-events: none;
}

@media (min-width: 992px) {
  .ven-top-slider__content {
    padding: 160px 0;
  }
}

.ven-top-slider__content .container {
  height: 100%;
}

.ven-top-slider__content .btn-common {
  pointer-events: visible;
}

.ven-top-slider__row {
  height: 100%;
  align-items: flex-end;
}

.ven-top-slider__title {
  font-size: 48px;
  margin-bottom: 8px;
  line-height: 1.04;
}

@media (min-width: 992px) {
  .ven-top-slider__title {
    font-size: 80px;
    margin-bottom: 22px;
  }
}

.ven-top-slider__subtitle {
  font-size: 24px;
  margin-bottom: 23px;
}

@media (min-width: 992px) {
  .ven-top-slider__subtitle {
    font-size: 32px;
  }
}

.ven-top-slider__description {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 25px;
}

@media (min-width: 992px) {
  .ven-top-slider__description {
    font-size: 24px;
    margin-bottom: 50px;
  }
}

.ven-top-slider__video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.ven-top-slider__video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ven-top-slider__video.is-playing .ven-top-slider__button {
  opacity: 0;
  transition-delay: 1s;
  visibility: hidden;
}

.ven-top-slider__video.is-playing .ven-top-slider__button svg[role="img"]:nth-child(1) {
  display: none;
}

.ven-top-slider__video.is-playing .ven-top-slider__button svg[role="img"]:nth-child(2) {
  display: block;
}

.ven-top-slider__sound {
  position: absolute;
  left: 34px;
  bottom: 34px;
  pointer-events: none;
  z-index: 10;
  font-size: 0;
}

.ven-top-slider__sound button {
  appearance: none;
  border-radius: 0;
  border: none;
  padding: 0;
  width: 35px;
  height: 35px;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.5 0C7.83333 0 0 7.83333 0 17.5C0 27.1667 7.83333 35 17.5 35C27.1667 35 35 27.1667 35 17.5C35 7.83333 27.1667 0 17.5 0ZM17.9999 27.9167L10.9582 22.1666H5.41673V12.7083H11.125L17.9998 7.08311L17.9999 27.9167ZM20.875 22.2915L19.125 20.5415C20.3751 20.0832 21.2499 18.9165 21.2499 17.4999C21.2499 16.1249 20.3748 14.9167 19.125 14.4583L20.875 12.7083C22.5 13.6666 23.6251 15.4584 23.6251 17.4999C23.5835 19.5416 22.5 21.3332 20.875 22.2914V22.2915ZM24.125 25.5833L22.4585 23.8749C24.5002 22.4583 25.7919 20.1251 25.7919 17.4999C25.7919 14.8748 24.4584 12.5 22.4585 11.1249L24.125 9.41652C26.5832 11.2914 28.1667 14.208 28.1667 17.4999C28.1667 20.7914 26.5832 23.7499 24.125 25.5832V25.5833Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  pointer-events: visible;
  outline: none;
}

.ven-top-slider__sound button.is-muted {
  background-image: url("data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.5 0C7.83333 0 0 7.83333 0 17.5C0 27.1667 7.83333 35 17.5 35C27.1667 35 35 27.1667 35 17.5C35 7.83333 27.1667 0 17.5 0ZM5.41667 12.7083H11.1249L17.9998 7.08318V11.0833L6.91644 22.1667H5.41644L5.41667 12.7083ZM24.125 25.5833L22.4585 23.8749C24.5002 22.4583 25.7919 20.1251 25.7919 17.4999C25.7919 15.2501 24.8336 13.2083 23.2502 11.7917L21.7083 13.3333C22.875 14.3749 23.5833 15.8334 23.5833 17.4999C23.5833 19.5417 22.5 21.3333 20.8332 22.2914L19.0832 20.5414C20.3333 20.0831 21.2081 18.9164 21.2081 17.4999C21.2081 16.4998 20.7498 15.583 19.9999 14.9997L17.9582 17.0414L17.9585 27.9164L12.0418 22.9999L7.50022 27.5415L5.8337 25.875L26.042 5.66667L27.7086 7.33318L24.9585 10.0833C26.9168 11.9167 28.1669 14.5833 28.1669 17.4999C28.1666 20.7914 26.5832 23.7499 24.1249 25.5833L24.125 25.5833Z' fill='white'/%3E%3C/svg%3E%0A");
}

.ven-top-slider__button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  transition: all 0.3s ease;
  z-index: 1;
  cursor: pointer;
  outline: none;
}

.ven-top-slider__button svg[role="img"] {
  width: 88px;
  height: 88px;
}

.ven-top-slider__button svg[role="img"]:nth-child(2) {
  display: none;
}

.ven-top-slider .item-intro {
  padding: 40px 0;
}

.ven-top-slider .item-intro .wrap-img {
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .ven-top-slider .item-intro .wrap-img {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }
}

@media (min-width: 1600px) {
  .ven-top-slider .item-intro .wrap-img {
    max-width: 15vw;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2.5vw;
  }
}

.ven-top-slider .item-intro .img-drop {
  padding-top: 100%;
  display: block;
}

.ven-top-slider .item-intro .wrap-text {
  width: 100%;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1600px) {
  .ven-top-slider .item-intro .wrap-text {
    max-width: 22.5vw;
    margin-left: auto;
    margin-right: auto;
  }
}

.ven-top-slider .item-intro .wrap-text .title-medium {
  font-family: "Circular Std";
  font-size: 34px;
  font-weight: 700;
  margin-bottom: 15px;
}

@media (min-width: 992px) {
  .ven-top-slider .item-intro .wrap-text .title-medium {
    font-size: 42px;
  }
}

@media (min-width: 1600px) {
  .ven-top-slider .item-intro .wrap-text .title-medium {
    font-size: 2.1vw;
    margin-bottom: 0.75vw;
  }
}

.ven-top-slider .item-intro .wrap-text .desc {
  line-height: 1.75em;
  letter-spacing: -0.195652px;
}

@media (max-width: 767px) {
  .ven-top-slider .item-intro .wrap-text .desc {
    padding: 0 15px;
  }
}

.ven-top-slider .item-intro .wrap-text .desc a {
  color: #000;
  background: #62bd89;
  display: block;
  max-width: 250px;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "Circular Std";
  position: relative;
  z-index: 0;
  cursor: pointer;
  border: 1px solid #000;
  padding: 10px 0;
  margin: 20px auto 0;
}

.ven-programs-wrapper {
  padding: 80px 0;
  background-image: url(../img/bg-program.jpg);
  background-repeat: no-repeat;
  background-size: 400px auto;
  background-position: right -150px top -80px;
}

@media (min-width: 1200px) {
  .ven-programs-wrapper {
    padding: 150px 0 110px;
    background-size: 773px auto;
    background-position: right top -150px;
  }
}

.ven-programs-wrapper__header {
  margin-bottom: 50px;
}

@media (min-width: 1200px) {
  .ven-programs-wrapper__header {
    margin-bottom: 94px;
  }
}

@media (min-width: 992px) {
  .ven-programs-wrapper__description {
    font-size: 18px;
  }
}

.ven-programs-wrapper__footer {
  text-align: center;
  margin-top: 80px;
}

.ven-programs-wrapper__footer .btn-common::after {
  border-color: #605e98;
}

@media (min-width: 768px) {
  .ven-modal-subscribe .modal-dialog {
    max-width: 600px;
  }
}

.ven-subscribe-card {
  background: #f4f2ed url(../img/bg-subscribe.svg) no-repeat;
  background-position: 50% 10px;
  background-size: 95% auto;
  padding: 15px;
}

@media (min-width: 992px) {
  .ven-subscribe-card {
    padding: 30px;
  }
}

.ven-subscribe-card__header {
  text-align: center;
  padding-bottom: 15px;
  margin-bottom: 15px;
  position: relative;
}

@media (min-width: 992px) {
  .ven-subscribe-card__header {
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
}

.ven-subscribe-card__header::before {
  content: "";
  display: block;
  width: 38px;
  border-bottom: thin solid #000;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.ven-subscribe-card__header img {
  width: 164px;
}

.ven-subscribe-card__body {
  font-family: "Circular Std";
  text-align: center;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .ven-subscribe-card__body {
    margin-bottom: 40px;
  }
}

.ven-subscribe-card__body h3 {
  font-size: 18px;
  font-weight: 900;
  line-height: 1.81818;
}

@media (min-width: 992px) {
  .ven-subscribe-card__body h3 {
    font-size: 22px;
  }
}

.ven-subscribe-card__body h2 {
  font-size: 60px;
  font-weight: 900;
  line-height: 1;
  color: #62bd89;
  text-shadow: 0.06667em 0.05em #000;
}

@media (min-width: 992px) {
  .ven-subscribe-card__body h2 {
    font-size: 120px;
  }
}

.ven-subscribe-card__body hr {
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.ven-subscribe-card__body p small {
  font-size: 10px;
  color: #979797;
}

.ven-subscribe-form {
  position: relative;
}

@media (min-width: 992px) {
  .ven-subscribe-form {
    max-width: 79.05983%;
    margin-left: auto;
    margin-right: auto;
  }
}

.ven-subscribe-form .gform_validation_errors {
  display: none;
}

.ven-subscribe-form .gfield:not(:last-child) {
  margin-bottom: 25px;
}

.ven-subscribe-form .gfield.is-email {
  position: relative;
}

.ven-subscribe-form .gfield.is-email .ginput_container {
  position: relative;
  z-index: 2;
}

.ven-subscribe-form .gfield.is-email::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-color: #62bd89;
  border-style: solid;
  border-width: 0 2px 2px 0;
  position: absolute;
  right: -6px;
  bottom: -6px;
}

.ven-subscribe-form .gfield_label {
  display: none;
}

.ven-subscribe-form .ginput_container input[type="text"],
.ven-subscribe-form .ginput_container input[type="email"] {
  display: block;
  width: 100%;
  background-color: transparent;
  border: thin solid #000;
  border-radius: 0;
  padding: 18px 120px 18px 20px;
  outline: none;
}

.ven-subscribe-form .gfield_checkbox li {
  display: flex;
  user-select: none;
}

.ven-subscribe-form .gfield_checkbox li input {
  appearance: none;
  width: 26px;
  height: 26px;
  border: thin solid #000;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 20px;
  border-radius: 0;
  margin-right: 15px;
}

.ven-subscribe-form .gfield_checkbox li input:checked {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='19' height='19' fill='black' stroke='black'/%3E%3C/svg%3E%0A");
}

.ven-subscribe-form .gfield_checkbox li label {
  flex: 1;
  margin-bottom: 0;
  padding-top: 5px;
  font-size: 12px;
}

.ven-subscribe-form .gform_button {
  background-color: transparent;
  border: none;
  border-radius: 0;
  text-transform: uppercase;
  letter-spacing: 0.125em;
  padding: 20px 20px 18px;
}

.ven-subscribe-form .gform_footer {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
}

.ven-subscribe-form .gform_ajax_spinner {
  top: 15px;
  right: 100px;
}

.v-block-banner-info {
  margin: 40px 0 0;
}

.v-banner-info {
  min-height: 300px;
  margin: 0;
}

.v-banner-info__header {
  padding-left: 0;
  padding-right: 0;
}

.v-banner-info__image {
  padding-bottom: 74.28571%;
}

@media (min-width: 992px) {
  .v-banner-info__image {
    padding-bottom: 0;
    height: 100%;
  }
}

.v-banner-info__body {
  padding: 20px 15px;
  background-color: #62bd89;
  color: #ffffff;
}

@media (min-width: 992px) {
  .v-banner-info__body {
    padding: 30px 60px;
  }
}

.v-banner-info__content {
  max-width: 600px;
}

.v-banner-info__subtitle {
  margin-bottom: 0;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-family: "Circular Std";
  font-weight: 500;
}

@media (min-width: 992px) {
  .v-banner-info__subtitle {
    font-size: 18px;
  }
}

.v-banner-info__title {
  margin-bottom: 1rem;
  font-family: "Circular Std";
  font-weight: bold;
}

.v-banner-info__description {
  margin-bottom: 24px;
}

.v-banner-info__more p {
  font-weight: 500;
  margin-bottom: 10px;
}

.v-banner-info__button {
  display: inline-block;
  background-color: #fff;
  border: none;
  border-radius: 0;
  padding: 14px 20px;
  font-size: 14px;
  color: #62bd89;
  text-align: left;
  outline: none;
}

.v-banner-info__button:hover {
  text-decoration: none;
  color: #62bd89;
}

.v-banner-info__button:focus {
  outline: none;
}

section.banner-product {
  position: relative;
}

@media (max-width: 991px) {
  section.banner-product {
    background: #fff;
  }
}

@media print {
  section.banner-product {
    display: block;
  }
}

section.banner-product .img-drop {
  height: 30.68182vw;
  max-height: 540px;
  min-height: 300px;
}

@media (min-width: 1600px) {
  section.banner-product .img-drop {
    max-height: 27vw;
  }
}

@media print {
  section.banner-product .img-drop {
    max-height: 360px;
  }
}

section.banner-product .text-cover {
  text-align: center;
  z-index: 1;
  padding: 30px 20px;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 992px) {
  section.banner-product .text-cover {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    padding: 50px 85px 50px;
    max-width: 620px;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
  }
}

@media (min-width: 1600px) {
  section.banner-product .text-cover {
    max-width: 31vw;
    margin-left: auto;
    margin-right: auto;
    padding: 2.5vw 4.25vw 2.5vw;
  }
}

section.banner-product .text-cover.big {
  max-width: 875px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 992px) {
  section.banner-product .text-cover.big {
    padding: 25px 65px 45px;
  }
}

@media (min-width: 992px) {
  section.banner-product .text-cover.big.for-testimonial {
    padding: 40px 65px 45px;
  }
}

section.banner-product .text-cover.big .des-page {
  margin-bottom: 18px;
}

section.banner-product .text-cover.big .des-page p {
  margin-bottom: 18px;
}

section.banner-product .text-cover.big .btn-common {
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}

section.list-product .row-list-product {
  margin-top: 30px;
}

@media (min-width: 768px) {
  section.list-product .row-list-product {
    margin-top: 70px;
  }
}

@media (min-width: 1600px) {
  section.list-product .row-list-product {
    margin-top: 3.5vw;
  }
}

@media (max-width: 767px) {
  section.list-product .row-list-product .col-md-4:not(:last-child) {
    margin-bottom: 40px;
  }
}

section.list-book .row-list-book {
  margin-top: 30px;
}

@media (min-width: 768px) {
  section.list-book .row-list-book {
    margin-top: 70px;
  }
}

@media (min-width: 1600px) {
  section.list-book .row-list-book {
    margin-top: 3.5vw;
  }
}

@media (max-width: 767px) {
  section.list-book .row-list-book .col-md-4:not(:last-child) {
    margin-bottom: 40px;
  }
}

.item-product {
  position: relative;
  height: 100%;
  padding: 50px 0;
  transition: all .5s ease;
}

@media (min-width: 1600px) {
  .item-product {
    padding-top: 2.5vw;
    padding-bottom: 2.5vw;
  }
}

@media (max-width: 767px) {
  .item-product {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

.item-product.is-disabled {
  pointer-events: none;
  user-select: none;
  filter: grayscale(1);
  opacity: 0.5;
}

.item-product .img-drop {
  padding-top: 100%;
  margin-bottom: 20px;
}

@media (min-width: 1600px) {
  .item-product .img-drop {
    margin-bottom: 1vw;
  }
}

.item-product .name {
  font: 700 22px/normal "Circular Std";
  text-align: center;
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .item-product .name {
    font: 700 28px/normal "Circular Std";
  }
}

@media (min-width: 1600px) {
  .item-product .name {
    font-size: 1.4vw;
  }
}

.item-product .price {
  text-align: center;
  font: 500 20px/normal "Circular Std";
}

@media (min-width: 1600px) {
  .item-product .price {
    font-size: 1vw;
  }
}

.item-product .addcart-btn {
  position: absolute;
  top: 5px;
  right: 20%;
}

@media (min-width: 768px) {
  .item-product .addcart-btn {
    top: 10%;
    right: 14%;
    opacity: 0;
    visibility: hidden;
  }
}

@media (min-width: 1600px) {
  .item-product .addcart-btn {
    top: -1.9vw;
  }
}

.item-product .wrap-product-information {
  transition: all .3s ease;
}

.item-product .wrap-product-information:hover {
  background-color: transparent !important;
}

.item-product.no-event {
  pointer-events: none;
}

.wrap-buttons-cart a {
  margin: 0 auto;
}

.addcart-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff url("../img/cart.svg") no-repeat center right 12px/28px auto;
  transition: all 0.5s ease;
  display: block;
  position: relative;
  z-index: 3;
}

@media (min-width: 768px) {
  .addcart-btn {
    width: 72px;
    height: 72px;
    background: #fff url("../img/cart.svg") no-repeat center right 20px/38px auto;
  }
}

@media (min-width: 1600px) {
  .addcart-btn {
    width: 3.5vw;
    height: 3.5vw;
    background-position: center right 0.9vw;
    background-size: 1.9vw auto;
  }
}

.addcart-btn span {
  width: 18px;
  height: 18px;
  background: #62bd89;
  position: absolute;
  top: 9px;
  right: 7px;
  border-radius: 50%;
}

@media (min-width: 768px) {
  .addcart-btn span {
    top: 15px;
    right: 12px;
  }
}

@media (min-width: 1600px) {
  .addcart-btn span {
    width: 0.9vw;
    height: 0.9vw;
    top: 0.75vw;
    right: 0.6vw;
  }
}

.addcart-btn span:after, .addcart-btn span:before {
  content: "";
  position: absolute;
  top: 48%;
  left: calc(50% - 5px);
  width: 10px;
  height: 2px;
  background: #fff;
  transform: translate3d(0, 0, 0);
}

@media (min-width: 1600px) {
  .addcart-btn span:after, .addcart-btn span:before {
    width: 0.5vw;
    height: 0.1vw;
    left: calc(50% - 0.25vw);
  }
}

.addcart-btn span:after {
  transform: rotate(90deg) translate3d(0, 0, 0);
}

.item-book {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-flow: column wrap;
  height: 100%;
  transition: all 0.5s ease;
}

@media (min-width: 1600px) {
  .item-book {
    padding-top: 2.5vw;
    padding-bottom: 2.5vw;
  }
}

.item-book .img-drop {
  padding-top: 66.60988%;
  margin-bottom: 20px;
}

@media (min-width: 1600px) {
  .item-book .img-drop {
    margin-bottom: 1vw;
  }
}

.item-book .img-drop img {
  object-fit: contain;
}

.item-book .name {
  font: 700 22px/normal "Circular Std";
  text-align: center;
  max-width: 234px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .item-book .name {
    font: 700 28px/normal "Circular Std";
  }
}

@media (min-width: 1600px) {
  .item-book .name {
    font-size: 1.4vw;
    margin-bottom: 1vw;
    max-width: 11.7vw;
    margin-left: auto;
    margin-right: auto;
  }
}

.item-book .price {
  text-align: center;
  font: 500 20px/normal "Circular Std";
  margin-bottom: 16px;
}

@media (min-width: 1600px) {
  .item-book .price {
    font-size: 1vw;
  }
}

.item-book .price del {
  opacity: .45;
}

.item-book .price ins {
  text-decoration: none;
}

.item-book .addcart-btn {
  position: absolute;
  top: 5px;
  right: 20%;
}

@media (min-width: 768px) {
  .item-book .addcart-btn {
    top: 10%;
    right: 13%;
    opacity: 0;
    visibility: hidden;
  }
}

@media (min-width: 1600px) {
  .item-book .addcart-btn {
    top: -0.25vw;
  }
}

.item-book.highlight, .item-book:hover {
  background: transparent !important;
}

.item-book.highlight .addcart-btn, .item-book:hover .addcart-btn {
  opacity: 1;
  visibility: visible;
}

.item-book.no-event {
  pointer-events: none;
}

section.category-nav {
  background-color: #f4f2ed;
}

section.category-nav .tab-navi-learn {
  border-bottom: solid 2px #E8E6DE;
  background-color: #f4f2ed;
}

@media (min-width: 992px) {
  section.category-nav .nav-sticky-onscroll.stick {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    padding-top: 60px;
    z-index: 2;
  }
  section.category-nav .nav-sticky-onscroll.stick + .tab-content {
    padding-top: 189px;
  }
}

.product-single {
  position: relative;
}

.product-single .add-to-cart del {
  opacity: .45;
}

.product-single .add-to-cart ins {
  text-decoration: none;
}

.wrap-flex {
  display: flex;
}

@media (max-width: 991px) {
  .wrap-flex {
    flex-direction: column;
    align-items: center;
  }
}

.product-img-wrap {
  width: 100%;
  max-width: 440px;
  margin-right: 30px;
}

@media (min-width: 1600px) {
  .product-img-wrap {
    max-width: 22vw;
  }
}

@media (max-width: 991px) {
  .product-img-wrap {
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.cta-add-to-cart {
  margin-bottom: 30px;
}

.cta-add-to-cart .ven-price-custom {
  display: none !important;
}

.cta-add-to-cart .variations_form .variations {
  width: 100%;
}

.cta-add-to-cart .variations_form .variations tr td {
  padding-bottom: 10px;
  padding-top: 10px;
  vertical-align: middle;
}

.cta-add-to-cart .variations_form .variations tr td label {
  margin-bottom: 0;
  font-weight: 700;
}

.cta-add-to-cart .variations_form .variations tr td .reset_variations {
  display: none !important;
}

.cta-add-to-cart .variations_form .variations select {
  border-radius: 0;
  outline: 0;
  width: 100%;
  height: 50px;
  font-size: 16px;
  padding: 12px 15px;
  border: 1px solid #000;
  background: transparent;
}

@media (min-width: 992px) {
  .cta-add-to-cart .variations_form .variations select {
    font-size: 14px;
  }
}

.cta-add-to-cart .variations_form .single_variation_wrap .single_variation {
  display: none !important;
}

.cta-add-to-cart .variations_form .single_variation_wrap .qty-box-wrap {
  margin-bottom: 15px;
}

.cta-add-to-cart .variations_form .single_variation_wrap .qty-box-wrap label {
  font-weight: 700;
}

.cta-add-to-cart .variations_form .single_variation_wrap .qty-box-wrap .qty-box {
  width: 100%;
}

.cta-add-to-cart .variations_form .single_variation_wrap .single_add_to_cart_button,
.cta-add-to-cart .variations_form .single_variation_wrap #product-option-outstock {
  width: 100%;
}

.cta-add-to-cart .variations_form .single_variation_wrap #product-option-outstock {
  cursor: no-drop;
}

.product-single-info {
  width: 550px;
  margin-left: auto;
}

@media (min-width: 1600px) {
  .product-single-info {
    width: 27.5vw;
  }
}

@media (max-width: 991px) {
  .product-single-info {
    width: 100%;
  }
}

.product-single-info .product-description {
  max-height: 90px !important;
  overflow-x: hidden;
  padding-right: 25px;
  font-size: 18px;
  line-height: 1.7em;
  margin-bottom: 30px;
}

.product-single-info .product-description.medium-height {
  max-height: 260px !important;
}

.product-single-info .product-description.high-height {
  max-height: 450px !important;
}

.product-single-info .product-description.no-scroll {
  max-height: 100% !important;
}

@media (max-width: 575px) {
  .product-single-info .product-description {
    font-size: 16px;
    line-height: 1.8em;
    margin-bottom: 30px;
  }
}

.scroll-element.scroll-y .scroll-bar {
  left: -5px !important;
  width: 4px !important;
  opacity: 1 !important;
  border-radius: 0 !important;
  background-color: #62bd89 !important;
}

.scroll-element.scroll-y .scroll-element_size {
  left: -5px !important;
  width: 4px !important;
  background: #DCDCDC !important;
  top: 0 !important;
  height: calc(100% - 4px) !important;
}

.owl-product-single {
  margin-bottom: 190px;
}

@media (max-width: 991px) {
  .owl-product-single {
    margin-bottom: 135px;
  }
}

@media (max-width: 575px) {
  .owl-product-single {
    width: 100%;
  }
}

.owl-product-single .img-drop img {
  object-fit: cover;
}

.owl-product-single .img-drop.contain-image img {
  object-fit: contain;
}

.owl-product-single .owl-dots {
  position: absolute;
  bottom: -120px;
  transform: translate3d(0, 0, 0);
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (min-width: 1600px) {
  .owl-product-single .owl-dots {
    bottom: -6vw;
  }
}

@media (max-width: 575px) {
  .owl-product-single .owl-dots {
    position: absolute;
    top: auto;
    right: auto;
    bottom: -120px;
    width: 100%;
    transform: translate3d(0, 0, 0);
    display: block;
    text-align: center;
  }
}

.owl-product-single button.owl-dot {
  margin-top: 15px;
  min-width: 85px;
  height: 85px;
  outline: none;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all .3s ease;
  position: relative;
  transform: translate3d(0, 0, 0);
  background-color: #fff;
  border-radius: 50%;
  margin: 0 12px;
}

@media (min-width: 2000px) {
  .owl-product-single button.owl-dot {
    height: 4.25vw;
    min-width: 4.25vw;
    margin-top: 0.75vw;
  }
}

@media (max-width: 575px) {
  .owl-product-single button.owl-dot {
    min-width: 65px;
    height: 65px;
    margin: 0 7px;
  }
}

.owl-product-single button.owl-dot:before {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border: 4px solid #f4f2ed;
  border-radius: 50%;
  transition: all .3s ease;
  opacity: 0;
  z-index: 2;
}

.owl-product-single button.owl-dot:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: all .3s ease;
  opacity: 0;
  border: 5px solid transparent;
}

.owl-product-single button.owl-dot.active:before {
  opacity: 1;
}

.owl-product-single button.owl-dot.active:after {
  border-color: #62bd89;
  opacity: 1;
}

.owl-product-single .owl-item.animated {
  animation-duration: .5s;
}

.owl-product-single.for-book .owl-item > div {
  max-width: 410px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
}

.owl-product-single.for-book .img-drop img {
  object-fit: contain;
}

.owl-product-single.for-book button.owl-dot {
  background-size: 120% 120%;
}

select.qty-box {
  width: 60px;
  display: inline-block;
  height: 60px;
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  border: 1px solid #000;
  color: #000;
  background: transparent;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;
  padding-left: 24px;
}

@media (max-width: 575px) {
  select.qty-box {
    height: 50px;
    width: 50px;
    padding-left: 19px;
  }
}

.product-info-tab {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid #000;
  margin-bottom: 28px;
  gap: 20px;
}

@media (min-width: 768px) {
  .product-info-tab {
    justify-content: flex-start;
  }
}

@media (min-width: 1200px) {
  .product-info-tab {
    gap: 40px;
  }
}

.product-info-tab > li {
  margin-bottom: 8px;
}

.product-info-tab > li .nav-link {
  padding: 0;
  font: 400 20px/1.25em "Circular Std";
  color: #000;
  position: relative;
  display: inline-block;
}

@media (max-width: 1199px) {
  .product-info-tab > li .nav-link {
    font-size: 16px;
  }
}

@media (max-width: 350px) {
  .product-info-tab > li .nav-link {
    font-size: 14px;
  }
}

.product-info-tab > li .nav-link:before {
  content: '';
  position: absolute;
  bottom: -16px;
  height: 5px;
  width: 100%;
  left: 0;
  background: #000;
  transform: translate3d(0, 50%, 0);
  opacity: 0;
  transition: all .3s ease;
}

.product-info-tab > li .nav-link.active:before {
  opacity: 1;
}

.product-tag-list {
  padding-left: 0;
  list-style-type: none;
}

.product-tag-list > li {
  padding: 10px 20px;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 6px;
  display: inline-block;
  vertical-align: top;
  margin-right: 6px;
  margin-bottom: 6px;
}

.product-tag-list > li:before {
  display: none;
}

@media (max-width: 575px) {
  .product-tag-list > li {
    padding: 6px 10px;
    font-size: 12px;
  }
}

.tab-info {
  margin-bottom: 26px;
}

.tab-info .bg-gray {
  padding: 8px;
}

.tab-info ul {
  margin-bottom: 16px;
  font-size: 16px;
}

.tab-info ul li {
  margin-bottom: 6px;
}

.tab-info ul li:before {
  content: "•";
  color: #62bd89;
  padding-right: 10px;
}

.tab-info p {
  margin-bottom: 1rem;
}

.table-nutrition {
  border: 3px solid #62bd89;
}

.table-nutrition table {
  width: 100%;
}

.table-nutrition table > thead > tr > th {
  text-align: center;
  font-family: "Circular Std";
  color: #62bd89;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 15px 10px;
  border-bottom: 1px solid #62bd89;
  border-top: 1px solid #62bd89;
  border-right: 1px solid #62bd89;
}

@media (max-width: 575px) {
  .table-nutrition table > thead > tr > th {
    padding: 10px;
    font-size: 12px;
  }
}

.table-nutrition table > thead > tr > th:last-child {
  border-right: 0;
}

.table-nutrition table > tbody > tr > td {
  border-right: 1px solid #62bd89;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 15px;
}

@media (max-width: 575px) {
  .table-nutrition table > tbody > tr > td {
    font-size: 14px;
  }
}

.table-nutrition table > tbody > tr > td:last-child {
  border-right: 0;
}

.table-nutrition table > tbody > tr > td:not(:first-child) {
  text-align: center;
}

.table-nutrition table > tbody > tr:last-child > td {
  padding-bottom: 12px;
}

.table-nutrition table > tbody > tr.basic-nutrition > td {
  padding-top: 12px;
}

.table-nutrition table > tbody > tr.basic-nutrition > td:first-child {
  font-weight: 600;
  padding-left: 30px;
}

@media (max-width: 575px) {
  .table-nutrition table > tbody > tr.basic-nutrition > td:first-child {
    padding-left: 15px;
  }
}

.table-nutrition table > tbody > tr.details-nutrition > td {
  padding-top: 6px;
}

.table-nutrition table > tbody > tr.details-nutrition > td:first-child {
  padding-left: 50px;
}

@media (max-width: 575px) {
  .table-nutrition table > tbody > tr.details-nutrition > td:first-child {
    padding-left: 15px;
  }
}

.serving {
  padding: 20px 12px 10px;
}

@media (max-width: 575px) {
  .serving {
    padding: 10px 12px 5px;
  }
}

.serving-item {
  font-weight: 600;
  margin-right: 35px;
  margin-bottom: 5px;
  display: inline-block;
}

@media (max-width: 575px) {
  .serving-item {
    margin-right: 15px;
    font-size: 14px;
  }
}

.small-title {
  font: 700 15px/1.8em "Circular Std";
  letter-spacing: 1.88px;
  text-transform: uppercase;
  margin-bottom: .6em;
}

.education-article a:hover .article-wrap {
  opacity: .8;
}

.education-article .article-wrap {
  display: flex;
  align-items: center;
  background: #fff;
  transition: all .3s ease;
}

@media (max-width: 575px) {
  .education-article .article-wrap {
    margin-bottom: 15px;
  }
}

.education-article .article-wrap .img-wrap {
  min-width: 36%;
}

.education-article .article-wrap .article-excerpt {
  flex: auto;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  height: 44px;
  overflow: hidden;
  font: 700 16px/1.25em "Circular Std";
  letter-spacing: -.17px;
}

@media (max-width: 575px) {
  .education-article .article-wrap .article-excerpt {
    font-size: 15px;
    height: 38px;
  }
}

.to-below-section {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 55px;
  cursor: pointer;
  z-index: 2;
  transition: all .3s ease;
}

.to-below-section:hover {
  opacity: .7;
}

@media (max-width: 991px) {
  .to-below-section {
    bottom: 35px;
  }
}

.ratio-extra-info {
  padding-top: 119.56522%;
}

@media (min-width: 576px) {
  .extra-info {
    padding-top: 50px;
  }
}

.row-collapse {
  padding-bottom: 16px;
}

.row-collapse > a[data-toggle=collapse] {
  display: block;
  margin-bottom: 5px;
  position: relative;
}

.row-collapse > a[data-toggle=collapse]:after {
  content: '';
  position: absolute;
  right: 0;
  top: 3px;
  width: 22px;
  height: 22px;
  background: transparent url("../img/plus-icon.svg") center/22px auto no-repeat;
}

.row-collapse > a[data-toggle=collapse][aria-expanded=true]:after {
  background: transparent url("../img/minus-icon.svg") center/22px auto no-repeat;
}

.font-product {
  padding-right: 25px;
  font-size: 18px;
  line-height: 1.7em;
}

@media (max-width: 575px) {
  .font-product {
    font-size: 16px;
  }
}

@media print {
  .related-recipes {
    display: none;
    background: #ffffff !important;
  }
}

@media print {
  .related-recipes .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.related-recipes .wrap-text {
  display: flex;
  align-items: flex-start;
  margin-bottom: 45px;
}

@media (max-width: 575px) {
  .related-recipes .wrap-text {
    flex-direction: column;
    margin-bottom: 35px;
  }
  .related-recipes .wrap-text .font-product {
    margin-bottom: 15px;
  }
}

.related-recipes .row-recipes {
  margin-left: -1.5px;
  margin-right: -1.5px;
}

.related-recipes .row-recipes > .col,
.related-recipes .row-recipes > [class*="col"] {
  padding-left: 1.5px;
  padding-right: 1.5px;
}

.hover-toggle,
.item-post {
  background: #fff;
  transition: all .5s ease;
  position: relative;
}

@media (max-width: 991px) {
  .hover-toggle,
  .item-post {
    margin-bottom: 3px;
  }
}

@media (max-width: 575px) {
  .hover-toggle,
  .item-post {
    margin-bottom: 15px;
  }
}

.hover-toggle .img-drop,
.item-post .img-drop {
  background-color: #000;
  display: block;
}

.hover-toggle .wrap-info,
.item-post .wrap-info {
  padding: 20px 25px 0;
  overflow: hidden;
}

.hover-toggle .wrap-info h3,
.item-post .wrap-info h3 {
  font: 700 18px/1.3em "Circular Std";
  letter-spacing: -.2px;
  margin-bottom: 25px;
}

@media (max-width: 575px) {
  .hover-toggle .wrap-info h3,
  .item-post .wrap-info h3 {
    height: auto !important;
  }
}

.hover-toggle .wrap-info .d-flex,
.item-post .wrap-info .d-flex {
  margin-bottom: 20px;
}

.hover-toggle .wrap-info .qty-info,
.hover-toggle .wrap-info .time-info,
.item-post .wrap-info .qty-info,
.item-post .wrap-info .time-info {
  font: 400 16/1.2em "Circular Std";
  letter-spacing: -.17px;
}

.hover-toggle .wrap-info .qty-info,
.item-post .wrap-info .qty-info {
  padding-right: 5px;
}

.hover-toggle .wrap-info .time-info,
.item-post .wrap-info .time-info {
  margin-left: auto;
}

.hover-toggle .wrap-info .btn-common,
.item-post .wrap-info .btn-common {
  max-width: 100%;
}

.hover-toggle .wrap-info .row-read-btn,
.item-post .wrap-info .row-read-btn {
  transition: all .3s ease;
  margin: auto;
  background: #fff;
  padding-bottom: 25px;
}

@media (min-width: 992px) {
  .hover-toggle .wrap-info .row-read-btn,
  .item-post .wrap-info .row-read-btn {
    opacity: 0;
    visibility: hidden;
    height: 0;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 0 20px 20px;
    text-align: center;
  }
}

@media (min-width: 992px) {
  .hover-toggle:not(.lock):hover .row-read-btn,
  .item-post:not(.lock):hover .row-read-btn {
    opacity: 1;
    visibility: visible;
    height: 80px;
    z-index: 1;
  }
}

@media (min-width: 1600px) {
  .hover-toggle:not(.lock):hover .row-read-btn,
  .item-post:not(.lock):hover .row-read-btn {
    height: 4vw;
  }
}

.hover-toggle.lock .img-drop,
.item-post.lock .img-drop {
  background-color: rgba(0, 0, 0, 0);
}

.hover-toggle.lock .img-drop img,
.hover-toggle.lock .img-drop .label-servers,
.item-post.lock .img-drop img,
.item-post.lock .img-drop .label-servers {
  opacity: 0.5;
}

.hover-toggle.lock .wrap-info,
.item-post.lock .wrap-info {
  opacity: 0.5;
}

@media print {
  .hover-toggle .row-read-btn,
  .item-post .row-read-btn {
    opacity: 1 !important;
    visibility: visible !important;
    height: 80px !important;
    z-index: 1;
    position: relative !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 20px !important;
    margin-bottom: 20px !important;
  }
}

.absolute-video {
  margin-top: 20px;
  margin-bottom: 20px;
}

.absolute-video .img-drop {
  padding-top: 56.25%;
  height: auto;
  background: #E8E6DE;
}

.absolute-video .home-play-btn {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 50%;
  background: #62bd89;
  border: 1px solid #000;
  border-radius: 50%;
  z-index: 1;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  outline: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  transition: all .3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 575px) {
  .absolute-video .home-play-btn {
    width: 50px;
    height: 50px;
  }
}

.absolute-video .home-play-btn:before {
  background: #fff;
  transition: all .35s ease;
  transform: scale(1);
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
}

.absolute-video .home-play-btn i {
  z-index: 2;
  position: relative;
  background: transparent url("../img/arrow-triangle-right.svg") no-repeat center/auto 100%;
  width: 16px;
  height: 16px;
  display: inline-block;
  left: 2px;
}

@media (max-width: 575px) {
  .absolute-video .home-play-btn i {
    font-size: 15px;
  }
}

.absolute-video .home-play-btn:hover:before {
  transform: scale(0);
}

@media (min-width: 1200px) {
  section.related-book .title-product {
    max-width: 270px;
    margin-top: 30px;
  }
}

@media (max-width: 991px) {
  section.related-book .for-intro {
    padding: 0;
  }
}

.product-img-wrap .single_variation_wrap {
  display: none !important;
}

.product-img-wrap .variations_form.cart {
  padding-top: 20px;
}

.product-img-wrap .variations_form.cart .reset_variations {
  display: none !important;
}

.product-img-wrap .variations {
  margin: auto;
  max-width: 360px;
  width: 100%;
}

@media (max-width: 575px) {
  .product-img-wrap .variations {
    max-width: 290px;
  }
}

.product-img-wrap .variations tbody > tr > td {
  padding-bottom: 15px;
  vertical-align: middle;
}

.product-img-wrap .variations tbody > tr > td:first-child {
  padding-right: 20px;
}

@media (max-width: 575px) {
  .product-img-wrap .variations tbody > tr > td:first-child {
    padding-right: 10px;
  }
}

.product-img-wrap .variations tbody .label {
  text-align: left;
}

.product-img-wrap .variations tbody .label label {
  text-transform: uppercase;
  margin-bottom: 0;
  font-weight: 500;
}

@media (max-width: 575px) {
  .product-img-wrap .variations tbody .label label {
    font-size: 14px;
  }
}

.product-img-wrap .variations tbody .value select {
  width: 100%;
  display: inline-block;
  height: 60px;
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  border: 1px solid #000;
  color: #000;
  background: transparent;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  padding: 0 30px;
}

@media (max-width: 575px) {
  .product-img-wrap .variations tbody .value select {
    font-size: 14px;
    padding: 0 20px;
    height: 50px;
  }
}

.extended-2-col-benefits {
  display: none !important;
}

@media (max-width: 991px) {
  .extended-2-col-benefits.active.extended-2-col-benefit-mobile {
    display: block !important;
  }
}

@media (min-width: 992px) {
  .extended-2-col-benefits.active.extended-2-col-benefit-desktop {
    display: block !important;
  }
}

.extended-2-col-benefits.extended-2-col-benefit-desktop {
  margin-top: 1.5rem;
}

.extended-2-col-benefits.extended-2-col-benefit-desktop .row .cs-6 {
  padding: 0 15px;
}

.extended-2-col-benefits.extended-2-col-benefit-desktop .row .cs-6:nth-child(odd) {
  width: calc(100% - 580px);
}

.extended-2-col-benefits.extended-2-col-benefit-desktop .row .cs-6:nth-child(even) {
  width: 580px;
}

.extended-2-col-benefits p {
  margin-bottom: 1rem;
}

.extended-2-col-benefits ul {
  margin-bottom: 1rem;
  list-style: disc;
  padding-left: 18px;
}

._df_book, .main-pdf {
  background-color: #f4f2ed !important;
}

@media (max-width: 991px) {
  .about-nutritional {
    padding-top: 20px;
    padding-bottom: 50px;
  }
}

@media (max-width: 991px) {
  .about-nutritional .wrap-text-4-item {
    padding: 40px 0;
  }
}

.about-nutritional .img-drop {
  padding-top: 119.56522%;
}

.our-promise {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (min-width: 992px) {
  .our-promise {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.our-promise .list-nutritional {
  background-color: #FFF;
  padding: 40px 20px;
  margin-top: 40px;
  position: relative;
  z-index: 1;
}

@media (min-width: 992px) {
  .our-promise .list-nutritional {
    padding: 80px 50px;
  }
}

@media (min-width: 1600px) {
  .our-promise .list-nutritional {
    padding: 4vw 2.5vw;
  }
}

.our-promise .list-nutritional .items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.our-promise .list-nutritional .items:not(:last-child) {
  border-bottom: 1px solid rgba(98, 189, 137, 0.4);
  margin-bottom: 30px;
  padding-bottom: 30px;
}

@media (min-width: 992px) {
  .our-promise .list-nutritional .items:not(:last-child) {
    margin-bottom: 50px;
    padding-bottom: 50px;
  }
}

@media (min-width: 1600px) {
  .our-promise .list-nutritional .items:not(:last-child) {
    margin-bottom: 2.5vw;
    padding-bottom: 2.5vw;
  }
}

.our-promise .list-nutritional .items .icon {
  max-width: 80px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .our-promise .list-nutritional .items .icon {
    flex: 0 0 120px;
    margin-bottom: 0;
  }
}

@media (min-width: 1600px) {
  .our-promise .list-nutritional .items .icon {
    flex: 0 0 6vw;
  }
}

@media (min-width: 768px) {
  .our-promise .list-nutritional .items .wrap-item {
    flex: 0 0 calc(100% - 120px);
  }
}

.our-promise .list-nutritional .items .wrap-item h5 {
  font: 700 22px/normal "Circular Std";
  letter-spacing: -0.347826px;
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .our-promise .list-nutritional .items .wrap-item h5 {
    font-size: 28px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1600px) {
  .our-promise .list-nutritional .items .wrap-item h5 {
    font-size: 1.4vw;
    margin-bottom: 1vw;
  }
}

.our-promise .box-quote {
  text-align: center;
  position: relative;
}

.our-promise .box-quote:after, .our-promise .box-quote:before {
  content: '';
  position: absolute;
  background: url(../../upload/img-quote.png) no-repeat center/100%;
  width: 150px;
  height: 150px;
  z-index: 0;
}

@media (min-width: 768px) {
  .our-promise .box-quote:after, .our-promise .box-quote:before {
    width: 370px;
    height: 270px;
  }
}

@media (min-width: 1600px) {
  .our-promise .box-quote:after, .our-promise .box-quote:before {
    width: 18.5vw;
    height: 13.5vw;
  }
}

.our-promise .box-quote:before {
  top: -150px;
  left: -20px;
}

@media (min-width: 768px) {
  .our-promise .box-quote:before {
    left: -130px;
  }
}

@media (min-width: 1600px) {
  .our-promise .box-quote:before {
    left: -6.5vw;
    top: -7.5vw;
  }
}

.our-promise .box-quote:after {
  transform: rotate(180deg);
  bottom: -80px;
  right: -20px;
}

@media (min-width: 768px) {
  .our-promise .box-quote:after {
    right: -130px;
    bottom: -150px;
  }
}

@media (min-width: 1600px) {
  .our-promise .box-quote:after {
    right: -6.5vw;
    bottom: -4vw;
  }
}

.our-promise .box-quote .avatar {
  background-color: #E8E6DE;
  border-radius: 50%;
  width: 186px;
  height: 186px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 120px;
  position: relative;
}

@media (min-width: 1600px) {
  .our-promise .box-quote .avatar {
    width: 9.3vw;
    height: 9.3vw;
    margin-top: 6vw;
  }
}

.our-promise .box-quote .avatar:after {
  content: '';
  position: absolute;
  left: 50%;
  top: -90px;
  width: 1px;
  height: 60px;
  background-color: #000;
}

@media (min-width: 1600px) {
  .our-promise .box-quote .avatar {
    width: 9.3vw;
    height: 9.3vw;
  }
}

.our-promise .box-quote .avatar .img-drop {
  padding-top: 100%;
}

.our-promise .box-quote .avatar img {
  border-radius: 50%;
}

.our-promise .box-quote .desc {
  font: 500 20px/normal "Circular Std";
  font-style: italic;
  margin: 30px 0;
}

@media (min-width: 1600px) {
  .our-promise .box-quote .desc {
    font-size: 1vw;
    margin: 1.5vw 0;
  }
}

.our-promise .box-quote .signature {
  font-weight: 700;
  font-family: "Circular Std";
}

.our-promise .box-quote .signature span {
  display: block;
  color: #9B9B9B;
  font-weight: 300;
}

.about-contact {
  position: relative;
  z-index: 1;
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .about-contact {
    padding-top: 80px;
  }
}

@media (min-width: 992px) {
  .about-contact {
    padding-top: 150px;
  }
}

@media (min-width: 1600px) {
  .about-contact {
    min-height: auto;
    padding-bottom: 150px;
  }
}

.about-contact .wrap-contact {
  max-width: 666px;
}

@media (min-width: 1600px) {
  .about-contact .wrap-contact {
    max-width: 33.3vw;
  }
}

.about-contact .wrap-contact .validation_error {
  display: none;
}

.about-contact .wrap-contact ul li {
  margin-bottom: 20px;
}

.about-contact .wrap-contact ul li label {
  font-weight: 700;
  position: relative;
  width: 100%;
  display: block;
}

.about-contact .wrap-contact ul li label span {
  position: absolute;
  right: 0;
  top: 2px;
}

.about-contact .wrap-contact input:not([type="submit"]),
.about-contact .wrap-contact textarea {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  background-color: #62bd89;
  border: 1px solid #000;
  padding: 10px 20px;
  width: 100%;
}

.about-contact .wrap-contact input:not([type="submit"]):-webkit-autofill, .about-contact .wrap-contact input:not([type="submit"]):-webkit-autofill:hover, .about-contact .wrap-contact input:not([type="submit"]):-webkit-autofill:focus, .about-contact .wrap-contact input:not([type="submit"]):-webkit-autofill:active,
.about-contact .wrap-contact textarea:-webkit-autofill,
.about-contact .wrap-contact textarea:-webkit-autofill:hover,
.about-contact .wrap-contact textarea:-webkit-autofill:focus,
.about-contact .wrap-contact textarea:-webkit-autofill:active {
  transition: background 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000;
}

.about-contact .wrap-contact textarea {
  max-height: 150px;
  resize: none;
}

.about-contact .wrap-contact .gform_footer {
  border: 1px solid #000;
  max-width: 220px;
  display: inline-block;
  width: 100%;
  position: relative;
}

.about-contact .wrap-contact .gform_footer:after {
  content: '';
  position: absolute;
  bottom: -6px;
  right: -6px;
  width: 100%;
  height: 100%;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  transition: all 0.5s ease;
  z-index: -2;
}

@media (min-width: 1600px) {
  .about-contact .wrap-contact .gform_footer:after {
    bottom: -0.3vw;
    right: -0.3vw;
    border-width: 0.1vw;
  }
}

.about-contact .wrap-contact input[type="submit"] {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  background-color: transparent;
  display: block;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "Circular Std";
  padding: 20px 0;
  width: 100%;
  position: relative;
  cursor: pointer;
  z-index: 2;
}

.about-contact .wrap-contact input[type="submit"]:-webkit-autofill, .about-contact .wrap-contact input[type="submit"]:-webkit-autofill:hover, .about-contact .wrap-contact input[type="submit"]:-webkit-autofill:focus, .about-contact .wrap-contact input[type="submit"]:-webkit-autofill:active {
  transition: background 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000;
}

@media (min-width: 1600px) {
  .about-contact .wrap-contact input[type="submit"] {
    max-width: 11vw;
    font-size: 0.7vw;
  }
}

.about-contact .wrap-contact .validation_error {
  display: none;
}

.about-contact .wrap-contact .gform_ajax_spinner {
  right: 10px;
  top: 16px;
  width: 20px;
  height: auto;
}

.about-contact .wrap-contact .gform_validation_container {
  display: none;
}

@media print {
  section.blog-content {
    background: #fff !important;
  }
}

section.blog-content .title-product {
  margin-bottom: 40px;
}

section.blog-content .img-food {
  margin-bottom: 0;
  padding-top: 24.44444%;
}

section.blog-content .intro {
  font-weight: 500;
  padding-bottom: 20px;
  border-bottom: 3px solid #000000;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  section.blog-content .intro {
    padding-bottom: 40px;
    margin-bottom: 40px;
  }
}

section.blog-content .intro,
section.blog-content .content {
  line-height: 1.55556em;
  font-size: 17px;
}

@media (min-width: 768px) {
  section.blog-content .intro,
  section.blog-content .content {
    font-size: 18px;
  }
}

section.blog-content .desc {
  margin-bottom: 30px;
}

section.blog-content .desc p {
  margin-bottom: 20px;
  font-size: 18px;
}

section.blog-content .content p,
section.blog-content .content blockquote {
  font-size: 16px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  section.blog-content .content p,
  section.blog-content .content blockquote {
    margin-bottom: 40px;
  }
}

section.blog-content .content ul {
  margin-bottom: 1rem;
  font-size: 16px;
}

section.blog-content .content ul li:before {
  content: "•";
  color: #62bd89;
  padding-right: 10px;
}

section.blog-content .content h2,
section.blog-content .content h3,
section.blog-content .content h4,
section.blog-content .content h5,
section.blog-content .content h6 {
  margin-bottom: 10px;
  letter-spacing: -0.521739px;
}

section.blog-content .content h2 {
  font-size: 24px;
  line-height: 34px;
}

section.blog-content .content h3 {
  font-size: 20px;
  line-height: 29px;
}

section.blog-content .content h4 {
  font-size: 16px;
  line-height: 24px;
}

section.blog-content .content h5 {
  font-size: 12px;
  line-height: 19px;
}

section.blog-content .content h6 {
  font-size: 10px;
  line-height: 17px;
  text-transform: uppercase;
}

section.blog-content .content p:after {
  content: '';
  clear: both;
  display: block;
}

section.blog-content .content img {
  width: 100%;
  height: auto;
}

section.blog-content .content blockquote {
  font-weight: 600;
  line-height: 1.66667em;
  padding: 45px 20px;
  position: relative;
}

@media (min-width: 768px) {
  section.blog-content .content blockquote {
    padding: 45px 120px;
  }
}

section.blog-content .content blockquote:before, section.blog-content .content blockquote:after {
  content: "";
  position: absolute;
  width: 48px;
  height: 48px;
  background: transparent url("../img/icon-quote.svg") no-repeat top left/100% auto;
}

@media (min-width: 768px) {
  section.blog-content .content blockquote:before, section.blog-content .content blockquote:after {
    width: 88px;
    height: 88px;
  }
}

section.blog-content .content blockquote:before {
  top: 0;
  left: 0;
}

section.blog-content .content blockquote:after {
  bottom: 0;
  right: 0;
  transform: rotateZ(180deg);
}

@media (min-width: 992px) {
  section.blog-content .wrap-sidebar-blog-single {
    padding-top: 20px;
    padding-bottom: 26px;
  }
}

@media print {
  section.blog-content .wrap-sidebar-blog-single {
    position: relative !important;
    width: 100% !important;
  }
}

section.blog-content .next-post .sub-cate {
  margin-bottom: 20px;
}

section.blog-content .next-post .item-posts {
  max-width: 320px;
}

section.blog-content .next-post.margin-top {
  margin-top: 50px;
}

@media (min-width: 992px) {
  section.blog-content .next-post.prev-post {
    padding-bottom: 65px;
  }
}

@media (min-width: 1200px) {
  section.blog-content .row {
    margin-left: -50px;
    margin-right: -50px;
  }
  section.blog-content .row > .col,
  section.blog-content .row > [class*="col"] {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media print {
  section.blog-content .row {
    display: block !important;
  }
}

@media print {
  section.blog-content .col-lg-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media print {
  section.blog-content .col-lg-4 {
    flex: 0 0 100%;
    max-width: 100%;
    page-break-before: always;
    display: none;
  }
}

.group-share-social {
  margin-bottom: 50px;
  display: flex;
  align-items: center;
}

@media print {
  .group-share-social {
    display: none;
  }
}

.group-share-social h5 {
  font-size: 18px;
  padding-right: 20px;
  margin-bottom: 0;
}

.group-share-social .jssocials-share-link {
  background-color: #f4f2ed;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.group-share-social .fa.fa-facebook {
  background: url(../img/icon-fb.svg) no-repeat center/auto 100%;
  left: 0;
  top: 2px;
  width: 20px;
  height: 20px;
  transition: all 0.5s ease;
  display: inline-block;
  margin-right: 5px;
}

.group-share-social .fa.fa-facebook:before {
  content: '';
}

@media print {
  .btn-print-pdf {
    display: none;
  }
}

.blog-content-archive .container {
  padding-bottom: 35px;
  position: relative;
}

.blog-content-archive .container:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 15px;
  width: calc(100% - 30px);
  height: 2px;
  background: #000000;
  z-index: 1;
}

@media (min-width: 992px) {
  .blog-content-archive .container {
    padding-bottom: 70px;
  }
}

.blog-content-archive .row {
  margin-left: -8px;
  margin-right: -8px;
}

.blog-content-archive .row > .col,
.blog-content-archive .row > [class*="col"] {
  padding-left: 8px;
  padding-right: 8px;
}

.blog-content-archive .row [class*="col"] {
  margin-bottom: 16px;
}

@media (min-width: 992px) {
  .blog-content-archive .row [class*="col"]:nth-child(3) {
    order: 4;
  }
}

@media (min-width: 992px) {
  .blog-content-archive .row [class*="col"]:nth-child(4) {
    order: 3;
  }
}

.blog-content-archive .img-drop {
  width: 100%;
}

.blog-content-archive .img-drop.width-big {
  padding-top: 61.71171%;
}

@media (min-width: 992px) {
  .blog-content-archive .img-drop.width-big {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }
}

.blog-content-archive .img-drop.width-small {
  padding-top: 104.3038%;
  min-width: 100%;
}

.blog-content-archive .item-inner {
  position: relative;
  display: flex;
  height: 100%;
  overflow: hidden;
}

@media (min-width: 768px) and (max-width: 991px) {
  .blog-content-archive .item-inner {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}

.blog-content-archive .item-inner:hover img,
.blog-content-archive .item-inner:hover .img-drop {
  transform: scale(1.02);
}

.blog-content-archive .item-inner img,
.blog-content-archive .item-inner .img-drop {
  transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.blog-content-archive .item-inner:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 60%, #000000 100%);
  opacity: 0.4;
  z-index: 1;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.blog-content-archive .item-inner .wrap-text {
  position: absolute;
  width: calc(100% - 40px);
  bottom: 25px;
  left: 20px;
  right: 20px;
  top: auto;
  color: #FFF;
  z-index: 2;
  font-family: "Circular Std";
}

@media (min-width: 992px) {
  .blog-content-archive .item-inner .wrap-text {
    left: 40px;
    width: calc(100% - 80px);
    right: 40px;
    bottom: 35px;
  }
}

@media (min-width: 1600px) {
  .blog-content-archive .item-inner .wrap-text {
    left: 2vw;
    right: 2vw;
    bottom: 1.75vw;
  }
}

.blog-content-archive .item-inner .wrap-text span {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
  display: inline-block;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .blog-content-archive .item-inner .wrap-text span {
    font-size: 18px;
    margin-bottom: 15px;
  }
}

@media (min-width: 1600px) {
  .blog-content-archive .item-inner .wrap-text span {
    font-size: 0.9vw;
    margin-bottom: 0.75vw;
  }
}

.blog-content-archive .item-inner .wrap-text h3 {
  font-size: 20px;
  font-weight: 700;
}

@media (min-width: 992px) {
  .blog-content-archive .item-inner .wrap-text h3 {
    font-size: 28px;
  }
}

@media (min-width: 1600px) {
  .blog-content-archive .item-inner .wrap-text h3 {
    font-size: 1.4vw;
  }
}

.blog-load-more {
  padding-top: 35px;
}

@media (min-width: 992px) {
  .blog-load-more {
    padding-top: 70px;
  }
}

.blog-load-more .row {
  margin-left: -8px;
  margin-right: -8px;
}

.blog-load-more .row > .col,
.blog-load-more .row > [class*="col"] {
  padding-left: 8px;
  padding-right: 8px;
}

.blog-load-more .row [class*="col"] {
  margin-bottom: 16px;
}

.blog-load-more #btn-more {
  text-align: center;
  display: block;
}

.blog-load-more #btn-more img {
  width: 40px;
}

.blog-load-more #btn-more span {
  display: block;
  text-transform: uppercase;
  font-family: "Circular Std";
  letter-spacing: 2px;
}

.item-posts {
  display: block;
  position: relative;
  overflow: hidden;
}

.item-posts .img-drop {
  padding-top: 139.92248%;
}

@media (max-width: 575px) {
  .item-posts .img-drop {
    padding-top: 80%;
  }
}

.item-posts img {
  transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.item-posts:hover img {
  transform: scale(1.02);
}

.item-posts:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 60%, #000000 100%);
  opacity: 0.4;
  z-index: 1;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.item-posts .wrap-post {
  position: absolute;
  width: calc(100% - 40px);
  bottom: 25px;
  left: 20px;
  right: 20px;
  top: auto;
  color: #FFF;
  z-index: 2;
  font-family: "Circular Std";
  font-weight: 700;
  font-size: 18px;
}

@media (min-width: 1600px) {
  .item-posts .wrap-post {
    bottom: 1.25vw;
    left: 1vw;
    right: 1vw;
    font-size: 0.9vw;
  }
}

.gallery:after {
  content: "";
  display: table;
  clear: both;
}

img.aligncenter,
div.aligncenter,
figure.aligncenter,
img.wp-post-image {
  display: block;
  margin: 1em auto;
}

img.alignright,
div.alignright,
figure.alignright {
  float: right;
  margin: 1em 0 1em 2em;
}

img.alignleft,
div.alignleft,
figure.alignleft,
img.wp-post-image.attachment-thumb {
  float: left;
  margin: 1em 1em 2em 0;
}

figure {
  max-width: 100%;
  height: auto;
  margin: 1em 0;
}

p img.alignright,
p img.alignleft {
  margin-top: 0;
}

.gallery figure img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
}

.gallery figure {
  float: left;
  margin: 0 2% 1em 0;
}

.gallery.gallery-columns-1 figure {
  width: 100%;
  margin: 0 0 1em 0;
  float: none;
}

.gallery.gallery-columns-3 figure {
  width: 32%;
}

.gallery.gallery-columns-3 figure:nth-of-type(3n+3) {
  margin-right: 0;
}

.gallery.gallery-columns-3 figure:nth-of-type(3n+4) {
  clear: left;
}

.gallery.gallery-columns-2 figure {
  width: 49%;
}

.gallery.gallery-columns-2 figure:nth-of-type(even) {
  margin-right: 0;
}

.gallery.gallery-columns-2 figure:nth-of-type(odd) {
  clear: left;
}

.gallery.gallery-columns-4 figure {
  width: 23.25%;
}

.gallery.gallery-columns-4 figure:nth-of-type(4n+4) {
  margin-right: 0;
}

.gallery.gallery-columns-4 figure:nth-of-type(4n+5) {
  clear: left;
}

.gallery.gallery-columns-5 figure {
  width: 18%;
}

.gallery.gallery-columns-5 figure:nth-of-type(5n+5) {
  margin-right: 0;
}

.gallery.gallery-columns-5 figure:nth-of-type(5n+6) {
  clear: left;
}

.gallery.gallery-columns-6 figure {
  width: 14.2%;
}

.gallery.gallery-columns-6 figure:nth-of-type(6n+6) {
  margin-right: 0;
}

.gallery.gallery-columns-6 figure:nth-of-type(6n+7) {
  clear: left;
}

.gallery.gallery-columns-7 figure {
  width: 12%;
}

.gallery.gallery-columns-7 figure:nth-of-type(7n+7) {
  margin-right: 0;
}

.gallery.gallery-columns-7 figure:nth-of-type(7n+8) {
  clear: left;
}

.gallery.gallery-columns-8 figure {
  width: 10.2%;
}

.gallery.gallery-columns-8 figure:nth-of-type(8n+8) {
  margin-right: 0;
}

.gallery.gallery-columns-8 figure:nth-of-type(8n+9) {
  clear: left;
}

.gallery.gallery-columns-9 figure {
  width: 8.85%;
}

.gallery.gallery-columns-9 figure:nth-of-type(9n+9) {
  margin-right: 0;
}

.gallery.gallery-columns-9 figure:nth-of-type(9n+10) {
  clear: left;
}

@media (max-width: 767px) {
  img.alignright,
  div.alignright,
  figure.alignright,
  img.alignleft,
  div.alignleft,
  figure.alignleft,
  img.wp-post-image.attachment-thumb {
    display: block;
    margin: 1em auto;
    float: none;
  }
  .gallery.gallery-columns-4 figure,
  .gallery.gallery-columns-5 figure,
  .gallery.gallery-columns-6 figure,
  .gallery.gallery-columns-7 figure,
  .gallery.gallery-columns-8 figure,
  .gallery.gallery-columns-9 figure {
    width: 100%;
    margin: 0 0 1em 0;
    float: none;
  }
}

section.recipes-content-archive .featured-recipe {
  margin-left: 0;
  margin-right: 0;
  border-bottom: 2px solid #000000;
  margin-bottom: 40px;
  padding-bottom: 40px;
}

section.recipes-content-archive .featured-recipe > .col,
section.recipes-content-archive .featured-recipe > [class*="col"] {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 992px) {
  section.recipes-content-archive .featured-recipe {
    margin-bottom: 70px;
    padding-bottom: 70px;
  }
}

section.recipes-content-archive .featured-recipe .col-md-5 {
  overflow: hidden;
}

section.recipes-content-archive .featured-recipe .img-drop {
  display: block;
}

@media (min-width: 768px) {
  section.recipes-content-archive .featured-recipe .img-drop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 0;
  }
}

@media (max-width: 767px) {
  section.recipes-content-archive .featured-recipe .img-drop img {
    position: relative;
  }
}

section.recipes-content-archive .featured-recipe .d-flex {
  height: 100%;
  background: #fff;
}

section.recipes-content-archive .featured-recipe .wrap-text {
  padding: 25px 25px;
}

@media (min-width: 768px) and (max-width: 991px) {
  section.recipes-content-archive .featured-recipe .wrap-text {
    padding: 45px 40px;
  }
}

@media (min-width: 992px) {
  section.recipes-content-archive .featured-recipe .wrap-text {
    padding: 75px 70px;
  }
}

section.recipes-content-archive .featured-recipe .product-tag-list {
  margin-top: 30px;
}

section.recipes-content-archive .featured-recipe .product-tag-list li {
  font-weight: 700;
}

section.recipes-content-archive .list-recipe-archive {
  margin-left: -7px;
  margin-right: -7px;
}

section.recipes-content-archive .list-recipe-archive > .col,
section.recipes-content-archive .list-recipe-archive > [class*="col"] {
  padding-left: 7px;
  padding-right: 7px;
}

section.recipes-content-archive .list-recipe-archive .item-post {
  margin-bottom: 14px;
}

section.recipes-content-archive .list-recipe-archive h3 {
  margin-bottom: 0;
}

section.recipes-content-archive .list-recipe-archive .time-info {
  margin-left: 0;
  padding-top: 5px;
}

.label-servers {
  padding: 5px 12px;
  background: rgba(255, 255, 255, 0.75);
  border-radius: 6px;
  display: inline-block;
  position: absolute;
  top: 7px;
  right: 7px;
}

.label-servers .icon.person {
  vertical-align: middle;
}

.label-servers sub {
  font-size: 12px;
  margin-left: 2px;
  font-weight: 500;
}

.label-servers span {
  font-size: 12px;
  margin-left: 2px;
  font-weight: 500;
  position: relative;
  top: 1px;
}

.label-lock {
  padding: 5px 12px;
  border-radius: 6px;
  bottom: 7px;
  right: 7px;
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  background: rgba(255, 255, 255, 0.75) url(../img/icon-locker.svg) center center/auto 26px no-repeat;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}

.loading-more-row {
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 70px;
}

.loading-more-row i {
  margin-bottom: 20px;
  width: 24px;
  height: 24px;
}

.loading-more-row span {
  display: block;
}

.gallery-sidebar h6 {
  margin-bottom: 20px;
  font: 700 16px/1.25em "Circular Std";
  letter-spacing: 2px;
  text-transform: uppercase;
}

@media print {
  .gallery-sidebar h6 {
    font: 700 36px/1.2em "Circular Std" !important;
    letter-spacing: -.52px;
    color: #000;
    margin-bottom: .5em;
    text-transform: capitalize;
  }
}

.gallery-thumb-list {
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  margin: 0 -5px;
}

.gallery-thumb-list > a {
  width: calc(50% - 10px);
  margin: 0 5px 10px;
  position: relative;
}

@media print {
  .gallery-thumb-list > a {
    width: calc(33.333% - 10px);
  }
}

.gallery-thumb-list > a:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
  transition: all .3s ease;
  opacity: 0;
}

.gallery-thumb-list > a:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  transform: translate3d(-50%, -50%, 0);
  background: transparent url(../img/search-solid.svg) center/auto 100% no-repeat;
  z-index: 1;
  transition: all .3s .05s ease;
  opacity: 0;
}

.gallery-thumb-list > a:hover:before {
  opacity: 1;
}

.gallery-thumb-list > a:hover:after {
  opacity: 1;
}

.lg-sub-html {
  display: none;
}

.gray-tag-list {
  list-style-type: none;
  margin-bottom: 50px;
}

@media (max-width: 991px) {
  .gray-tag-list {
    margin-bottom: 35px;
  }
}

@media print {
  .gray-tag-list {
    margin-bottom: 0 !important;
  }
}

.gray-tag-list > li {
  padding: 10px 20px;
  border: 1px solid #ddd;
  background: #e8e6de;
  border-radius: 6px;
  display: inline-block;
  vertical-align: top;
  margin-right: 6px;
  margin-bottom: 6px;
}

@media (max-width: 575px) {
  .gray-tag-list > li {
    padding: 6px 10px;
    font-size: 12px;
  }
}

.gray-tag-list > li span {
  font-family: "Circular Std";
  font-weight: 500;
  letter-spacing: -.17px;
}

.recipes-tab {
  display: flex;
  padding-bottom: 10px;
  border-bottom: 2px solid #e8e6de;
  margin-bottom: 40px;
}

@media (max-width: 575px) {
  .recipes-tab {
    margin-bottom: 25px;
  }
}

@media print {
  .recipes-tab {
    display: none;
  }
}

.recipes-tab > li {
  margin: 0 15px;
}

.recipes-tab > li:first-child {
  margin-left: 0;
}

.recipes-tab > li .nav-link {
  padding: 0;
  font: 700 18px/1.25em "Circular Std";
  color: #000;
  position: relative;
  letter-spacing: 2.25px;
  text-transform: uppercase;
}

@media (max-width: 1199px) {
  .recipes-tab > li .nav-link {
    font-size: 16px;
  }
}

@media (max-width: 350px) {
  .recipes-tab > li .nav-link {
    font-size: 14px;
  }
}

.recipes-tab > li .nav-link:before {
  content: '';
  position: absolute;
  bottom: -11px;
  height: 2px;
  width: 100%;
  left: 0;
  background: #62bd89;
  transform: translate3d(0, 50%, 0);
  opacity: 0;
  transition: all .3s ease;
}

.recipes-tab > li .nav-link.active {
  color: #62bd89;
}

.recipes-tab > li .nav-link.active:before {
  opacity: 1;
}

.recipes-info {
  font-size: 18px;
  line-height: 2em;
  height: auto !important;
}

@media (max-width: 991px) {
  .recipes-info {
    margin-bottom: 30px;
    font-size: 16px;
  }
}

@media print {
  .recipes-info {
    height: auto !important;
    margin-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .recipes-info .tab-pane {
    display: block !important;
    opacity: 1 !important;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.recipes-info p {
  margin-bottom: 1em;
}

@media print {
  .recipes-info p {
    margin-bottom: 0.5em;
  }
}

.recipes-info h3,
.recipes-info h4,
.recipes-info h5,
.recipes-info h6 {
  font: 700 32px/1.25em "Circular Std";
  margin-bottom: 35px;
}

@media (max-width: 991px) {
  .recipes-info h3,
  .recipes-info h4,
  .recipes-info h5,
  .recipes-info h6 {
    font-size: 28px;
    margin-bottom: 25px;
  }
}

@media print {
  .recipes-info h3,
  .recipes-info h4,
  .recipes-info h5,
  .recipes-info h6 {
    padding-top: 20px;
  }
}

.recipes-info ul, .recipes-info ol {
  padding: revert;
  list-style: revert;
}

section.education .des-intro {
  margin-bottom: 30px;
}

section.education .des-page img {
  max-width: 100%;
  width: 100%;
  margin-bottom: 10px;
}

section.education .learn-text-editor {
  padding-top: 45px;
}

section.education .tab-content {
  padding-top: 70px;
}

section.education .content-learn p {
  margin-bottom: 1em;
}

section.education .content-learn ul {
  list-style: initial;
  padding-left: 20px;
}

section.education .content-learn img {
  height: auto;
  width: 100%;
}

.tab-navi-learn {
  justify-content: center;
  padding: 0 15px;
  border-bottom: 2px solid #e8e6de;
  padding-top: 45px;
}

.tab-navi-learn li:not(:last-child) {
  margin-right: 25px;
}

@media (min-width: 1600px) {
  .tab-navi-learn li:not(:last-child) {
    margin-right: 1.25vw;
  }
}

.tab-navi-learn li.active a {
  color: #62bd89;
}

.tab-navi-learn li.active a:after {
  width: 100%;
  opacity: 1;
}

.tab-navi-learn a {
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 2.25px;
  text-align: center;
  font-weight: 600;
  position: relative;
  padding: 10px 0;
  display: block;
}

@media (min-width: 1600px) {
  .tab-navi-learn a {
    font-size: 0.9vw;
    padding: 0.5vw 0;
  }
}

.tab-navi-learn a:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  background: #62bd89;
  height: 2px;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.tab-navi-learn a:hover {
  color: gray;
}

.tab-navi-learn a.active {
  color: #62bd89;
}

.tab-navi-learn a.active:after {
  width: 100%;
  opacity: 1;
}

.ingredients-select {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  padding: 12px 40px 12px 15px;
  background: transparent url(../img/icon-triangle-down.svg) no-repeat center right 18px/10px auto;
  border: 1px solid #D8D8D8;
  letter-spacing: 0.44px;
  float: right;
  font-weight: 500;
  color: #000000;
  letter-spacing: 2px;
  margin-bottom: 30px;
}

.ingredient-item {
  display: flex;
}

.ingredient-item:not(:last-child) {
  border-bottom: 2px solid #e8e6de;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

@media (min-width: 992px) {
  .ingredient-item:not(:last-child) {
    margin-bottom: 40px;
    padding-bottom: 40px;
  }
}

.ingredient-item .ingredient-img {
  flex: 0 0 80px;
  margin-right: 20px;
}

@media (min-width: 992px) {
  .ingredient-item .ingredient-img {
    flex: 0 0 138px;
    margin-right: 50px;
  }
}

.ingredient-item .ingredient-img .img-drop {
  padding-top: 100%;
  border-radius: 50%;
  overflow: hidden;
  background: gray;
}

.ingredient-item .ingredient-img .img-drop img {
  border-radius: 50%;
}

.ingredient-item .ingredient-des {
  width: 100%;
}

@media (min-width: 576px) {
  .account .tab-navi-learn {
    padding-top: 40px;
  }
}

.account .tab-content {
  padding-top: 40px;
}

@media (min-width: 576px) {
  .account .tab-content {
    padding-top: 80px;
  }
}

@media (max-width: 767px) {
  .account .title-page br {
    display: none;
  }
}

.woocommerce-message,
.woocommerce-info,
.woocommerce-error,
.woocommerce-noreviews,
p.no-comments {
  text-align: center;
  display: block !important;
  background-color: transparent !important;
  margin-bottom: 20px;
  font-weight: 700;
}

.woocommerce-error {
  color: #e2401c;
}

.woocommerce-message,
.woocommerce-info,
.woocommerce-noreviews,
p.no-comments {
  color: #0f834d;
}

.custom-form {
  /* STYLE 1 */
  /* STYLE 2 */
}

.custom-form h3 {
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 15px;
  font-size: 26px;
  display: block;
  width: 100%;
}

@media (min-width: 768px) {
  .custom-form h3 {
    font-size: 36px;
    margin-bottom: 30px;
  }
}

.custom-form form,
.custom-form .form {
  display: flex;
  flex-wrap: wrap;
  margin-right: -9px;
  margin-left: -9px;
}

.custom-form .has-error {
  border-color: red !important;
}

.custom-form .error {
  color: red;
  display: block;
}

.custom-form input:not([type="submit"]),
.custom-form select,
.custom-form textarea {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  background-color: transparent;
  border: 1px solid #000;
  padding: 10px 15px;
  width: 100%;
  height: 50px;
  transition: all 0.5s ease;
}

.custom-form input:not([type="submit"]):-webkit-autofill, .custom-form input:not([type="submit"]):-webkit-autofill:hover, .custom-form input:not([type="submit"]):-webkit-autofill:focus, .custom-form input:not([type="submit"]):-webkit-autofill:active,
.custom-form select:-webkit-autofill,
.custom-form select:-webkit-autofill:hover,
.custom-form select:-webkit-autofill:focus,
.custom-form select:-webkit-autofill:active,
.custom-form textarea:-webkit-autofill,
.custom-form textarea:-webkit-autofill:hover,
.custom-form textarea:-webkit-autofill:focus,
.custom-form textarea:-webkit-autofill:active {
  transition: background 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000;
}

@media (min-width: 576px) {
  .custom-form input:not([type="submit"]),
  .custom-form select,
  .custom-form textarea {
    padding: 15px;
    height: 60px;
  }
}

.custom-form input:not([type="submit"])[disabled],
.custom-form select[disabled],
.custom-form textarea[disabled] {
  background: #E3E3E3;
  border: 1px solid #B7B7B7;
}

.custom-form textarea {
  height: 150px;
  resize: none;
}

.cart_totals .big-label,
.big-label {
  color: #000;
  font-family: "Circular Std";
  font-size: 18px;
  font-weight: 700;
  padding-left: 9px;
  padding-right: 9px;
  width: 100%;
}

.custom-form fieldset,
.custom-form .fieldset {
  float: none !important;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
}

.custom-form fieldset legend,
.custom-form .fieldset legend {
  display: none;
}

.custom-form .form-row {
  float: none !important;
  padding-left: 9px;
  padding-right: 9px;
  margin-bottom: 15px;
  width: 100%;
}

@media (min-width: 576px) {
  .custom-form .form-row {
    margin-bottom: 30px;
  }
}

.custom-form .form-row label {
  font: 700 16px/normal "Circular Std";
}

@media (min-width: 768px) {
  .custom-form .form-row label {
    font-size: 18px;
    margin-bottom: 15px;
  }
}

.custom-form .form-row label abbr.required {
  color: #000;
  text-decoration: none;
  cursor: default;
}

.custom-form .form-row.form-row-wide {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 576px) {
  .custom-form .form-row.form-row-first, .custom-form .form-row.form-row-last {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 576px) {
  .custom-form .form-row.form-row-one {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
}

@media (min-width: 576px) {
  .custom-form .form-row.form-row-two, .custom-form .form-row.form-row-three {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
}

.custom-form .form-row.mailchimp-newsletter input {
  display: none;
}

.custom-form .form-row.mailchimp-newsletter input:checked + label:after {
  opacity: 1;
  visibility: visible;
}

.custom-form .form-row.mailchimp-newsletter label {
  position: relative;
}

.custom-form .form-row.mailchimp-newsletter label:before {
  content: '';
  position: absolute;
  top: 0;
  right: -15px;
  width: 25px;
  height: 25px;
  border: 1px solid #000;
  transform: translateX(100%);
}

.custom-form .form-row.mailchimp-newsletter label:after {
  content: '';
  position: absolute;
  top: 5px;
  right: -20px;
  background: #000;
  width: 15px;
  height: 15px;
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease;
}

.custom-form .active-show-shipping-fieldset {
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 9px;
  padding-right: 9px;
}

@media (min-width: 992px) {
  .custom-form .active-show-shipping-fieldset {
    margin-bottom: 50px;
    margin-top: 30px;
  }
}

@media (max-width: 991px) {
  .custom-form .active-show-shipping-fieldset {
    margin-bottom: 40px;
    margin-top: 20px;
  }
}

.custom-form .active-show-shipping-fieldset label {
  font-weight: 700;
  color: #000;
  padding-left: 40px;
  position: relative;
  cursor: pointer;
  font-size: 18px;
  font-family: "Circular Std";
}

.custom-form .active-show-shipping-fieldset label:before, .custom-form .active-show-shipping-fieldset label:after {
  content: "";
  position: absolute;
  top: -4px;
  left: 0;
  width: 28px;
  height: 28px;
  border: 1px solid #000;
  transition: all 0.4s ease;
}

.custom-form .active-show-shipping-fieldset label:before {
  background-color: #000;
  top: 0;
  left: 4px;
  width: 20px;
  height: 20px;
  opacity: 0;
  transition: all 0.5s ease;
}

.custom-form .active-show-shipping-fieldset input {
  display: none;
}

.custom-form .active-show-shipping-fieldset input:checked + label:before {
  opacity: 1;
}

.cart_totals .radio-style-inline,
.radio-style-inline {
  flex: 0 0 100%;
  max-width: 100%;
}

.cart_totals .radio-style-inline input,
.radio-style-inline input {
  display: none;
}

.cart_totals .radio-style-inline .form-row,
.radio-style-inline .form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
  margin-top: 20px;
  justify-content: space-between;
}

.cart_totals .radio-style-inline label,
.radio-style-inline label {
  background: #fff;
  padding: 30px 20px;
  border: 1px solid transparent;
  cursor: pointer !important;
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 15px;
  transition: all .3s ease;
  font-size: 20px !important;
  font-family: "Circular Std";
  font-weight: 700;
}

@media (min-width: 768px) {
  .cart_totals .radio-style-inline label,
  .radio-style-inline label {
    flex: 0 0 49%;
    max-width: 49%;
    margin-bottom: 0;
    padding: 50px 35px;
    font-size: 22px !important;
  }
}

.cart_totals .radio-style-inline label span:nth-child(1),
.radio-style-inline label span:nth-child(1) {
  float: right;
  font-weight: 500;
}

.cart_totals .radio-style-inline label span:nth-child(2),
.radio-style-inline label span:nth-child(2) {
  display: block;
  font-family: "Circular Std";
  font-size: 14px;
  letter-spacing: 0.47px;
  padding-top: 10px;
  line-height: 1.5em;
  font-weight: 400;
}

.cart_totals .radio-style-inline input:checked + label,
.cart_totals .radio-style-inline input[type="hidden"] + label,
.radio-style-inline input:checked + label,
.radio-style-inline input[type="hidden"] + label {
  background: #fff;
  border-color: #000;
}

.custom-form .radio-style-block {
  flex: 0 0 100%;
  max-width: 100%;
}

.custom-form .radio-style-block input {
  display: none;
}

.custom-form .radio-style-block .form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
  margin-top: 20px;
  justify-content: space-between;
}

.custom-form .radio-style-block label {
  flex: 0 0 100%;
  max-width: 100%;
  background: #fff;
  font-size: 20px !important;
  border: 1px solid transparent;
  cursor: pointer !important;
  margin-bottom: 15px;
  padding: 30px 20px;
  transition: all .3s ease;
}

@media (min-width: 768px) {
  .custom-form .radio-style-block label {
    padding: 50px 35px;
    font-size: 22px !important;
  }
}

.custom-form .radio-style-block label span:nth-child(1) {
  font-family: "Circular Std";
  font-size: 14px;
  letter-spacing: 0.47px !important;
  padding-top: 10px;
  line-height: 1.5em;
  font-weight: 400;
  display: none;
}

.custom-form .radio-style-block input:checked + label {
  background: #fff;
  border-color: #000;
}

.custom-form .radio-style-block input:checked + label span {
  display: block;
}

/* Select 2 */
.select2 {
  width: 100% !important;
}

.select2-border-container,
.woocommerce-page .select2-container {
  height: auto !important;
  border: 0 !important;
  outline: 0;
  box-shadow: 0;
  border-radius: 0 !important;
  background: transparent !important;
}

.select2-border-container .select2-selection__rendered,
.woocommerce-page .select2-container .select2-selection__rendered {
  background: transparent;
  border: 1px solid #000;
  width: 100%;
  font-family: "Circular Std";
  height: 50px;
  color: #000;
  padding: 10px 15px !important;
}

@media (min-width: 576px) {
  .select2-border-container .select2-selection__rendered,
  .woocommerce-page .select2-container .select2-selection__rendered {
    padding: 15px !important;
    height: 60px;
  }
}

.select2-border-container .select2-selection--single,
.woocommerce-page .select2-container .select2-selection--single {
  border: 0;
  background-color: transparent;
  height: 60px;
  outline: 0;
}

.select2-border-container .select2-selection__arrow,
.woocommerce-page .select2-container .select2-selection__arrow {
  background: transparent url("../img/icon-back.svg") no-repeat center/8px auto;
  height: 100% !important;
  width: 10px !important;
  right: 22px !important;
  top: 3px !important;
  transition: all 0.3s ease;
  transform: rotate(-90deg);
}

.select2-border-container .select2-selection__arrow b,
.woocommerce-page .select2-container .select2-selection__arrow b {
  display: none;
}

.select2-border-container[aria-expanded="true"] .select2-selection__arrow,
.woocommerce-page .select2-container[aria-expanded="true"] .select2-selection__arrow {
  transform: rotate(90deg) !important;
}

.select2-border-container .select2-container .select2-selection--single,
.woocommerce-page .select2-container .select2-container .select2-selection--single {
  height: 50px;
}

@media (min-width: 576px) {
  .select2-border-container .select2-container .select2-selection--single,
  .woocommerce-page .select2-container .select2-container .select2-selection--single {
    height: 60px;
  }
}

.select2-border-container.custom-testimonials-container .select2-selection__rendered,
.woocommerce-page .select2-container.custom-testimonials-container .select2-selection__rendered {
  height: auto;
  padding: 10px 16px !important;
  color: #000 !important;
  background-color: #74c597 !important;
  font-size: 14px;
}

@media (min-width: 768px) {
  .select2-border-container.custom-testimonials-container .select2-selection__rendered,
  .woocommerce-page .select2-container.custom-testimonials-container .select2-selection__rendered {
    padding: 20px 25px !important;
    font-size: 16px;
  }
}

.select2-border-container.custom-testimonials-container .select2-selection__arrow,
.woocommerce-page .select2-container.custom-testimonials-container .select2-selection__arrow {
  background: transparent url("../img/arrow-triangle-right.svg") no-repeat center/8px auto;
  transform: rotate(90deg);
}

@media (max-width: 767px) {
  .select2-border-container.custom-testimonials-container .select2-selection__arrow,
  .woocommerce-page .select2-container.custom-testimonials-container .select2-selection__arrow {
    right: 15px !important;
    top: 1px !important;
  }
}

.select2-border-container.custom-testimonials-container[aria-expanded="true"] .select2-selection__arrow,
.woocommerce-page .select2-container.custom-testimonials-container[aria-expanded="true"] .select2-selection__arrow {
  transform: rotate(-90deg) !important;
}

.select2-border-dropdown,
.woocommerce-page .select2-dropdown {
  border: 1px solid #000;
  box-shadow: 1px 1px 5px rgba(128, 128, 128, 0.3);
}

.select2-border-dropdown li.select2-results__option,
.woocommerce-page .select2-dropdown li.select2-results__option {
  line-height: 1.3em !important;
  padding: 10px 15px !important;
  color: #000 !important;
  font-family: "Circular Std";
}

@media (min-width: 576px) {
  .select2-border-dropdown li.select2-results__option,
  .woocommerce-page .select2-dropdown li.select2-results__option {
    padding: 15px !important;
  }
}

.select2-border-dropdown li.select2-results__option:not(:last-child),
.woocommerce-page .select2-dropdown li.select2-results__option:not(:last-child) {
  border-bottom: 1px solid #000;
}

.select2-border-dropdown li.select2-results__option.select2-results__option--highlighted,
.woocommerce-page .select2-dropdown li.select2-results__option.select2-results__option--highlighted {
  background: #fafafa !important;
}

.select2-border-dropdown li.select2-results__option[aria-selected=true],
.woocommerce-page .select2-dropdown li.select2-results__option[aria-selected=true] {
  background: rgba(0, 0, 0, 0.3) !important;
  color: #fff !important;
}

.select2-border-dropdown input.select2-search__field,
.woocommerce-page .select2-dropdown input.select2-search__field {
  outline: 0 !important;
  box-shadow: 0 !important;
}

.list-order-history ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.list-order-history ul li {
  background: #fff;
  padding: 45px 35px;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 10px;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .list-order-history ul li {
    padding: 15px 0 15px 20px;
  }
}

.list-order-history ul li.no-orders {
  margin-bottom: 35px;
}

.list-order-history ul li > div {
  padding-right: 20px;
  margin-top: 10px;
}

.list-order-history ul li p {
  font: 400 20px/normal "Circular Std";
  margin-bottom: 2px;
  letter-spacing: 0.79px;
  color: #000;
}

@media (max-width: 575px) {
  .list-order-history ul li p {
    font-size: 16px;
  }
}

.list-order-history ul li span {
  font: 400 14px/2em "Circular Std";
  color: #000000;
  letter-spacing: 0.47px;
}

.list-order-history ul li .order-number {
  width: 270px;
}

@media (max-width: 767px) {
  .list-order-history ul li .order-number {
    width: 60%;
    order: 1;
  }
}

@media (max-width: 575px) {
  .list-order-history ul li .order-number {
    width: 100%;
  }
}

.list-order-history ul li .order-date {
  width: 155px;
  font: 400 20px/1.1em "Circular Std";
  letter-spacing: 0.67px;
  color: rgba(0, 0, 0, 0.25);
}

@media (max-width: 767px) {
  .list-order-history ul li .order-date {
    order: 3;
  }
}

@media (max-width: 575px) {
  .list-order-history ul li .order-date {
    font-size: 18px;
    width: 100%;
  }
}

.list-order-history ul li .order-price {
  text-align: right;
}

.list-order-history ul li .order-price .price {
  font: 700 20px/normal "Circular Std";
  letter-spacing: 0.67px;
}

@media (max-width: 767px) {
  .list-order-history ul li .order-price {
    order: 2;
  }
}

@media (max-width: 1050px) {
  .list-order-history ul li .order-price {
    text-align: left;
  }
}

.list-order-history ul li .view-detail-order {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .list-order-history ul li .view-detail-order {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .list-order-history ul li .view-detail-order {
    order: 4;
  }
}

.list-order-history ul li .view-detail-order a {
  font: 400 16px/normal "Circular Std";
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: right;
  color: #000;
}

@media (max-width: 767px) {
  .list-order-history ul li .view-detail-order a {
    font-size: 16px;
  }
}

.content-account .wrap-list {
  position: relative;
}

.content-account .wrap-list .list-order-history,
.content-account .wrap-list .list-order-detail {
  transition: all 0.5s ease;
}

.content-account .wrap-list .list-order-detail .btn-back-list {
  text-transform: uppercase;
  background: url(../img/icon-back.svg) no-repeat left center/8px auto;
  letter-spacing: 2px;
  padding-left: 20px;
}

.content-account .wrap-list .list-order-detail .order-number {
  margin-top: 30px;
  margin-bottom: 30px;
  font: 400 28px/normal "Circular Std";
}

@media (min-width: 768px) {
  .content-account .wrap-list .list-order-detail .order-number {
    font-size: 36px;
  }
}

.content-account .wrap-list .list-order-detail > .scrollbar-macosx {
  max-height: 430px;
  margin-right: -15px;
}

.content-account .wrap-list .list-order-detail .product-review {
  padding-right: 15px;
}

.content-account .wrap-list .list-order-detail .product-review li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px 0;
}

.content-account .wrap-list .list-order-detail .product-review li:not(:last-child) {
  border-bottom: 1px solid #E6E6E6;
}

.content-account .wrap-list .list-order-detail .product-review li .img-prod {
  flex: 0 0 20%;
}

.content-account .wrap-list .list-order-detail .product-review li .img-prod img {
  width: 100%;
}

.content-account .wrap-list .list-order-detail .product-review li .name-prod {
  flex: 0 0 40%;
  font-weight: 700;
}

.content-account .wrap-list .list-order-detail .product-review li .quality {
  flex: 0 0 20%;
  text-align: center;
}

.content-account .wrap-list .list-order-detail .product-review li .price {
  flex: 1 0 20%;
  text-align: right;
}

.content-account .wrap-list .list-order-detail .wrap-box {
  border-top: 2px solid #000000;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 20px;
}

.content-account .wrap-list .list-order-detail .wrap-box .preview-detail {
  width: 100%;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .content-account .wrap-list .list-order-detail .wrap-box .preview-detail {
    max-width: 250px;
    margin-bottom: 0;
  }
}

.content-account .wrap-list .list-order-detail .wrap-box .preview-detail .bg-gray {
  background-color: #E8E6DE;
  padding: 20px 15px;
}

@media (min-width: 992px) {
  .content-account .wrap-list .list-order-detail .wrap-box .preview-detail .bg-gray {
    padding: 40px 30px;
  }
}

.content-account .wrap-list .list-order-detail .wrap-box .preview-detail h3 {
  font-weight: 700;
  font-family: "Circular Std";
  font-size: 18px;
  margin-bottom: 5px;
  margin-top: 20px;
}

@media (min-width: 992px) {
  .content-account .wrap-list .list-order-detail .wrap-box .preview-detail h3 {
    font-size: 20px;
  }
}

.content-account .wrap-list .list-order-detail .wrap-box .preview-detail h3:first-child {
  margin-top: 0;
}

.content-account .wrap-list .list-order-detail .wrap-box .box-total {
  width: 100%;
}

@media (min-width: 992px) {
  .content-account .wrap-list .list-order-detail .wrap-box .box-total {
    max-width: 400px;
  }
}

.content-account .wrap-list .list-order-detail .wrap-box .box-total ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.content-account .wrap-list .list-order-detail .wrap-box .box-total ul li:not(:last-child) {
  padding-bottom: 20px;
}

.content-account .wrap-list .list-order-detail .wrap-box .box-total ul li .tax,
.content-account .wrap-list .list-order-detail .wrap-box .box-total ul li .price {
  font-weight: 400;
  font-size: 16px;
}

.content-account .wrap-list .list-order-detail .wrap-box .box-total ul li .tax {
  opacity: 0.25;
}

.content-account .wrap-list .list-order-detail .wrap-box .box-total ul li p {
  font-size: 18px;
  font-weight: 700;
  font-family: "Circular Std";
}

@media (min-width: 768px) {
  .content-account .wrap-list .list-order-detail .wrap-box .box-total ul li p {
    font-size: 20px;
  }
}

.content-account .wrap-list .list-order-detail .wrap-box .box-total .list-subtotal li:not(:last-child) {
  border-bottom: 1px solid #E6E6E6;
}

.content-account .wrap-list .list-order-detail .wrap-box .box-total .total {
  border-top: 2px solid #000;
  padding-top: 20px;
  margin-bottom: 30px;
}

.content-account .wrap-list .list-order-detail .wrap-box .box-total .total .price-total {
  font-size: 28px;
  letter-spacing: 1.2px;
  font-weight: 400;
  font-family: "Circular Std";
}

@media (min-width: 768px) {
  .content-account .wrap-list .list-order-detail .wrap-box .box-total .total .price-total {
    font-size: 36px;
  }
}

.wrap-content-account-password {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

#coupon-msg-ajax .woocommerce-message,
#coupon-msg-ajax .woocommerce-info,
#coupon-msg-ajax .woocommerce-error,
#coupon-msg-ajax .woocommerce-noreviews {
  text-align: left;
}

.ven-checkout {
  background-color: #f4f2ed;
  padding: 60px 0 0;
}

@media (min-width: 992px) {
  .ven-checkout {
    padding: 60px 60px 0;
  }
}

.ven-checkout__container {
  padding: 60px 0 110px;
}

.ven-checkout-block {
  background-color: #fff;
  padding: 15px;
  margin-bottom: 30px;
  font-size: 14px;
}

.ven-checkout-block.user-login .login-box .note {
  font-weight: 500;
  font-size: 9px;
  line-height: 140%;
  color: #33322B;
  margin: 0;
  margin-top: 10px;
}

.ven-checkout-heading {
  font-family: "Circular Std";
  font-size: 26px;
  line-height: 1.02;
  letter-spacing: 0.06em;
  margin-bottom: 20px;
}

.ven-checkout-title {
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 0.15em;
}

.ven-checkout-title:not(:first-child) {
  margin-top: 30px;
}

.ven-cart-product {
  display: flex;
}

.ven-cart-product__header {
  width: 80px;
  margin-right: 15px;
}

@media (min-width: 992px) {
  .ven-cart-product__header {
    margin-right: 25px;
  }
}

.ven-cart-product__body {
  flex: 1;
}

.ven-cart-product__image {
  padding-bottom: 100%;
}

.ven-cart-product__title {
  font-size: 16px;
  margin-bottom: 7px;
}

.ven-cart-product__variables {
  font-size: 12px;
  line-height: 1.02;
  margin-bottom: 14px;
}

.ven-cart-product__footer {
  text-align: right;
  margin-left: 15px;
}

.ven-cart-product__footer .ven-cart-product__price {
  margin-bottom: 7px;
}

.ven-cart-product__remove {
  padding-right: 50px;
}

.ven-cart-product__remove a {
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 102%;
  color: #949494;
}

@media (max-width: 767px) {
  .ven-cart-product__remove {
    padding: 0 10px;
  }
}

.ven-order-list {
  font-size: 14px;
}

.ven-order-list__item:not(:last-child) {
  margin-bottom: 30px;
}

.ven-order-list #update_cart {
  display: none;
}

.ven-edit-cart {
  font-size: 12px;
}

.ven-delivery-list__item:not(:last-child) {
  margin-bottom: 10px;
}

.ven-delivery-list__item > label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 60px;
  border: thin solid #cdcdcd;
  padding: 15px;
  margin-bottom: 0;
  cursor: pointer;
}

.ven-delivery-list__item > input {
  display: none;
}

.ven-delivery-list__item > input:checked + label {
  border-color: #000;
}

.ven-delivery-list__item > input:checked + label .ven-delivery-list__header::before {
  content: "";
  display: block;
  width: 11px;
  height: 9px;
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.9021L4.12245 7.0896L10 1.0896' stroke='%2333322B' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 4px;
  left: 0;
  transition: all 0.2s ease-out;
}

.ven-delivery-list__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding-left: 20px;
}

.ven-delivery-list__body {
  padding-left: 20px;
}

.ven-delivery-list__title {
  flex: 1;
  text-transform: uppercase;
  letter-spacing: 0.15em;
}

#shipping_country_field:not(.has-select),
#billing_country_field:not(.has-select) {
  opacity: 0.3;
  cursor: not-allowed;
}

#shipping_country_field:not(.has-select) .ven-form-label,
#billing_country_field:not(.has-select) .ven-form-label {
  position: static;
  font-size: 12px;
}

#shipping_country_field:not(.has-select) .woocommerce-input-wrapper strong,
#billing_country_field:not(.has-select) .woocommerce-input-wrapper strong {
  display: block;
  padding: 8px 0;
  font-size: 14px;
  font-weight: normal;
  border-bottom: thin solid #cdcdcd;
}

.ven-shipping-info {
  font-size: 12px;
  margin-top: 6px;
  margin-bottom: 24px;
  text-align: center;
}

.ven-checkout-delivery {
  margin-top: 30px;
}

.ven-payment-alert {
  background-color: rgba(119, 188, 31, 0.1);
  padding: 8px 15px;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.ven-payment-alert::before {
  content: "";
  width: 14px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.7723 7.59092C12.5601 7.37878 12.3026 7.27279 11.9995 7.27279H11.636V5.09085C11.636 3.69693 11.1362 2.50007 10.1361 1.50007C9.13617 0.500022 7.93942 0 6.54531 0C5.15123 0 3.95425 0.500022 2.95436 1.50003C1.95432 2.50007 1.45446 3.69689 1.45446 5.09085V7.27279H1.09087C0.787944 7.27279 0.530311 7.37878 0.318171 7.59092C0.10603 7.8029 0 8.06054 0 8.36374V14.9091C0 15.2121 0.10607 15.4698 0.318171 15.6819C0.530311 15.8939 0.787944 16 1.09087 16H11.9997C12.3029 16 12.5603 15.894 12.7726 15.6819C12.9845 15.4698 13.0907 15.2121 13.0907 14.9091V8.36362C13.0909 8.06066 12.9845 7.80306 12.7723 7.59092ZM9.45434 7.27279H3.63628V5.09085C3.63628 4.28786 3.92042 3.60221 4.48858 3.03412C5.05682 2.46596 5.74236 2.18194 6.54543 2.18194C7.34858 2.18194 8.03395 2.46592 8.60223 3.03412C9.17024 3.60217 9.45434 4.28786 9.45434 5.09085V7.27279Z' fill='%2377BC1F'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-right: 14px;
}

.ven-payment-alert__inner {
  flex: 1;
}

.ven-payment-block {
  margin-bottom: 32px;
}

.ven-payment__item:not(:last-child) {
  margin-bottom: 15px;
}

.ven-payment__item > label {
  display: block;
  border: thin solid #cdcdcd;
  overflow: hidden;
  margin-bottom: 0;
}

.ven-payment__item > input {
  display: none;
}

.ven-payment__item > input:checked + label {
  border-color: #000;
}

.ven-payment__item > input:checked + label .ven-payment__title {
  padding-left: 20px;
}

.ven-payment__item > input:checked + label .ven-payment__title::before {
  transform: scale(1);
}

.ven-payment__item > input:checked + label .ven-payment__body {
  display: block;
}

.ven-payment__item.payment_method_afterpay .ven-payment__brand img {
  display: none;
}

.ven-payment__item.payment_method_afterpay .ven-payment__brand::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 110px;
  height: 20px;
  background: url(../img/woocommerce/afterpay.svg) no-repeat;
  background-size: 100% 100%;
}

.ven-payment__item.payment_method_paypal .ven-payment__brand img,
.ven-payment__item.payment_method_paypal .ven-payment__brand .about_paypal, .ven-payment__item.payment_method_ppec_paypal .ven-payment__brand img,
.ven-payment__item.payment_method_ppec_paypal .ven-payment__brand .about_paypal, .ven-payment__item.payment_method_yith-paypal-ec .ven-payment__brand img,
.ven-payment__item.payment_method_yith-paypal-ec .ven-payment__brand .about_paypal {
  display: none;
}

.ven-payment__item.payment_method_paypal .ven-payment__brand::before, .ven-payment__item.payment_method_ppec_paypal .ven-payment__brand::before, .ven-payment__item.payment_method_yith-paypal-ec .ven-payment__brand::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 82px;
  height: 20px;
  background: url(../img/woocommerce/paypal.svg) no-repeat;
  background-size: 100% 100%;
}

.ven-payment__item.payment_method_paypal .ven-payment__body, .ven-payment__item.payment_method_ppec_paypal .ven-payment__body, .ven-payment__item.payment_method_yith-paypal-ec .ven-payment__body {
  display: none !important;
}

.ven-payment__header {
  display: flex;
  align-items: center;
  padding: 30px 15px;
}

.ven-payment__body {
  padding: 20px 15px;
  display: none;
}

.ven-payment__title {
  flex: 1;
  text-transform: uppercase;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.ven-payment__title::before {
  content: "";
  display: block;
  width: 11px;
  height: 9px;
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.9021L4.12245 7.0896L10 1.0896' stroke='%2333322B' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 4px;
  left: 0;
  transform: scale(0);
  transition: all 0.2s ease-out;
}

.ven-payment__brand {
  font-size: 0;
}

.ven-payment__brand .stripe-icon {
  height: 20px;
  margin-left: 1px;
}

#stripe-payment-data > p:first-child {
  display: none;
}

.wc-saved-payment-methods > li {
  margin-bottom: 15px;
}

.wc-saved-payment-methods > li label {
  display: block !important;
  width: 100%;
  margin-bottom: 0;
  padding: 15px;
  border: thin solid #cdcdcd;
  position: relative;
}

.wc-saved-payment-methods > li input {
  display: none;
}

.wc-saved-payment-methods > li input:checked + label {
  border-color: #000;
}

.wc-credit-card-form .clear {
  clear: both;
}

.wc-credit-card-form .form-row {
  margin-bottom: 20px;
}

.wc-credit-card-form .form-row label {
  font-size: 10px;
  margin-bottom: 0;
}

.wc-credit-card-form .form-row-first {
  float: left;
  width: calc(50% - 15px);
}

.wc-credit-card-form .form-row-last {
  float: right;
  width: calc(50% - 15px);
}

.wc-credit-card-form .wc-stripe-elements-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 36px;
  border-color: #cdcdcd;
  border-style: solid;
  border-width: 0 0 thin;
  margin: 0;
}

.wc-credit-card-form .wc-stripe-elements-field.focused {
  border-color: #9d9d9d;
}

.wc-credit-card-form .wc-stripe-elements-field.invalid {
  border-color: #eb6161;
}

.ven-coupon {
  position: relative;
}

.ven-coupon input {
  display: block;
  width: 100%;
  border-radius: 0;
  background-color: transparent;
  border-color: #000;
  border-style: solid;
  border-width: 0 0 thin;
  padding: 8px 32px 8px 0;
  outline: none;
}

@media (min-width: 992px) {
  .ven-coupon input {
    font-size: 14px;
  }
}

.ven-coupon button {
  width: 23px;
  height: 23px;
  background-color: transparent;
  border-radius: 0;
  border: none;
  padding: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='11.5' cy='11.5' r='11.5' fill='%23161616'/%3E%3Cpath d='M5 12H16.5' stroke='white' stroke-width='1.5'/%3E%3Cpath d='M13 8L17 12L13 16' stroke='white' stroke-width='1.5'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-size: 0;
  position: absolute;
  top: 6px;
  right: 0;
}

.ven-order-summary {
  font-size: 14px;
}

.ven-order-summary dl {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.ven-order-summary dt,
.ven-order-summary dd {
  font-weight: normal;
  margin: 0;
}

.ven-divider {
  border-bottom: thin solid #000;
  margin: 25px 0;
}

.ven-order-total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  font-size: 18px;
}

.ven-order-total dt,
.ven-order-total dd {
  font-weight: 500;
  margin: 0;
}

.ven-order-total dd {
  font-size: 20px;
}

.ven-order-info {
  padding: 15px 0;
  border-color: #000;
  border-style: solid;
  border-width: thin 0;
  font-size: 14px;
  line-height: 1.28571;
  margin-top: 15px;
  margin-bottom: 15px;
}

.ven-order-info strong {
  font-weight: 500;
  color: #62bd89;
}

.ven-checkout-subcribe {
  margin-top: 30px;
}

.ven-checkout-submit {
  margin-top: 20px;
}

.ven-checkout-submit button, .ven-checkout-submit a {
  background-color: #62bd89;
  border: none;
  border-radius: 0;
  padding: 8px 15px;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  width: 100%;
  cursor: pointer;
  display: block;
}

.ven-checkout-submit button:hover, .ven-checkout-submit a:hover {
  background-color: #74c597;
}

.ven-checkout-submit button.disabled, .ven-checkout-submit a.disabled {
  background: #ddd;
}

#kl_newsletter_checkbox_field {
  display: none;
}

.search-stockist {
  /* SCROLLBAR CUSTOM */
}

.search-stockist .wrap-content-stockist {
  position: relative;
  min-height: calc(100vh - 130px);
}

.search-stockist #f-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.search-stockist #f-map button[draggable="false"],
.search-stockist #f-map .gm-svpc,
.search-stockist #f-map .gm-style-mtc,
.search-stockist #f-map .gm-style-mtc,
.search-stockist #f-map .gm-style-cc {
  display: none;
}

.search-stockist.is-show .box-search {
  visibility: hidden;
  opacity: 0;
}

.search-stockist.is-show .box-result {
  visibility: visible;
  opacity: 1;
}

.search-stockist.is-show .box-result .wrap-ani {
  transform: translateY(0);
}

.search-stockist .box-search {
  overflow: hidden;
  position: absolute;
  top: 50px;
  left: 50px;
  max-width: 500px;
  width: 100%;
  transition: all 0.5s ease;
  background: #FFFFFF;
  box-shadow: 7px 11px 13px 0 rgba(0, 0, 0, 0.08);
}

@media (max-width: 767px) {
  .search-stockist .box-search {
    top: 0;
    left: 50%;
    margin: 0 auto;
    transform: translate3d(-50%, 0, 0);
  }
}

.search-stockist .box-search input[type="text"] {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  width: 100%;
  padding: 25px 150px 25px 30px;
  background: #FFF;
  text-overflow: ellipsis;
  color: #9B9B9B;
  font-size: 18px;
  letter-spacing: -.2px;
}

.search-stockist .box-search input[type="text"]:disabled {
  font-size: 18px;
  background: #FFF;
  padding-left: 25px;
}

.search-stockist .box-search input[type="text"]:focus::-webkit-input-placeholder {
  opacity: 0;
}

.search-stockist .box-search input[type="text"]:focus::-moz-placeholder {
  opacity: 0;
}

.search-stockist .box-search input[type="text"]:focus:-ms-input-placeholder {
  opacity: 0;
}

.search-stockist .box-search input[type="text"]:focus:-moz-placeholder {
  opacity: 0;
}

.search-stockist .box-search input[type="text"]::-webkit-input-placeholder {
  transition: all 0.5s ease;
}

.search-stockist .box-search input[type="text"]::-moz-placeholder {
  transition: all 0.5s ease;
}

.search-stockist .box-search input[type="text"]:-ms-input-placeholder {
  transition: all 0.5s ease;
}

.search-stockist .box-search input[type="text"]:-moz-placeholder {
  transition: all 0.5s ease;
}

.search-stockist .box-search .btn-click {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 25px;
  height: 76px;
  min-width: 76px;
  line-height: 76px;
  color: #FFF;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: width 0.5s linear;
  font-family: "Circular Std";
  background: #62bd89;
}

.search-stockist .box-search .btn-click:hover {
  cursor: pointer;
  color: #FFF;
}

.search-stockist .box-result {
  height: 0;
  overflow: hidden;
}

.search-stockist .box-result .wrap-ani {
  transition: transform 0.5s ease-out;
}

.search-stockist .box-result .des {
  background-color: #F4F2ED;
  font-size: 16px;
  padding: 15px 25px;
}

.search-stockist .box-result .des span {
  font-weight: 700;
  padding-right: 10px;
}

.search-stockist .box-result .des .bigger {
  font-size: 18px;
}

.search-stockist .box-result .des a {
  display: block;
}

@media (min-width: 768px) {
  .search-stockist .box-result .des a {
    float: right;
  }
}

.search-stockist .box-result .list-add-result {
  max-height: calc(65vh - 100px);
  background-color: #FFF;
}

@media (max-width: 767px) {
  .search-stockist .box-result .list-add-result {
    max-height: 140px;
  }
}

.flourish-infomap,
.item-add-result {
  font-family: "Avenir Next";
  position: relative;
  cursor: pointer;
}

.flourish-infomap:not(.flourish-infomap),
.item-add-result:not(.flourish-infomap) {
  padding: 15px 25px;
}

.flourish-infomap:not(:last-child),
.item-add-result:not(:last-child) {
  border-bottom: 1px solid #E8E6DE;
}

.flourish-infomap.selected,
.item-add-result.selected {
  background: rgba(238, 238, 238, 0.6);
}

.flourish-infomap:not(.flourish-infomap):hover,
.item-add-result:not(.flourish-infomap):hover {
  background: rgba(238, 238, 238, 0.3);
}

.flourish-infomap h5,
.item-add-result h5 {
  font: 700 16px/1.5em "Circular Std";
  padding-right: 65px;
}

.flourish-infomap b,
.item-add-result b {
  font-weight: 700;
}

.flourish-infomap p,
.item-add-result p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.5em;
}

.flourish-infomap span,
.item-add-result span {
  display: inline-block;
  text-align: center;
  letter-spacing: 1.4px;
  background-color: #F4F2ED;
  position: absolute;
  right: 25px;
  top: 15px;
  font-size: 14px;
  font-family: "Circular Std";
  color: #231F20;
  font-weight: 400;
  padding: 5px 7px;
}

.search-stockist .box-result .list-add-result .item-online-result {
  padding: 15px 25px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  min-height: 100px;
}

.search-stockist .box-result .list-add-result .item-online-result:not(:last-child) {
  border-bottom: 1px solid #E8E6DE;
}

.search-stockist .box-result .list-add-result .item-online-result:hover {
  background: rgba(238, 238, 238, 0.3);
}

.search-stockist .box-result .list-add-result .item-online-result .avatar {
  width: calc(40% - 50px);
  margin-right: 50px;
}

.search-stockist .box-result .list-add-result .item-online-result .avatar img {
  width: 100%;
  height: auto;
}

.search-stockist .box-result .list-add-result .item-online-result .info {
  width: 55%;
}

.search-stockist .box-result .list-add-result .item-online-result .info h6 {
  font-weight: 700;
  font-family: "Circular Std";
  font-size: 14px;
  text-transform: uppercase;
  line-height: 1.5em;
}

.search-stockist .box-result .list-add-result .item-online-result .info p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.5em;
}

.search-stockist .box-result#box-online-result {
  height: auto;
}

@media (max-width: 767px) {
  .search-stockist .box-result#box-online-result .list-add-result {
    min-height: calc(100vh - 210px);
  }
}

.search-stockist .scrollbar-inner > .scroll-element.scroll-y {
  right: 0;
}

.search-stockist .scrollbar-inner > .scroll-element .scroll-element_outer,
.search-stockist .scrollbar-inner > .scroll-element .scroll-element_track,
.search-stockist .scrollbar-inner > .scroll-element .scroll-bar {
  border-radius: 0;
}

.hunterlab-infomap {
  font-family: "Circular Std";
  font-size: 16px;
  line-height: 24px;
  max-width: 310px;
}

.hunterlab-infomap h5 {
  font: 700 18px/normal 'Lato';
  text-transform: uppercase;
  padding-right: 65px;
  letter-spacing: 0.5px;
}

.ven-quantity {
  display: flex;
  width: 62px;
  border-bottom: thin solid #000;
}

.ven-quantity input {
  width: 100%;
  font-size: 16px;
  flex: 1;
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0 5px;
  appearance: none;
  text-align: center;
  color: #000;
  outline: none;
  -moz-appearance: textfield;
}

.ven-quantity input::-webkit-outer-spin-button, .ven-quantity input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (min-width: 992px) {
  .ven-quantity input {
    font-size: 14px;
  }
}

.ven-quantity button {
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
  outline: none;
  cursor: pointer;
}

.ven-cart {
  background-color: #f4f2ed;
  padding: 100px 0 50px;
}

@media (min-width: 992px) {
  .ven-cart {
    padding: 150px 60px 110px;
  }
}

@media (max-width: 991px) {
  .ven-cart__row > div:not(:last-child) {
    margin-bottom: 60px;
  }
}

.ven-cart-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.02;
  letter-spacing: 0.06em;
  margin-bottom: 25px;
}

@media (min-width: 992px) {
  .ven-cart-title {
    font-size: 38px;
  }
}

.ven-order-summary-title {
  font-family: "Circular Std";
  font-size: 24px;
  font-weight: 700;
  line-height: 1.02;
  text-transform: uppercase;
}

@media (min-width: 992px) {
  .ven-order-summary-title {
    font-size: 32px;
  }
}

.table-list {
  margin-left: auto;
  width: 100%;
  max-width: 660px;
  table-layout: fixed;
  margin-bottom: 20px;
  font-weight: 500;
}

@media (min-width: 1600px) {
  .table-list {
    max-width: 33vw;
  }
}

@media (max-width: 991px) {
  .table-list {
    margin-left: 0;
    table-layout: unset;
    max-width: 100%;
  }
}

@media (max-width: 640px) {
  .table-list {
    border: 0;
  }
  .table-list thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .table-list tbody > tr {
    display: block;
    margin-bottom: 15px;
    padding: 10px;
    background: #F8F6F4;
    border: 0 !important;
  }
  .table-list tbody td {
    width: auto;
    border-bottom: 1px solid #e8e6de;
    display: block;
    font-size: .9em;
    text-align: right;
  }
  .table-list tbody td:before {
    content: attr(data-label) !important;
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    padding-right: 15px;
    text-align: left;
  }
  .table-list tbody td:last-child {
    border-bottom: 0;
  }
}

.table-list thead > tr {
  border-bottom: 1px solid #e8e6de;
}

.table-list thead > tr > td {
  padding-bottom: 16px;
  font-size: 20px;
  font-weight: 700;
  font-family: "Circular Std";
  vertical-align: bottom;
}

.table-list thead > tr > td:last-child {
  text-align: right;
}

@media (min-width: 2000px) {
  .table-list thead > tr > td {
    padding-bottom: 0.5vw;
  }
}

@media (max-width: 640px) {
  .table-list thead > tr > td {
    padding-bottom: 10px;
  }
}

.table-list tbody > tr {
  border-bottom: 1px solid #e8e6de;
}

.table-list tbody > tr > td {
  padding: 10px 0;
}

@media (min-width: 2000px) {
  .table-list tbody > tr > td {
    padding: 0.5vw 0;
  }
}

@media (max-width: 640px) {
  .table-list tbody > tr > td {
    padding: 10px 0;
    vertical-align: top;
  }
}

.table-list tbody > tr.coupon-cart-row {
  border-bottom: 0;
}

.table-list tbody > tr.coupon-cart-row > td {
  padding-top: 30px;
}

@media (max-width: 640px) {
  .table-list tbody > tr.coupon-cart-row > td {
    padding-top: 10px;
  }
}

.table-list tbody > tr.cart_item > td:last-child,
.table-list tbody > tr.wishlist_item > td:last-child {
  text-align: right;
}

.table-list tbody > tr.wishlist_item > td {
  vertical-align: middle;
}

.table-list button[name=update_cart] {
  font-family: "Circular Std";
  cursor: pointer;
  color: #fff;
  display: inline-flex;
  letter-spacing: 1.2px;
  align-items: center;
  height: 35px;
  font-weight: 500;
  font-size: 16px;
  padding: 5px 20px;
  border: 0;
  max-width: 210px;
  width: 100%;
  background: #000 url(../../src/img/arrows-rotate.svg) center right 20px/18px 18px no-repeat;
  outline: 0 !important;
}

.table-list button[name=update_cart][disabled] {
  opacity: .5;
}

.table-list button[name=update_cart][disabled]:hover {
  opacity: .5;
  cursor: not-allowed;
}

.table-list button[name=update_cart]:hover {
  opacity: .9;
}

@media (min-width: 2000px) {
  .table-list button[name=update_cart] {
    height: 3vw;
    padding: 0.25vw 1vw;
    max-width: 10.5vw;
    font-size: 0.8vw;
    background: #000 url(../../src/img/arrows-rotate.svg) center right 1.11111 0.9vw no-repeat;
  }
}

@media (max-width: 767px) {
  .table-list button[name=update_cart] {
    max-width: 100%;
    margin-bottom: 15px;
  }
}

.coupon {
  display: inline-flex;
  float: right;
  position: relative;
}

@media (max-width: 767px) {
  .coupon {
    width: 100%;
    float: none;
  }
}

.coupon input {
  margin-left: 10px;
  width: 395px;
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  border: 1px solid #000;
  padding: 0 100px 0 30px;
  height: 60px;
  font-size: 16px;
  letter-spacing: -.17px;
  line-height: 1em;
  font-weight: 400;
  display: inline-block;
}

.coupon input:-webkit-autofill, .coupon input:-webkit-autofill:hover, .coupon input:-webkit-autofill:focus, .coupon input:-webkit-autofill:active {
  transition: background 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000;
}

@media (min-width: 2000px) {
  .coupon input {
    width: 19.75vw;
    font-size: 0.8vw;
    height: 3vw;
    padding: 0 5vw 0 1.5vw;
  }
}

@media (max-width: 991px) {
  .coupon input {
    width: 300px;
  }
}

@media (max-width: 767px) {
  .coupon input {
    width: 100%;
    margin-left: 0;
  }
}

.coupon input::-webkit-input-placeholder {
  color: #9b9b9b;
}

.coupon input::-moz-placeholder {
  color: #9b9b9b;
}

.coupon input:-ms-input-placeholder {
  color: #9b9b9b;
}

.coupon input:-moz-placeholder {
  color: #9b9b9b;
}

.coupon button[type=submit] {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  font-family: "Circular Std";
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  background: #fff;
  color: #000;
  display: inline-flex;
  letter-spacing: 2px;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 5px 20px;
  text-transform: uppercase;
  border: 0;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.coupon button[type=submit]:hover {
  opacity: .7;
}

@media (min-width: 2000px) {
  .coupon button[type=submit] {
    height: 2.5vw;
    padding: 0.25vw 1vw;
    font-size: 0.8vw;
  }
}

.coupon .wrap-coupon-complete {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.coupon .wrap-coupon-complete .wrap-txt {
  font: 700 16px/19px "Circular Std";
}

.coupon .wrap-coupon-complete .wrap-price {
  font-size: 16px;
}

.coupon .wrap-coupon-complete .wrap-price .woocommerce-Price-amount {
  display: inline-block;
  margin-left: 5px;
  margin-right: 10px;
  font-weight: 700;
}

.banner-health-video .close-btn {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  padding: 8px;
  background-color: rgba(37, 37, 37, 0.8);
  position: absolute;
  right: 15px;
  top: 10px;
  z-index: 999;
  cursor: pointer;
  color: #FFF;
  opacity: 0;
  transition: all 0.5s ease;
}

@media (min-width: 576px) {
  .banner-health-video .close-btn {
    right: 55px;
  }
}

.banner-health-video .close-btn span {
  background: url("../img/icon-close-w.svg") no-repeat center/auto 100%;
  display: block;
  width: 20px;
  height: 20px;
}

.banner-health-video .play-btn {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  background: #FFF;
  border: 0;
  border-radius: 50%;
  z-index: 1;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  outline: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  transition: all .3s ease;
}

@media (min-width: 576px) {
  .banner-health-video .play-btn {
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 992px) {
  .banner-health-video .play-btn {
    width: 80px;
    height: 80px;
  }
}

.banner-health-video .play-btn:before {
  background: #fff;
  transition: all .35s ease;
  transform: scale(1);
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
}

.banner-health-video .play-btn i {
  color: #000;
  font-size: 10px;
  margin-left: 3px;
  vertical-align: middle;
  transition: all .5s ease;
  z-index: 2;
  position: relative;
}

@media (min-width: 576px) {
  .banner-health-video .play-btn i {
    font-size: 15px;
  }
}

@media (min-width: 992px) {
  .banner-health-video .play-btn i {
    font-size: 18px;
  }
}

.banner-health-video .play-btn:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.banner-health-video .play-btn:hover:before {
  transform: scale(0);
  background-color: #000;
}

.banner-health-video .video-holder {
  height: 100%;
  padding-top: 30.625%;
  background: #000;
  transition: all 1s ease;
}

.banner-health-video .video-holder.ani {
  padding-top: 56.25%;
}

.banner-health-video .video-holder.ani .close-btn {
  opacity: 1;
}

.banner-health-video .video-holder.ani:hover .close-btn {
  opacity: 1;
}

.banner-health-video .video-holder iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: #000;
  object-fit: fill;
  transform: translate3d(-50%, -50%, 0);
}

.content-health .wrap-text-4-item {
  margin-bottom: 15px;
}

@media (min-width: 992px) {
  .content-health .wrap-text-4-item {
    margin-bottom: 35px;
  }
}

.content-health .description {
  text-align: center;
}

.content-health .description p {
  margin-bottom: 20px;
}

.content-health .btn-black-get {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  background-color: #000;
  color: #FFF;
  display: inline-block;
  min-width: 200px;
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;
  padding: 20px 0;
  font-family: "Circular Std";
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 60px;
  transition: all 0.5s ease;
}

.content-health .btn-black-get:hover {
  opacity: 0.7;
}

.content-health .box-day-scroll .title-medium {
  margin-bottom: 35px;
}

@media (min-width: 992px) {
  .content-health .box-day-scroll .title-medium {
    margin-bottom: 50px;
  }
}

.content-health .box-day-scroll .introduction {
  max-width: 1075px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 992px) {
  .content-health .box-day-scroll .introduction dl {
    display: flex;
  }
}

.content-health .box-day-scroll .introduction dl:not(:last-child) {
  margin-bottom: 35px;
}

.content-health .box-day-scroll .introduction dl dt {
  font-family: "Circular Std";
  font-size: 20px;
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .content-health .box-day-scroll .introduction dl dt {
    flex-basis: 35%;
  }
}

.content-health .box-day-scroll .introduction dl dd a:not(.btn-common) {
  font-weight: 700;
  text-decoration: underline;
}

@media (min-width: 992px) {
  .content-health .box-day-scroll .introduction dl dd {
    flex-basis: 65%;
  }
}

.content-health .img-food {
  margin-bottom: 30px;
  padding-top: 40%;
}

@media (min-width: 992px) {
  .content-health .img-food {
    padding-top: 24.44444%;
  }
}

.content-health .modal-content {
  border-radius: 0;
  overflow-y: auto;
}

.content-health .modal {
  -webkit-overflow-scrolling: touch;
}

.content-health .modal .modal-body {
  padding: 0;
}

.content-health .modal-dialog {
  max-width: 1325px;
}

.content-health .close {
  position: fixed;
  padding: 0;
  right: 20px;
  top: 15px;
  z-index: 5;
  cursor: pointer;
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  transform: translate3d(0, 0, 0);
  background-color: transparent;
}

@media (min-width: 576px) {
  .content-health .close {
    right: 40px;
    position: absolute;
  }
}

.content-health .close span {
  font-size: 30px;
}

.lg-outer.lg-visible {
  background: #0D0A0A;
  z-index: 9999;
}

.popup-health {
  max-height: calc(100% - 60px);
  min-height: calc(100% - 60px);
  overflow: auto;
}

@media (max-width: 767px) {
  .popup-health .img-food {
    padding-top: 40% !important;
  }
}

@media (min-width: 1200px) {
  .hero-health {
    height: 540px;
  }
}

.row-health-hub {
  padding-bottom: 35px;
}

@media (min-width: 768px) {
  .row-health-hub {
    margin-left: -8px;
    margin-right: -8px;
    padding-bottom: 50px;
  }
  .row-health-hub > .col,
  .row-health-hub > [class*="col"] {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.row-health-hub .btn-common {
  width: calc(100% - 6px);
}

@media (max-width: 767px) {
  .row-health-hub [class^=col-]:not(:last-child) {
    margin-bottom: 35px;
  }
}

.row-health-hub .btn-detail {
  display: inline-block;
  margin-bottom: 30px;
  font-weight: 700;
  position: relative;
  font-family: "Circular Std";
}

.row-health-hub .btn-detail:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #62bd89;
}

.item-health {
  background: #fff;
  margin-bottom: 18px;
}

.item-health .img-drop {
  padding-top: 70.42254%;
}

.item-health .img-drop.disabled {
  opacity: .5;
}

.item-health .img-drop img {
  margin: 0;
}

.item-health .wrapper-text {
  padding: 28px 70px 32px 31px;
  position: relative;
  font: 700 16px/1em "Circular Std";
}

@media (min-width: 768px) and (max-width: 991px) {
  .item-health .wrapper-text {
    padding: 28px 70px 32px 20px;
  }
}

.item-health .locker {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}

.bottom-health-hub {
  padding-top: 20px;
  border-top: 1px solid #000;
  padding-bottom: 35px;
}

@media (min-width: 768px) {
  .bottom-health-hub {
    padding-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .bottom-health-hub [class^=col-]:not(:last-child) {
    margin-bottom: 25px;
  }
}

.bottom-health-hub p {
  font-family: "Circular Std";
  line-height: 1.5em;
}

.bottom-health-hub .btn-common {
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .bottom-health-hub .btn-common {
    margin-left: 15px;
  }
}

#recipes-modal .modal,
.health-hub .modal {
  -webkit-overflow-scrolling: touch;
}

#recipes-modal .modal-body,
.health-hub .modal-body {
  -webkit-overflow-scrolling: touch;
}

#recipes-modal .modal-dialog,
.health-hub .modal-dialog {
  transform: none !important;
}

#recipes-modal .iframe_div iframe,
.health-hub .iframe_div iframe {
  display: block !important;
  border: 0 !important;
  width: 100% !important;
  max-height: calc(100vh - 60px) !important;
}

.health-hub .modal-content {
  border-radius: 0;
}

.health-hub .modal {
  -webkit-overflow-scrolling: touch;
}

.health-hub .modal .modal-body {
  padding: 0;
}

.health-hub .modal-dialog {
  max-width: 1325px;
}

.health-hub .close {
  position: fixed;
  right: 20px;
  top: 15px;
  z-index: 5;
  padding: 0;
  cursor: pointer;
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  background-color: transparent;
  transform: translate3d(0, 0, 0);
}

@media (min-width: 576px) {
  .health-hub .close {
    right: 40px;
    position: absolute;
  }
}

.health-hub .close span {
  font-size: 30px;
}

.loading-recipes {
  background: rgba(255, 255, 255, 0.8);
}

@media (max-width: 991px) {
  .sticky-bar {
    position: relative;
  }
  .sticky-bar .toggle-nav {
    background: transparent url(../img/icon-back.svg) no-repeat center/8px auto;
    height: 50px;
    width: 50px;
    position: absolute;
    right: 0;
    top: 25px;
    transform: rotate(-90deg) translateY(-50%);
    transform-origin: center 0;
    transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    cursor: pointer;
    z-index: 12;
  }
  .sticky-bar .toggle-nav.fixed {
    top: 85px;
    position: fixed;
  }
  .sticky-bar .toggle-nav.is-active {
    transform: rotate(90deg) translateY(-50%);
  }
  .sticky-bar.slideUp .toggle-nav.fixed {
    transform: rotate(-90deg) translateY(-50%) translateX(100%);
  }
  .sticky-bar.slideUp .wrap-sticky.fixed {
    transform: translateY(-100%) translateX(-50%);
  }
}

.sticky-bar .wrap-sticky {
  background-color: #FFF;
  transition: position 0.5s ease;
}

.sticky-bar .wrap-sticky.fixed {
  position: fixed;
  top: 60px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  z-index: 10;
}

@media (min-width: 1600px) {
  .sticky-bar .wrap-sticky.fixed {
    top: 3vw;
  }
}

.sticky-bar .content-center {
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-family: "Circular Std";
  padding: 15px 0;
  transition: all 0.5s ease;
  overflow: hidden;
}

@media (min-width: 992px) {
  .sticky-bar .content-center {
    padding: 25px 0;
    height: auto !important;
  }
}

.sticky-bar .content-center span {
  display: inline-block;
  margin-right: 40px;
}

@media (min-width: 992px) {
  .sticky-bar .list-days {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 991px) {
  .sticky-bar .list-days.expand li:not(:first-child) {
    margin-top: 15px;
  }
}

.sticky-bar .list-days li {
  position: relative;
}

@media (max-width: 991px) {
  .sticky-bar .list-days li:last-child {
    margin-bottom: 5px;
  }
}

.sticky-bar .list-days li:not(:first-child) {
  padding-left: 30px;
  background: url(../img/icon-check-gray.svg) no-repeat left center/auto 100%;
}

@media (min-width: 992px) {
  .sticky-bar .list-days li:not(:first-child) {
    margin-top: 0;
    margin-left: 20px;
  }
}

@media (min-width: 1200px) {
  .sticky-bar .list-days li:not(:first-child) {
    margin-left: 40px;
  }
}

.sticky-bar .list-days li:not(:first-child) a {
  color: rgba(0, 0, 0, 0.2);
}

.sticky-bar .list-days li.finished {
  background: url(../img/icon-check-green.svg) no-repeat left center/auto 100%;
}

.sticky-bar .list-days li.finished a {
  color: #000;
}

@media (max-width: 991px) {
  .sticky-bar .list-days li.is-hide {
    display: none;
  }
  .sticky-bar .list-days li.active {
    display: block !important;
    opacity: 1 !important;
    pointer-events: none;
  }
}

.sticky-bar .list-days li.active a,
.sticky-bar .list-days li a.active {
  color: #000;
}

@media (min-width: 992px) {
  .sticky-bar .list-days li.active a:after,
  .sticky-bar .list-days li a.active:after {
    content: '';
    position: absolute;
    bottom: -25px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #62bd89;
  }
}

.admin-bar .sticky-bar .wrap-sticky.fixed {
  top: 106px;
}

@media (min-width: 768px) {
  .admin-bar .sticky-bar .wrap-sticky.fixed {
    top: 92px;
  }
}

.content-health .wp-block-button .wp-block-button__link {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  background-color: #000;
  color: #FFF;
  display: inline-block;
  min-width: 200px;
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;
  padding: 20px;
  font-family: "Circular Std";
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 60px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.content-health .wp-block-button.btn-common {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.content-health .wp-block-button.btn-common .wp-block-button__link {
  margin: 0;
  border: 1px solid #000;
  background-color: #F4F2ED;
  color: #000;
}

@media (min-width: 768px) {
  .wrap-title-health {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}

@media (min-width: 768px) {
  .wrap-btn-health {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .wrap-btn-health .product-tag-list {
    flex-basis: 70%;
  }
  .wrap-btn-health .product-tag-list li {
    cursor: pointer;
  }
  .wrap-btn-health .btn-common {
    flex-basis: 30%;
    margin-bottom: 1rem;
  }
}

*[data-toggle-tooltip="tooltip"] {
  position: relative;
}

.tooltip.show {
  opacity: 1;
}

.bs-tooltip-right .arrow::before {
  left: -1px;
  border-right-color: #FFF;
  z-index: 10;
}

.bs-tooltip-bottom .arrow::before {
  bottom: -1px;
  border-bottom-color: #FFF;
  z-index: 10;
}

.tooltip-inner {
  padding: 10px 15px;
  color: #000;
  text-align: left;
  background-color: #FFF;
  max-width: 400px;
}

.btn-play-popup {
  font: 700 14px/normal "Circular Std";
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  transition: all .3s ease;
}

.btn-play-popup:hover {
  opacity: .7;
}

.btn-play-popup .fa {
  margin-left: 15px;
  color: #62bd89;
  position: relative;
  transition: all .3s ease;
}

.btn-play-popup .fa:after {
  content: '';
  top: 50%;
  left: calc(50% - 2px);
  transform: translate3d(-50%, -50%, 0);
  border: 1px solid #62bd89;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: absolute;
  transition: all .3s ease;
}

.popup-video {
  position: fixed;
  top: calc(50% + 15px);
  left: calc(50% - 60px);
  transform: translate3d(-50%, -50%, 0);
  max-width: 70vw;
  padding: 0 15px;
  margin: 0 60px;
  width: calc(100% - 120px);
  z-index: 102;
  visibility: hidden;
  opacity: 0;
  transition: all .7s ease;
  -webkit-overflow-scrolling: touch;
}

.popup-video.expand {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.popup-video .video-holder {
  padding-top: 56.25%;
}

.popup-video .video-holder:hover #mute-video {
  opacity: 1;
  visibility: visible;
}

.popup-video .close-btn {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  padding: 8px;
  background-color: rgba(37, 37, 37, 0.8);
  position: absolute;
  right: 15px;
  top: 10px;
  z-index: 999;
  cursor: pointer;
  color: #FFF;
  opacity: 0;
  transition: all 0.5s ease;
}

@media (min-width: 576px) {
  .popup-video .close-btn {
    right: 55px;
  }
}

.popup-video .close-btn span {
  background: url("../img/icon-close-w.svg") no-repeat center/auto 100%;
  display: block;
  width: 20px;
  height: 20px;
}

.popup-video .close-btn.health {
  top: -40px;
  right: 15px;
  opacity: 1;
}

.popup-video iframe {
  background: #000;
  object-fit: fill;
}

.popup-video #mute-video {
  position: absolute;
  bottom: 50px;
  right: 20px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease;
}

.popup-video #mute-video i {
  font-size: 30px;
  color: #fff;
}

.popup-video-backdrop {
  background: rgba(0, 0, 0, 0.75);
  z-index: 101;
  background: rgba(232, 230, 222, 0.85);
  cursor: not-allowed;
}

.banner-promotion-health-hub {
  display: none;
}

@media (min-width: 576px) {
  .banner-promotion-health-hub {
    display: block;
  }
}

.banner-promotion-health-hub img {
  width: 100%;
  height: auto;
}

@media (min-width: 576px) {
  .banner-promotion-health-hub-mobile {
    display: none;
  }
}

.banner-promotion-health-hub-mobile img {
  width: 100%;
  height: auto;
}

.pages-row .row {
  margin-left: 0;
  margin-right: 0;
}

.pages-row .row > .col,
.pages-row .row > [class*="col"] {
  padding-left: 0;
  padding-right: 0;
}

.pages-row .row > [class^=col-]:nth-child(3n+1) {
  background: rgba(207, 131, 11, 0.64);
}

.pages-row .row > [class^=col-]:nth-child(3n+2) {
  background: #acd8f3;
}

.pages-row .row > [class^=col-]:nth-child(3n+3) {
  background: #eaf3bd;
}

.pages-row .wrapper {
  padding: 35px 30px 200px 30px;
}

@media (min-width: 576px) {
  .pages-row .wrapper {
    padding: 50px 25% 220px 56px;
  }
}

@media (min-width: 1200px) {
  .pages-row .wrapper {
    padding: 80px 20% 220px 56px;
  }
}

.pages-row .wrapper .title-page {
  z-index: 1;
  position: relative;
}

.pages-row .wrapper p {
  margin-bottom: 1rem;
  line-height: 1.55556em;
  z-index: 1;
  position: relative;
}

@media (min-width: 992px) {
  .pages-row .wrapper p {
    font-size: 18px;
  }
}

.pages-row .wrapper .btn-common {
  z-index: 1;
  margin-top: 35px;
  max-width: 170px;
}

.pages-row .wrapper .btn-common .pos {
  padding: 15px 0;
}

.pages-row .wrapper .img-bg {
  position: absolute;
  right: 0;
  bottom: 0;
  max-height: 250px;
  width: auto;
  max-width: 100%;
}

@media (max-width: 991px) {
  .banner-product.appointment-banner {
    overflow: auto;
    background-color: #f4f2ed;
  }
}

@media (max-width: 991px) {
  .banner-product.appointment-banner .text-cover {
    position: relative;
    margin-top: 210px;
    background: #fff;
  }
}

@media (max-width: 575px) {
  .banner-product.appointment-banner .text-cover {
    max-width: calc(100% - 30px);
  }
}

@media (max-width: 991px) {
  .banner-product.appointment-banner .img-drop {
    min-height: auto;
    height: 260px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.banner-product.appointment-banner .des-page {
  font-size: 18px;
  letter-spacing: -.2px;
}

.appointment-benefits {
  padding-top: 45px;
  padding-bottom: 45px;
}

@media (min-width: 992px) {
  .appointment-benefits {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.appointment-benefits p {
  margin-bottom: 1rem;
  line-height: 1.55556em;
  font-size: 18px;
  letter-spacing: -.2px;
}

.appointment-benefits .row-benefits-block {
  margin-top: 25px;
}

@media (min-width: 992px) {
  .appointment-benefits .row-benefits-block {
    margin-left: -5px;
    margin-right: -5px;
    margin-top: 40px;
  }
  .appointment-benefits .row-benefits-block > .col,
  .appointment-benefits .row-benefits-block > [class*="col"] {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.appointment-benefits .row-benefits-block > [class^=col-] {
  margin-bottom: 15px;
}

@media (min-width: 992px) {
  .appointment-benefits .row-benefits-block > [class^=col-] {
    margin-bottom: 0;
  }
}

.appointment-benefits .block-benefit {
  background: #fff;
  padding: 24px 24px 32px;
  height: 100%;
}

@media (min-width: 992px) {
  .appointment-benefits .block-benefit {
    margin-bottom: 0;
    padding: 30px 30px 48px;
  }
}

.appointment-benefits .block-benefit p {
  font-size: 16px;
}

.appointment-benefits .block-benefit .medium-title {
  margin-bottom: 0;
}

.appointment-benefits .block-benefit .flex-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
}

@media (min-width: 992px) {
  .appointment-benefits .block-benefit .flex-row {
    margin-bottom: 24px;
  }
}

.appointment-benefits .block-benefit .flex-row img {
  max-height: 60px;
  width: auto;
  margin-left: 10px;
}

.what-will-get {
  padding-top: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right top;
}

@media (min-width: 992px) {
  .what-will-get {
    background-position: left top;
    padding-top: 100px;
  }
}

.what-will-get .title-page {
  max-width: 340px;
}

@media (max-width: 575px) {
  .what-will-get .title-page {
    max-width: 250px;
  }
}

.what-will-get p {
  margin-bottom: 1rem;
  line-height: 1.55556em;
  font-size: 18px;
}

.what-will-get .btn-common {
  margin-top: 40px;
}

@media (max-width: 575px) {
  .what-will-get .btn-common {
    max-width: 100%;
    width: 100%;
  }
  .what-will-get .btn-common .pos {
    padding: 16px 0;
  }
}

.what-will-get h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.36364em;
  margin-bottom: .5rem;
}

@media (min-width: 576px) {
  .what-will-get h3 {
    font-size: 22px;
  }
}

.appointment-pricing {
  padding-top: 50px;
}

@media (min-width: 992px) {
  .appointment-pricing {
    padding-top: 80px;
  }
}

.appointment-pricing .container {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 992px) {
  .appointment-pricing .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.appointment-pricing .desc {
  font-size: 18px;
}

.appointment-pricing .slider-pricing .owl-stage-outer {
  padding: 0px;
}

@media (min-width: 992px) {
  .appointment-pricing .slider-pricing .owl-stage-outer {
    padding: 0 0 90px;
  }
}

.appointment-pricing .slider-pricing .owl-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 20px;
}

.appointment-pricing .slider-pricing .owl-dots.disabled {
  display: none;
}

.appointment-pricing .slider-pricing .owl-dots .owl-dot {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  width: 20px;
  height: 6px;
  border-radius: 7.5px;
  border: 1px solid #000;
  margin: 0 7.5px;
  transition: all .3s ease;
}

.appointment-pricing .slider-pricing .owl-dots .owl-dot.active {
  background: #000;
}

.appointment-pricing .slider-pricing .owl-item.center .block-pricing {
  padding: 0;
}

.appointment-pricing .slider-pricing .owl-item.center .block-pricing .item-icon {
  width: 80px;
  height: 80px;
}

.appointment-pricing .slider-pricing .owl-item.center .block-pricing .item-icon:before {
  width: 100px;
  height: 100px;
}

.appointment-pricing .slider-pricing .owl-item.center .block-pricing .item-icon:after {
  width: 120px;
  height: 120px;
}

.appointment-pricing .mobile-button-booking {
  display: inline-block;
  width: 100%;
}

@media (min-width: 992px) {
  .appointment-pricing .mobile-button-booking {
    display: none;
  }
}

.appointment-pricing .wrap-title {
  max-width: 520px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 992px) {
  .appointment-pricing .wrap-title {
    padding-left: 0;
    padding-right: 0;
  }
}

.appointment-pricing .wrap-title .title-page {
  margin-bottom: 24px;
}

.appointment-pricing .wrap-width {
  max-width: 890px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  padding-top: 0;
}

.appointment-pricing .wrap-width .slider-pricing .block-pricing {
  padding: 30px 0;
  position: relative;
  transition: all .25s ease;
}

@media (min-width: 992px) {
  .appointment-pricing .wrap-width .slider-pricing .block-pricing:hover {
    padding: 0;
  }
  .appointment-pricing .wrap-width .slider-pricing .block-pricing:hover .item-icon {
    width: 80px;
    height: 80px;
  }
  .appointment-pricing .wrap-width .slider-pricing .block-pricing:hover .item-icon:before {
    width: 100px;
    height: 100px;
  }
  .appointment-pricing .wrap-width .slider-pricing .block-pricing:hover .item-icon:after {
    width: 120px;
    height: 120px;
  }
}

.appointment-pricing .wrap-width .slider-pricing .block-pricing:hover a.btn-common {
  opacity: 1;
}

.appointment-pricing .wrap-width .slider-pricing .block-pricing a.btn-common {
  position: absolute;
  margin: 30px auto 0;
  opacity: 1;
  width: 100%;
  max-width: 100%;
  transition: all .25s ease;
  display: none;
}

@media (min-width: 992px) {
  .appointment-pricing .wrap-width .slider-pricing .block-pricing a.btn-common {
    display: block;
    opacity: 0;
  }
}

.appointment-pricing .wrap-width .slider-pricing .block-pricing a.btn-common .pos {
  transition: all .3s ease;
}

.appointment-pricing .wrap-width .slider-pricing .block-pricing a.btn-common:hover .pos {
  background: #000;
  color: #fff;
}

.appointment-pricing p {
  line-height: 1.55556em;
}

.appointment-pricing .item-pricing {
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 290px;
  margin-left: auto;
  margin-right: auto;
}

.appointment-pricing .item-pricing .item-top {
  padding: 16px 12px;
  background: #fff;
  font-family: "Circular Std";
  font-size: 18px;
  font-weight: 700;
}

.appointment-pricing .item-pricing .bg-skin {
  padding: 24px 32px 48px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.appointment-pricing .item-pricing .item-icon {
  margin: 24px auto 32px;
  width: 40px;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .25s ease;
}

.appointment-pricing .item-pricing .item-icon img {
  width: 100%;
  max-height: 100%;
  z-index: 2;
}

.appointment-pricing .item-pricing .item-icon:before, .appointment-pricing .item-pricing .item-icon:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  border-radius: 50%;
  transition: all .25s ease;
}

.appointment-pricing .item-pricing .item-icon:before {
  background: rgba(255, 255, 255, 0.8);
  width: 60px;
  height: 60px;
}

.appointment-pricing .item-pricing .item-icon:after {
  background: rgba(255, 255, 255, 0.5);
  width: 80px;
  height: 80px;
}

.appointment-pricing .item-pricing .item-cost {
  font-size: 60px;
  line-height: 1em;
  font-weight: 700;
  color: #62bd89;
  position: relative;
  align-self: center;
}

.appointment-pricing .item-pricing .item-cost .item-currency {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  font-size: 24px;
  font-weight: 500;
  line-height: 1.6em;
}

.appointment-pricing .item-pricing .item-time {
  font-size: 18px;
  line-height: 1.38889em;
  font-weight: 500;
  margin-bottom: 1rem;
}

.appointment-pricing .item-pricing p {
  max-width: 220px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
}

.booking-appointment {
  overflow: hidden;
  padding-top: 40px;
  background: #fff;
  position: relative;
}

@media (min-width: 992px) {
  .booking-appointment {
    padding-top: 80px;
  }
}

@media (max-width: 575px) {
  .booking-appointment .book-appointment-btn {
    max-width: 100% !important;
    width: calc(100% - 7px);
  }
  .booking-appointment .book-appointment-btn .pos {
    padding: 16px 0;
  }
}

.booking-appointment .mobile-appointment-title {
  display: block;
}

@media (max-width: 575px) {
  .booking-appointment .mobile-appointment-title {
    margin-bottom: 20px !important;
  }
}

@media (min-width: 992px) {
  .booking-appointment .mobile-appointment-title {
    display: none;
  }
}

.booking-appointment .desktop-appointment-title {
  display: none;
}

@media (min-width: 992px) {
  .booking-appointment .desktop-appointment-title {
    display: block;
  }
}

.booking-appointment .row-top-step {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media (min-width: 992px) {
  .booking-appointment .row-top-step {
    margin-bottom: 45px;
  }
}

.booking-appointment .row-top-step .title-page {
  width: 100%;
  margin-bottom: 0;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .booking-appointment .row-top-step .title-page {
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .booking-appointment .row-top-step .title-page {
    margin-bottom: 20px;
  }
}

.booking-appointment .row-top-step .btn-common {
  margin-top: 0;
  margin-left: auto;
  right: 30px;
  top: 0;
  margin-bottom: 16px;
}

@media (min-width: 992px) {
  .booking-appointment .row-top-step .btn-common {
    position: absolute;
    margin-bottom: 0;
  }
}

@media (max-width: 575px) {
  .booking-appointment .row-top-step .btn-common {
    right: 15px;
  }
}

.booking-appointment .row-top-step .btn-common .pos {
  padding: 10px 30px;
}

@media (min-width: 992px) {
  .booking-appointment .row-top-step .btn-common .pos {
    padding: 15px 40px;
  }
}

.booking-appointment .book-step-next {
  display: none;
}

.booking-appointment .book-step-next.show {
  display: inline-flex;
}

.booking-appointment .btn-final-step {
  opacity: .7;
  pointer-events: none;
}

.booking-appointment .btn-final-step.clickable {
  opacity: 1;
  pointer-events: auto;
}

.booking-appointment .btn-final-step.show {
  min-width: 280px;
  display: inline-flex;
}

@media (min-width: 576px) {
  .booking-appointment .btn-final-step.show {
    min-width: 160px;
  }
}

.booking-appointment .btn-transparent {
  min-width: 280px;
  margin-bottom: 8px;
}

@media (min-width: 576px) {
  .booking-appointment .btn-transparent {
    min-width: 160px;
    margin-right: 8px;
    margin-bottom: 0px;
  }
}

.booking-appointment .btn-black {
  min-width: 250px;
  padding: 5px 22px;
}

.booking-appointment .btn-black.book-step-next {
  opacity: .35;
  pointer-events: none;
}

.booking-appointment .btn-black.bookable {
  opacity: 1;
  pointer-events: auto;
}

.booking-appointment .dot-holder {
  margin-bottom: 40px;
}

.booking-appointment .dot-holder .dot {
  width: 20px;
  height: 6px;
  border-radius: 7.5px;
  border: 1px solid #000;
  display: inline-block;
  margin-right: 8px;
  margin-left: 8px;
}

.booking-appointment .dot-holder .dot.active {
  background: #000;
}

.booking-appointment .wrapper-effect {
  position: relative;
  transition: all .3s ease;
}

.booking-appointment .wrap-avt {
  padding: 50px;
  margin-bottom: 30px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  display: none;
}

@media (min-width: 992px) {
  .booking-appointment .wrap-avt {
    display: block;
  }
}

.booking-appointment .wrap-avt .img-drop {
  border-radius: 50%;
  background: #e8e6de;
}

.booking-appointment .wrap-avt .img-drop:before, .booking-appointment .wrap-avt .img-drop:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  border-radius: 50%;
  z-index: 2;
}

.booking-appointment .wrap-avt .img-drop:before {
  width: calc(100% + 50px);
  height: calc(100% + 50px);
  border: 1px solid rgba(216, 216, 216, 0.4);
}

.booking-appointment .wrap-avt .img-drop:after {
  width: calc(100% + 100px);
  height: calc(100% + 100px);
  border: 1px solid rgba(98, 189, 137, 0.8);
}

.booking-appointment .wrap-avt .img-drop img {
  border-radius: 50%;
  object-fit: contain;
  object-position: 100% 100%;
}

.booking-appointment .wrapper-intro {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 992px) {
  .booking-appointment .wrapper-intro {
    max-width: 650px;
    margin-right: 0;
    margin-left: 0;
  }
}

.booking-appointment .wrapper-intro .title-page {
  text-align: center;
}

@media (min-width: 992px) {
  .booking-appointment .wrapper-intro .title-page {
    text-align: left;
  }
}

.booking-appointment .row-intro {
  transition: all .7s ease;
  transform: translateX(-100vw);
}

.booking-appointment .row-intro.active {
  transform: translateX(0);
}

@media (min-width: 992px) {
  .booking-appointment .row-intro .row {
    margin-left: -20px;
    margin-right: -20px;
  }
  .booking-appointment .row-intro .row > .col,
  .booking-appointment .row-intro .row > [class*="col"] {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 1200px) {
  .booking-appointment .row-intro .row {
    margin-left: -40px;
    margin-right: -40px;
  }
  .booking-appointment .row-intro .row > .col,
  .booking-appointment .row-intro .row > [class*="col"] {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.booking-appointment .row-intro .title-page {
  margin-bottom: 40px;
}

.booking-appointment .row-intro .abbr-pos {
  color: rgba(118, 118, 118, 0.51);
  font-family: "Circular Std";
  font-weight: 700;
  margin-bottom: 24px;
}

.booking-appointment .row-intro .short-des {
  margin-bottom: 32px;
}

.booking-appointment .row-intro .short-des p {
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .booking-appointment .row-intro .degree-info {
    columns: 2;
  }
}

.booking-appointment .row-intro .degree-info p {
  margin-bottom: 12px;
}

.booking-appointment .btn-common {
  display: inline-block;
  max-width: 300px;
  margin-top: 20px;
}

@media (min-width: 576px) {
  .booking-appointment .btn-common {
    margin-top: 40px;
  }
}

.booking-appointment .btn-common .pos {
  padding: 20px 40px;
}

.booking-appointment .practitioners-slider .owl-stage {
  padding-left: 0 !important;
}

.booking-appointment .practitioners-slider .owl-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.booking-appointment .practitioners-slider .owl-dots .owl-dot {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  width: 20px;
  height: 6px;
  border-radius: 7.5px;
  border: 1px solid #000;
  margin: 0 7.5px;
  transition: all .3s ease;
}

.booking-appointment .practitioners-slider .owl-dots .owl-dot.active {
  background: #000;
}

.booking-appointment .practitioners-slider .practitioner-item {
  text-align: center;
  display: flex;
  align-items: center;
  padding: 20px;
}

@media (min-width: 992px) {
  .booking-appointment .practitioners-slider .practitioner-item {
    display: block;
    padding: 0;
    align-items: flex-start;
  }
}

@media (min-width: 992px) {
  .booking-appointment .practitioners-slider .practitioner-item:hover .img-drop {
    opacity: 1;
  }
  .booking-appointment .practitioners-slider .practitioner-item:hover h5 {
    opacity: 1;
  }
}

.booking-appointment .practitioners-slider .practitioner-item.active .img-drop {
  opacity: 1;
}

.booking-appointment .practitioners-slider .practitioner-item.active .img-drop:before, .booking-appointment .practitioners-slider .practitioner-item.active .img-drop:after {
  opacity: 1;
}

.booking-appointment .practitioners-slider .practitioner-item.active h5 {
  opacity: 1;
}

.booking-appointment .practitioners-slider .practitioner-item .practitioner-text {
  text-align: left;
}

@media (min-width: 992px) {
  .booking-appointment .practitioners-slider .practitioner-item .practitioner-text {
    text-align: center;
  }
}

.booking-appointment .practitioners-slider .practitioner-item h5 {
  font-size: 22px;
  font-family: "Circular Std";
  font-weight: 700;
  opacity: .35;
  transition: all .45s ease;
  margin-bottom: 8px;
  letter-spacing: -.26px;
}

@media (min-width: 992px) {
  .booking-appointment .practitioners-slider .practitioner-item h5 {
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 18px;
  }
}

.booking-appointment .practitioners-slider .practitioner-item .desktop-name {
  display: none;
}

@media (min-width: 992px) {
  .booking-appointment .practitioners-slider .practitioner-item .desktop-name {
    display: block;
  }
}

.booking-appointment .practitioners-slider .practitioner-item .mobile-name {
  display: block;
}

@media (min-width: 992px) {
  .booking-appointment .practitioners-slider .practitioner-item .mobile-name {
    display: none;
  }
}

.booking-appointment .practitioners-slider .practitioner-item .abbr-pos {
  display: block;
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .booking-appointment .practitioners-slider .practitioner-item .abbr-pos {
    display: none;
  }
}

.booking-appointment .practitioners-slider .img-drop {
  height: 87px;
  min-width: 87px;
  opacity: .35;
  margin-bottom: 8px;
  transition: all .45s ease;
  border-radius: 50%;
  margin-right: 32px;
}

@media (min-width: 992px) {
  .booking-appointment .practitioners-slider .img-drop {
    overflow: hidden;
    padding-top: 100%;
    height: auto;
    min-width: auto;
    margin-right: 0;
  }
}

.booking-appointment .practitioners-slider .img-drop:before, .booking-appointment .practitioners-slider .img-drop:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  border-radius: 50%;
  z-index: 2;
  transition: all .3s ease;
  opacity: 0;
}

.booking-appointment .practitioners-slider .img-drop:before {
  width: calc(100% + 16px);
  height: calc(100% + 16px);
  border: 1px solid rgba(216, 216, 216, 0.4);
}

.booking-appointment .practitioners-slider .img-drop:after {
  width: calc(100% + 32px);
  height: calc(100% + 32px);
  border: 1px solid rgba(98, 189, 137, 0.8);
}

.booking-appointment .practitioners-slider .img-drop img {
  border-radius: 50%;
}

.booking-appointment .wrap-slider {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 992px) {
  .booking-appointment .wrap-slider {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    margin-left: 0;
  }
}

.booking-appointment .row-step-1 {
  transform: translateX(100vw);
  transition: all .7s ease;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@media (max-width: 767px) {
  .booking-appointment .row-step-1 {
    padding-right: 0;
  }
}

.booking-appointment .row-step-1.active {
  transform: translateX(0);
}

@media (min-width: 992px) {
  .booking-appointment .owl-profile {
    margin-bottom: 60px;
  }
}

.booking-appointment .owl-profile .owl-item {
  padding: 0 0 10px;
}

.booking-appointment .owl-profile.owl-about-profile {
  margin-bottom: 30px;
}

.booking-appointment .owl-profile .title-section,
.booking-appointment .owl-profile .abbr-pos {
  display: none;
}

@media (min-width: 992px) {
  .booking-appointment .owl-profile .title-section,
  .booking-appointment .owl-profile .abbr-pos {
    display: block;
  }
}

.booking-appointment .owl-profile .short-des {
  font-size: 18px;
}

.booking-appointment .wrapper-stepper {
  position: relative;
}

.booking-appointment .wrapper-stepper .default {
  opacity: 1 !important;
  pointer-events: auto !important;
}

.selection-practitioner-list {
  display: flex;
  overflow-x: auto;
}

@media (min-width: 768px) {
  .selection-practitioner-list {
    flex-wrap: wrap;
  }
}

.selection-practitioner {
  margin-right: 30px;
  margin-bottom: 10px;
  min-width: 72px;
}

@media (min-width: 768px) {
  .selection-practitioner {
    width: 92px;
    width: 100px;
  }
}

.selection-practitioner:not(.active) {
  opacity: 0.3 !important;
}

.selection-practitioner:not(.active):hover {
  opacity: 1 !important;
}

.selection-practitioner.active .img-drop:before, .selection-practitioner.active .img-drop:after {
  opacity: 1;
  visibility: visible;
}

.selection-practitioner .img-drop {
  padding-top: 100%;
  margin-bottom: 8px;
  border-radius: 50%;
}

.selection-practitioner .img-drop:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 30px;
  height: 50px;
  background: transparent url("../img/icon-check.svg") center/contain no-repeat;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease;
}

.selection-practitioner .img-drop:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background: #62bd89;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease;
}

.selection-practitioner .img-drop img {
  border-radius: 50%;
}

.selection-practitioner h5 {
  font-family: "Circular Std";
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
}

@media (min-width: 576px) {
  .selection-practitioner h5 {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .consult-wrapper-append {
    display: flex;
    overflow: auto;
  }
}

.consult-wrapper {
  display: block;
  text-align: center;
  margin-right: 10px;
}

@media (min-width: 640px) {
  .consult-wrapper {
    display: inline-block;
    vertical-align: top;
  }
}

.consult-wrapper label {
  border: 1px solid #62bd89;
  width: 154px;
  height: 175px;
  cursor: pointer;
  background-color: #F4F2ED;
  position: relative;
  transition: all .3s ease;
}

@media (min-width: 768px) {
  .consult-wrapper label {
    margin-bottom: 10px;
    width: 145px;
  }
}

@media (min-width: 1400px) {
  .consult-wrapper label {
    width: 170px;
  }
}

.consult-wrapper label:before {
  content: '';
  position: absolute;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  bottom: 16px;
  width: 40px;
  height: 40px;
  border: 1px solid #62bd89;
  border-radius: 50%;
  background: #fff url("../img/icon-check-green-small.svg") center/15px auto no-repeat;
  transition: all .3s ease;
}

.consult-wrapper input {
  display: none;
}

.consult-wrapper input:checked + label {
  background: #fff;
}

.consult-wrapper input:checked + label:before {
  background: #62bd89 url("../img/icon-check.svg") center/25px auto no-repeat;
  bottom: 45px;
  width: 45px;
  height: 45px;
}

@media (min-width: 992px) {
  .consult-wrapper input:checked + label:before {
    width: 60px;
    height: 60px;
  }
}

.consult-wrapper input:checked + label .title-consult {
  justify-content: space-between;
  height: 100% !important;
  transition: all .3s ease;
}

.consult-wrapper .title-consult {
  font-size: 16px;
  font-family: "Circular Std";
  font-weight: 700;
  line-height: 1.27778em;
  padding: 10px 15px;
  height: 98px;
  background: #fff;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (min-width: 992px) {
  .consult-wrapper .title-consult {
    font-size: 18px;
  }
}

@media (min-width: 1400px) {
  .consult-wrapper .title-consult {
    padding: 10px 30px;
  }
}

.consult-wrapper .price-consult {
  margin-top: 15px;
  font-size: 42px;
  line-height: 1.39583em;
  font-weight: 700;
  color: #62bd89;
  position: relative;
  display: none;
}

.consult-wrapper .price-consult .currency {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  font-size: 24px;
  font-weight: 500;
  line-height: 1.6em;
}

.consult-wrapper .time-consult {
  font-size: 16px;
  line-height: 1.38889em;
  font-weight: 500;
  width: 100%;
  margin-top: 10px;
  text-transform: none;
  display: block;
}

.method-wrapper-append {
  width: 100%;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .method-wrapper-append {
    display: flex;
    overflow: auto;
  }
}

.step-number {
  font-family: "Circular Std";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 1.25em;
  color: #62bd89;
  margin-bottom: 10px;
}

.stepper {
  position: relative;
}

.stepper > .row {
  padding-top: 20px;
  padding-bottom: 20px;
  transition: all .3s ease;
  position: relative;
}

@media (max-width: 767px) {
  .stepper > .row:before {
    content: '';
    position: absolute;
    background: #dedede;
    height: calc(100% - 50px);
    width: 1px;
    left: 29px;
    top: 62px;
  }
}

.stepper > .row:last-child:before {
  height: calc(100% - 85px);
}

@media (min-width: 768px) {
  .stepper > .row {
    opacity: .35;
    pointer-events: none;
  }
}

@media (min-width: 992px) {
  .stepper > .row {
    margin-left: -30px;
    margin-right: -30px;
  }
  .stepper > .row > .col,
  .stepper > .row > [class*="col"] {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 767px) {
  .stepper > .row > .col-xl-9 {
    padding-left: 50px;
  }
}

@media (min-width: 768px) {
  .stepper > .row > .col-xl-9 {
    flex: 0 0 calc(100% - 270px);
    max-width: calc(100% - 270px);
  }
}

@media (min-width: 992px) {
  .stepper > .row > .col-xl-9 {
    flex: 0 0 calc(100% - 380px);
    max-width: calc(100% - 380px);
  }
}

@media (min-width: 1200px) {
  .stepper > .row > .col-xl-9 {
    flex: 0 0 calc(75% - 275px);
    max-width: calc(75% - 275px);
  }
}

.stepper > .row.current-focus {
  pointer-events: auto;
  opacity: 1;
}

.stepper > .row.current-focus .step-title:before {
  background-color: #62bd89;
}

@media (max-width: 767px) {
  .stepper > .row.selected .text-mobile {
    display: block;
  }
}

.stepper > .row.selected .step-title:before {
  content: '';
  background: #62bd89 url("../img/icon-check.svg") center center/15px auto no-repeat;
}

.stepper > .row.selected .edit-back-mobile {
  display: inline-block;
}

.stepper > .row .step-number {
  display: none;
}

@media (min-width: 768px) {
  .stepper > .row .step-number {
    display: block;
  }
}

.stepper .step-title {
  font-family: "Circular Std";
  font-weight: 300;
  margin-bottom: 15px;
  font-size: 20px;
  padding-left: 37px;
  position: relative;
  line-height: 26px;
  display: inline-block;
}

@media (min-width: 768px) {
  .stepper .step-title {
    margin-bottom: 30px;
    display: block;
  }
}

.stepper .step-title:before {
  content: attr(data-step);
  width: 28px;
  height: 28px;
  font-size: 14px;
  color: #fff;
  border-radius: 50%;
  background: #dedede;
  position: absolute;
  left: 0;
  top: -2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .stepper .step-title:before {
    display: none;
  }
}

@media (min-width: 576px) {
  .stepper .step-title {
    font-size: 22px;
    line-height: 1.16667em;
  }
}

@media (min-width: 768px) {
  .stepper .step-title {
    font-size: 24px;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .stepper .step-title {
    max-width: 285px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1200px) {
  .stepper .step-title {
    margin-bottom: 0;
    font-size: 28px;
  }
}

.step-slide-2 {
  display: none;
}

.method-wrapper {
  margin-right: 6px;
  display: block;
  text-align: center;
}

.method-wrapper:last-child {
  margin-right: 0;
}

@media (min-width: 576px) {
  .method-wrapper {
    text-align: left;
    display: inline-block;
  }
}

.method-wrapper input {
  display: none;
}

.method-wrapper input:checked + label {
  background: #62bd89;
  color: #fff;
}

.method-wrapper label {
  border-radius: 6px;
  border: 1px solid #62bd89;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 16px;
  font-family: "Circular Std";
  min-width: 153px;
  margin-bottom: 8px;
  text-align: center;
  cursor: pointer;
  transition: all .3s ease;
  padding: 14px 10px;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .method-wrapper label {
    padding: 19px 15px;
    min-width: 140px;
  }
}

@media (min-width: 992px) {
  .method-wrapper label {
    min-width: 153px;
  }
}

@media (min-width: 1400px) {
  .method-wrapper label {
    min-width: 170px;
  }
}

.wrap-slide-time-picker {
  padding: 20px 10px;
  display: none;
}

@media (min-width: 576px) {
  .wrap-slide-time-picker {
    padding: 30px 25px 15px;
  }
}

@media (min-width: 1200px) {
  .wrap-slide-time-picker {
    padding: 30px 55px 15px;
  }
}

.wrap-slide-time-picker .title-time-picker {
  font-size: 20px;
  line-height: 1.18182em;
  font-family: "Circular Std";
  font-weight: 700;
  max-width: 215px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 50px;
  position: relative;
}

.wrap-slide-time-picker .title-time-picker i {
  font-size: 10px;
  font-weight: 300;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 45px;
  height: 25px;
  border-radius: 3px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.wrap-slide-time-picker .title-time-picker i:hover {
  background: #eee;
}

.time-picker-box {
  display: flex;
  flex-wrap: wrap;
}

.owl-time-picker .owl-prev,
.owl-time-picker .owl-next {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  width: 30px;
  height: 30px;
  background: transparent url("../img/next-btn.svg") center/contain no-repeat !important;
}

.owl-time-picker .owl-prev {
  right: -60px;
  transform: translate3d(0, -50%, 0) scaleX(-1);
}

.owl-time-picker .owl-next {
  right: -100px;
}

.item-time-picker {
  flex: 0 0 33.33%;
  max-width: 33%;
  margin-bottom: 15px;
  text-align: center;
}

.item-time-picker input {
  display: none;
}

.item-time-picker input:checked + label {
  background: #62bd89;
  color: #fff;
}

.item-time-picker label {
  border-radius: 6px;
  border: 1px solid #62bd89;
  font-size: 18px;
  font-family: "Circular Std";
  text-align: center;
  font-weight: 700;
  margin-bottom: 0;
  padding: 10px 10px;
  transition: all .3s ease;
  cursor: pointer;
  width: 80px;
}

@media (min-width: 576px) {
  .item-time-picker label {
    width: 90px;
  }
}

@media (min-width: 1280px) {
  .item-time-picker label {
    width: 120px;
    padding: 10px 20px;
  }
}

.item-time-picker label.disabled {
  background: #e1e1e1;
  pointer-events: none;
  border-color: #e1e1e1;
}

.modal-alert .close {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  background: none !important;
}

.modal-alert .modal-body h6 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5em;
}

.modal-alert .modal-body .btn-transparent,
.modal-alert .modal-body .btn-black {
  margin: 20px 10px 5px;
  height: 50px;
  min-width: 200px;
  font-size: 14px;
}

.method-content-info {
  width: 100%;
  line-height: 24px;
}

.method-content-info p {
  margin-bottom: 10px;
}

.method-face-info {
  display: none;
}

.method-extra-info {
  font-style: italic;
  color: #C8C9CC;
}

.date-time-flex {
  display: flex;
  flex-wrap: wrap;
}

.datetime-wrapper {
  margin-bottom: 8px;
  margin-right: 10px;
}

.holder-box-info {
  position: absolute;
  right: 30px;
  z-index: 2;
  top: 150px;
  display: none;
  height: calc(100% - 250px);
  width: 250px;
}

@media (max-width: 767px) {
  .holder-box-info {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .holder-box-info {
    height: calc(100% - 210px);
    top: 120px;
    width: 350px;
  }
}

.holder-box-info .box-info-pick {
  padding: 20px;
  width: 250px;
  box-shadow: 0 8px 28px rgba(171, 171, 171, 0.15);
}

@media (min-width: 992px) {
  .holder-box-info .box-info-pick {
    width: 350px;
    padding: 30px;
  }
}

.holder-box-info .box-info-pick .edit-back {
  color: #62bd89;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  transition: all .3s ease;
  display: none;
}

.holder-box-info .box-info-pick .edit-back:hover {
  opacity: .7;
}

.holder-box-info .box-info-pick .item-inner {
  position: relative;
}

.holder-box-info .box-info-pick .item-inner:not(:last-child) {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #D8D8D8;
}

.holder-box-info .box-info-pick .item-inner .step-number {
  margin-bottom: 8px;
}

.holder-box-info .box-info-pick .item-inner .title {
  line-height: 24px;
}

.holder-box-info .box-info-pick .item-inner .text {
  font-family: "Circular Std";
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.container-stepper {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 767px) {
  .book-step-prev {
    display: none;
  }
}

@media (max-width: 767px) {
  .mobile-toggle-wrapper {
    display: none;
  }
}

.text-mobile {
  display: none;
}

.selected-checker {
  font-size: 14px;
  font-weight: 700;
  font-family: "Circular Std";
  color: #000;
  text-transform: uppercase;
}

.edit-back-mobile {
  display: none;
  vertical-align: text-top;
  margin-left: 12px;
}

@media (max-width: 575px) {
  .page-template-template-appointment .title-page {
    font-size: 32px;
    letter-spacing: -.32px;
    line-height: 1em;
  }
}

.calendar-custom {
  width: 100%;
}

@media (max-width: 767px) {
  .calendar-custom {
    padding-right: 20px;
  }
}

.calendar-custom .wrap-calendar {
  border: 1px solid #62bd89;
  height: 100%;
  width: 100%;
  position: relative;
  margin-right: 10px;
  max-width: 300px;
}

@media (min-width: 576px) {
  .calendar-custom .wrap-calendar {
    max-width: 420px;
  }
}

@media (min-width: 1200px) {
  .calendar-custom .wrap-calendar {
    max-width: 480px;
  }
}

@media (min-width: 1400px) {
  .calendar-custom .wrap-calendar {
    max-width: 530px;
  }
}

.calendar-custom .wrap-calendar .perfect-datetimepicker {
  width: 100% !important;
  border: 0;
  margin: 0;
  background-color: #FFF;
  padding: 20px 10px;
}

@media (min-width: 576px) {
  .calendar-custom .wrap-calendar .perfect-datetimepicker {
    padding: 40px 35px;
  }
}

.calendar-custom .wrap-calendar .perfect-datetimepicker table {
  width: 100% !important;
  padding: 0;
  border-spacing: 0;
  top: 40px !important;
  left: 40px !important;
}

.calendar-custom .wrap-calendar .perfect-datetimepicker table thead td.hover {
  background-color: transparent;
  border: 0;
}

.calendar-custom .wrap-calendar .perfect-datetimepicker table tbody td.selected {
  border: 1px solid #62bd89;
  background-color: #62bd89;
  border-radius: 50%;
}

.calendar-custom .wrap-calendar .perfect-datetimepicker table tbody td.selected.today {
  color: #FFF;
}

.calendar-custom .wrap-calendar .perfect-datetimepicker table tbody td.today {
  color: #62bd89;
}

.calendar-custom .wrap-calendar .perfect-datetimepicker table td {
  height: 47px;
  width: 47px;
}

.calendar-custom .wrap-calendar .perfect-datetimepicker table:nth-child(1) .mainhead .prevm {
  text-align: right;
}

.calendar-custom .wrap-calendar .perfect-datetimepicker table:nth-child(1) .mainhead .title,
.calendar-custom .wrap-calendar .perfect-datetimepicker table:nth-child(1) .mainhead .prevm,
.calendar-custom .wrap-calendar .perfect-datetimepicker table:nth-child(1) .mainhead .nextm {
  padding-top: 10px;
  padding-bottom: 10px;
}

.calendar-custom .wrap-calendar .perfect-datetimepicker table:nth-child(1) .mainhead .nextm {
  text-align: left;
}

.calendar-custom .wrap-calendar .perfect-datetimepicker table:nth-child(2) {
  width: calc(100% - 80px) !important;
}

.calendar-custom .wrap-calendar .perfect-datetimepicker .buttonpane {
  border-top: 0;
}

.calendar-custom .wrap-calendar .perfect-datetimepicker .buttonpane .clear {
  display: none;
}

.calendar-custom .box-chosen-date {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .calendar-custom .box-chosen-date {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

.calendar-custom .box-chosen-date:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-left: 10px solid #62bd89;
  border-bottom: 5px solid transparent;
}

.calendar-custom .box-chosen-date .date-chosen {
  display: block;
  font-weight: 700;
  font-size: 16px;
}

.mask-data {
  opacity: .2;
}

.details-appointment .box-info-pick {
  width: 100%;
  max-width: 300px;
}

#calendar .datepicker {
  width: 100%;
  padding: 20px 10px;
}

@media (min-width: 576px) {
  #calendar .datepicker {
    padding: 20px 25px 20px;
  }
}

@media (min-width: 1200px) {
  #calendar .datepicker {
    padding: 20px 55px 20px;
  }
}

#calendar .datepicker .datepicker-days table {
  border-collapse: separate;
  border-spacing: 0 7px;
}

#calendar .datepicker .datepicker-days table tfoot {
  display: none;
}

#calendar .datepicker table {
  width: 100%;
}

#calendar .datepicker table th {
  padding-top: 5px;
  padding-bottom: 5px;
}

#calendar .datepicker table th.datepicker-switch {
  font-size: 20px;
  line-height: 1.18182em;
  font-family: "Circular Std";
  font-weight: 700;
}

#calendar .datepicker table th.dow {
  padding-top: 20px;
  color: #C8C9CC;
  font-size: 14px;
  line-height: 20px;
  font-family: "Circular Std";
  padding-bottom: 8px;
  border-bottom: 1px solid #C8C9CC;
  border-radius: 0;
}

@media (min-width: 576px) {
  #calendar .datepicker table th.dow {
    font-size: 16px;
    padding-top: 36px;
  }
}

#calendar .datepicker table th.prev i {
  font-weight: 300;
  font-size: 10px;
}

#calendar .datepicker table th.next i {
  font-weight: 300;
  font-size: 10px;
}

#calendar .datepicker table td {
  height: 40px;
  padding: 10px;
  border-radius: 50%;
  font-size: 13px;
  line-height: 18px;
  font-family: Arial, Helvetica, sans-serif;
}

@media (min-width: 576px) {
  #calendar .datepicker table td {
    height: 53px;
    width: 36px;
    font-size: 15px;
  }
}

@media (min-width: 1400px) {
  #calendar .datepicker table td {
    height: 60px;
  }
}

#calendar .datepicker table td.active {
  background: #62bd89;
}

#calendar .datepicker table td.disabled, #calendar .datepicker table td.disabled:hover {
  color: #E1E1E1;
}

#calendar .datepicker table td span.active {
  background: #62bd89;
}

#calendar .datepicker table td.disable-date, #calendar .datepicker table td.disable-date:hover {
  color: #fff;
  border-radius: 50%;
  background: #ccc;
}

.logo-subscription img {
  max-width: 145px;
  height: auto;
}

.modal-form .modal-dialog {
  margin: 1rem 1.3rem;
  min-height: calc(100% - 5.5rem);
}

@media (max-width: 575px) {
  .modal-form .modal-dialog {
    min-height: calc(100% - 1.8rem);
  }
}

@media (min-width: 576px) {
  .modal-form .modal-dialog {
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
  }
}

.modal-form .modal-content {
  border-radius: 0;
}

.modal-form .wrap-info-form {
  max-width: 365px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 25px;
}

.modal-form .logo-subscription {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 15px;
}

.modal-form .logo-subscription:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 38px;
  height: 1px;
  background: #231F20;
}

.modal-form .close-form {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  position: absolute;
  top: 0;
  right: 0;
  background: #ebebeb;
  border-radius: 50%;
  transform: translate(50%, -50%);
  outline: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

@media (min-width: 576px) {
  .modal-form .close-form {
    width: 50px;
    height: 50px;
  }
}

.modal-form .close {
  width: 11px;
  height: 11px;
}

.modal-form .modal-content {
  padding: 17px;
  background: #fff;
}

@media (min-width: 576px) {
  .modal-form .modal-content {
    padding: 36px;
  }
}

.modal-form .modal-body {
  background: url("../img/vege-bg.png") 7px 10px/auto 60% no-repeat;
  background-color: #F4F2ED;
  padding: 15px;
}

@media (min-width: 576px) {
  .modal-form .modal-body {
    padding: 30px 15px;
    background: url("../img/vege-bg.png") 7px 10px/auto 60% no-repeat, url("../img/ginger-bg.png") right 30px bottom 35%/auto 25% no-repeat;
    background-color: #F4F2ED;
  }
}

.modal-form .title-form {
  font-family: "Circular Std";
  font-weight: 900;
  font-size: 16px;
  line-height: 1.81818em;
  letter-spacing: 2.5px;
  color: #231F20;
  text-transform: uppercase;
  margin-bottom: 10px;
}

@media (min-width: 576px) {
  .modal-form .title-form {
    font-size: 22px;
    letter-spacing: 5px;
  }
}

.modal-form .percent-off {
  font-weight: 900;
  font-size: 70px;
  line-height: 100%;
  letter-spacing: 26px;
  color: #62BD89;
  position: relative;
  display: inline-block;
  z-index: 2;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-family: "Circular Std";
}

@media (min-width: 576px) {
  .modal-form .percent-off {
    font-size: 90px;
  }
}

.modal-form .percent-off .text-shadow {
  color: #000;
  position: absolute;
  z-index: -1;
  top: 5px;
  left: 6px;
}

@media (min-width: 576px) {
  .modal-form .percent-off .text-shadow {
    top: 6px;
    left: 9px;
  }
}

.modal-form .required-condition {
  max-width: 230px;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  line-height: 1.75em;
  letter-spacing: -0.195652px;
  color: #231F20;
  font-family: "Circular Std";
  margin-bottom: 15px;
}

.modal-form .bottomline-text {
  position: relative;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.195652px;
  color: #9B9B9B;
  padding-top: 10px;
}

.modal-form .bottomline-text:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 207px;
  height: 1px;
  background: #D8D8D8;
}

.modal-form .wrap-form-popup {
  position: relative;
  max-width: 365px;
  margin-left: auto;
  margin-right: auto;
}

.modal-form .wrap-form-popup .validation_error {
  display: none;
}

.modal-form .wrap-form-popup .gfield_description.validation_message {
  padding-top: 10px;
  font-size: 12px;
  font-weight: 500;
}

.modal-form .wrap-form-popup .gform_ajax_spinner {
  position: absolute;
  top: -3px;
  right: -20px;
}

.modal-form .wrap-form-popup .gform_confirmation_message {
  text-align: center;
  font-weight: 500;
}

.modal-form .wrap-form-popup .gform_body .gfield_label {
  display: none;
}

.modal-form .wrap-form-popup .gform_body .gfield {
  margin-bottom: 25px;
}

.modal-form .wrap-form-popup .gform_body .no-mg {
  margin-bottom: 0;
}

.modal-form .wrap-form-popup .gform_body .ginput_container_email {
  position: relative;
}

.modal-form .wrap-form-popup .gform_body .ginput_container_email:after {
  content: '';
  position: absolute;
  width: calc(100% + 6px);
  height: 100%;
  top: 6px;
  left: 0;
  z-index: 0;
  border-right: 2px solid #62BD89;
  border-bottom: 2px solid #62BD89;
}

.modal-form .wrap-form-popup .gform_body input[type=text],
.modal-form .wrap-form-popup .gform_body input[type=email] {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  font-family: "Avenir Next";
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.195652px;
  padding: 15px 105px 15px 15px;
  width: 100%;
  color: #000;
  background: transparent;
  border: 1px solid #000000;
  position: relative;
  z-index: 2;
}

.modal-form .wrap-form-popup .gform_body input[type=text]:-webkit-autofill, .modal-form .wrap-form-popup .gform_body input[type=text]:-webkit-autofill:hover, .modal-form .wrap-form-popup .gform_body input[type=text]:-webkit-autofill:focus, .modal-form .wrap-form-popup .gform_body input[type=text]:-webkit-autofill:active,
.modal-form .wrap-form-popup .gform_body input[type=email]:-webkit-autofill,
.modal-form .wrap-form-popup .gform_body input[type=email]:-webkit-autofill:hover,
.modal-form .wrap-form-popup .gform_body input[type=email]:-webkit-autofill:focus,
.modal-form .wrap-form-popup .gform_body input[type=email]:-webkit-autofill:active {
  transition: background 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000;
}

@media (min-width: 576px) {
  .modal-form .wrap-form-popup .gform_body input[type=text],
  .modal-form .wrap-form-popup .gform_body input[type=email] {
    padding: 18px 116px 18px 20px;
  }
}

.modal-form .wrap-form-popup .gform_body input[type=text]::-webkit-input-placeholder,
.modal-form .wrap-form-popup .gform_body input[type=email]::-webkit-input-placeholder {
  color: #9B9B9B;
}

.modal-form .wrap-form-popup .gform_body input[type=text]::-moz-placeholder,
.modal-form .wrap-form-popup .gform_body input[type=email]::-moz-placeholder {
  color: #9B9B9B;
}

.modal-form .wrap-form-popup .gform_body input[type=text]:-ms-input-placeholder,
.modal-form .wrap-form-popup .gform_body input[type=email]:-ms-input-placeholder {
  color: #9B9B9B;
}

.modal-form .wrap-form-popup .gform_body input[type=text]:-moz-placeholder,
.modal-form .wrap-form-popup .gform_body input[type=email]:-moz-placeholder {
  color: #9B9B9B;
}

.modal-form .wrap-form-popup .gform_body .gfield_checkbox > li input {
  display: none;
}

.modal-form .wrap-form-popup .gform_body .gfield_checkbox > li input:checked + label:after {
  opacity: 1;
}

.modal-form .wrap-form-popup .gform_body .gfield_checkbox > li label {
  position: relative;
  padding-left: 41px;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 0;
  cursor: pointer;
}

.modal-form .wrap-form-popup .gform_body .gfield_checkbox > li label:before {
  content: '';
  position: absolute;
  left: 0;
  top: -3px;
  width: 26px;
  height: 26px;
  border: 1px solid #000;
}

.modal-form .wrap-form-popup .gform_body .gfield_checkbox > li label:after {
  content: '';
  position: absolute;
  left: 3px;
  top: 0px;
  width: 20px;
  height: 20px;
  background: #000;
  opacity: 0;
  transition: opacity .3s ease;
}

.modal-form .wrap-form-popup .gform_body .gfield_checkbox > li a {
  font-family: "Circular Std";
  font-weight: 700;
  display: inline-block;
  position: relative;
}

.modal-form .wrap-form-popup .gform_body .gfield_checkbox > li a:after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #62BD89;
}

.modal-form .wrap-form-popup .gform_footer {
  position: absolute;
  z-index: 5;
  top: 15px;
  right: 15px;
}

@media (min-width: 576px) {
  .modal-form .wrap-form-popup .gform_footer {
    right: 20px;
    top: 18px;
  }
}

.modal-form .wrap-form-popup .gform_footer input[type=submit] {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  background: transparent;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: "Circular Std";
  color: #000000;
  cursor: pointer;
  transition: all .3s ease;
}

.modal-form .wrap-form-popup .gform_footer input[type=submit]:hover {
  opacity: .7;
}

.modal-form .gform_not_found {
  text-align: center;
}

@media (min-width: 992px) {
  .banner-testimonials {
    padding-top: 109px;
    padding-bottom: 109px;
  }
}

.banner-testimonials .img-drop {
  width: 100%;
  height: 37.06667%;
  max-height: 100% !important;
  min-height: 100% !important;
}

@media (min-width: 992px) {
  .banner-testimonials .img-drop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100% !important;
  }
}

.banner-testimonials .text-cover.big {
  position: relative;
  top: 0;
  left: 0;
  transform: none;
  background-color: #fff;
  padding-bottom: 40px;
}

@media (max-width: 991px) {
  .banner-testimonials .text-cover.big {
    padding-right: 16px;
    padding-left: 16px;
  }
}

@media (max-width: 767px) {
  .banner-testimonials .text-cover.big .title-page {
    font-size: 32px;
    margin-bottom: 16px;
  }
}

@media (min-width: 1200px) {
  .testimonials-content .container {
    max-width: 1190px;
  }
}

.testimonials-content .tab-navi-learn {
  margin-bottom: 100px;
  position: relative;
  z-index: 1;
}

@media (max-width: 991px) {
  .testimonials-content .tab-navi-learn {
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 40px;
  }
}

.testimonials-content .tab-navi-learn li {
  flex-basis: 100%;
}

@media (min-width: 768px) {
  .testimonials-content .tab-navi-learn li {
    flex-basis: auto;
  }
}

.testimonials-content .tab-navi-learn li:not(:last-child) {
  margin-right: 0;
}

@media (min-width: 768px) {
  .testimonials-content .tab-navi-learn li:not(:last-child) {
    margin-right: 35px;
  }
}

.testimonials-content .tab-navi-learn li a {
  text-align: left;
  display: inline-block;
}

@media (min-width: 768px) {
  .testimonials-content .tab-navi-learn li a {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .testimonials-content .tab-navi-learn.nav-sticky-onscroll.stick {
    position: fixed;
    left: 0;
    right: 0;
    background: #f4f2ed;
    top: 0;
    padding-top: 60px;
    z-index: 2;
  }
  .testimonials-content .tab-navi-learn.nav-sticky-onscroll.stick + .tab-content {
    padding-top: 189px;
  }
}

.testimonials-content .wrapper-testimonials .tab-content-testimonials {
  min-height: 600px;
  padding-bottom: 60px;
}

@media (min-width: 992px) {
  .testimonials-content .wrapper-testimonials .tab-content-testimonials {
    padding-bottom: 220px;
  }
}

.testimonials-content .wrapper-testimonials .tab-content-testimonials.show-all {
  display: block;
  opacity: 1;
}

.testimonials-content .wrapper-testimonials .wrap-img {
  position: relative;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 991px) {
  .testimonials-content .wrapper-testimonials .wrap-img {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 70px;
    padding-top: 60px;
  }
}

.testimonials-content .wrapper-testimonials .wrap-img .img-drop {
  padding-top: 75.26132%;
  position: relative;
  z-index: 1;
}

.testimonials-content .wrapper-testimonials .wrap-img .img-trans {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.testimonials-content .wrapper-testimonials .wrap-img .img-trans img {
  width: 100%;
}

.testimonials-content .wrapper-testimonials .wrap-title-desktop {
  margin-bottom: 24px;
}

@media (max-width: 991px) {
  .testimonials-content .wrapper-testimonials .wrap-title-desktop {
    display: none;
  }
}

.testimonials-content .wrapper-testimonials .wrap-title-mobile {
  text-align: center;
}

@media (min-width: 992px) {
  .testimonials-content .wrapper-testimonials .wrap-title-mobile {
    display: none;
  }
}

.testimonials-content .wrapper-testimonials .title-page {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .testimonials-content .wrapper-testimonials .title-page {
    font-size: 48px;
  }
}

.testimonials-content .wrapper-testimonials .text-green {
  font-family: "Circular Std";
  font-size: 16px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #62bd89;
}

@media (min-width: 768px) {
  .testimonials-content .wrapper-testimonials .text-green {
    font-size: 22px;
  }
}

.testimonials-content .wrapper-testimonials .wrap-blockquote {
  background-color: #fdfcf9;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.testimonials-content .wrapper-testimonials .wrap-blockquote .wrap-content-blockquote::-webkit-scrollbar {
  width: 4px;
}

.testimonials-content .wrapper-testimonials .wrap-blockquote .wrap-content-blockquote::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.testimonials-content .wrapper-testimonials .wrap-blockquote .wrap-content-blockquote::-webkit-scrollbar-thumb {
  background-color: #62bd89;
  outline: 1px solid slategrey;
}

.testimonials-content .wrapper-testimonials .wrap-blockquote .wrap-content-blockquote.has-scroll {
  max-height: 285px;
  overflow-y: scroll;
  overflow-x: auto;
  padding-right: 20px;
}

@media (min-width: 768px) {
  .testimonials-content .wrapper-testimonials .wrap-blockquote {
    padding: 30px 30px 25px;
    margin-bottom: 20px;
  }
}

.testimonials-content .wrapper-testimonials .wrap-blockquote blockquote {
  padding-left: 36px;
  background: url("../img/icon-blockquote.svg") no-repeat left top 5px/26px auto;
  line-height: 20px;
  letter-spacing: -0.2px;
  margin-bottom: 10px;
  min-height: 26px;
}

@media (min-width: 768px) {
  .testimonials-content .wrapper-testimonials .wrap-blockquote blockquote {
    padding-left: 50px;
    font-size: 18px;
    line-height: 28px;
    background-size: 30px auto;
  }
}

.testimonials-content .wrapper-testimonials .wrap-blockquote .signature {
  font-weight: 700;
  line-height: 20px;
  padding-left: 36px;
}

.testimonials-content .wrapper-testimonials .wrap-blockquote .signature.has-padding {
  padding-top: 15px;
}

@media (min-width: 768px) {
  .testimonials-content .wrapper-testimonials .wrap-blockquote .signature {
    line-height: 28px;
    font-size: 18px;
    padding-left: 50px;
  }
}

.testimonials-content .wrapper-testimonials .wrap-blockquote .signature span {
  font-weight: 400;
  color: #979797;
}

.testimonials-content .wrapper-testimonials .btn-common {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .testimonials-content .wrapper-testimonials .btn-common {
    margin-top: 40px;
  }
}

@media (min-width: 992px) {
  .testimonials-content #testimonials-1 .wrap-img {
    padding-top: 135px;
  }
}

.testimonials-content #testimonials-1 .img-trans {
  top: -28%;
  left: -45%;
}

@media (min-width: 992px) {
  .testimonials-content #testimonials-1 .img-trans {
    top: -10%;
  }
}

@media (min-width: 768px) {
  .testimonials-content #testimonials-2 .wrap-img {
    margin-top: 45px;
  }
}

.testimonials-content #testimonials-2 .img-trans {
  top: -10%;
  left: -7%;
  max-width: 50%;
}

@media (min-width: 768px) {
  .testimonials-content #testimonials-2 .img-trans {
    top: -40%;
    max-width: 100%;
  }
}

.testimonials-content #testimonials-3 {
  padding-bottom: 80px;
}

@media (min-width: 992px) {
  .testimonials-content #testimonials-3 {
    padding-bottom: 160px;
  }
}

@media (min-width: 992px) {
  .testimonials-content #testimonials-3 .wrap-img {
    margin-top: 175px;
  }
}

.testimonials-content #testimonials-3 .img-trans {
  top: 5%;
  left: -10%;
  max-width: 50%;
}

@media (min-width: 992px) {
  .testimonials-content #testimonials-3 .img-trans {
    top: -60%;
    left: -28%;
    max-width: 100%;
  }
}

.testimonials-content #testimonials-4 .wrap-img {
  margin-top: 0;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 16px;
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .testimonials-content #testimonials-4 .wrap-img {
    max-width: 450px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: 0;
  }
}

.testimonials-content #testimonials-4 .wrap-title-desktop {
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .testimonials-content #testimonials-4 .wrap-title-desktop {
    margin-bottom: 50px;
  }
}

.testimonials-content #testimonials-4 .img-drop {
  padding-top: 114.33892%;
}

.testimonials-content #testimonials-5 {
  padding-bottom: 0;
}

.testimonials-content #testimonials-5 .bg-img {
  position: relative;
  background-position: top left;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.testimonials-content #testimonials-5 .wrapper-custom {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 992px) {
  .testimonials-content #testimonials-5 .wrapper-custom {
    flex-wrap: nowrap;
  }
}

.testimonials-content #testimonials-5 .wrapper-custom .bg-img {
  width: 100%;
  height: 57.86667%;
}

@media (min-width: 992px) {
  .testimonials-content #testimonials-5 .wrapper-custom .bg-img {
    flex: 0 0 42%;
    max-width: 42%;
    height: auto;
  }
}

.testimonials-content #testimonials-5 .wrapper-custom .bg-img img {
  width: 100%;
}

.testimonials-content #testimonials-5 .wrapper-custom .wrap-content {
  padding: 20px 16px;
  flex-basis: 100%;
}

@media (min-width: 768px) {
  .testimonials-content #testimonials-5 .wrapper-custom .wrap-content {
    padding: 50px 0;
  }
}

@media (min-width: 992px) {
  .testimonials-content #testimonials-5 .wrapper-custom .wrap-content {
    flex: 0 0 58%;
    max-width: 58%;
    padding: 100px 0;
  }
}

.testimonials-content #testimonials-5 .wrapper-custom .wrap-content .wrap-inner {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

.testimonials-content #testimonials-5 .wrapper-custom .wrap-content .title-page {
  margin-bottom: 16px;
  text-align: center;
}

@media (min-width: 768px) {
  .testimonials-content #testimonials-5 .wrapper-custom .wrap-content .title-page {
    margin-bottom: 50px;
    text-align: left;
  }
}

@media (max-width: 991px) {
  .desktop-show {
    display: none;
  }
}

@media (min-width: 992px) {
  .mobile-show {
    display: none;
  }
}

.testimonials-submit {
  background-color: #74c597;
  padding-top: 30px;
  padding-bottom: 60px;
  background-repeat: no-repeat, no-repeat;
  background-position: top -50px left -100px, right -50px bottom 0;
  background-size: 100% auto, 50% auto;
}

@media (min-width: 768px) {
  .testimonials-submit {
    padding-top: 100px;
    padding-bottom: 100px;
    background-size: 80% auto, auto;
    background-position: top -15% left -60%, right 0% bottom -2%;
  }
}

@media (min-width: 992px) {
  .testimonials-submit {
    background-size: auto, auto;
    background-position: top left -4%, right 15% bottom 10%;
  }
}

.testimonials-submit .title-page {
  margin-bottom: 16px;
  text-align: center;
}

@media (min-width: 768px) {
  .testimonials-submit .title-page {
    text-align: left;
    margin-bottom: 40px;
  }
}

.testimonials-submit .wrap-form-submit ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.testimonials-submit .wrap-form-submit ul .form-row {
  margin-bottom: 16px;
  flex-basis: 100%;
}

@media (min-width: 768px) {
  .testimonials-submit .wrap-form-submit ul .form-row {
    margin-bottom: 30px;
  }
}

@media (min-width: 992px) {
  .testimonials-submit .wrap-form-submit ul .form-row.col-half {
    flex-basis: calc(50% - 30px);
  }
}

.testimonials-submit .wrap-form-submit ul .form-row.col-full {
  flex-basis: 100%;
}

.testimonials-submit .wrap-form-submit label {
  font-family: "Circular Std";
  font-weight: 700;
  line-height: 23px;
  display: block;
}

@media (min-width: 768px) {
  .testimonials-submit .wrap-form-submit label {
    font-size: 18px;
  }
}

.testimonials-submit .wrap-form-submit input:not([type="submit"]),
.testimonials-submit .wrap-form-submit textarea {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  background-color: #74c597;
  width: 100%;
  border: 1px solid #000;
  padding: 13px 16px;
}

.testimonials-submit .wrap-form-submit input:not([type="submit"]):-webkit-autofill, .testimonials-submit .wrap-form-submit input:not([type="submit"]):-webkit-autofill:hover, .testimonials-submit .wrap-form-submit input:not([type="submit"]):-webkit-autofill:focus, .testimonials-submit .wrap-form-submit input:not([type="submit"]):-webkit-autofill:active,
.testimonials-submit .wrap-form-submit textarea:-webkit-autofill,
.testimonials-submit .wrap-form-submit textarea:-webkit-autofill:hover,
.testimonials-submit .wrap-form-submit textarea:-webkit-autofill:focus,
.testimonials-submit .wrap-form-submit textarea:-webkit-autofill:active {
  transition: background 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000;
}

@media (min-width: 768px) {
  .testimonials-submit .wrap-form-submit input:not([type="submit"]),
  .testimonials-submit .wrap-form-submit textarea {
    padding: 23px 25px;
  }
}

.testimonials-submit .wrap-form-submit input[type='submit'] {
  -webkit-appearance: none;
  border: 0;
  border-radius: 0;
  outline: 0;
  max-width: 270px;
  width: 100%;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #000;
  color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
  transition: opacity 0.5s ease;
}

.testimonials-submit .wrap-form-submit input[type='submit']:hover {
  opacity: 0.7;
}

.testimonials-submit .validation_error {
  display: none;
}

.testimonials-submit .gform_footer {
  position: relative;
}

.testimonials-submit .gform_footer .gform_ajax_spinner {
  position: absolute;
  top: 15px;
  width: 30px;
  height: 30px;
  right: auto;
}

/* #payment.woocommerce-checkout-payment {
  .wc_payment_methods {
    .title-product {
      width: 100%;
    }
    .wc_payment_method {
      width: calc(100% / 3);
    }
  }
} */
.ven-general-template {
  font-size: 18px;
  line-height: 1.55556;
  padding: 40px 60px;
  background-color: #f4f2ed;
}

@media (min-width: 1200px) {
  .ven-general-template {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.ven-general-template h2,
.ven-general-template h3,
.ven-general-template h4,
.ven-general-template h5 {
  font-weight: bold;
  margin-bottom: 30px;
}

.ven-general-template h5 {
  font-size: 26px;
  font-weight: normal;
}

.ven-general-template a {
  color: #4a90e2;
}

.ven-general-template a:hover {
  text-decoration: underline;
}

.ven-general-template hr {
  margin: 70px 0;
}

.ven-general-template ol {
  counter-reset: my-awesome-counter;
}

.ven-general-template ol:not(:first-child) {
  margin-top: 80px;
}

.ven-general-template ol > li {
  counter-increment: my-awesome-counter;
  padding-left: 45px;
  position: relative;
}

.ven-general-template ol > li::before {
  content: counter(my-awesome-counter) ".";
  font-family: "Circular Std";
  font-size: 28px;
  font-weight: bold;
  line-height: 1;
  position: absolute;
  top: -3px;
  left: 0;
}

.ven-general-template ol > li:not(:last-child) {
  margin-bottom: 40px;
}

.ven-general-template ul {
  list-style: initial;
  list-style-position: outside;
  margin-left: 1em;
}

.ven-general-template ul li:not(:last-child) {
  margin-bottom: 16px;
}

.ven-general-template li > ul {
  margin-top: 20px;
}

.ven-competition-intro > .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 992px) {
  .ven-competition-intro > .container-fluid > .row {
    flex-direction: row-reverse;
  }
}

.ven-competition-intro__content {
  font-family: "Circular Std";
  padding: 40px 15px 50px;
}

@media (min-width: 1200px) {
  .ven-competition-intro__content {
    padding: 85px 0;
    background: url(../img/competition-background.png) no-repeat 50% 0;
    background-size: 1067px auto;
    min-height: 910px;
  }
}

.ven-competition-intro__wrapper {
  max-width: 664px;
  margin-left: auto;
  margin-right: auto;
}

.ven-competition-intro__title {
  font-weight: 900;
  line-height: 1.1;
  letter-spacing: -0.521739px;
  margin-bottom: 30px;
}

@media (min-width: 1200px) {
  .ven-competition-intro__title {
    font-size: 78px;
    max-width: 570px;
  }
}

.ven-competition-intro__price {
  margin-bottom: 47px;
}

.ven-competition-intro__price-label {
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.21429em;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.ven-competition-intro__price-value {
  font-size: 80px;
  font-weight: 900;
  line-height: 1;
  letter-spacing: 0.26em;
  color: #62bd89;
  text-shadow: 6px 7px #231f20;
}

@media (min-width: 1200px) {
  .ven-competition-intro__price-value {
    font-size: 100px;
  }
}

.ven-competition-intro__description {
  font-size: 22px;
  line-height: 1.27273;
  letter-spacing: -0.195652px;
}

.ven-competition-intro__description p {
  margin-bottom: 26px;
}

.ven-competition-intro__more {
  margin-top: 40px;
}

.ven-competition-intro__more .btn-common {
  font-size: 16px;
  max-width: 100%;
}

@media (min-width: 1200px) {
  .ven-competition-intro__more .btn-common {
    display: inline-block;
    max-width: none;
  }
}

.ven-competition-intro__more .btn-common .pos {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 1200px) {
  .ven-competition-intro__more .btn-common .pos {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.ven-competition-intro__image {
  height: 50vh;
}

@media (min-width: 992px) {
  .ven-competition-intro__image {
    height: 100%;
  }
}

.ven-competition-intro__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ven-competition-form {
  background-color: #f4f2ed;
  padding: 50px 0;
}

@media (min-width: 1200px) {
  .ven-competition-form {
    padding: 110px 0;
  }
}

.ven-competition-form__title {
  font-family: "Circular Std";
  font-weight: 900;
  font-size: 48px;
  line-height: 1.04167;
  letter-spacing: -0.521739px;
}

.ven-competition-form .btn-white.login-fb {
  font-family: "Circular Std";
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 1.875px;
  margin-bottom: 0;
  text-transform: uppercase;
  height: 70px;
}

.ven-competition-form .btn-white.login-fb i {
  margin-right: 15px;
}

.ven-scroll-section {
  position: relative;
}

.ven-scroll-target {
  position: absolute;
  top: -60px;
  left: 0;
}

.aw-referrals-share-page {
  max-width: 470px;
}

.aw-referrals-share-page .woocommerce-error {
  list-style: none;
  padding: 0;
  margin-bottom: 15px;
}

.aw-referrals-share-page .woocommerce-error li {
  list-style: none;
  padding: 0;
}

.aw-referrals-share-page .woocommerce-error li:before {
  display: none;
}

.aw-referrals-share-page .aw-referrals-share-buttons {
  margin-bottom: 40px;
}

.aw-referrals-share-page .aw-referrals-share-buttons a {
  width: 100%;
  padding: .84em 0 0.44em;
  margin: 0 0 10px;
}

.aw-referrals-share-page .aw-referrals-share-buttons a:before {
  top: -3px;
}

.aw-referrals-share-page .referral-code {
  margin-bottom: 40px;
  text-align: center;
  font-size: 16px;
}

.ven-form-row .aw-referrals-share-or {
  width: 100%;
  color: white;
  text-transform: inherit;
}

.ven-form-row .aw-email-referral-form {
  width: 100%;
}

.ven-form-row .aw-email-referral-form .ven-form-group.form-row {
  margin-bottom: 25px;
}

.ven-form-row .aw-email-referral-form .ven-form-group .ven-form-control {
  border-color: white;
}

.ven-form-row .aw-email-referral-form .email-button {
  margin-top: 40px;
}

.ven-referrals-share-container {
  max-width: 470px;
  margin-left: auto;
  margin-right: auto;
}

.ven-referrals-share-container .referral-title {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 106.9%;
  text-align: center;
  margin-bottom: 20px;
}

.ven-referrals-share-container .referral-description {
  text-align: center;
  margin-bottom: 25px;
  line-height: 17px;
}

.ven-referrals-share-container .aw-referrals-share-buttons,
.ven-referrals-share-container .ven-aw-referrals-share-buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.ven-referrals-share-container .aw-referrals-share-buttons:before, .ven-referrals-share-container .aw-referrals-share-buttons:after,
.ven-referrals-share-container .ven-aw-referrals-share-buttons:before,
.ven-referrals-share-container .ven-aw-referrals-share-buttons:after {
  display: none;
}

.ven-referrals-share-container .aw-referrals-share-buttons a,
.ven-referrals-share-container .ven-aw-referrals-share-buttons a {
  width: calc(50% - 5px);
  min-width: auto !important;
  height: 44px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px !important;
  line-height: 14px !important;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  padding: 15px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@media (max-width: 991px) {
  .ven-referrals-share-container .aw-referrals-share-buttons a,
  .ven-referrals-share-container .ven-aw-referrals-share-buttons a {
    width: 100%;
  }
}

.ven-referrals-share-container .aw-email-referral-form .ven-form-group .ven-form-control {
  border-color: rgba(0, 0, 0, 0.35);
}

.ven-referrals-share-container .email-button .ven-btn {
  height: 52px;
}

.ven-referrals-share-container .aw-referrals-share-page .woocommerce-message {
  margin-bottom: 15px;
}

.aw-referrals-share-widget {
  margin-top: 80px;
}

.aw-referrals-share-widget .referrals-share-widget-title {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.aw-referrals-share-widget .referrals-share-widget-description {
  padding-bottom: 10px;
}

.aw-referrals-share-widget .aw-referrals-share-buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
}

.aw-referrals-share-widget .aw-referrals-share-buttons:before, .aw-referrals-share-widget .aw-referrals-share-buttons:after {
  display: none;
}

.aw-referrals-share-widget .aw-referrals-share-buttons a {
  width: 150px;
  min-width: auto;
  height: 35px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  padding: 10px 5px;
  margin: 0;
}

.aw-referrals-share-widget .aw-referrals-share-buttons a:before {
  display: none;
}

@media (max-width: 991px) {
  .aw-referrals-share-widget .aw-referrals-share-buttons a {
    width: 100%;
    margin-bottom: 10px;
  }
  .aw-referrals-share-widget .aw-referrals-share-buttons a:last-child {
    margin-bottom: 0;
  }
}

.referral-message {
  margin-bottom: 40px;
}

.db-h {
  font-family: "Circular Std";
  font-weight: 700;
  line-height: 1.04167;
}

@media (min-width: 992px) {
  .db-h {
    font-size: 48px;
  }
}

.db-h1 {
  font-size: 48px;
  margin-bottom: 48px;
}

@media (min-width: 992px) {
  .db-h1 {
    font-size: 55px;
  }
}

.db-h2 {
  font-size: 32px;
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .db-h2 {
    font-size: 44px;
  }
}

.db-btn {
  display: inline-block;
  position: relative;
  padding: 20px;
  font-family: "Circular Std";
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.125em;
  text-transform: uppercase;
  text-align: center;
  min-width: 220px;
  background-color: transparent;
  border-radius: 0;
  border: thin solid #000;
  margin: 0 6px 6px 0;
  outline: none;
  cursor: pointer;
}

.db-btn::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-color: #605e98;
  border-style: solid;
  border-width: 0 2px 2px 0;
  position: absolute;
  right: -6px;
  bottom: -6px;
}

.db-btn:focus {
  outline: none;
}

.db-btn svg[role="img"] {
  width: 10px;
  height: 10px;
  vertical-align: 0.0625em;
  margin-right: 10px;
}

.db-btn--sm {
  padding-top: 9px;
  padding-bottom: 9px;
  min-width: unset;
}

.db-btn--link {
  border: none;
  padding: 0;
  margin: 0;
  min-width: unset;
  text-decoration: underline;
}

.db-btn--link::after {
  display: none;
}

.db-btn-print {
  display: inline-block;
  padding: 10px 5px;
  font-family: "Circular Std";
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.125em;
  text-transform: uppercase;
  text-align: center;
  background-color: #000;
  color: #fff;
  border-radius: 0;
  width: 100%;
}

.db-btn-print:hover {
  color: #fff;
}

.db-btn-print img {
  position: relative;
  top: -4px;
  margin-right: 10px;
}

@media (min-width: 992px) {
  .db-btn-print {
    width: auto;
    min-width: 220px;
    font-size: 16px;
    height: 60px;
    line-height: 45px;
  }
}

.db-btn-reset {
  display: inline-block;
  padding: 10px 5px;
  font-family: "Circular Std";
  font-size: 14px;
  line-height: 1.25;
  letter-spacing: 0.125em;
  text-transform: uppercase;
  text-align: center;
  background-color: transparent;
  border: thin solid #000;
  border-radius: 0;
  width: 100%;
}

@media (min-width: 992px) {
  .db-btn-reset {
    width: auto;
    min-width: 220px;
    font-size: 16px;
    padding: 20px;
  }
}

.db-section {
  padding: 50px 0;
}

@media (min-width: 992px) {
  .db-section {
    padding: 100px 0;
  }
}

.db-section__legend {
  font-family: "Circular Std";
  font-size: 18px;
  letter-spacing: 0.10556em;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 25px;
}

@media (min-width: 992px) {
  .db-section__legend {
    margin-bottom: 50px;
  }
}

.db-section__header {
  align-items: flex-end;
  margin-bottom: 25px;
}

@media (min-width: 992px) {
  .db-section__header {
    margin-bottom: 50px;
  }
}

.db-section__header .db-h:last-child {
  margin-bottom: 0 !important;
}

@media (max-width: 991px) {
  .db-section__header > div:not(:last-child) {
    margin-bottom: 15px;
  }
}

@media (min-width: 1400px) {
  .db-overview {
    margin-left: -50px;
    margin-right: -50px;
  }
  .db-overview > .col,
  .db-overview > [class*="col"] {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 991px) {
  .db-overview > div:not(:last-child) {
    margin-bottom: 50px;
  }
}

.db-purchase {
  display: block;
  padding: 25px 15px;
  background-color: #fff;
  font-family: "Circular Std";
  font-size: 14px;
}

.db-purchase__title {
  font-size: 20px;
  margin-bottom: 15px;
}

.db-purchase__counter {
  margin-bottom: 15px;
}

.db-purchase__date {
  font-size: 20px;
  opacity: 0.25;
  margin-bottom: 20px;
}

.db-purchase__price {
  font-size: 20px;
  margin-bottom: 5px;
}

.db-purchase__status {
  margin-bottom: 30px;
}

.db-purchase__more {
  text-transform: uppercase;
  letter-spacing: 0.125em;
}

.db-purchases {
  margin-left: -8px;
  margin-right: -8px;
  margin-bottom: -16px;
}

.db-purchases > .col,
.db-purchases > [class*="col"] {
  padding-left: 8px;
  padding-right: 8px;
}

.db-purchases__item {
  margin-bottom: 16px;
}

.db-reward__image {
  text-align: center;
  margin-bottom: 24px;
}

.db-reward__image img {
  max-width: 141px;
}

.db-reward__progress {
  position: relative;
  height: 10px;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 10px;
}

.db-reward__progress::before {
  content: "";
  display: block;
  height: 100%;
  border: thin solid #000;
  border-radius: 20px;
}

.db-reward__progress > span {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #62bd89;
}

.db-reward__message {
  background-color: #fff;
  border-radius: 5px;
  padding: 7px 15px;
  font-family: "Circular Std";
  font-weight: 500;
  color: #62bd89;
  letter-spacing: 0.01562em;
}

.db-program {
  display: block;
}

.db-program__header {
  margin-bottom: 15px;
}

@media (min-width: 992px) {
  .db-program__header {
    margin-bottom: 23px;
  }
}

.db-program__image {
  padding-bottom: 100%;
}

.db-program__title {
  font-size: 24px;
  margin-bottom: 12px;
}

@media (min-width: 992px) {
  .db-program__title {
    font-size: 32px;
  }
}

@media (min-width: 992px) {
  .db-program__description {
    font-size: 18px;
  }
}

.db-program.is-disabled {
  pointer-events: none;
  user-select: none;
  filter: grayscale(1);
  opacity: 0.5;
}

.db-program-blank {
  padding-bottom: 100%;
  border: thin solid #000;
  position: relative;
  cursor: pointer;
}

.db-program-blank::before {
  content: "";
  display: block;
  border-color: #605e98;
  border-style: solid;
  border-width: 0 2px 2px 0;
  position: absolute;
  right: -6px;
  bottom: -6px;
  width: 100%;
  height: 100%;
}

.db-program-blank__inner a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  padding: 15px;
  text-align: center;
}

.db-program-blank__inner a h3 {
  font-family: "Circular Std";
  font-size: 16px;
  line-height: 1.26667;
  text-transform: uppercase;
  letter-spacing: 0.12667em;
  margin-top: 12%;
  margin-bottom: 0;
  max-width: 182px;
}

@media (min-width: 992px) {
  .db-program-blank__inner a h3 {
    font-size: 20px;
  }
}

.db-program-blank__inner a svg {
  width: 46.28975%;
}

.db-programs {
  margin-bottom: -30px;
  margin-left: -8px;
  margin-right: -8px;
}

.db-programs > .col,
.db-programs > [class*="col"] {
  padding-left: 8px;
  padding-right: 8px;
}

@media (min-width: 1400px) {
  .db-programs {
    margin-left: -30px;
    margin-right: -30px;
    margin-bottom: -60px;
  }
  .db-programs > .col,
  .db-programs > [class*="col"] {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.db-programs__item {
  margin-bottom: 30px;
}

@media (min-width: 1400px) {
  .db-programs__item {
    margin-bottom: 60px;
  }
}

.db-recipe {
  display: block;
  min-height: 100%;
  background-color: #fff;
}

.db-recipe__image {
  padding-bottom: 100%;
}

.db-recipe__body {
  padding: 15px;
  font-family: "Circular Std";
}

@media (min-width: 992px) {
  .db-recipe__body {
    padding: 20px;
  }
}

.db-recipe__title {
  font-size: 18px;
  margin-bottom: 22px;
}

.db-favourite {
  appearance: none;
  width: 32px;
  height: 31px;
  border: none;
  border-radius: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='31' viewBox='0 0 32 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 3.29057L18.8244 12.1448L19.0464 12.8409H19.7771H28.9677L21.5166 18.3552L20.9412 18.781L21.1588 19.463L23.9951 28.3547L16.5949 22.8781L16 22.4378L15.4051 22.8781L8.00492 28.3547L10.8412 19.463L11.0588 18.781L10.4834 18.3552L3.03227 12.8409H12.2229H12.9536L13.1756 12.1448L16 3.29057Z' stroke='%2362BD89' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-repeat: none;
  background-size: 100% 100%;
  cursor: pointer;
}

.db-favourite:checked {
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='31' viewBox='0 0 32 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 0L19.7771 11.8409H32L22.1115 19.1591L25.8885 31L16 23.6819L6.11146 31L9.88854 19.1591L0 11.8409H12.2229L16 0Z' fill='%2362BD89'/%3E%3C/svg%3E%0A");
}

.db-recipes {
  margin-left: -8px;
  margin-right: -8px;
  margin-bottom: -16px;
}

.db-recipes > .col,
.db-recipes > [class*="col"] {
  padding-left: 8px;
  padding-right: 8px;
}

.db-recipes__item {
  margin-bottom: 16px;
}

.db-post {
  display: block;
  position: relative;
}

.db-post::before {
  content: "";
  display: block;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, #000000 100%);
  opacity: 0.4;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  height: 33%;
}

.db-post__image {
  padding-bottom: 139.92248%;
}

.db-post__title {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
  z-index: 2;
  font-size: 16px;
  color: #fff;
}

@media (min-width: 992px) {
  .db-post__title {
    font-size: 18px;
  }
}

.db-posts {
  margin-left: -8px;
  margin-right: -8px;
  margin-bottom: -16px;
}

.db-posts > .col,
.db-posts > [class*="col"] {
  padding-left: 8px;
  padding-right: 8px;
}

.db-posts__item {
  margin-bottom: 16px;
}

.db-top-banner img {
  display: block;
  width: 100%;
}

.db-program-intro {
  padding: 40px 15px 110px;
}

@media (min-width: 1200px) {
  .db-program-intro {
    padding: 40px 60px 110px;
  }
}

.db-program-intro__header {
  margin-bottom: 32px;
}

.db-program-intro__header .row {
  margin-left: -8px;
  margin-right: -8px;
}

.db-program-intro__header .row > .col,
.db-program-intro__header .row > [class*="col"] {
  padding-left: 8px;
  padding-right: 8px;
}

@media (max-width: 767px) {
  .db-program-intro__header .db-btn {
    padding-left: 6px;
    padding-right: 6px;
    font-size: 12px;
  }
}

.db-program-intro__body {
  max-width: 800px;
  margin: 0 auto;
}

.db-program-intro__title {
  text-align: center;
}

@media (min-width: 992px) {
  .db-program-intro__description {
    font-size: 18px;
  }
}

.db-program-intro__description.restriction {
  font-size: 0;
}

.db-program-intro__description.restriction div {
  font-size: 28px;
  margin-top: 0 !important;
}

.db-program-intro__description > ul {
  list-style: disc;
  padding-left: 1.2em;
  margin-bottom: 1.5rem;
}

.db-program-pane {
  padding: 85px 15px;
}

@media (min-width: 992px) {
  .db-program-pane {
    padding: 85px 60px;
  }
}

.db-program-pane__header {
  text-align: center;
  margin-bottom: 45px;
}

.db-program-pane__title {
  margin-bottom: 10px;
}

.db-program-pane__description {
  margin-top: 17px;
}

@media (min-width: 768px) {
  .db-program-pane__description {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .db-program-pane__description {
    font-size: 18px;
  }
}

.db-program-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.db-program-nav li {
  width: 50%;
}

@media (min-width: 768px) {
  .db-program-nav li {
    width: 33.33333%;
  }
}

@media (min-width: 992px) {
  .db-program-nav li {
    width: 16.66667%;
  }
}

.db-program-nav li a {
  display: block;
  padding: 35px 25px 25px;
  text-align: center;
  transition: all 0.3s ease;
}

.db-program-nav li a img {
  height: 76px;
  margin-bottom: 28px;
  transition: all 0.2s ease;
}

.db-program-nav li a h4 {
  font-family: "Circular Std";
  font-size: 16px;
  font-weight: 700;
  line-height: 1.25;
  text-transform: uppercase;
  margin-bottom: 0;
}

.db-program-nav li a:hover {
  background-color: #fff;
  color: #62bd89;
}

.db-program-nav li a:hover img {
  filter: invert(69%) sepia(49%) saturate(357%) hue-rotate(93deg) brightness(88%) contrast(87%);
}

.db-program-status {
  display: inline-block;
  font-family: "Circular Std";
  font-size: 16px;
  line-height: 1.25;
  padding: 3px 20px 5px;
  border-radius: 40px;
  background-color: #fff;
}

.db-program-status svg[role="img"] {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  vertical-align: -0.1875em;
}

.db-program-status--completed {
  background-color: #62bd89;
  color: #fff;
}

.db-program-progress {
  max-width: 244px;
  margin: 0 auto;
  height: 28px;
  background-color: #fff;
  border-radius: 28px;
  position: relative;
}

.db-program-progress__inner {
  display: flex;
  align-items: center;
  padding: 0 25px 2px;
  position: relative;
  z-index: 2;
  height: 100%;
  font-family: "Circular Std";
  font-size: 12px;
  font-weight: 500;
}

.db-program-progress__inner > span:not(:last-child) {
  margin-right: 20px;
}

.db-program-progress__value {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #fdcc61;
  border-radius: 28px;
}

.db-loading {
  text-align: center;
  margin-top: 30px;
}

.db-loading > span {
  display: block;
  margin-top: 15px;
  font-family: "Circular Std";
  line-height: 1.25;
  text-transform: uppercase;
  letter-spacing: 0.125em;
}

.db-navbar {
  padding: 5px 0;
  background-color: #fff;
  font-family: "Circular Std";
  font-weight: 700;
  line-height: 1.27778;
  text-transform: uppercase;
  letter-spacing: 0.125em;
  position: sticky;
  top: calc(60px + var(--offset-top));
  z-index: 5;
}

@media (min-width: 1600px) {
  .db-navbar .container-fluid {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (min-width: 1400px) {
  .db-navbar .container-fluid {
    display: flex;
    align-items: flex-end;
  }
}

.db-navbar__label {
  margin-right: 3%;
  align-self: center;
}

.db-navbar .dropdown {
  display: inline-block;
}

.db-navbar .dropdown button {
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L4.29289 4.29289C4.68342 4.68342 5.31658 4.68342 5.70711 4.29289L9 1' stroke='black' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right 12px center;
  border-radius: 0;
  border: thin solid #000;
  padding: 5px 60px 5px 20px;
  font-family: "Circular Std";
  font-weight: 700;
  line-height: 1.27778;
  text-transform: uppercase;
  letter-spacing: 0.06944em;
  color: #62bd89;
  outline: none;
  margin-bottom: 2px;
}

.db-navbar .dropdown .dropdown-menu {
  min-width: 80px;
  width: 100%;
  border-radius: 0;
  border-color: #000;
  margin: 0;
}

@media (min-width: 992px) {
  .db-navbar .dropdown .dropdown-menu {
    display: block !important;
    opacity: 0;
    visibility: hidden;
  }
}

.db-navbar .dropdown .dropdown-item {
  padding: 8px 20px;
  font-family: "Circular Std";
  font-weight: 700;
  line-height: 1.27778;
  text-transform: uppercase;
  letter-spacing: 0.06944em;
}

.db-navbar .dropdown .dropdown-item:hover {
  background-color: transparent;
  color: #62bd89;
}

@media (min-width: 992px) {
  .db-navbar .dropdown:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
  }
}

.db-navbar-nav {
  flex: 1;
  margin-top: 20px;
  margin-bottom: -7px;
}

@media (min-width: 1400px) {
  .db-navbar-nav {
    margin-top: 0;
  }
}

@media (max-width: 991px) {
  .db-navbar-nav {
    margin-left: -15px;
    margin-right: -15px;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .db-navbar-nav::-webkit-scrollbar {
    display: none;
  }
}

.db-navbar-nav ul {
  display: inline-flex;
}

@media (min-width: 1200px) {
  .db-navbar-nav ul {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (min-width: 1400px) {
  .db-navbar-nav ul {
    justify-content: flex-end;
  }
}

@media (max-width: 991px) {
  .db-navbar-nav ul {
    padding-left: 15px;
    padding-right: 15px;
    white-space: nowrap;
  }
}

.db-navbar-nav ul li a {
  display: block;
  padding: 0 0 10px;
  border-bottom: 2px solid transparent;
}

.db-navbar-nav ul li a.is-active {
  border-color: #62bd89;
}

.db-navbar-nav ul li:not(:last-child) {
  margin-right: 3%;
}

.db-main {
  padding: 50px 0;
  background-color: #f4f2ed;
}

@media (min-width: 992px) {
  .db-main {
    padding: 100px 0;
  }
}

.db-recipes-archive__title {
  text-align: center;
}

.db-recipes-toolbar {
  margin-bottom: 55px;
  margin-left: -8px;
  margin-right: -8px;
}

.db-recipes-toolbar > .col,
.db-recipes-toolbar > [class*="col"] {
  padding-left: 8px;
  padding-right: 8px;
}

@media (max-width: 991px) {
  .db-recipes-toolbar > div:not(:last-child) {
    margin-bottom: 15px;
  }
}

.db-recipes-search {
  display: flex;
  height: 66px;
  border: thin solid #000;
  padding-left: 28px;
}

.db-recipes-search svg {
  align-self: center;
  margin-right: 10px;
}

.db-recipes-search input {
  flex: 1;
  padding: 0 28px 0 0;
  appearance: none;
  background-color: transparent;
  border: none;
  border-radius: 0;
  outline: none;
}

@media (min-width: 992px) {
  .db-recipes-search input {
    font-size: 18px;
  }
}

.db-recipes-search.is-loading {
  background: url(../img/loading-2.svg) no-repeat;
  background-size: 35px 35px;
  background-position: 100% 50%;
  padding-right: 32px;
}

.db-recipes-select {
  appearance: none;
  width: 100%;
  height: 66px;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='16' viewBox='0 0 30 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L14.2929 14.2929C14.6834 14.6834 15.3166 14.6834 15.7071 14.2929L29 1' stroke='black' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right 28px center;
  border: none;
  border-radius: 0;
  border: thin solid #000;
  padding: 0 62px 0 28px;
  outline: none;
}

@media (min-width: 992px) {
  .db-recipes-select {
    font-size: 18px;
  }
}

.db-education__title {
  text-align: center;
}

.db-education__body {
  padding: 15px;
  background-color: #fff;
}

@media (min-width: 992px) {
  .db-education__body {
    padding: 54px;
  }
}

@media (min-width: 1600px) {
  .db-meal > .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.db-meal__header {
  margin-bottom: 50px;
  justify-content: center;
  margin-left: -8px;
  margin-right: -8px;
}

.db-meal__header > .col,
.db-meal__header > [class*="col"] {
  padding-left: 8px;
  padding-right: 8px;
}

.db-meal__title {
  margin-bottom: 0;
  text-align: center;
}

.db-table-meal-wrapper {
  position: relative;
}

.db-table-meal-wrapper.inview .db-table-meal-arrow::before {
  position: fixed;
  top: 190px;
}

.db-table-meal-wrapper.inview .db-table-meal-arrow--prev::before {
  left: 15px;
}

@media (min-width: 992px) {
  .db-table-meal-wrapper.inview .db-table-meal-arrow--prev::before {
    left: 75px;
  }
}

.db-table-meal-wrapper.inview .db-table-meal-arrow--next::before {
  right: 15px;
}

@media (min-width: 992px) {
  .db-table-meal-wrapper.inview .db-table-meal-arrow--next::before {
    right: 75px;
  }
}

.db-table-meal-wrapper.inbottom .db-table-meal-arrow::before {
  top: auto;
  bottom: 158px;
}

.db-table-meal-arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 120px;
  z-index: 3;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.db-table-meal-arrow::before {
  content: "";
  display: block;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../img/arrow-c-next.svg);
  position: absolute;
  top: 130px;
}

.db-table-meal-arrow--prev {
  background: linear-gradient(270deg, rgba(244, 242, 237, 0) 0%, #f4f2ed 80%);
  left: 0;
}

.db-table-meal-arrow--prev::before {
  left: 0;
  transform: scale(-1);
}

.db-table-meal-arrow--next {
  background: linear-gradient(90deg, rgba(244, 242, 237, 0) 0%, #f4f2ed 80%);
  right: 0;
}

.db-table-meal-arrow--next::before {
  right: 0;
}

.db-table-meal-arrow.disabled {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.db-table-meal-content {
  overflow-x: auto;
  scroll-behavior: smooth;
}

@media (max-width: 991px) {
  .db-table-meal-content {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .db-table-meal-content::-webkit-scrollbar {
    display: none;
  }
}

.db-table-meal {
  width: 100%;
  table-layout: fixed;
  min-width: 1500px;
}

.db-table-meal th,
.db-table-meal td {
  padding: 8px;
  width: 25%;
}

.db-table-meal th:first-child,
.db-table-meal td:first-child {
  width: 5em;
}

@media (min-width: 1200px) {
  .db-table-meal th,
  .db-table-meal td {
    padding: 15px;
  }
}

.db-table-meal th {
  font-size: 18px;
  font-weight: 700;
  vertical-align: middle;
  text-align: center;
}

.db-table-meal th a {
  font-size: 15px;
  font-weight: 400;
  text-decoration: underline;
}

.db-table-meal thead {
  text-align: center;
}

.db-table-meal tbody tr {
  transition: all 0.2s ease-out;
}

.db-table-meal tbody tr:hover {
  box-shadow: inset 0 0 0 1px #000000;
}

.db-table-meal tbody tr.is-active th,
.db-table-meal tbody tr.is-active .db-meal-card {
  opacity: 0.5;
}

.db-table-meal tbody tr.is-active .reset-day {
  display: inline-block;
}

.db-table-meal.hide-cat .cat {
  display: none;
}

.db-meal-card {
  display: block;
}

.db-meal-card__image {
  padding-bottom: 80.24691%;
}

.db-meal-card__body {
  background-color: #fff;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  min-height: 55px;
}

.db-btn-check {
  cursor: pointer;
  text-align: center;
}

.db-btn-check input {
  display: none;
}

.db-btn-check input + span {
  display: inline-block;
  position: relative;
  padding: 9px;
  font-family: "Circular Std";
  font-size: 14px;
  line-height: 1.25;
  letter-spacing: 0.125em;
  text-transform: uppercase;
  text-align: center;
  background-color: transparent;
  border-radius: 0;
  border: thin solid #000;
  min-width: 156px;
  margin: 0 6px 6px 0;
}

.db-btn-check input + span::before {
  display: block;
  content: attr(data-text);
  background-repeat: no-repeat;
  background-position: 0 50%;
  transition: all 0.2s ease-out;
}

.db-btn-check input + span::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-color: #605e98;
  border-style: solid;
  border-width: 0 2px 2px 0;
  position: absolute;
  right: -6px;
  bottom: -6px;
}

.db-btn-check input:checked + span {
  background-color: #62bd89;
}

.db-btn-check input:checked + span::before {
  content: attr(data-text-checked);
  background-image: url("data:image/svg+xml,%3Csvg width='17' height='11' viewBox='0 0 17 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5L5.79289 9.79289C6.18342 10.1834 6.81658 10.1834 7.20711 9.79289L16.5 0.5' stroke='black' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  padding-left: 20px;
}

.db-btn-check .reset-day {
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 1.875px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #000;
  margin-top: 15px;
  display: none;
}

.db-shopping {
  background-color: #f4f2ed;
  background-image: url(../img/bg-vegetables.png);
  background-repeat: no-repeat;
  background-size: 100vw auto;
  background-position: 50% 15vw;
}

@media (min-width: 992px) {
  .db-shopping {
    background-size: 48.90625vw auto;
  }
}

.db-shopping__title {
  text-align: center;
}

.db-shopping__more {
  margin-top: 90px;
  text-align: center;
}

.db-form-check {
  margin-bottom: 0;
  display: flex;
  user-select: none;
}

@media (min-width: 992px) {
  .db-form-check {
    font-size: 18px;
  }
}

.db-form-check input {
  appearance: none;
  width: 1.55556em;
  height: 1.55556em;
  border: thin solid #000;
  border-radius: 50%;
  margin-right: 10px;
}

.db-form-check input:checked {
  background-color: #62bd89;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5.50024L4.79289 9.29314C5.18342 9.68366 5.81658 9.68366 6.20711 9.29314L14.5 1.00024' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.db-form-check span {
  flex: 1;
  padding-top: 0.11111em;
  font-weight: 500;
}

.db-check-list li {
  margin-bottom: 20px;
}

.db-check-list:last-child li:last-child {
  margin-bottom: 0;
}

.db-shopping-list .card-header {
  border-top: 2px solid #e8e6de;
  display: flex;
  align-items: center;
  padding: 17px 0;
  font-family: "Circular Std";
  line-height: 1.25;
  cursor: pointer;
}

.db-shopping-list .card-header::before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 11px 0 11px 12px;
  border-color: transparent transparent transparent #000;
  margin-right: 24px;
  align-self: flex-start;
}

@media (min-width: 992px) {
  .db-shopping-list .card-header::before {
    margin-top: 0.35em;
  }
}

.db-shopping-list .card-header h3 {
  flex: 1;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .db-shopping-list .card-header h3 {
    font-size: 32px;
  }
}

.db-shopping-list .card-header p {
  color: #979797;
  margin-bottom: 0;
  margin-left: 15px;
}

.db-shopping-list .card-header[aria-expanded="true"] {
  border-color: #62bd89;
}

.db-shopping-list .card-header[aria-expanded="true"]::before {
  border-color: transparent transparent transparent #62bd89;
  transform: rotate(90deg) translate(0, -5px);
}

.db-shopping-list .card-header[aria-expanded="true"] h3 {
  color: #62bd89;
}

.db-shopping-list .card-body {
  padding: 15px 0 30px;
}

.db-shopping-list .card-body h3 {
  font-family: "Circular Std";
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 23px;
}

@media (min-width: 992px) {
  .db-shopping-list .card-body h3 {
    font-size: 24px;
  }
}

.db-shopping-list .card-body h3:not(:first-child) {
  margin-top: 48px;
}

@media (min-width: 992px) {
  .db-shopping-list .card-body .db-check-list {
    column-gap: 30px;
    column-count: 2;
  }
}

.db-check-wrapper {
  overflow: hidden;
}

@media (min-width: 1200px) {
  .db-check-wrapper .container-fluid {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.db-check-wrapper__title {
  text-align: center;
}

.db-check-wrapper__more {
  margin-top: 90px;
  text-align: center;
}

@media (min-width: 1200px) {
  .db-check-card {
    display: flex;
    flex-wrap: wrap;
  }
}

.db-check-card__header {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: thin solid #000;
  font-family: "Circular Std";
  font-size: 24px;
  font-weight: 700;
}

@media (min-width: 1200px) {
  .db-check-card__header {
    width: 130px;
    padding-bottom: 0;
    padding-right: 30px;
    margin-bottom: 0;
    margin-right: 80px;
    border-bottom: none;
    border-right: thin solid #000;
    display: flex;
    align-items: center;
  }
}

.db-check-card__body {
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
  margin-right: -30px;
}

@media (min-width: 992px) {
  .db-check-card__body {
    flex: 1;
    padding: 60px 0 0;
    border-bottom: thin solid #000;
  }
}

@media (max-width: 575px) {
  .db-check-card__body {
    height: auto !important;
  }
}

.db-check-card__body section {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 60px;
}

@media (min-width: 576px) {
  .db-check-card__body section {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .db-check-card__body section {
    width: 33.33333%;
  }
}

@media (min-width: 1600px) {
  .db-check-card__body section {
    width: 20%;
  }
}

@media (max-width: 575px) {
  .db-check-card__body section {
    position: static !important;
  }
}

.db-check-card__body section section {
  width: 100%;
  padding: 0;
}

.db-check-card__body section h3 {
  font-family: "Circular Std";
  font-size: 18px;
  font-weight: 700;
  line-height: 1.27778;
  letter-spacing: 0.125em;
  color: #62bd89;
  text-transform: uppercase;
  margin-bottom: 18px;
}

.db-check-card__body section h3:not(:first-child) {
  margin-top: 45px;
}

.db-check-card__body section p {
  font-size: 18px;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.db-check-card__body section .db-btn {
  margin-top: 15px;
}

.db-check-card__body section .db-btn svg[role="img"] {
  width: 24px;
  height: 24px;
  margin-top: -3px;
  margin-bottom: -3px;
  vertical-align: -3px;
  color: #979797;
}

.db-check-card:first-child .db-check-card__body {
  padding-top: 0;
}

.db-check-card:last-child .db-check-card__body {
  padding-bottom: 0;
  border-bottom: none;
}

.db-form-inline {
  display: flex;
  align-items: center;
}

.db-form-inline label {
  margin-bottom: 0;
  margin-right: 13px;
  font-family: "Circular Std";
  font-size: 18px;
}

.db-form-inline input {
  flex: 1;
  appearance: none;
  background-color: transparent;
  border: thin solid #000;
  border-radius: 0;
  padding: 5px 10px;
  outline: none;
  overflow: hidden;
}

.db-check-rating {
  font-size: 18px;
  margin-bottom: 16px;
}

.db-check-rating input {
  appearance: none;
  width: 28px;
  height: 28px;
  border: thin solid #000;
  border-radius: 50%;
  margin-right: 15px;
}

.db-check-rating input:checked {
  background-color: #62bd89;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5.50024L4.79289 9.29314C5.18342 9.68366 5.81658 9.68366 6.20711 9.29314L14.5 1.00024' stroke='black' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

@media (min-width: 768px) {
  .db-modal-simple .modal-dialog {
    max-width: 630px;
  }
}

.db-modal-simple#modal-reminder .modal-dialog {
  max-width: 1160px;
}

.db-modal-simple#modal-reminder .db-textarea .content {
  padding: 15px;
  font-size: 16px;
  display: block;
  width: 100%;
  background-color: transparent;
  border: thin solid #000;
  resize: none;
  outline: none;
  height: calc(100vh - 400px);
  overflow-y: auto;
  text-align: left;
}

@media (min-width: 992px) {
  .db-modal-simple#modal-reminder .db-textarea .content {
    min-height: 450px;
    padding: 25px 30px 50px;
    font-size: 22px;
  }
}

.db-modal-simple .modal-content {
  background-color: #f4f2ed;
  border-radius: 0;
  border: none;
  text-align: center;
}

.db-modal-simple .modal-header {
  display: block;
  padding: 50px 15px 20px;
  border-bottom: none;
}

@media (min-width: 992px) {
  .db-modal-simple .modal-header {
    padding: 70px 25px 20px;
  }
}

.db-modal-simple .modal-header .close {
  background-color: transparent;
  padding: 0;
  margin: 0;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: thin solid #000;
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 30px;
  cursor: pointer;
  outline: none;
}

@media (min-width: 992px) {
  .db-modal-simple .modal-header .close {
    top: 25px;
    right: 25px;
  }
}

.db-modal-simple .modal-title {
  font-family: "Circular Std";
  font-size: 32px;
  font-weight: 700;
  line-height: 1.26087;
  color: #62bd89;
}

@media (min-width: 992px) {
  .db-modal-simple .modal-title {
    font-size: 46px;
  }
}

.db-modal-simple .modal-body {
  padding: 0 15px;
  font-size: 22px;
}

@media (min-width: 992px) {
  .db-modal-simple .modal-body {
    padding: 0 25px;
  }
}

.db-modal-simple .modal-footer {
  display: block;
  padding: 30px 15px;
  border-top: none;
}

@media (min-width: 992px) {
  .db-modal-simple .modal-footer {
    padding: 30px 25px 55px;
  }
}

.db-modal-simple .modal-footer .db-btn {
  min-width: 200px;
}

.db-textarea {
  position: relative;
}

.db-textarea textarea {
  display: block;
  width: 100%;
  padding: 15px 15px 40px;
  background-color: transparent;
  border: thin solid #000;
  resize: none;
  outline: none;
}

@media (min-width: 992px) {
  .db-textarea textarea {
    padding: 25px 30px 50px;
    font-size: 22px;
  }
}

.db-textarea__info {
  position: absolute;
  right: 15px;
  bottom: 15px;
  font-size: 12px;
  font-weight: 500;
  color: #979797;
}

@media (min-width: 1200px) {
  .db-classes-wrapper .container-fluid {
    padding-left: 120px;
    padding-right: 120px;
  }
}

.db-classes-wrapper__title {
  text-align: center;
}

.db-classes {
  margin-bottom: -45px;
}

@media (min-width: 1200px) {
  .db-classes {
    margin-bottom: -90px;
    margin-left: -45px;
    margin-right: -45px;
  }
  .db-classes > .col,
  .db-classes > [class*="col"] {
    padding-left: 45px;
    padding-right: 45px;
  }
}

.db-classes__item {
  margin-bottom: 45px;
}

@media (min-width: 992px) {
  .db-classes__item {
    margin-bottom: 90px;
  }
}

.db-class__header {
  margin-bottom: 40px;
}

.db-class__title {
  font-size: 24px;
  color: #62bd89;
}

@media (min-width: 992px) {
  .db-class__title {
    font-size: 32px;
  }
}

@media (min-width: 992px) {
  .db-class__description {
    font-size: 18px;
    font-weight: 500;
  }
}

@media (min-width: 576px) {
  .db-modal-tutorial .modal-dialog {
    max-width: 100%;
    margin: 1.75rem 40px;
  }
}

@media (min-width: 1600px) {
  .db-modal-tutorial .modal-dialog {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
  }
}

.db-tutorial {
  background-color: #f4f2ed;
  padding: 15px;
}

@media (min-width: 992px) {
  .db-tutorial {
    padding: 30px 60px;
  }
}

.db-tutorial__header {
  text-align: center;
  margin-bottom: 20px;
}

.db-tutorial__header img {
  width: 164px;
}

.db-tutorial-slider {
  position: static;
}

.db-tutorial-slider .owl-dots {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  left: 50%;
  bottom: 5px;
  transform: translate(-50%, 0);
}

@media (min-width: 992px) {
  .db-tutorial-slider .owl-dots {
    bottom: 20px;
  }
}

.db-tutorial-slider .owl-dots button {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 6px;
  border: thin solid #000;
  border-radius: 6px;
  margin: 0 7px;
  outline: none;
}

.db-tutorial-slider .owl-dots button.active {
  background-color: #000;
}

.db-tutorial-card__body {
  height: 50vh;
  background-color: transparent;
  overflow-y: auto;
}

@media (min-width: 992px) {
  .db-tutorial-card__body {
    height: 500px;
  }
}

.db-tutorial-card__content {
  padding: 15px;
  height: 100%;
}

@media (min-width: 992px) {
  .db-tutorial-card__content {
    padding: 30px;
  }
}

.db-tutorial-card__content img {
  height: 100%;
  width: auto !important;
  margin: 0 auto;
}

.db-tutorial-card__footer {
  font-family: "Circular Std";
  font-weight: 700;
  font-size: 20px;
  line-height: 1.42857;
  margin-top: 0px;
  text-align: center;
}

@media (min-width: 992px) {
  .db-tutorial-card__footer {
    font-size: 35px;
  }
}

.db-heading {
  background-color: #f4f2ed;
  padding: 40px 0 20px;
  text-align: center;
}

.db-heading h2 {
  margin-bottom: 0;
  font-family: "Circular Std";
  font-size: 18px;
  letter-spacing: 0.10417em;
  text-transform: uppercase;
}

.db-nav-spy {
  overflow: hidden;
  background-color: #f4f2ed;
}

@media (min-width: 992px) {
  .db-nav-spy {
    position: sticky;
    top: calc(60px + var(--offset-top));
    z-index: 2;
  }
}

.db-nav-spy ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0 40px;
  box-shadow: 0 -2px #e8e6de inset;
}

.db-nav-spy ul li a {
  display: block;
  padding: 10px 0;
  font-weight: 600;
  letter-spacing: 0.14062em;
  text-transform: uppercase;
  position: relative;
}

.db-nav-spy ul li a::after {
  content: "";
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  border-bottom: 2px solid #62bd89;
  width: 0;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.db-nav-spy ul li.active a {
  color: #62bd89;
}

.db-nav-spy ul li.active a::after {
  width: 100%;
}

#modal-tutorial .db-tutorial-slider .owl-nav {
  position: absolute;
  top: 45%;
  width: 100%;
  left: 0;
}

#modal-tutorial .db-tutorial-slider .owl-prev, #modal-tutorial .db-tutorial-slider .owl-next {
  outline: none;
  opacity: 0.4;
}

#modal-tutorial .db-tutorial-slider .owl-prev:hover, #modal-tutorial .db-tutorial-slider .owl-next:hover {
  opacity: 1;
}

#modal-tutorial .db-tutorial-slider .owl-prev {
  float: left;
  margin-left: 90px;
}

#modal-tutorial .db-tutorial-slider .owl-next {
  float: right;
  margin-right: 90px;
}

#modal-tutorial .db-tutorial-slider__text-footer {
  font-weight: 400;
  font-size: 20px;
  line-height: 133.5%;
  text-align: center;
  max-width: 70%;
  margin: 0 auto;
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  #modal-tutorial .db-tutorial-slider .owl-prev {
    margin-left: 25px;
  }
  #modal-tutorial .db-tutorial-slider .owl-next {
    margin-right: 25px;
  }
}

@media (max-width: 991px) {
  #modal-tutorial .db-tutorial-slider__text-footer {
    font-size: 18px;
    max-width: 100%;
  }
}

.db-magazines {
  gap: 1rem 0;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.db-magazines > .col,
.db-magazines > [class*="col"] {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

@media (min-width: 992px) {
  .db-magazines {
    gap: 22px 0;
    margin-left: -11px;
    margin-right: -11px;
  }
  .db-magazines > .col,
  .db-magazines > [class*="col"] {
    padding-left: 11px;
    padding-right: 11px;
  }
}

.db-magazine {
  display: block;
}

.db-magazine__image {
  padding-bottom: 139.92248%;
}

.db-magazine__image > div {
  position: absolute;
  top: 0;
  bottom: 6px;
  left: 0;
  right: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem 0;
  border: thin solid #000;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: 0.095em;
  text-transform: uppercase;
  text-align: center;
}

@media (min-width: 768px) {
  .db-magazine__image > div {
    font-size: 20px;
  }
}

.db-magazine__image > div::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-color: #605E98;
  border-style: solid;
  border-width: 0 2px 2px 0;
  position: absolute;
  top: 6px;
  right: -6px;
}

.db-magazine__image > div svg {
  width: 52.61044%;
}

.db-magazine__image > div > span {
  padding: 0 10% 0;
}
