.aw-referrals-share-page {
	max-width: 470px;

	.woocommerce-error {
		list-style: none;
		padding: 0;
		margin-bottom: 15px;

		li {
			list-style: none;
			padding: 0;

			&:before {
				display: none;
			}
		}
	}

	.aw-referrals-share-buttons {
		margin-bottom: 40px;

		a {
			width: 100%;
			padding: .84em 0 0.44em;
			margin: 0 0 10px;

			&:before {				    
			    top: -3px;
			}
		}
	}
	.referral-code {
		margin-bottom: 40px;
		text-align: center;
		font-size: 16px;
	}
}

.ven-form-row {
	.aw-referrals-share-or {
		width: 100%;
		color: white;
		text-transform: inherit;
	}

	.aw-email-referral-form {
		width: 100%;

		.ven-form-group {
			&.form-row {
				margin-bottom: 25px;
			}

			.ven-form-control {
				border-color: white;
			}
		}

		.email-button {
			margin-top: 40px;
		}
		
	}
}

.ven-referrals-share-container {
	max-width: 470px;
	margin-left: auto;
    margin-right: auto;

    .referral-title {
		font-style: normal;
		font-weight: normal;
		font-size: 32px;
		line-height: 106.9%;
		text-align: center;
		margin-bottom: 20px;
    }

    .referral-description {
    	text-align: center;
    	margin-bottom: 25px;
    	line-height: 17px;
    }

    .aw-referrals-share-buttons,
    .ven-aw-referrals-share-buttons {
    	display: flex;
	    justify-content: space-between;
	    flex-wrap: wrap;   

	    &:before,
	    &:after {
	    	display: none;
	    }

	    a {
	    	width: calc(50% - 5px);
	    	min-width: auto !important;
	    	height: 44px;
			font-style: normal;
			font-weight: 600;
			font-size: 12px !important;
			line-height: 14px !important;
			letter-spacing: 0.09em;
			text-transform: uppercase;
			padding: 15px !important;
			margin-left: 0 !important;
			margin-right: 0 !important;

			@include tablet-down {
				width: 100%;
			}
			 
	    }	    
    }

    .aw-email-referral-form {
    	.ven-form-group {
	    	.ven-form-control {
	    		border-color: rgba(0, 0, 0, 0.35);
	    	}
	    }
    }

    .email-button {
    	.ven-btn {
    		height: 52px;
    	}
    }

    .aw-referrals-share-page { 
    	.woocommerce-message {
    		margin-bottom: 15px;
    	}
    }
}

.aw-referrals-share-widget {
	margin-top: 80px;
	
	.referrals-share-widget-title {
		font-style: normal;
		font-weight: normal;
		font-size: 32px;
		line-height: 40px;
		letter-spacing: 0.02em;
		text-transform: uppercase;
		margin-bottom: 20px;
	}

	.referrals-share-widget-description {
		padding-bottom: 10px;
	}

	.aw-referrals-share-buttons {
		display: flex;
	    justify-content: space-between;
	    flex-wrap: wrap;   
	    margin: 0;

	    &:before,
	    &:after {
	    	display: none;
	    }

	    a {
	    	width: 150px;
	    	min-width: auto;
	    	height: 35px;
	    	font-style: normal;
			font-weight: 600;
			font-size: 12px;
			line-height: 14px;
			letter-spacing: 0.09em;
			text-transform: uppercase;
			padding: 10px 5px;
			margin: 0;

			&:before {
		    	display: none;
		    }

		    @include tablet-down {
				width: 100%;
				margin-bottom: 10px;

				&:last-child {
					margin-bottom: 0;
				}
			}
	    }
	}
}

.referral-message {
	margin-bottom: 40px;
}