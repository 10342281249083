.ven-checkout {
    background-color: #f4f2ed;
    padding: 60px 0 0;

    @include tablet-up {
        padding: 60px 60px 0;
    }

    &__container {
        padding: 60px 0 110px;
    }
}

.ven-checkout-block {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 30px;
    font-size: 14px;

    &.user-login{
        .login-box{
            .note{
                font-weight: 500;
                font-size: 9px;
                line-height: 140%;
                color: #33322B;
                margin: 0;
                margin-top: 10px;
            }
        }
    }
}

.ven-checkout-heading {
    font-family: $font-Cir;
    font-size: 26px;
    line-height: 1.02;
    letter-spacing: 0.06em;
    margin-bottom: 20px;
}

.ven-checkout-title {
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 0.15em;

    &:not(:first-child) {
        margin-top: 30px;
    }
}

.ven-cart-product {
    display: flex;

    &__header {
        width: 80px;
        margin-right: 15px;

        @include tablet-up {
            margin-right: 25px;
        }
    }

    &__body {
        flex: 1;
    }

    &__image {
        @include img-drop(1, 1);
    }

    &__title {
        font-size: 16px;
        margin-bottom: 7px;
    }

    &__variables {
        font-size: 12px;
        line-height: 1.02;
        margin-bottom: 14px;
    }

    &__footer {
        text-align: right;
        margin-left: 15px;

        .ven-cart-product__price {
            margin-bottom: 7px;
        }
    }
    &__remove{
        padding-right: 50px;
        a{
            font-style: italic;
            font-weight: 400;
            font-size: 12px;
            line-height: 102%;
            color: #949494;
        }

        @include mobile-down {
            padding: 0 10px;
        }
    }
}

.ven-order-list {
    font-size: 14px;

    &__item {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
    #update_cart{
        display: none;
    }
}

.ven-edit-cart {
    font-size: 12px;
}

.ven-delivery-list {
    &__item {
        &:not(:last-child) {
            margin-bottom: 10px;
        }

        > label {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 60px;
            border: thin solid #cdcdcd;
            padding: 15px;
            margin-bottom: 0;
            cursor: pointer;
        }

        > input {
            display: none;

            &:checked + label {
                border-color: #000;

                .ven-delivery-list__header {
                    &::before {
                        content: "";
                        display: block;
                        width: 11px;
                        height: 9px;
                        background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.9021L4.12245 7.0896L10 1.0896' stroke='%2333322B' stroke-width='2'/%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        position: absolute;
                        top: 4px;
                        left: 0;
                        transition: all 0.2s ease-out;
                    }
                }
            }
        }
    }

    &__header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        padding-left: 20px;
    }

    &__body {
        padding-left: 20px;
    }

    &__title {
        flex: 1;
        text-transform: uppercase;
        letter-spacing: 0.15em;
    }
}

#shipping_country_field:not(.has-select),
#billing_country_field:not(.has-select) {
    opacity: 0.3;
    cursor: not-allowed;

    .ven-form-label {
        position: static;
        font-size: 12px;
    }

    .woocommerce-input-wrapper {
        strong {
            display: block;
            padding: 8px 0;
            font-size: 14px;
            font-weight: normal;
            border-bottom: thin solid #cdcdcd;
        }
    }
}

.ven-shipping-info {
    font-size: 12px;
    margin-top: 6px;
    margin-bottom: 24px;
    text-align: center;
}

.ven-checkout-delivery {
    margin-top: 30px;
}

.ven-payment-alert {
    background-color: rgba(119, 188, 31, 0.1);
    padding: 8px 15px;
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    &::before {
        content: "";
        width: 14px;
        height: 16px;
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.7723 7.59092C12.5601 7.37878 12.3026 7.27279 11.9995 7.27279H11.636V5.09085C11.636 3.69693 11.1362 2.50007 10.1361 1.50007C9.13617 0.500022 7.93942 0 6.54531 0C5.15123 0 3.95425 0.500022 2.95436 1.50003C1.95432 2.50007 1.45446 3.69689 1.45446 5.09085V7.27279H1.09087C0.787944 7.27279 0.530311 7.37878 0.318171 7.59092C0.10603 7.8029 0 8.06054 0 8.36374V14.9091C0 15.2121 0.10607 15.4698 0.318171 15.6819C0.530311 15.8939 0.787944 16 1.09087 16H11.9997C12.3029 16 12.5603 15.894 12.7726 15.6819C12.9845 15.4698 13.0907 15.2121 13.0907 14.9091V8.36362C13.0909 8.06066 12.9845 7.80306 12.7723 7.59092ZM9.45434 7.27279H3.63628V5.09085C3.63628 4.28786 3.92042 3.60221 4.48858 3.03412C5.05682 2.46596 5.74236 2.18194 6.54543 2.18194C7.34858 2.18194 8.03395 2.46592 8.60223 3.03412C9.17024 3.60217 9.45434 4.28786 9.45434 5.09085V7.27279Z' fill='%2377BC1F'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-right: 14px;
    }

    &__inner {
        flex: 1;
    }
}

.ven-payment-block {
    margin-bottom: 32px;
}

.ven-payment {
    &__item {
        &:not(:last-child) {
            margin-bottom: 15px;
        }

        > label {
            display: block;
            border: thin solid #cdcdcd;
            overflow: hidden;
            margin-bottom: 0;
        }

        > input {
            display: none;

            &:checked + label {
                border-color: #000;

                .ven-payment__title {
                    padding-left: 20px;

                    &::before {
                        transform: scale(1);
                    }
                }

                .ven-payment__body {
                    display: block;
                }
            }
        }

        &.payment_method_afterpay {
            .ven-payment__brand {
                img {
                    display: none;
                }

                &::before {
                    content: "";
                    display: inline-block;
                    vertical-align: middle;
                    width: 110px;
                    height: 20px;
                    background: url(../img/woocommerce/afterpay.svg) no-repeat;
                    background-size: 100% 100%;
                }
            }
        }

        &.payment_method_paypal,
        &.payment_method_ppec_paypal,
        &.payment_method_yith-paypal-ec {
            .ven-payment__brand {
                img,
                .about_paypal {
                    display: none;
                }

                &::before {
                    content: "";
                    display: inline-block;
                    vertical-align: middle;
                    width: 82px;
                    height: 20px;
                    background: url(../img/woocommerce/paypal.svg) no-repeat;
                    background-size: 100% 100%;
                }
            }

            .ven-payment__body {
                display: none !important;
            }
        }
    }

    &__header {
        display: flex;
        align-items: center;
        padding: 30px 15px;
    }

    &__body {
        padding: 20px 15px;
        display: none;
    }

    &__title {
        flex: 1;
        text-transform: uppercase;
        position: relative;
        transition: all 0.3s ease-in-out;

        &::before {
            content: "";
            display: block;
            width: 11px;
            height: 9px;
            background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.9021L4.12245 7.0896L10 1.0896' stroke='%2333322B' stroke-width='2'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            position: absolute;
            top: 4px;
            left: 0;
            transform: scale(0);
            transition: all 0.2s ease-out;
        }
    }

    &__brand {
        font-size: 0;

        .stripe-icon {
            height: 20px;
            margin-left: 1px;
        }
    }
}

#stripe-payment-data {
    > p:first-child {
        display: none;
    }
}

.wc-saved-payment-methods {
    > li {
        margin-bottom: 15px;

        label {
            display: block !important;
            width: 100%;
            margin-bottom: 0;
            padding: 15px;
            border: thin solid #cdcdcd;
            position: relative;
        }

        input {
            display: none;

            &:checked + label {
                border-color: #000;
            }
        }
    }
}

.wc-credit-card-form {
    .clear {
        clear: both;
    }

    .form-row {
        margin-bottom: 20px;

        label {
            font-size: 10px;
            margin-bottom: 0;
        }

        &-first {
            float: left;
            width: calc(50% - 15px);
        }

        &-last {
            float: right;
            width: calc(50% - 15px);
        }
    }

    .wc-stripe-elements-field {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 36px;
        border-color: #cdcdcd;
        border-style: solid;
        border-width: 0 0 thin;
        margin: 0;

        &.focused {
            border-color: #9d9d9d;
        }

        &.invalid {
            border-color: $red;
        }
    }
}

.woocommerce-SavedPaymentMethods-saveNew {
    @extend %form-custom-ctr;
}

.ven-coupon {
    position: relative;

    input {
        display: block;
        width: 100%;
        border-radius: 0;
        background-color: transparent;
        border-color: #000;
        border-style: solid;
        border-width: 0 0 thin;
        padding: 8px 32px 8px 0;
        outline: none;

        @include tablet-up {
            font-size: 14px;
        }
    }

    button {
        width: 23px;
        height: 23px;
        background-color: transparent;
        border-radius: 0;
        border: none;
        padding: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='11.5' cy='11.5' r='11.5' fill='%23161616'/%3E%3Cpath d='M5 12H16.5' stroke='white' stroke-width='1.5'/%3E%3Cpath d='M13 8L17 12L13 16' stroke='white' stroke-width='1.5'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        font-size: 0;
        position: absolute;
        top: 6px;
        right: 0;
    }
}

.ven-order-summary {
    font-size: 14px;

    dl {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        // &:last-child {
        //     margin-bottom: 0;
        // }
    }

    dt,
    dd {
        font-weight: normal;
        margin: 0;
    }
}

.ven-divider {
    border-bottom: thin solid #000;
    margin: 25px 0;
}

.ven-order-total {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    font-size: 18px;

    dt,
    dd {
        font-weight: 500;
        margin: 0;
    }

    dd {
        font-size: 20px;
    }
}

.ven-order-info {
    padding: 15px 0;
    border-color: #000;
    border-style: solid;
    border-width: thin 0;
    font-size: 14px;
    line-height: (18/14);
    margin-top: 15px;
    margin-bottom: 15px;

    strong {
        font-weight: 500;
        color: $green;
    }
}

.ven-checkout-subcribe {
    margin-top: 30px;
}

.ven-checkout-submit {
    margin-top: 20px;

    button,a {
        background-color: $green;
        border: none;
        border-radius: 0;
        padding: 8px 15px;
        font-size: 14px;
        text-transform: uppercase;
        text-align: center;
        color: #fff;
        width: 100%;
        cursor: pointer;
        display: block;

        &:hover {
            background-color: $green-2;
        }
        &.disabled{
            background: #ddd;
        }
    }
}

#kl_newsletter_checkbox_field{
    display: none;
}
