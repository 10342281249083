.ven-h {
    font-family: $font-Cir;
    font-weight: 700;
    line-height: 1.2;
}

.ven-h2 {
    font-size: 36px;

    @include tablet-up {
        font-size: 64px;
    }
}

.ven-h5 {
    font-size: 16px;
    letter-spacing: (2/16 + em);
    text-transform: uppercase;
}

.ven-bg-light {
    background-color: #f4f2ed;
}

.ven-bg-bluelight {
    background-color: #e7e9f5;
}

.ven-bg-greenlight {
    background-color: #dff4eb;
}

.ven-bg-purplelight {
    background-color: #d7d3ea;
}
